// @flow
'use strict';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Utils from '../../utils';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { getUser } from '../../redux/reducers';

import { Menu, MenuItem, Button } from '@material-ui/core';
import { PersonOutline as Person } from '@material-ui/icons';
import { logout } from '../../redux/actions';
import { GA_EVENTS, useTrackEvents } from '../../ga/analytics';

// import { receiveUserIdAndToken } from '../../redux/actions';

const useStyles = makeStyles(() => ({
    button: {
        height: '100%',
        padding: 0,
    },
    white: {
        color: 'white',
    },
    menuItem: {
        backgroundColor: '#1C8DE6',
        color: 'white',
    },
    loginButton: {
        paddingLeft: '10px',
        paddingRight: '10px',
        color: 'white',
        fontSize: '16px',
        verticalAlign: 'middle',
    },
}));

declare type ProfileButtonProps = {
    handleOpenImpersonateModal: () => void,
};

export function ProfileButton(props: ProfileButtonProps) {
    const { handleOpenImpersonateModal } = props;
    const classes = useStyles();
    const user = useSelector(getUser);

    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const loginRedirectUrl = Utils.getLoginRedirectUrl();
    const logoutUrl = Utils.getLogoutUrl();
    const userExists = user && !Utils.isEmpty(user._id);
    const trackEvent = useTrackEvents();
    const switchBack = () => {
        dispatch(logout());
    };
    if (!userExists) {
        return (
            <Button
                className={classes.loginButton}
                onClick={() => {
                    window.location.href = loginRedirectUrl;
                }}
                underline='none'
            >
                Login
            </Button>
        );
    }

    return (
        <>
            <div>
                <Button aria-label='Menu' aria-owns={open ? 'long-menu' : undefined} aria-haspopup='true' onClick={(e) => setAnchorEl(e.currentTarget)} className={classes.button}>
                    <Person fontSize='large' className={classes.white} />
                </Button>
                <Menu id='long-menu' anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={() => setAnchorEl(null)} classes={{ paper: classes.menuItem }}>
                    {user && user.primaryContact ? (
                        <MenuItem onClick={() => setAnchorEl(null)} className={classes.menuItem}>
                            <Link className={classes.menuItem} to={'/schoolAdmin/permissions'}>
                                School Admin
                            </Link>
                        </MenuItem>
                    ) : null}
                    {user && user.nboaAdmin ? (
                        <MenuItem
                            onClick={() => {
                                handleOpenImpersonateModal(true);
                                setAnchorEl(null);
                            }}
                            className={classes.menuItem}
                        >
                            <Button className={classes.white}>Impersonation</Button>
                        </MenuItem>
                    ) : null}
                    {user.impersonating ? (
                        <MenuItem onClick={() => setAnchorEl(null)} className={classes.menuItem}>
                            <Button onClick={switchBack} className={classes.white}>
                                Switch Back
                            </Button>
                        </MenuItem>
                    ) : (
                        <MenuItem onClick={() => setAnchorEl(null)} className={classes.menuItem}>
                            <Button
                                onClick={() => {
                                    trackEvent(GA_EVENTS.SECURITY.EVENTS.LOG_OUT, GA_EVENTS.SECURITY);
                                    window.location.href = logoutUrl;
                                }}
                                className={classes.white}
                            >
                                Logout
                            </Button>
                        </MenuItem>
                    )}
                </Menu>
            </div>
        </>
    );
}

export default ProfileButton;
