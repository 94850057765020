// @flow
'use strict';

import parser from 'html-react-parser';
import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Exporting from 'highcharts/modules/exporting';
// import ChartComp from '../ChartComp';
import styles from './dashboardItem.scss';

import { formatNumericValueAsString } from '../../utils';
import { dashboardChartOptions } from '../../highCharts';
import { useState, useEffect, useRef } from 'react';
// import { DashboardContext } from '../../views/Dashboards';

const useStyles = makeStyles(() => ({
    card: {
        // height: '100%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
    },
    graph: {
        // width: '462px',
        // height: '250px',
        flexGrow: 0.9,
        flexBasis: '50%',
    },
    graphItemTitle: {
        color: 'black',
        textAlign: 'left',
        padding: 0,
        marginTop: '5px',
        marginLeft: '10px',
    },
    numberCard: {
        height: '100%',
        textAlign: 'center',
        margin: 'auto',
        padding: '4px',
        alignContent: 'unset',
        paddingBottom: 0,
    },
    numberHeader: {
        alignContent: 'unset',
        height: '33%',
        fontSize: '16px',
    },
    number: {
        height: '66%',
        fontSize: '40px',
    },
    numberWrapper: {
        padding: 0,
    },
    graphItemGraph: {
        height: '100%',
    },
    graphItemSubheader: {
        fontSize: '12px',
    },
    graphItemHeaderTitle: {
        fontSize: '90%',
    },
}));

type PropTypes = {|
    className?: string,
    style?: Object,
    item: DashboardItemType,
|};

Exporting(Highcharts);
export function DashboardItem(props: PropTypes) {
    const [chartOptions, setChartOptions] = useState({});
    const { className = '', item, style = {} } = props;
    const chart = useRef();

    const classes = useStyles();
    const convertDataForHighCharts = (dashboardOptions) => {
        setChartOptions(dashboardOptions);
    };

    useEffect(() => {
        if (item && item.type === 'graph' && chart.current) {
            const dashboardOptions = dashboardChartOptions(item);
            convertDataForHighCharts(dashboardOptions);
        }
    }, [item]);

    const renderNumericComponent = (item: DashboardItemNumericType) => {
        const format = item.unit === 'year' ? 'year' : item.format || 'integer';
        const unit = item.unit === 'year' ? 'raw' : item.unit || 'raw';
        let number: string = formatNumericValueAsString(item.value, format, unit, item.decimalPlaces);
        return (
            <Card raised={true} className={classes.numberCard + ' ' + styles.numberCard + ' ' + className}>
                <CardHeader
                    classes={{
                        title: classes.numberHeader,
                    }}
                    className={classes.numberHeader + ' ' + styles.numberCardHeader}
                    title={parser(item.title)}
                />
                <CardContent
                    classes={{
                        root: classes.numberWrapper,
                    }}
                >
                    <Typography className={classes.number + ' ' + styles.numberCardNumber} component='div'>
                        {number}
                    </Typography>
                </CardContent>
            </Card>
        );
    };

    const renderGraphComponent = (item: ReportDataType) => {
        const data: ?ChartDataType = item.data
            ? {
                  ...item.data,
              }
            : undefined;
        if (!data) {
            return;
        }

        data.datasets = data.datasets.map(function (oDataset) {
            let dataset = {
                ...oDataset,
            };
            if (dataset.color) {
                dataset.backgroundColor = dataset.color;
                dataset.borderColor = dataset.color;
            }
            if (dataset.data) {
                dataset.data = dataset.data.map((d) => {
                    const format = item.unit === 'year' ? 'year' : item.format || 'integer';
                    const unit = item.unit === 'year' ? 'raw' : item.unit || 'raw';
                    const intermValue = formatNumericValueAsString(d, format, unit, item.decimalPlaces).replace(/[^0-9.-]/g, '');
                    if (item.format === 'float') {
                        return unit === 'raw' && d !== null ? d.toFixed(item.decimalPlaces) : parseFloat(intermValue);
                    } else {
                        return parseInt(intermValue);
                    }
                });
            }
            return dataset;
        });
        if (!style.width) {
            style.width = '100%';
        }
        return (
            <Card raised={true} style={style} className={classes.card + ' ' + className + ' graph-component'} dataId={item._id}>
                <CardHeader
                    className={classes.graphItemTitle}
                    classes={{
                        title: classes.graphItemHeaderTitle,
                        subheader: classes.graphItemSubheader,
                    }}
                    title={parser(item.title || '')}
                    subheader={parser(item.subtitle || '')}
                />
                <CardContent className={classes.graph}>
                    <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chart} containerProps={{ className: classes.graphItemGraph }} />
                </CardContent>
            </Card>
        );
    };

    const renderChart = () => {
        switch (item.type) {
            case 'number':
                return renderNumericComponent(item);
            case 'graph':
                // $FlowFixMe
                return renderGraphComponent(item);
            default:
                return <div />;
        }
    };

    return <span>{renderChart()}</span>;
}

export default DashboardItem;
