// @flow
'use strict';

import { useState } from 'react';
import { connect } from 'react-redux';
import SideBarSections from '../SideBarSections';
import {
    Drawer,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Checkbox,
    FormControlLabel,
    CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ProgressBar from '../ProgressBar';

import {
    getSelectedYear,
    getSelectedSchoolId,
    getDataPointsStatus,
    getDataPointSectionData,
    getRequiredCounts,
    isDisplayingUnanswered,
    isNboaAdmin,
    isOnDataCommittee,
} from '../../redux/reducers';

import { setDisplayingUnanswered, updateDataPointStatus } from '../../redux/actions';

const useStyles = makeStyles(() => ({
    overallWrapper: {
        minWidth: '370px',
        '@media print': {
            display: 'none',
        },
    },
    drawerPaper: {
        position: 'static',
        backgroundColor: '#A7A6A6',
        overflow: 'hidden',
    },
    drawer: {
        flexShrink: 0,
        height: '100%',
    },
    button: {
        fontFamily: 'sans-serif',
        margin: '3px 15px',
    },
    panel: {
        backgroundColor: '#A7A6A6',
        margin: '0 !important',
    },
    panelSummary: {
        fontSize: '16px !important',
        minHeight: '36px !important',
        color: '#ddd',
    },
    selectedSection: {
        fontSize: '18px !important',
        minHeight: '36px !important',
        color: 'white',
        fontWeight: 'bold',
    },
    summaryContent: {
        margin: '6px 0 !important',
    },
    panelDetail: {
        fontSize: '14px !important',
        padding: 0,
    },
    panelExpansionIcon: {
        padding: 0,
        color: '#eee',
    },
    subSection: {
        textAlign: 'left',
        textTransform: 'capitalize',
        padding: '0 8px',
        color: '#eee',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    selectedSubsection: {
        textAlign: 'left',
        textTransform: 'capitalize',
        padding: '0 8px',
        color: '#203480',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    successContent: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        paddingTop: '50px !important',
    },
    successContentText: {
        marginBottom: '0 !important',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '20px',
    },
    successDialogAction: {
        justifyContent: 'center',
    },
    circularProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-9px',
        marginLeft: '-8px',
        width: '20px !important',
        height: '20px !important',
    },
    sideBarWrapper: {
        height: '100%',
        width: '100%',
        marginTop: '8px',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    bottomWrapper: {
        marginBottom: '8px',
    },
    submitOverrideWrapper: {
        display: 'flex',
        height: '25px',
        marginTop: '-8px',
        marginLeft: '15px',
    },

    progressBar: {
        marginBottom: '10px',
    },
    btnWrapper: {
        display: 'flex',
        flexdirection: 'column',
        justifyContent: 'center',
    },
    confirmWrapper: {
        position: 'relative',
    },

    sectionListWrapper: {
        overflowY: 'auto',
    },
}));

type ReduxReducerProps = {|
    isAdminOrScrubber: boolean,
    status: ?DataPointStatusType,
    year: YearNumberType,
    selectedSchoolId: ?string,
    sectionData: ?SectionDataType,
    isDisplayingUnanswered: boolean,
    requiredCounts: ?RequiredCountsType,
|};

type ReduxActionProps = {|
    setDisplayingUnanswered: (unanswered: boolean) => void,
    updateDataPointStatus: (schoolId: string, status: DataPointStatusUpdateType, year: YearNumberType) => Promise<void>,
|};

type OwnProps = {|
    hideSubsections?: boolean,
    hideProgressBar?: boolean,
    setSelectedSectionId: (selectedSectionId: string) => void,
    setSelectedSubsectionId: (selectedSubsectionId: string) => void,
|};

type Props = {
    requiredCounts: ?RequiredCountsType,
    ...ReduxReducerProps,
    ...ReduxActionProps,
    ...OwnProps,
};

export function SideBar(props: Props) {
    const {
        setSelectedSectionId,
        setSelectedSubsectionId,
        hideSubsections,
        year,
        status,
        selectedSchoolId,
        isAdminOrScrubber,
        updateDataPointStatus,
        isDisplayingUnanswered,
        setDisplayingUnanswered,
        sectionData,
        hideProgressBar,
        requiredCounts,
    } = props;
    const classes = useStyles();
    let [overrideSubmit, setOverrideSubmit] = useState(false);
    let [dialogWindow, setDialogWindow] = useState();
    let [successDialogWindow, setSuccessDialogWindow] = useState();
    let [forUnsubmit, setForUnsubmit] = useState();
    let [updatedStatus, setUpdatedStatus] = useState();

    const handleOverrideChange: (event: SyntheticInputEvent<*>) => void = (event) => {
        setOverrideSubmit(event.target.checked);
    };

    const renderSubmitOverride: () => React$Element<*> = () => {
        return (
            <div key='submitoverride' className={classes.submitOverrideWrapper} id='submitOverrideWrapper'>
                <FormControlLabel
                    control={<Checkbox checked={overrideSubmit} onChange={handleOverrideChange} />}
                    label='Override Submit'
                />
            </div>
        );
    };

    const getConfirmText: (isAdminOrScrubber: boolean, status: string) => ?string = (isAdminOrScrubber, status) => {
        switch (status) {
            case 'Submitted':
                return isAdminOrScrubber ? 'Mark Reviewed' : 'Undo Submission';
            case 'Reviewed':
                return isAdminOrScrubber ? 'Undo Review' : 'Undo Submission';
            case 'Saved':
                return 'Submit';
        }
    };

    const getConfirmClickFunction: (forUnsubmit?: boolean) => void = (forUnsubmit) => {
        if (isAdminOrScrubber && status === 'Reviewed' && !forUnsubmit) {
            setUpdatedStatus('Unreview');
        } else if (isAdminOrScrubber && status === 'Submitted' && !forUnsubmit) {
            setUpdatedStatus('Review');
        } else if (status === 'Submitted' && forUnsubmit) {
            setUpdatedStatus('Unsubmit');
        } else if (status !== 'Submitted') {
            setUpdatedStatus('Submit');
        }
        setForUnsubmit(forUnsubmit);
        setDialogWindow('open');
    };

    const renderSuccessTitle = () => {
        if (isAdminOrScrubber && status === 'Reviewed') {
            return 'Data marked reviewed!';
        } else if (isAdminOrScrubber && status === 'Submitted' && successDialogWindow === 'Reviewed') {
            return 'Data marked as not reviewed!';
        } else if (status === 'Saved' && forUnsubmit) {
            return 'Data successfully unsubmitted!';
        } else if (status === 'Submitted' && successDialogWindow === 'Saved') {
            return 'Data successfully submitted!';
        }
    };

    const renderText = () => {
        if (isAdminOrScrubber && status === 'Reviewed' && !forUnsubmit) {
            return 'Are you sure you would like to mark the data as not reviewed?';
        } else if (isAdminOrScrubber && status === 'Submitted' && !forUnsubmit) {
            return 'Are you sure you would like to mark the data as reviewed?';
        } else if (status === 'Submitted' && forUnsubmit) {
            return 'Are you sure you would like to unsubmit the data?';
        } else if (status !== 'Submitted') {
            return 'Are you sure you would like to submit the data?';
        }
    };

    const renderTitle = () => {
        if (isAdminOrScrubber && status === 'Reviewed' && !forUnsubmit) {
            return 'Confirm Unreview';
        } else if (isAdminOrScrubber && status === 'Submitted' && !forUnsubmit) {
            return 'Confirm Review';
        } else if (status === 'Submitted' && forUnsubmit) {
            return 'Confirm Unsubmit';
        } else if (status !== 'Submitted') {
            return 'Confirm Submission';
        }
    };

    const renderButtons: () => ?React$Element<'div'> = () => {
        if (!status || !requiredCounts) {
            return;
        }
        let content = [];
        if ((status === 'Submitted' || status === 'Reviewed') && !isAdminOrScrubber) {
            return;
        }
        const completed = requiredCounts.answered === requiredCounts.required;
        if (!completed && isAdminOrScrubber) {
            content.push(renderSubmitOverride());
        }
        content.push(
            <Button
                variant='contained'
                key='button1'
                id='submitButton'
                className={classes.button}
                disabled={!completed && !overrideSubmit}
                onClick={() => getConfirmClickFunction()}
            >
                {getConfirmText(isAdminOrScrubber, status) || 'Submit'}
            </Button>,
        );
        if (isAdminOrScrubber && status === 'Submitted') {
            content.push(
                <Button
                    className={classes.button}
                    variant='contained'
                    key='button2'
                    onClick={() => getConfirmClickFunction(true)}
                >
                    Unsubmit
                </Button>,
            );
        }
        return <div className={classes.btnWrapper}>{content}</div>;
    };

    const renderFooter: () => React$Element<'div'> = () => {
        return (
            <div id='jr-bottomWrapper' className={classes.bottomWrapper}>
                {!requiredCounts ? null : (
                    <ProgressBar
                        id='progressBar'
                        className={classes.progressBar}
                        onLabelClick={() => setDisplayingUnanswered(!isDisplayingUnanswered)}
                        labelText={() =>
                            requiredCounts.answered === requiredCounts.required
                                ? 'Complete'
                                : isDisplayingUnanswered
                                    ? requiredCounts.required - requiredCounts.answered + ' Remaining'
                                    : requiredCounts.answered + ' / ' + requiredCounts.required
                        }
                        labelHelpText={
                            isDisplayingUnanswered
                                ? 'Click to show all questions.'
                                : 'Click to show unanswered questions.'
                        }
                        count={requiredCounts.answered}
                        total={requiredCounts.required}
                    />
                )}
                {renderButtons()}
            </div>
        );
    };

    const handleConfirm: () => void = () => {
        setDialogWindow('updating');
        if (!selectedSchoolId || !updatedStatus || !year) {
            return;
        }
        updateDataPointStatus(selectedSchoolId, updatedStatus, year)
            .then(() => setDialogWindow(undefined))
            .finally(() => setSuccessDialogWindow(status));
    };

    if (!sectionData) {
        return <div />;
    }
    
    return (
        <div className={classes.overallWrapper}>
            <Drawer
                className={classes.drawer}
                classes={{ paper: classes.drawerPaper }}
                variant='persistent'
                anchor='left'
                open={true}
            >
                <div id='sidebar' className={classes.sideBarWrapper}>
                    <SideBarSections
                        hideSubsections={hideSubsections}
                        setSelectedSectionId={setSelectedSectionId}
                        setSelectedSubsectionId={setSelectedSubsectionId}
                    />
                    {hideProgressBar ? null : renderFooter()}
                </div>
            </Drawer>
            <Dialog open={!!dialogWindow}>
                <DialogTitle>{renderTitle()}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{renderText()}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className={classes.confirmWrapper}>
                        <Button
                            className={classes.button}
                            color='primary'
                            variant='outlined'
                            onClick={handleConfirm}
                            disabled={dialogWindow === 'updating'}
                            id='confirmButton'
                        >
                            Confirm
                        </Button>
                        {dialogWindow === 'updating' ? <CircularProgress className={classes.circularProgress} /> : null}
                    </div>
                    <Button
                        className={classes.button}
                        color='secondary'
                        variant='outlined'
                        onClick={() => {
                            setDialogWindow(undefined);
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={!!successDialogWindow}>
                <DialogContent className={classes.successContent}>
                    <DialogContentText className={classes.successContentText}>{renderSuccessTitle()}</DialogContentText>
                </DialogContent>
                <DialogActions className={classes.successDialogAction}>
                    <Button
                        className={classes.button}
                        variant='outlined'
                        onClick={() => setSuccessDialogWindow(undefined)}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
/* istanbul ignore next */
const mapStateToProps: (state: ReduxStateType) => ReduxReducerProps = (state) => {
    return {
        status: getDataPointsStatus(state),
        year: getSelectedYear(state),
        requiredCounts: getRequiredCounts(state),
        sectionData: getDataPointSectionData(state),
        isDisplayingUnanswered: isDisplayingUnanswered(state),
        selectedSchoolId: getSelectedSchoolId(state),
        isAdminOrScrubber: isNboaAdmin(state) || isOnDataCommittee(state),
    };
};
/* istanbul ignore next */
const mapDispatchToProps: (dispatch: GenericDispatch, ownProps: OwnProps) => ReduxActionProps = (dispatch) => {
    return {
        setDisplayingUnanswered: (unanswered: boolean) => {
            dispatch(setDisplayingUnanswered(unanswered));
        },
        updateDataPointStatus: (schoolId: string, status: DataPointStatusUpdateType, year: YearNumberType) => {
            return dispatch(updateDataPointStatus(schoolId, status, year));
        },
    };
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(SideBar);
