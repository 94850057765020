export default {
    _id: 'financial',
    pages: [
        {
            rows: [
                {
                    items: [
                        {
                            _id: '5bf42f9e52239754a7da0b5d',

                            height: 150,
                            width: 250,
                            x: 20,
                            y: 30,
                        },
                        {
                            _id: '5bf4717770e8b1247168c142',

                            height: 150,
                            width: 250,
                            x: 300,
                            y: 30,
                        },
                    ],
                },
                {
                    items: [
                        {
                            _id: '5bf493b6812defbab630e67c',

                            height: 150,
                            width: 250,
                            x: 20,
                            y: 250,
                        },
                        {
                            _id: '5bf498f4812defbab630e67d',

                            height: 150,
                            width: 250,
                            x: 300,
                            y: 250,
                        },
                    ],
                },
                {
                    items: [
                        {
                            _id: '5c335d64fb14d35e229a753e',

                            height: 170,
                            width: 250,
                            x: 20,
                            y: 30,
                        },
                        {
                            _id: '5c33675670fd25d6cfce8e73',

                            height: 170,
                            width: 250,
                            x: 300,
                            y: 30,
                        },
                    ],
                },
            ],
        },
        {
            rows: [
                {
                    items: [
                        {
                            _id: '5c3381d870fd25d6cfce8e74',

                            height: 150,
                            width: 250,
                            x: 20,
                            y: 30,
                        },
                        {
                            _id: '5c33832f70fd25d6cfce8e75',

                            height: 150,
                            width: 250,
                            x: 300,
                            y: 30,
                        },
                    ],
                },
                {
                    items: [
                        {
                            _id: '5c33c8408825dbc1ff021226',

                            height: 150,
                            width: 250,
                            x: 20,
                            y: 250,
                        },
                        {
                            _id: '5c3385d370fd25d6cfce8e77',

                            height: 150,
                            width: 250,
                            x: 300,
                            y: 250,
                        },
                    ],
                },
                {
                    items: [
                        {
                            _id: '5c33d6a066b1c76dfbdc9743',

                            height: 150,
                            width: 250,
                            x: 20,
                            y: 30,
                        },
                        {
                            _id: '5c339ab670fd25d6cfce8e79',

                            height: 150,
                            width: 250,
                            x: 300,
                            y: 30,
                        },
                    ],
                },
            ],
        },
    ],
};
