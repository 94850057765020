// @flow
'use strict';

import axios from 'axios';
import * as Utils from '../utils';

const Constants = Utils.Constants;

const BaseUrl = Constants.apiBaseUrl + 'associationschools';

const AssociationSchools = {};

const loadSchools: () => Promise<Array<any>> = () => {
    return axios
        .get(BaseUrl, {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Data returned from Api');
            }
            return response.data;
        });
};

AssociationSchools.loadSchools = loadSchools;

const getUserInformation: (userId: string) => Promise<any> = (userId) => {
    return axios
        .get(BaseUrl + '/user/' + userId, {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Data returned from Api');
            }
            return response.data;
        });
};

AssociationSchools.getUserInformation = getUserInformation;

const getSchoolInformation: (val: string, byName: boolean) => Promise<any> = (val, byName) => {
    return axios
        .get(`${BaseUrl}/school/${val}/${byName ? 'name' : 'id'}`, {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Data returned from Api');
            }
            return response.data;
        });
};

AssociationSchools.getSchoolInformation = getSchoolInformation;

export default AssociationSchools;
