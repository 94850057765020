// @flow
'use strict';

import { Component } from 'react';
import { MenuItem, Select, withStyles } from '@material-ui/core';

const TIMEOUT = 1000;

const inlineStyles = {
    main: {
        width: '100%',
    },
};
type PropTypes = {|
    choices: Array<string>,
    editable: boolean,
    value: string,
    className?: string,
    name: string,
    year: YearNumberType,
    multiple: boolean,
    onChange: (dataPointId: string, year: string, answer: string) => void,
    classes: { [$Keys<typeof inlineStyles>]: string },
|};

type StateType = {|
    value: string,
|};

export class BufferedDropdown extends Component<PropTypes, StateType> {
    timeout: ?TimeoutID;

    constructor(props: PropTypes) {
        super(props);
        this.state = {
            value: props.value,
        };
    }

    onChange: (event: SyntheticInputEvent<*>, child: Object) => void = (event) => {
        if (!Array.isArray(event)) {
            this.setState({
                value: event.target.value,
            });
        } else {
            this.setState({
                value: event.map((o) => o.value).join(','),
            });
        }
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(this.onTimeout, TIMEOUT);
    };


    onTimeout: () => void = () => {
        if (this.props.onChange) {
            this.props.onChange(
                this.props.name.substring(0, this.props.name.indexOf('-')),
                this.state.value,
                '' + this.props.year,
            );
        }
    };

    render() {
        const { className, multiple, classes, choices, name, editable } = this.props;

        let mainClassName = classes.main;
        if (className) {
            mainClassName += ' ' + className;
        }
        let value = this.state.value || '';
        if (multiple) {
            let tValue = [];
            if (this.state.value) {
                if (Array.isArray(this.state.value)) {
                    tValue = this.state.value.join(',');
                } else {
                    tValue = this.state.value;
                }
                value = tValue.split(',').filter((v) => v && v !== '');
            } else {
                value = tValue;
            }
        }
        const options = [{ value: '', label: '' }].concat(
            choices.map(function(choice) {
                return { label: choice, value: choice };
            }),
        );
        return (
            <Select
                className={mainClassName}
                multiple={multiple}
                name={name}
                disabled={!editable}
                value={value}
                onChange={this.onChange}
                options={options}
            >
                {options.map(function(option) {
                    return (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    );
                })}
            </Select>
        );
    }
}

export default withStyles(inlineStyles)(BufferedDropdown);
