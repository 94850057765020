// @flow
'use strict';

import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button,
    Input,
    MenuItem,
    Select,
    Typography,
    Tooltip,
    AppBar,
    Tabs,
    Tab,
    Toolbar,
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText,
    DialogTitle,
    FormControl,
    CircularProgress,
} from '@material-ui/core';
import {
    CalendarToday,
    Edit,
    SaveAlt,
    ImportExport,
    // $FlowFixMe -- missing from flow-typed definitions
    // EmojiSymbols,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import Ratios from '../Ratios';
import DataEntryCopy from '../DataEntryCopy';

import SchoolSelect from '../SchoolSelect';
import { saveAs } from 'file-saver';
import Api from '../../api';

import { canEdit as rCanEdit, isAdmin, getSelectedSchool, getSelectedYear, getDataPointsStatus, canChangeEdit as rCanChangeEdit, getDataPointFilter } from '../../redux/reducers';

import { setSelectedFilter, selectYear as rSelectYear, setEditDataPoints as rSetEditDataPoints } from '../../redux/actions';
import { possibleYears } from '../../utils/constants';

const useStyles = makeStyles(() => ({
    spacer: {
        flexGrow: 1,
    },
    toolbar: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        margin: 0,
        minHeight: '48px',
    },
    white: {
        color: 'white',
    },
    importButton: {
        width: 140,
        height: '36px !important',
    },
}));

const isCSVFile = (file?: File) => {
    return !!file; // file && (file.type === 'text/csv' || (file.type === '' && file.name.endsWith('.csv')));
};

type Props = {|
    dataPointFilters: Array<DataPointFilterType>,
|};

export function DataEntryHeader({ dataPointFilters }: Props) {
    const selectedSchool = useSelector(getSelectedSchool);
    const selectedYear = useSelector(getSelectedYear);
    const canEdit = useSelector(rCanEdit);
    const admin = useSelector(isAdmin);
    const status = useSelector(getDataPointsStatus);
    const canChangeEdit = useSelector(rCanChangeEdit);
    const selectedTab = useSelector(getDataPointFilter);
    const dispatch = useDispatch();
    const selectYear = (year: string) => dispatch(rSelectYear(year));
    const setEditDataPoints = (edit: boolean) => dispatch(rSetEditDataPoints(edit));
    const setFilter = (filter) => dispatch(setSelectedFilter(filter));
    const classes = useStyles();

    const [showingImportExport, showImportExport] = useState(false);
    const [showingFileTypeError, setShowFileTypeError] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [loading, setLoading] = useState(false);
    const [showRatios, setShowRatios] = useState(false);
    let filters = [
        {
            name: 'Main Data',
            id: '',
        },
    ];
    if (dataPointFilters) {
        filters = filters.concat(dataPointFilters);
    }

    const handleTabChange = (e, value) => {
        if (value !== selectedTab) {
            setFilter(value);
        }
    };

    const processImport: (event: SyntheticInputEvent<*>) => void = () => {
        if (selectedSchool && selectedFile) {
            setLoading(true);
            Api.Schools.uploadCSV(selectedFile, selectedSchool._id).then(function () {
                setLoading(false);
                showImportExport(false);
                selectYear('' + selectedYear);
            });
            return;
        }
    };

    const handleFileChange: (event: SyntheticInputEvent<*>) => void = (event) => {
        const file = event.target.files[0];
        if (isCSVFile(file)) {
            setSelectedFile(event.target.files[0]);
            return;
        }
        setShowFileTypeError(true);
        return;
    };

    const downloadData: () => void = () => {
        if (!selectedSchool) {
            return;
        }
        Api.Schools.downloadData(selectedSchool._id).then(function (csvData) {
            let blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
            const name = selectedSchool.name;
            saveAs(blob, name.replace(/ /g, '_').substring(0, name.length > 16 ? 16 : name.length) + '.csv');
        });
    };

    const handleDownload: (event: SyntheticInputEvent<*>) => void = (event) => {
        event.preventDefault();
        if (admin) {
            return showImportExport(true);
        }
        if (!selectedSchool) {
            return;
        }
        downloadData();
    };

    if (!dataPointFilters) {
        return <div />;
    }

    const uCanChangeEdit = admin || (canChangeEdit && status !== undefined && status !== 'Reviewed' && status !== 'Submitted');
    return (
        <AppBar position='relative' color='secondary'>
            <Toolbar className={classes.toolbar}>
                <Tabs id='mainDataFps' value={selectedTab === '' ? 'Main Data' : selectedTab} onChange={handleTabChange} indicatorColor='primary'>
                    {(filters || []).map(function (filter) {
                        return <Tab key={filter.id} label={filter.name} value={filter.name} />;
                    })}
                </Tabs>
                <Typography className={classes.spacer}> </Typography>
                {/* {!isAdmin ? null : (
                    <Tooltip title={canEdit ? 'FPS Ratios' : 'FPS Ratios'}>
                        <div id='editButtonId'>
                            <Button className={classes.white} onClick={() => setShowRatios(true)}>
                                <EmojiSymbols />
                            </Button>
                        </div>
                    </Tooltip>
                )} */}
                {!canChangeEdit ? null : (
                    <Tooltip title={canEdit ? 'Disable Editing' : 'Enable Editing'}>
                        <div id='editButtonId'>
                            <Button className={classes.white} onClick={() => uCanChangeEdit && setEditDataPoints(!canEdit)}>
                                <Edit disabled={!canEdit} />
                            </Button>
                        </div>
                    </Tooltip>
                )}

                <Tooltip disableFocusListener title={admin ? 'Import/Export Data' : 'Download Data'}>
                    <Button onClick={handleDownload} className={classes.white}>
                        {admin ? <ImportExport id='downloadJoyrideId' /> : <SaveAlt id='downloadJoyrideId' />}
                    </Button>
                </Tooltip>
                {selectedYear && selectedSchool ? <DataEntryCopy selectedYear={selectedYear} selectedSchoolId={selectedSchool._id} /> : null}
                <Tooltip disableFocusListener title='Select Year'>
                    <div id='yearJoyrideId'>
                        <Select
                            IconComponent={CalendarToday}
                            input={<Input disableUnderline={true} />}
                            value={selectedYear}
                            onChange={(e) => selectYear(e.target.value)}
                            className={classes.white}
                            classes={{
                                icon: classes.white,
                                root: classes.white,
                                select: classes.white,
                            }}
                        >
                            {possibleYears.map(function (year) {
                                return (
                                    <MenuItem key={year} value={year}>
                                        {year}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </div>
                </Tooltip>
                <SchoolSelect />
            </Toolbar>
            <Dialog onClose={() => showImportExport(false)} open={showingImportExport}>
                <DialogTitle>Import/Export Data</DialogTitle>
                <DialogContent>
                    <DialogContentText>Import or export data as a CSV file.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => downloadData()} variant='contained'>
                        Export Data
                    </Button>
                    <FormControl>
                        <Input type='file' onChange={handleFileChange} />
                        <Button className={classes.importButton} onClick={processImport} disabled={!isCSVFile(selectedFile)} variant='contained'>
                            {loading ? <CircularProgress size={24} /> : 'Import Data'}
                        </Button>
                    </FormControl>
                </DialogActions>
            </Dialog>
            <Dialog open={showingFileTypeError}>
                <DialogTitle>File Type Error</DialogTitle>
                <DialogContent>
                    <DialogContentText>Please provide a CSV file.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowFileTypeError(false)} variant='contained'>
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
            <Ratios showRatios={showRatios} close={() => setShowRatios(false)} />
        </AppBar>
    );
}

export default DataEntryHeader;
