// @flow
'use strict';

import { Component } from 'react';
import {DataPointScalarQuestion} from '../DataPointScalarQuestion';
import DataPointQuestionWrapper from '../DataPointQuestionWrapper';

import { HelpOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import styles from './dataPointQuestionContingentChoice.scss';

type PropTypes = {|
    dataPoint: DataPointContingentChoiceType,
    contingency?: {
        _id: string,
        value: any,
    },
    canEdit: boolean,
    admin: boolean,
    canWriteConfidentialData: boolean,
    selectedYear: YearNumberType,
|};

export default class DataPointQuestionContingentChoice extends Component<PropTypes> {
    element: ?HTMLElement;

    constructor(props: PropTypes) {
        super(props);
        this.renderName = this.renderName.bind(this);
    }


    isSelected: () => boolean = () => {
        let selected = false;
        let activeElement = document.activeElement;
        if (activeElement && this.element) {
            selected = this.element.contains(activeElement);
        }
        return selected;
    };

    renderDefinition: (dataPoint: DataPointType) => ?React$Element<any> = (dataPoint) => {
        if (!dataPoint.definition || dataPoint.definition === '') {
            return null;
        }
        return (
            <Tooltip placement='right' title={dataPoint.definition}>
                <HelpOutline className={styles.questionInfoButton} />
            </Tooltip>
        );
    };

    renderName: (dataPoint: DataPointType) => React$Element<*> = (dataPoint) => {
        let className = styles.name;
        if (this.isSelected()) {
            className += ' ' + styles.highlighted;
        }
        return (
            <div key={'name-' + dataPoint._id} className={className}>
                <p>{dataPoint.name}</p>
                {this.renderDefinition(dataPoint)}
            </div>
        );
    };


    render() {
        const { dataPoint, canEdit, admin, canWriteConfidentialData, selectedYear } = this.props;
        let value = this.props.dataPoint.answer?.value;

        const contingencyMet = value === this.props.dataPoint.contingency;
        const cDataPoint: DataPointChoiceType = {
            _id: dataPoint._id,
            name: dataPoint.name,
            confidential: dataPoint.confidential,
            definition: dataPoint.definition,
            order: dataPoint.order,
            section: dataPoint.section,
            subsection: dataPoint.subsection,
            required: dataPoint.required,
            validations: dataPoint.validations,
            priorYearAnswer: dataPoint.priorYearAnswer,
            requiredPriorYears: dataPoint.requiredPriorYears,
            priorYearAnswers: dataPoint.priorYearAnswers,
            answer: value,
            choices: dataPoint.choices,
            type: 'choice',
        };
        if (!contingencyMet) {
            return <DataPointScalarQuestion key={dataPoint._id} dataPoint={cDataPoint} canEdit={canEdit} />;
        }
        const items = [
            <DataPointScalarQuestion dataPoint={cDataPoint} key={dataPoint._id} canEdit={canEdit} />,
            ...dataPoint.children.map(function(dp) {
                const childDataPoint = {
                    ...dp,
                    _id: dataPoint._id + '.' + dp._id,
                    answer:
                        dataPoint.answer && dataPoint.answer.children ? dataPoint.answer.children[dp._id] : undefined,
                };
                return (
                    <DataPointQuestionWrapper
                        key={dp._id.toString()}
                        canEdit={canEdit}
                        dataPoint={childDataPoint}
                        admin={admin}
                        canWriteConfidentialData={canWriteConfidentialData}
                        selectedYear={selectedYear}
                    />
                );
            }),
        ];
        return items;
    }
}
