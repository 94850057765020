// @flow
('use strict');

import { connect } from 'react-redux';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {formatName} from '../../utils';

import { getSelectedYear, getDataPointSectionData, getSelectedSectionId, getSelectedSubsectionId } from '../../redux/reducers';

const useStyles = makeStyles(() => ({
    panel: {
        backgroundColor: '#A7A6A6',
        margin: '0 !important',
    },
    panelSummary: {
        fontSize: '16px !important',
        minHeight: '36px !important',
        color: '#ddd',
    },
    selectedSection: {
        fontSize: '18px !important',
        minHeight: '36px !important',
        color: 'white',
        fontWeight: 'bold',
    },
    summaryContent: {
        margin: '6px 0 !important',
    },
    panelDetail: {
        fontSize: '14px !important',
        padding: 0,
    },
    panelExpansionIcon: {
        padding: 0,
        color: '#eee',
    },
    subSection: {
        textAlign: 'left',
        textTransform: 'capitalize',
        padding: '0 8px',
        color: '#eee',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    selectedSubsection: {
        textAlign: 'left',
        textTransform: 'capitalize',
        padding: '0 8px',
        color: '#203480',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    sectionListWrapper: {
        overflowY: 'auto',
    },
    panelDetails: {
        margin: '-4px 0 10px 40px',
    },
}));

type ReduxReducerProps = {|
    sectionData: ?SectionDataType,
    selectedSectionId: ?string,
    selectedSubsectionId: ?string,
    selectedYear: YearNumberType,
|};

type ReduxActionProps = {||};

type OwnProps = {|
    hideSubsections?: boolean,
    setSelectedSectionId: (selectedSectionId: string) => void,
    setSelectedSubsectionId: (selectedSubsectionId: string) => void,
|};

type Props = {
    ...ReduxReducerProps,
    ...ReduxActionProps,
    ...OwnProps,
};

export function SideBarSections(props: Props) {
    const {
        selectedSectionId,
        selectedSubsectionId,
        setSelectedSectionId,
        setSelectedSubsectionId,
        hideSubsections,
        sectionData,
        selectedYear
    } = props;
    const classes = useStyles();

    const handleSelectSection: (sectionId: string) => void = (sectionId) => {
        if (sectionId === selectedSectionId) {
            return;
        }
        setSelectedSectionId(sectionId);
    };

    const handleSelectSubsection: (subsectionId: string) => void = (subsectionId) => {
        if (selectedSubsectionId === subsectionId) {
            return;
        }
        setSelectedSubsectionId(subsectionId);
    };

    if (!sectionData) {
        return <div />;
    }

    return (
        <div className={classes.sectionListWrapper}>
            {sectionData.sectionOrder.map((sectionId) => {
                const section = sectionData.sections[sectionId];
                const hasSubsections =
                    !hideSubsections && section && Array.isArray(section.subsections)
                        ? section.subsections.length !== 0
                        : false;
                return (
                    <Accordion
                        classes={{ root: classes.panel }}
                        expanded={selectedSectionId === section._id}
                        TransitionProps={{ unmountOnExit: true }}
                        key={section._id}
                    >
                        <AccordionSummary
                            onClick={() => handleSelectSection(section._id)}
                            classes={{
                                root:
                                    selectedSectionId === section._id ? classes.selectedSection : classes.panelSummary,
                                content: classes.summaryContent,
                            }}
                            expandIcon={
                                hasSubsections ? <ExpandMoreIcon className={classes.panelExpansionIcon} /> : null
                            }
                        >
                            <Typography>{formatName(section.name, selectedYear)}</Typography>
                        </AccordionSummary>
                        {section.subsections && hasSubsections ? (
                            <div className={classes.panelDetails}>
                                {section.subsections.map((subsection) => {
                                    const subSectionIsSelected = subsection._id === selectedSubsectionId;
                                    return (
                                        <AccordionDetails
                                            onClick={() => handleSelectSubsection(subsection._id)}
                                            className={classes.panelDetail}
                                            key={subsection._id}
                                        >
                                            <Typography
                                                className={
                                                    subSectionIsSelected
                                                        ? classes.selectedSubsection
                                                        : classes.subSection
                                                }
                                            >
                                                {formatName(subsection.name, selectedYear)}
                                            </Typography>
                                        </AccordionDetails>
                                    );
                                })}
                            </div>
                        ) : null}
                    </Accordion>
                );
            })}
        </div>
    );
}
/* istanbul ignore next */
const mapStateToProps: (state: ReduxStateType) => ReduxReducerProps = (state) => {
    return {
        selectedSectionId: getSelectedSectionId(state),
        selectedSubsectionId: getSelectedSubsectionId(state),
        sectionData: getDataPointSectionData(state),
        selectedYear: getSelectedYear(state),
    };
};
/* istanbul ignore next */

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps)(SideBarSections);
