// @flow
'use strict';

import { Component } from 'react';

import styles from './multiSelect.scss';

type PropTypes = {
    choices: Array<ReportFilterChoiceType>,
    onChange?: (id: $Keys<SelectedReportFilterType>, value: string) => void,
    value: string,
    className?: string,
    id: string,
};

type StateType = {
    open: boolean,
    value: Array<string>,
};

const TIMEOUT = 3 * 1000;

export default class MultiSelect extends Component<PropTypes, StateType> {
    timer: TimeoutID | null;

    constructor(props: PropTypes) {
        super(props);
        let value;
        if (props.value) {
            value = props.value.split(',');
        } else {
            value = [''];
        }
        if (this.showAll(props.choices || [], value)) {
            value = [''];
        }
        this.state = {
            open: false,
            value: value,
        };
    }

    showAll: (choices: Array<ReportFilterChoiceType>, value: Array<string>) => boolean = (choices, value) => {
        let showAll = true;
        choices.forEach(function(choice) {
            if (choice._id === '') {
                return;
            }
            if (value.indexOf(choice._id) === -1) {
                showAll = false;
            }
        });
        return showAll;
    };

    onClick: (event: SyntheticInputEvent<*>) => void = (event) => {
        event.preventDefault();
        const open = !this.state.open;
        this.setState({
            open,
        });
    };

    startTimeout: () => void = () => {
        if (this.timer) {
            this.stopTimeout(); // getSchools: (state?: StateType) => void = (state) => {
        }
        this.timer = setTimeout(this.handleTimeout, TIMEOUT);
    };

    stopTimeout: () => void = () => {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    };

    handleTimeout: () => void = () => {
        this.stopTimeout();
        let value;
        this.setState({
            open: false,
        });
        if (this.showAll(this.props.choices, this.state.value)) {
            value = '';
        } else {
            value = this.state.value.join(',');
        }
        if (this.props.onChange) {
            // $FlowFixMe
            this.props.onChange(this.props.id, value);
        }
    };

    changeValue: (event: SyntheticInputEvent<*>) => void = (event) => {

        if (event.target.value === '') {
            if (this.state.value.indexOf('') !== -1) {
                this.setState({
                    value: [],
                });
            } else {
                this.setState({
                    value: [''],
                });
            }
        } else {
            let value = this.state.value.slice(0);
            if (event.target.checked) {
                value.push(event.target.value);
            } else {
                if (value.indexOf('') !== -1) {
                    value = [];
                    this.props.choices.forEach(function(choice) {
                        if (choice._id === '') {
                            return;
                        }
                        value.push(choice._id);
                    });
                }
                value = value.filter(function(innerValue) {
                    return innerValue !== event.target.value || innerValue === '';
                });
            }
            if (this.showAll(this.props.choices, value)) {
                value = [''];
            }
            this.setState({
                value: value,
            });
        }
        this.startTimeout();
    };

    render() {
        let classNames = styles.multiSelect;
        if (this.props.className) {
            classNames += ' ' + this.props.className;
        }
        let selected;
        if (this.state.value.indexOf('') !== -1) {
            selected = 'All';
        } else {
            selected = this.state.value.join(', ');
        }
        const value = this.state.value;
        const self = this;
        return (
            <div className={classNames}>
                <div onClick={this.onClick} className={styles.selected}>
                    <span>{selected}</span>
                </div>
                {this.state.open ? (
                    <div className={styles.openedList}>
                        <ul className={styles.list}>
                            {this.props.choices.map(function(choice) {
                                let choiceSelected = selected === 'All' || value.indexOf(choice._id) !== -1;
                                return (
                                    <li key={choice._id}>
                                        <input
                                            type='checkbox'
                                            checked={choiceSelected}
                                            onChange={self.changeValue}
                                            value={choice._id}
                                        />
                                        {choice.name}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                ) : null}
            </div>
        );
    }
}
