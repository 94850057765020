// @flow
'use strict';
import styles from './header-biis.scss';
import nboaBiis from '../../images/redesign/nboa-biis.jpg';
import HelpDialog from '../../components/HelpDialog';
import * as Utils from '../../utils';
import { useSelector } from 'react-redux';
import { getUser } from '../../redux/reducers';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
export function HeaderBiis() {
    const [showHelp, setShowHelp] = useState(false);

    const user = useSelector(getUser);
    const userExists = user && !Utils.isEmpty(user._id);
    let environment = Utils.Constants.environment;
    if (environment && environment.length > 0) {
        environment = environment[0].toUpperCase() + environment.slice(1);
    }
    return (
        <>
            <HelpDialog open={showHelp} handleClose={() => setShowHelp(false)} />
            <section className={`${styles['biis-header']} ${styles['bg-white']}`}>
                <div style={{ flex: 1 }}>
                    <Link to='/'>
                        <img src={nboaBiis} className={styles['img-nboa-biis']} />
                    </Link>
                </div>
                <div className={`${styles['biis-join-container']}`}>
                    {!userExists && (
                        <button
                            className={styles['biis-join-button']}
                            onClick={() => {
                                window.location.href = 'http://www.nboa.org/join';
                            }}
                        >
                            JOIN NBOA
                        </button>
                    )}
                    <button
                        className={styles['biis-help-button']}
                        onClick={() => {
                            setShowHelp(true);
                        }}
                    >
                        <QuestionMarkIcon fontSize='inherit' />
                    </button>
                </div>
            </section>
        </>
    );
}

export default HeaderBiis;
