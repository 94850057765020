// @flow
'use strict';

import { Component } from 'react';
import DataPointScalarQuestion from '../DataPointScalarQuestion';
import { HelpOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import styles from './dataPointQuestionMatrix.scss';
import {formatName} from '../../utils';
type PropTypes = {|
    canEdit: boolean,
    dataPoint: DataPointMatrixType,
    className?: string,
    contingency?: {
        _id: string,
        value: any,
    },
    selectedYear: YearNumberType,
|};

type StateType = {|
    hoverIndex: ?number,
|};

export default class DataPointQuestionMatrix extends Component<PropTypes, StateType> {
    element: ?HTMLElement;

    constructor(props: PropTypes) {
        super(props);
        this.state = {
            hoverIndex: undefined,
        };
        this.renderName = this.renderName.bind(this);
    }

    isSelected: () => boolean = () => {
        let selected = false;
        let activeElement = document.activeElement;
        if (activeElement && this.element) {
            selected = this.element.contains(activeElement);
        }
        return selected;
    };

    renderDefinition: (dataPoint: DataPointType) => ?React$Element<any> = (dataPoint) => {
        if (!dataPoint.definition || dataPoint.definition === '') {
            return null;
        }
        return (
            <Tooltip placement='right' title={dataPoint.definition}>
                <HelpOutline className={styles.questionInfoButton} />
            </Tooltip>
        );
    };

    renderName: (dataPoint: DataPointType) => React$Element<*> = (dataPoint) => {
        let className = styles.name;
        const selectedYear = this.props.selectedYear;
        if (this.isSelected()) {
            className += ' ' + styles.highlighted;
        }
        return (
            <div key={'name-' + dataPoint._id} className={className}>
                <p>{formatName(dataPoint.name, selectedYear)}</p>
                {this.renderDefinition(dataPoint)}
            </div>
        );
    };

    renderMatrixHorizontally: () => React$Element<*> = () => {
        const { canEdit, dataPoint } = this.props;
        const { renderName } = this;
        if (!dataPoint) {
            return <div />;
        }
        let className = this.props.className || '';
        className += ' ' + styles.dataPointMatrixQuestion;
        let contingencyId;
        if (dataPoint.contingency && dataPoint.contingency._id) {
            contingencyId = dataPoint.contingency._id;
        }
        let contingencyValue;
        if (dataPoint.contingency && dataPoint.contingency._id) {
            contingencyValue = dataPoint.contingency.value;
        }
        return (
            <div key={dataPoint._id} className={className}>
                <table style={{ width: '100%', boxSizing: 'border-box' }}>
                    <thead>
                        <tr>
                            <th>{renderName(dataPoint)}</th>
                            {(dataPoint.questions || []).map(function (question) {
                                return <th key={question._id}>{renderName(question)}</th>;
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {dataPoint.items.map(function (item) {
                            {
                                /* const itemIsSpouse = item.name === "Head's Spouse"; */
                            }

                            const itemAnswers =
                                dataPoint.answer && typeof dataPoint.answer !== 'string'
                                    ? dataPoint.answer[item._id] || {}
                                    : {};
                            let contingencyMet;
                            if (contingencyId) {
                                let cValue = itemAnswers[contingencyId];
                                contingencyMet = cValue === contingencyValue;
                            }
                            return (
                                <tr key={item._id}>
                                    <td>{item.name}</td>
                                    {(dataPoint.questions || []).map(function (question) {
                                        let canEditQuestion = canEdit;
                                        if (
                                            canEdit &&
                                            contingencyId &&
                                            question._id !== contingencyId &&
                                            !contingencyMet
                                        ) {
                                            canEditQuestion = false;
                                        }
                                        // $FlowFixMe
                                        let questionDP = {
                                            ...question,
                                            _id: dataPoint._id + '.' + item._id + '.' + question._id,
                                            answer: itemAnswers[question._id],
                                            required: contingencyMet && question.required,
                                        };
                                        return (
                                            <td key={question._id}>
                                                {/* $FlowFixMe */}
                                                <DataPointScalarQuestion
                                                    canEdit={canEditQuestion}
                                                    // $FlowFixMe
                                                    dataPoint={questionDP}
                                                    inputOnly={true}
                                                />
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };

    renderMatrixVertically: () => React$Element<*> = () => {
        const { canEdit, dataPoint } = this.props;
        const hoverIndex = this.state.hoverIndex;
        const self = this;
        const { renderName } = this;
        if (!dataPoint) {
            return <div />;
        }
        let className = this.props.className || '';
        className += ' ' + styles.dataPointVerticalMatrixQuestion;
        let items = [];
        (dataPoint.items || []).forEach(function (item) {
            items.push(undefined);
            items.push(item);
        });
        let contingencyId;
        if (dataPoint.contingency && dataPoint.contingency._id) {
            contingencyId = dataPoint.contingency._id;
        }
        let contingencyValue;
        if (dataPoint.contingency && dataPoint.contingency._id) {
            contingencyValue = dataPoint.contingency.value;
        }
        return (
            <div key={dataPoint._id} className={className}>
                <table>
                    <colgroup>
                        <col className={styles.questionNameColumn} />
                        {items.map(function (item, itemIndex) {
                            if (!item) {
                                return <col key={'col-space' + itemIndex} className={styles.spacingColumn} />;
                            }
                            return <col key={'col' + item._id} className={styles.inputColumn} />;
                        })}
                    </colgroup>
                    <thead>
                        <tr>
                            <th className={styles.questionNameColumn}>{renderName(dataPoint)}</th>
                            {items.map(function (item, itemIndex) {
                                if (!item) {
                                    return <th className={styles.spacingColumn} key={itemIndex + 'spacing'} />;
                                }
                                return (
                                    <th
                                        onMouseOver={() => {
                                            self.setState({
                                                hoverIndex: itemIndex,
                                            });
                                        }}
                                        onMouseLeave={() => {
                                            self.setState({
                                                hoverIndex: undefined,
                                            });
                                        }}
                                        className={
                                            styles.inputColumn +
                                            ' ' +
                                            (hoverIndex === itemIndex ? styles.highlighted : '')
                                        }
                                        key={item._id}
                                    >
                                        {item.name}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {(dataPoint.questions || []).map(function (question) {
                            return (
                                <tr key={question._id} className={styles.singleRow}>
                                    <td className={styles.questionNameColumn}>{renderName(question)}</td>

                                    {items.map(function (item, itemIndex) {
                                        if (!item) {
                                            return (
                                                <td className={styles.spacingColumn} key={'spacing2-' + itemIndex} />
                                            );
                                        }
                                        const itemAnswers =
                                            dataPoint.answer && typeof dataPoint.answer !== 'string'
                                                ? dataPoint.answer[item._id] || {}
                                                : {};
                                        let contingencyMet;
                                        if (contingencyId) {
                                            let cValue = itemAnswers[contingencyId];
                                            contingencyMet = cValue === contingencyValue;
                                        }
                                        let canEditQuestion = canEdit;
                                        if (
                                            canEdit &&
                                            contingencyId &&
                                            question._id !== contingencyId &&
                                            !contingencyMet
                                        ) {
                                            canEditQuestion = false;
                                        }
                                        // $FlowFixMe
                                        let questionDP = {
                                            ...question,
                                            _id: dataPoint._id + '.' + item._id + '.' + question._id,
                                            answer: itemAnswers[question._id],
                                            required: contingencyMet && question.required,
                                        };
                                        return (
                                            <td
                                                key={item._id + question._id}
                                                onMouseOver={() => {
                                                    self.setState({
                                                        hoverIndex: itemIndex,
                                                    });
                                                }}
                                                onMouseLeave={() => {
                                                    self.setState({
                                                        hoverIndex: undefined,
                                                    });
                                                }}
                                                className={
                                                    styles.inputColumn +
                                                    ' ' +
                                                    (hoverIndex === itemIndex ? styles.highlighted : '')
                                                }
                                            >
                                                {/* $FlowFixMe */}
                                                <DataPointScalarQuestion dataPoint={questionDP}
                                                    canEdit={canEditQuestion}
                                                    inputOnly={true}
                                                />
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };

    render() {
        return this.renderMatrixHorizontally();
    }
}
