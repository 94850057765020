// @flow
'use strict';

import { Fragment } from 'react';
import { useDrop } from 'react-dnd';
import { useSelector, useDispatch } from 'react-redux';
import { Drawer, List, ListItem, ListItemText, ListSubheader, makeStyles } from '@material-ui/core';
import { moveDataPoint, setSelectedSectionAndSubsectionIds, resetOnlyDataPoints } from '../../redux/actions';

import { getSelectedSectionId, getSelectedSubsectionId } from '../../redux/reducers';

const useStyles = makeStyles(() => ({
    drawer: {
        flexShrink: 0,
    },
    white: {
        color: 'white',
    },
    background: {
        backgroundColor: '#A7A6A6',
    },
    drawerPaper: {
        position: 'static',
        backgroundColor: '#A7A6A6',
    },
    selected: {
        fontWeight: 'bold',
    },
    subsectionName: {
        marginLeft: '15px',
        color: 'white',
    },
    hover: {
        border: '1px solid white',
    },
}));

type Props = {|
    dragAndDropAccept: string,
    sections: Array<DataPointSectionType>,

    requiredCounts?: { required: number, answered: number },
|};

export function SideBarDragAndDrop(props: Props) {
    const selectedSectionId = useSelector(getSelectedSectionId);
    const selectedSubsectionId = useSelector(getSelectedSubsectionId);
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        dragAndDropAccept,
        sections
    } = props;

    return (
        <Drawer
            className={classes.drawer}
            classes={{ paper: classes.drawerPaper }}
            anchor='left'
            open={true}
            variant='permanent'
        >
            <List dense disablePadding>
                {sections.map(function(section) {
                    let selected = section._id === selectedSectionId;
                    return (
                        <Fragment key={section._id}>
                            <ListSubheader selected={selected} className={classes.white + ' ' + classes.background}>
                                {section.name}
                            </ListSubheader>
                            {section.subsections.map(function(subsection) {
                                const selectedSubsection = selected && subsection._id === selectedSubsectionId;
                                const [{ isOver }, drop] = useDrop({
                                    accept: dragAndDropAccept,
                                    drop: (item) => {
                                        const dataPointId = item.id;
                                        dispatch(moveDataPoint(dataPointId, section._id, subsection._id));
                                    },
                                    canDrop: (item) => {
                                        return item.section !== section._id || item.subsection !== subsection._id;
                                    },
                                    collect: (monitor) => ({
                                        isOver: monitor.isOver(),
                                    }),
                                });
                                return (
                                    <ListItem
                                        button={!selectedSubsection}
                                        ref={drop}
                                        selected={selectedSubsection}
                                        onClick={() => {
                                            if (!selectedSubsection) {
                                                dispatch(setSelectedSectionAndSubsectionIds(section._id, subsection._id));
                                                dispatch(resetOnlyDataPoints());
                                            }
                                        }}
                                        key={
                                            section.name +
                                            '-' +
                                            (typeof subsection === 'string' ? subsection : subsection._id)
                                        }
                                        className={isOver && !selectedSubsection ? classes.hover : ''}
                                    >
                                        <ListItemText className={classes.subsectionName} primary={subsection.name} />
                                    </ListItem>
                                );
                            })}
                        </Fragment>
                    );
                })
                }
            </List>
        </Drawer>
    );
}

export default SideBarDragAndDrop;

