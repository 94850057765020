// @flow
'use strict';

import { combineReducers } from 'redux';
import { parseQueryString, Constants } from '../../utils';

import userInfo, * as userSelectors from './userInfo';
import reporting, * as reportingSelectors from './reporting';
import dataPoints, * as dataPointsSelectors from './dataPoints';
import settings, * as settingsSelectors from './settings';
import dataTables, * as dataTablesSelectors from './dataTables';
import { SET_ERROR, UNSET_ERROR } from '../actions/constants';
import { possibleYears } from '../../utils/constants';

const error = function (state: ?ReduxErrorType = null, action: any): any {
    let e = action.error;
    switch (action.type) {
        case SET_ERROR:
            return {
                ...state,
                ...e,
            };
        case UNSET_ERROR:
            return null;
        default:
            return state;
    }
};

const rootReducer: (state?: ReduxStateType, action: Object) => ReduxStateType = combineReducers({
    userInfo,
    settings,
    reporting,
    dataPoints,
    dataTables,
    error,
});

export default rootReducer;

export const QUERY_ITEMS = {
    displayall: 'displayall',
    year: 'year',
    school: 'school',
    section: 'section',
    subsection: 'subsection',
    topic: 'topic',
};

export const reduxQueryItems = {
    selectedYear: 'year',
    selectedSchoolId: 'school',
    selectedSection: 'section',
    selectedSubsection: 'subsection',
    selectedReportTopicId: 'topic',
};

export const getProp: (state: ReduxStateType, prop: $Keys<typeof QUERY_ITEMS>) => ?string = (state, prop) => {
    switch (prop) {
        case QUERY_ITEMS.year:
            return '' + getSelectedYear(state);
        case QUERY_ITEMS.school:
            return getSelectedSchoolId(state);
        case QUERY_ITEMS.section:
            return getSelectedSectionId(state);
        case QUERY_ITEMS.subsection:
            return getSelectedSubsectionId(state);
        case QUERY_ITEMS.displayall:
            return isDisplayingAll(state) ? 'true' : undefined;
        case QUERY_ITEMS.topic:
            return getSelectedReportTopicId(state);
    }
};

export const reduxStateFromSearchQuery: (search: string) => ?ReduxStateType = (search) => {
    if (!search || search === '' || search === '?') {
        return;
    }
    let searchQuery: { [key: string]: string } = {};
    if (search) {
        let searchString = search;
        if (searchString[0] === '?') {
            searchString = searchString.slice(1);
        }
        searchQuery = parseQueryString(searchString);
    }
    const state = {
        userInfo: userSelectors.defaultState,
        settings: settingsSelectors.defaultState,
        reporting: reportingSelectors.defaultState,
        dataPoints: dataPointsSelectors.defaultState,
        dataTables: dataTablesSelectors.defaultState,
        error: undefined,
    };
    if (searchQuery.unanswered) {
        if (!state.dataPoints) {
            state.dataPoints = {};
        }
        state.dataPoints.displayUnanswered = true;
    }
    if (searchQuery.displayall) {
        if (!state.dataPoints) {
            state.dataPoints = {};
        }
        state.dataPoints.displayAll = true;
    }
    if (searchQuery.year) {
        let year = parseInt(searchQuery.year);
        if (parseInt(possibleYears[0]) < year || parseInt(possibleYears[possibleYears.length - 1]) > year) {
            year = parseInt(possibleYears[0]) < year ? parseInt(possibleYears[0]) : parseInt(possibleYears[possibleYears.length - 1]);
        }
        if (!state.settings) {
            state.settings = {};
        }
        state.settings.selectedYear = year;
    }

    if (searchQuery.section) {
        if (!state.dataPoints) {
            state.dataPoints = {};
        }
        state.dataPoints.selectedSectionId = searchQuery.section;
    }
    if (searchQuery.subsection && searchQuery.section) {
        if (!state.dataPoints) {
            state.dataPoints = {};
        }
        state.dataPoints.selectedSubsectionId = searchQuery.subsection;
    }

    if (searchQuery.school) {
        if (!state.userInfo) {
            state.userInfo = {};
        }
        state.userInfo.selectedSchoolId = searchQuery.school;
    }

    if (searchQuery.topic) {
        if (!state.reporting) {
            state.reporting = {};
        }
        state.reporting.selectedReportTopicId = searchQuery.topic;
    }

    return state;
};

export const canEdit = (state: ReduxStateType): boolean => {
    return settingsSelectors.canEdit(state.settings);
};

export const canChangeEdit = (state: ReduxStateType): boolean => {
    const admin = isNboaAdmin(state) || isOnDataCommittee(state);
    const user = state.userInfo.user;
    if (admin) {
        return true;
    }
    if (!user) {
        return false;
    }
    if (user.permissions.indexOf('writeSchoolNonConfidentialData') === -1) {
        return false;
    }
    if (!user.settings?.access?.dataEntry?.denyAccess) {
        return true;
    }
    return false;
};

export const hasTriedAuthenticating = (state: ReduxStateType): boolean => {
    return userSelectors.hasTriedAuthenticating(state.userInfo);
};

export const getParticipatingSchools = (state: ReduxStateType): ?Array<PeergroupSchoolType> => {
    return userSelectors.getParticipatingSchools(state.userInfo);
};

export const isLoading = (state: ReduxStateType): boolean => {
    return settingsSelectors.isLoading(state.settings);
};

export const isDisplayingUnanswered = (state: ReduxStateType): boolean => {
    return dataPointsSelectors.isDisplayingUnanswered(state.dataPoints);
};

export const isDisplayingAll = (state: ReduxStateType): boolean => {
    return dataPointsSelectors.isDisplayingAll(state.dataPoints);
};

export const getSelectedSectionId = (state: ReduxStateType): ?string => {
    return dataPointsSelectors.getSelectedSectionId(state.dataPoints);
};

export const getSelectedSection = (state: ReduxStateType): ?DataPointSectionType => {
    return dataPointsSelectors.getSelectedSection(state.dataPoints);
};

export const getSelectedSubsectionId = (state: ReduxStateType): ?string => {
    return dataPointsSelectors.getSelectedSubsectionId(state.dataPoints);
};

export const getUser = (state: ReduxStateType): ?UserType => {
    return userSelectors.getUser(state.userInfo);
};

export const getSelectedSchool = (state: ReduxStateType): ?SchoolType => {
    return userSelectors.getSelectedSchool(state.userInfo);
};

export const getSelectedYear = (state: ReduxStateType): YearNumberType => {
    return settingsSelectors.getSelectedYear(state.settings) || parseInt(Constants.currentYear);
};

export const isFetchingSelectedSchool = (state: ReduxStateType): boolean => {
    return userSelectors.isFetchingSelectedSchool(state.userInfo);
};

export const getFetchingUser = (state: ReduxStateType): boolean => {
    return userSelectors.getFetchingUser(state.userInfo);
};

export const getUserFetchFailed = (state: ReduxStateType): boolean => {
    return userSelectors.getUserFetchFailed(state.userInfo);
};

export const hasApiAuthFailed = (state: ReduxStateType): boolean => {
    return userSelectors.hasApiAuthFailed(state.userInfo);
};

export const getPeergroups = (state: ReduxStateType): ?{ [_id: string]: PeergroupType } => {
    return userSelectors.getPeergroups(state.userInfo);
};

export const isFetchingPeergroups = (state: ReduxStateType): boolean => {
    return userSelectors.isFetchingPeergroups(state.userInfo);
};

export const getCannedPeergroups = (state: ReduxStateType): ?{ [_id: string]: PeergroupType } => {
    return userSelectors.getCannedPeergroups(state.userInfo);
};

export const isFetchingCannedPeergroups = (state: ReduxStateType): boolean => {
    return userSelectors.isFetchingCannedPeergroups(state.userInfo);
};

export const getPeergroupSchools = (state: ReduxStateType): ?PeergroupSchoolResponseType => {
    return userSelectors.getPeergroupSchools(state.userInfo);
};

export const isFetchingPeergroupSchools = (state: ReduxStateType): boolean => {
    return userSelectors.isFetchingPeergroupSchools(state.userInfo);
};

export const isFetchingDataPoints = (state: ReduxStateType): boolean => {
    return dataPointsSelectors.isFetchingDataPoints(state.dataPoints);
};

export const getDataPoints = (state: ReduxStateType, sectionId?: string, subsectionId?: string): ?Array<DataPointType> => {
    return dataPointsSelectors.getDataPoints(state.dataPoints, sectionId, subsectionId);
};

export const getDataPointsForIds = (state: ReduxStateType, ids: Array<string>): Array<DataPointType> => {
    return dataPointsSelectors.getDataPointsForIds(state.dataPoints, ids);
};

export const getDataPointAnswerById = (state: ReduxStateType, id: string): any => {
    return dataPointsSelectors.getDataPointAnswerById(state.dataPoints, id);
};

export const getSectionById = (state: ReduxStateType, sectionId: ?string): ?DataPointSectionType => {
    if (!sectionId) {
        return;
    }
    return dataPointsSelectors.getSectionById(state.dataPoints, sectionId);
};

export const getDataPointById = (state: ReduxStateType, id: string): ?DataPointType => {
    return dataPointsSelectors.getDataPointById(state.dataPoints, id);
};

export const getDataPointFilter = (state: ReduxStateType): string => {
    return dataPointsSelectors.getFilter(state.dataPoints);
};

export const getDataPointsForSection = (state: ReduxStateType, sectionId: string): { [_id: string]: DataPointType } => {
    return dataPointsSelectors.getDataPointsForSection(state.dataPoints, sectionId);
};

export const getDataPointSectionData = (state: ReduxStateType): ?SectionDataType => {
    return dataPointsSelectors.getDataPointSectionData(state.dataPoints);
};

export const isFetchingDataPointSections = (state: ReduxStateType): boolean => {
    return dataPointsSelectors.isFetchingDataPointSections(state.dataPoints);
};

export const getRequiredCounts = (state: ReduxStateType): ?RequiredCountsType => {
    return dataPointsSelectors.getRequiredCounts(state.dataPoints);
};

export const getDataPointsStatus = (state: ReduxStateType): ?DataPointStatusType => {
    return dataPointsSelectors.getDataPointsStatus(state.dataPoints);
};

export const getDataPointFilters = (state: ReduxStateType): ?Array<DataPointFilterType> => {
    return dataPointsSelectors.getDataPointFilters(state.dataPoints);
};

export const isFetchingDataPointFilters = (state: ReduxStateType): boolean => {
    return dataPointsSelectors.isFetchingFilters(state.dataPoints);
};

export const hasSeenChartWizard = (state: ReduxStateType): boolean => {
    return userSelectors.hasSeenChartWizard(state.userInfo);
};

export const hasSeenDataEntryWizard = (state: ReduxStateType): boolean => {
    return userSelectors.hasSeenDataEntryWizard(state.userInfo);
};

export const hasSeenPeergroupWizard = (state: ReduxStateType): boolean => {
    return userSelectors.hasSeenPeergroupWizard(state.userInfo);
};

export const getSelectedSchoolId = (state: ReduxStateType): ?string => {
    return userSelectors.getSelectedSchoolId(state.userInfo);
};

export const getSchoolsByStatus = (state: ReduxStateType): ?SchoolStatusType => {
    return userSelectors.getSchoolsByStatus(state.userInfo);
};

export const isFetchingSchools = (state: ReduxStateType): boolean => {
    return userSelectors.isFetchingSchools(state.userInfo);
};

export const getError = (state: ReduxStateType): ?ReduxErrorType => {
    return state.error;
};

export const isAssociationAdmin = (state: ReduxStateType): boolean => {
    return userSelectors.isAssociationAdmin(state.userInfo);
};

export const isNboaAdmin = (state: ReduxStateType): boolean => {
    return userSelectors.isNboaAdmin(state.userInfo);
};

export const isOnDataCommittee = (state: ReduxStateType): boolean => {
    return userSelectors.isOnDataCommittee(state.userInfo);
};

export const isAdmin = (state: ReduxStateType): boolean => {
    return isNboaAdmin(state) || isOnDataCommittee(state);
};

export const isPrimaryContact = (state: ReduxStateType): boolean => {
    return userSelectors.isPrimaryContact(state.userInfo);
};

export const userCanWriteNonConfidentialData = (state: ReduxStateType): boolean => {
    return userSelectors.userCanWriteNonConfidentialData(state.userInfo);
};

export const userCanWriteConfidentialData = (state: ReduxStateType): boolean => {
    return userSelectors.userCanWriteConfidentialData(state.userInfo);
};

export const getSelectedPeergroupId = (state: ReduxStateType): ?string => {
    return userSelectors.getSelectedPeergroupId(state.userInfo);
};

export const getSelectedPeergroup = (state: ReduxStateType): ?PeergroupType => {
    return userSelectors.getSelectedPeergroup(state.userInfo);
};

export const getPeergroupsOrder = (state: ReduxStateType): Array<string> => {
    return userSelectors.getPeergroupsOrder(state.userInfo);
};

export const getSelectedCannedPeergroupId = (state: ReduxStateType): ?string => {
    return userSelectors.getSelectedCannedPeergroupId(state.userInfo);
};

export const getSelectedCannedPeergroup = (state: ReduxStateType): ?PeergroupType => {
    return userSelectors.getSelectedCannedPeergroup(state.userInfo);
};

export const getCannedPeergroupsOrder = (state: ReduxStateType): Array<string> => {
    return userSelectors.getCannedPeergroupsOrder(state.userInfo);
};

export const getPeergroupSchoolFilters = (state: ReduxStateType): { [id: ReportFilterKeyType]: ?string } => {
    return userSelectors.getPeergroupSchoolFilters(state.userInfo);
};

export const isFirstSubsection = (state: ReduxStateType): boolean => {
    return dataPointsSelectors.isFirstSubsection(state.dataPoints);
};

export const isLastSubsection = (state: ReduxStateType): boolean => {
    return dataPointsSelectors.isLastSubsection(state.dataPoints);
};

export const getScrubbers = (state: ReduxStateType): ?{ [id: string]: ScrubberType } => {
    return userSelectors.getScrubbers(state.userInfo);
};

export const getScrubberOrder = (state: ReduxStateType): ?Array<string> => {
    return userSelectors.getScrubberOrder(state.userInfo);
};

export const isFetchingScrubbers = (state: ReduxStateType): boolean => {
    return userSelectors.isFetchingScrubbers(state.userInfo);
};

export const getUnassignedSchools = (state: ReduxStateType): ?Array<SchoolMainType> => {
    return userSelectors.getUnassignedSchools(state.userInfo);
};

export const isFetchingUnassignedSchools = (state: ReduxStateType): boolean => {
    return userSelectors.isFetchingUnassignedSchools(state.userInfo);
};

export const showChromeWarning = (state: ReduxStateType): boolean => {
    return settingsSelectors.showChromeWarning(state.settings);
};

export const getDataPointsForEditing = (state: ReduxStateType): ?Array<DataPointType> => {
    return dataPointsSelectors.getDataPointsForEditing(state.dataPoints);
};

/*--------------------------------------
    Reporting Selectors
*/

export const getReportErrorMessage = (state: ReduxStateType): ?string => {
    return reportingSelectors.getReportErrorMessage(state.reporting);
};

export const getReportData = (state: ReduxStateType): any => {
    return reportingSelectors.getReportData(state.reporting);
};

export const getReportFilters = (state: ReduxStateType): any => {
    return reportingSelectors.getReportFilters(state.reporting);
};

export const getReportTopicsBySection = (state: ReduxStateType): ?Array<ReportTopicSectionType> => {
    return reportingSelectors.getReportTopicsBySection(state.reporting);
};

export const getReportTopics = (state: ReduxStateType): ?Array<ReportTopicType> => {
    return reportingSelectors.getReportTopics(state.reporting);
};

export const getSelectedReportTopicId = (state: ReduxStateType): ?string => {
    return reportingSelectors.getSelectedReportTopicId(state.reporting);
};

export const getSelectedReportTopicSectionId = (state: ReduxStateType): ?string => {
    return reportingSelectors.getSelectedReportTopicSectionId(state.reporting);
};
export const getRatioData = (state: ReduxStateType): ?ReportRatiosResponseType => {
    return reportingSelectors.getRatioData(state.reporting);
};

export const isFetchingReportData = (state: ReduxStateType): boolean => {
    return reportingSelectors.isFetchingReportData(state.reporting);
};

export const isFetchingRatioData = (state: ReduxStateType): boolean => {
    return reportingSelectors.isFetchingRatioData(state.reporting);
};

export const isFetchingReportFilters = (state: ReduxStateType): boolean => {
    return reportingSelectors.isFetchingReportFilters(state.reporting);
};

export const getSelectedReportFilters = (state: ReduxStateType): SelectedReportFilterType => {
    return reportingSelectors.getSelectedReportFilters(state.reporting);
};

export const isFetchingReportTopics = (state: ReduxStateType): boolean => {
    return reportingSelectors.isFetchingReportTopics(state.reporting);
};
export const isFetchingReportTopicSections = (state: ReduxStateType): boolean => {
    return reportingSelectors.isFetchingReportTopicSections(state.reporting);
};

export const getReportTopicSectionData = (state: ReduxStateType): ?ReportTopicSectionType => {
    return reportingSelectors.getReportTopicSectionData(state.reporting);
};

export const getDashboard = (state: ReduxStateType): ?DashboardType => {
    return reportingSelectors.getDashboard(state.reporting);
};

export const getAllSchoolReportData = (state: ReduxStateType): ?Array<AllSchoolReportDataType> => {
    return reportingSelectors.getAllSchoolReportData(state.reporting);
};

export const getOutlierSearchField = (state: ReduxStateType): ?string => {
    return reportingSelectors.getOutlierSearchField(state.reporting);
};

export const getOutlierDataPoints = (state: ReduxStateType): ?Array<OutlierDataPointSchoolsType> => {
    return reportingSelectors.getOutlierDataPoints(state.reporting);
};

export const getUserDashboard = (state: ReduxStateType): ?UserDashboardType => {
    return reportingSelectors.getUserDashboard(state.reporting);
};

export const getOutlierDataPointsBySchool = (state: ReduxStateType): DataPointsOutlierSchoolDataPointsType => {
    return reportingSelectors.getOutlierDataPointsBySchool(state.reporting);
};

export const getReportTopicsForEditing = (state: ReduxStateType): ?Array<ReportTopicType> => {
    return reportingSelectors.getReportTopicsForEditing(state.reporting);
};

/*--------------------------------------
    Data Table Selectors
*/
export const isFetchingDataTables = (state: ReduxStateType): boolean => {
    return dataTablesSelectors.isFetchingDataTables(state.dataTables);
};

export const getDataTables = (state: ReduxStateType): ?Array<DataTableShortType> => {
    return dataTablesSelectors.getDataTables(state.dataTables);
};

export const getDataTable = (state: ReduxStateType): ?DataTableType => {
    return dataTablesSelectors.getDataTable(state.dataTables);
};

export const getDataTableRows = (state: ReduxStateType): ?Array<DataTableCreateRowType> => {
    return dataTablesSelectors.getDataTableRows(state.dataTables);
};

export const isFetchingDataTable = (state: ReduxStateType): boolean => {
    return dataTablesSelectors.isFetchingDataTable(state.dataTables);
};

export const queryItemSelectors = {
    unanswered: isDisplayingUnanswered,
    displayall: isDisplayingAll,
    year: getSelectedYear,
    school: getSelectedSchoolId,
    section: getSelectedSectionId,
    subsection: getSelectedSubsectionId,
    topic: getSelectedReportTopicId,
};
