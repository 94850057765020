// @flow
'use strict';

import { Component } from 'react';
import ErrorBoundaryDialog from './ErrorBoundaryDialog';
import type {ChildrenArray} from 'react';
type Props = {
    children: ChildrenArray<React$Element<*>>,
};

type State = {
    error?: Error
};

export default class ErrorBoundary extends Component<Props, State> {
    constructor() {
        super();
        this.state = {};
    }

    static getDerivedStateFromError(error: Error) {
        return {error};
    }

    render() {
        const {children} = this.props;
        const {error} = this.state;
        return <ErrorBoundaryDialog error={error}>{children}</ErrorBoundaryDialog>;
    }
}
