// @flow
'use strict';

import styles from './header.scss';
import * as Utils from '../../utils';
import NavBar from '../NavBar';
import Header2 from '../Header2';
import HeaderBiis from '../Header-Biis';
import { GA_EVENTS, useTrackEvents } from '../../ga/analytics';
import { setPageTitle } from '../../utils/pages';
import { useEffect } from 'react';

export function Header() {
    const trackEvent = useTrackEvents();

    useEffect(() => {
        setPageTitle('User Dashboard');
        trackEvent(GA_EVENTS.PAGEVIEW.NAME);
    }, []);

    let environment = Utils.Constants.environment;
    if (environment && environment.length > 0) {
        environment = environment[0].toUpperCase() + environment.slice(1);
    }

    return (
        <div className={styles.header}>
            <Header2 />
            <HeaderBiis />
            <NavBar />
        </div>
    );
}

export default Header;
