// @flow
'use strict';

import { Component } from 'react';
import styles from './loadingAnimation.scss';
import { CircularProgress } from '@material-ui/core';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import { isLoading } from '../../redux/reducers';

type ReduxReducerProps = {|
    isLoading: boolean,
|};

type Props = {
    ...ReduxReducerProps,
};

export class LoadingAnimation extends Component<Props> {
    container: HTMLDivElement;

    constructor(props: Props) {
        super(props);
        this.container = document.createElement('div');
        this.container.className = styles.modal;
    }

    componentDidMount() {
        if (document.body && this.props.isLoading) {
            document.body.appendChild(this.container);
        }
    }

    componentWillUnmount() {
        if (document.body && document.body.contains(this.container)) {
            document.body.removeChild(this.container);
        }
    }

    componentDidUpdate() {
        if (!document.body) {
            return;
        }
        if (!this.props.isLoading && document.body.contains(this.container)) {
            document.body.removeChild(this.container);
        } else if (this.props.isLoading && !document.body.contains(this.container)) {
            document.body.appendChild(this.container);
        }
    }

    shouldComponentUpdate(nextProps: Props) {
        return !!this.props.isLoading !== !!nextProps.isLoading;
    }

    render() {
        if (this.props.isLoading) {
            return ReactDOM.createPortal(<CircularProgress variant='indeterminate' size={85} />, this.container);
        } else {
            return null;
        }
    }
}
/* istanbul ignore next */
const mapStateToProps: (state: ReduxStateType) => ReduxReducerProps = (state) => {
    return {
        isLoading: isLoading(state),
    };
};

export default connect<Props, {}, _, _, _, _>(mapStateToProps)(LoadingAnimation);
