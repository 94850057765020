// @flow
'use strict';

import { Component } from 'react';
import styles from './subsectionEditorItem.scss';
import { DragSource, DropTarget } from 'react-dnd';
import { flow } from 'lodash';


const TIMEOUT = 3 * 1000;

const itemSource = {
    beginDrag(props: PropTypes) {
        return {
            subsection: props.subsection,
            index: props.index,
            section: props.section,
        };
    },
};

const itemTarget = {
    drop(props: PropTypes) {
        props.onCardDrop();
    },
    hover(props: PropTypes, monitor, component) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        if (dragIndex === hoverIndex) {
            return;
        }

        if (!component) {
            return;
        }

        const domNode = component.getDecoratedComponentInstance();

        if (!domNode) {
            return;
        }

        const hoverBoundingRect = domNode;

        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        const clientOffset = monitor.getClientOffset();

        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
        }

        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
        }

        props.moveCard(dragIndex, hoverIndex);

        monitor.getItem().index = hoverIndex;
    },
};

type PropTypes = {|
    ...DragDropType,
    subsection: DataPointSubsectionType,
    index: number,
    section: string,
    updateSubsectionName: (subsection: string, name: string) => void,
    moveCard: (dragIndex: number, hoverIndex: number) => void, // eslint-disable-line
    onCardDrop: () => void, // eslint-disable-line
|};

type StateType = {
    subsection: DataPointSubsectionType,
};

export class SectionEditorSubsection extends Component<PropTypes, StateType> {
    timer: TimeoutID | null;

    constructor(props: PropTypes) {
        super(props);
        this.state = {
            subsection: this.props.subsection,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleTimeout = this.handleTimeout.bind(this);
        this.startTimeout = this.startTimeout.bind(this);
        this.stopTimeout = this.stopTimeout.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    startTimeout: () => void = () => {
        if (this.timer) {
            this.stopTimeout();
        }
        this.timer = setTimeout(this.handleTimeout, TIMEOUT);
    };

    stopTimeout: () => void = () => {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    };

    shouldComponentUpdate(nextProps: PropTypes, nextState: StateType) {
        const self = this;
        if (
            self.props.index !== nextProps.index ||
            self.props.section !== nextProps.section ||
            self.props.subsection._id !== nextProps.subsection._id ||
            self.props.subsection.name !== nextProps.subsection.name ||
            self.state.subsection._id !== nextState.subsection._id ||
            self.state.subsection.name !== nextState.subsection.name
        ) {
            return true;
        }
        return false;
    }

    handleTimeout: (subsection?: DataPointSubsectionType) => void = (subsection) => {
        const self = this;
        self.stopTimeout();
        this.props.updateSubsectionName(
            subsection ? subsection._id : this.state.subsection._id,
            subsection ? subsection.name : this.state.subsection.name,
        );
    };

    handleChange: (event: SyntheticInputEvent<*>) => void = (event) => {
        event.preventDefault();
        const field = event.target.name;
        const value = event.target.value;
        const subsection = Object.assign({}, this.state.subsection);
        subsection[field] = value;
        this.setState({
            subsection: subsection,
        });
        this.startTimeout();
    };

    handleBlur: (event: SyntheticInputEvent<*>) => void = (event) => {
        event.preventDefault();
        if (this.timer) {
            this.handleTimeout();
        }
    };

    onFocus: (event: SyntheticInputEvent<*>) => void = (event) => {
        event.preventDefault();
    };

    render() {
        const { isDragging, connectDragSource, connectDropTarget, connectDragPreview } = this.props;
        const self = this;
        let innerElement = (
            <div className={styles.leftRibbon}>
                <div className={styles.dragButton} />
            </div>
        );
        if (connectDragSource) {
            innerElement = connectDragSource(innerElement);
        }
        innerElement = (
            <div className={styles.subsectionItemDiv + ' ' + (isDragging ? styles.dragging : '')}>
                {innerElement}
                <div className={styles.subsectionItem}>
                    <input
                        type='text'
                        name='name'
                        className={styles.input}
                        onChange={self.handleChange}
                        value={self.state.subsection.name}
                        onFocus={self.onFocus}
                        onBlur={self.handleBlur}
                    />
                </div>
            </div>
        );
        if (connectDropTarget) {
            innerElement = connectDropTarget(innerElement);
        }
        if (connectDragPreview) {
            innerElement = connectDragPreview(innerElement);
        }
        return innerElement;
    }
}

export default flow([
    DragSource('SectionEditorSubsection', itemSource, (connect, monitor) => {
        return {
            connectDragSource: connect.dragSource(),
            connectDragPreview: connect.dragPreview(),
            isDragging: monitor.isDragging(),
        };
    }),
    DropTarget('SectionEditorSubsection', itemTarget, (connect) => {
        return {
            connectDropTarget: connect.dropTarget(),
        };
    }),
])(SectionEditorSubsection);
