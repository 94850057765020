// @flow
'use strict';
// import { values } from 'lodash';
import {
    RESET_REPORT_DATA,
    SET_REPORT_DATA,
    ERROR_FETCHING_REPORT_DATA,
    FETCHING_REPORT_DATA,
    FETCHING_REPORT_FILTERS,
    FETCHING_REPORT_TOPICS,
    SET_REPORT_FILTERS,
    SET_REPORT_TOPICS,
    SET_REPORT_TOPICS_FOR_EDITING,
    SET_SELECTED_REPORT_TOPIC,
    SET_SELECTED_REPORT_FILTERS,
    RESET_ALL_REPORT_DATA,
    RESET_REPORT_ERROR_MESSAGE,
    SET_RATIOS,
    SET_FETCHING_RATIOS,
    RECEIVE_DASHBOARD,
    RESET_DASHBOARD,
    SET_ALL_SCHOOL_REPORT_DATA,
    SET_REPORT_ERROR,
    SET_SEARCH_FILTER,
    SET_FETCHING_OUTLIERS,
    SET_OUTLIERS,
    RESET_DATA_TABLE,
    RECEIVE_USER_DASHBOARD,
    UPDATE_SINGLE_REPORTTOPIC,
    RECEIVE_REPORT_TOPIC_SECTIONS,
    SET_SELECTED_REPORT_TOPIC_SECTION_ID,
    FETCHING_REPORT_TOPICS_FOR_EDITING,
    SET_DIALOG_SELECTED_REPORT_SECTION_ID,
    SET_DIALOG_SELECTED_REPORT_TOPIC_ID,
    SET_DIALOG_SEARCH_TEXT,
} from '../actions/constants';

export const defaultState = {
    fetchingReportData: false,
    fetchingReportFilters: false,
    fetchingReportTopics: false,
    fetchingReportTopicSections: false,
    fetchingRatioData: false,
    selectedReportFilters: {},
    fetchingOutliers: false,

    selectedReportTopicSectionId: undefined,
    dialogSelectedReportSectionId: undefined,
    dialogSelectedReportTopicId: undefined,
    dialogSearchText: undefined,
    searchFilter: '',
};

const stateForReportData = function (reportData: ReportDataType, state: ReduxReportingStateType): ReduxReportingStateType {
    const updatedReportData = {
        ...state,
    };
    updatedReportData.data = {
        ...reportData,
    };
    if (reportData.filters) {
        updatedReportData.filters = reportData.filters;
    }
    if (reportData.doSetSelectedFilters && reportData.selectedFilters) {
        updatedReportData.selectedReportFilters = reportData.selectedFilters;
    }
    if (reportData.doSetSelectedTopic && reportData.selectedTopicId) {
        updatedReportData.selectedReportTopicId = reportData.selectedTopicId;
    }
    updatedReportData.fetchingReportData = false;

    return {
        ...updatedReportData,
    };
};

const reporting = (state: ReduxReportingStateType = defaultState, action: ReduxReportingActionType): ReduxReportingStateType => {
    const updatedReportData = {};
    switch (action.type) {
        case SET_DIALOG_SELECTED_REPORT_SECTION_ID:
            return {
                ...defaultState,
                dialogSelectedReportSectionId: action.sectionId,
            };
        case SET_DIALOG_SELECTED_REPORT_TOPIC_ID:
            return {
                ...defaultState,
                dialogSelectedReportTopicId: action.topicId,
            };
        case SET_DIALOG_SEARCH_TEXT:
            return {
                ...defaultState,
                dialogSearchText: action.searchText,
            };
        case RESET_ALL_REPORT_DATA:
            return {
                ...defaultState,
                selectedReportTopicId: state.selectedReportTopicId,
            };
        case SET_RATIOS:
            return {
                ...state,
                ratioData: action.ratios,
                fetchingRatioData: false,
            };
        case SET_FETCHING_RATIOS:
            return {
                ...state,
                fetchingRatioData: action.fetching,
            };
        case RESET_REPORT_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: undefined,
            };
        case FETCHING_REPORT_DATA:
            return {
                ...state,
                fetchingReportData: true,
            };
        case FETCHING_REPORT_FILTERS:
            return {
                ...state,
                fetchingReportFilters: true,
            };
        case FETCHING_REPORT_TOPICS:
            return {
                ...state,
                fetchingReportTopics: action.fetchingReportTopics,
            };
        case FETCHING_REPORT_TOPICS_FOR_EDITING:
            return {
                ...state,
                fetchingReportTopics: action.fetchingReportTopics,
            };
        case SET_SEARCH_FILTER:
            return {
                ...state,
                searchFilter: action.searchFilter,
            };
        case SET_REPORT_ERROR:
            updatedReportData.errorMessage = action.error.errorMessage;
            if (action.error.originalTopicId) {
                updatedReportData.selectedReportTopicId = action.error.originalTopicId;
            }
            if (action.error.originalSelectedFilters) {
                updatedReportData.selectedReportFilters = action.error.originalSelectedFilters;
            }
            if (!action.error.originalSelectedFilters && !action.error.originalTopicId) {
                updatedReportData.selectedReportFilters = {};
                updatedReportData.selectedReportTopicId = undefined;
            }
            // $FlowFixMe
            return {
                ...state,
                ...updatedReportData,
            };
        case SET_REPORT_DATA:
            return stateForReportData(action.reportData, state);
        case SET_REPORT_TOPICS: {
            if (action.flattened) {
                return {
                    ...state,
                    flattenedTopics: action.flattenedTopics,
                    fetchingReportTopics: false,
                };
            }

            return {
                ...state,
                topics: action.topics,
                fetchingReportTopics: false,
            };
        }
        case SET_REPORT_TOPICS_FOR_EDITING: {
            return {
                ...state,
                topicsForEditing: action.topicsForEditing,
                fetchingReportTopics: false,
            };
        }
        case SET_REPORT_FILTERS:
            return {
                ...state,
                filters: action.filters,
                fetchingReportFilters: false,
            };
        case SET_SELECTED_REPORT_TOPIC:
            return {
                ...state,
                selectedReportTopicId: action.selectedReportTopicId,
            };
        case RESET_REPORT_DATA:
            return {
                ...state,
                fetchingReportFilters: false,
                fetchingReportData: false,
                data: undefined,
            };
        case ERROR_FETCHING_REPORT_DATA:
            return {
                ...state,
                fetchingReportFilters: false,
                fetchingReportData: false,
                data: undefined,
            };
        case SET_SELECTED_REPORT_FILTERS:
            return {
                ...state,
                selectedReportFilters: action.selectedReportFilters,
            };
        case RECEIVE_DASHBOARD:
            return {
                ...state,
                dashboard: action.dashboard,
                fetchingReportData: false,
            };
        case RESET_DASHBOARD:
            return {
                ...defaultState,
            };
        case SET_ALL_SCHOOL_REPORT_DATA:
            return {
                ...state,
                fetchingReportData: false,
                allSchoolReportData: action.allSchoolReportData,
            };
        case SET_FETCHING_OUTLIERS:
            return {
                ...state,
                fetchingOutliers: action.fetching,
            };
        case SET_OUTLIERS:
            return {
                ...state,
                outliers: action.outliers,
            };
        case SET_SELECTED_REPORT_TOPIC_SECTION_ID: {
            return {
                ...state,
                selectedReportTopicSectionId: action.selectedReportTopicSectionId,
            };
        }
        case RESET_DATA_TABLE:
            return {
                ...state,
                selectedReportFilters: {},
            };
        case RECEIVE_USER_DASHBOARD:
            return {
                ...state,
                userDashboard: action.userDashboard,
            };
        case UPDATE_SINGLE_REPORTTOPIC: {
            var topics = [...state.topics];

            const iIndex = topics.findIndex((t) => t._id === action.reportTopic._id);

            topics[iIndex] = action.reportTopic;
            return {
                ...state,
                // $FlowFixMe
                topics,
            };
        }
        case RECEIVE_REPORT_TOPIC_SECTIONS: {
            let selectedSectionId = state.selectedSectionId;

            if (!selectedSectionId || !action.sections[selectedSectionId]) {
                selectedSectionId = action.sectionOrder[0];
            }

            return {
                ...state,
                sectionData: {
                    sections: action.sections,
                    sectionOrder: action.sectionOrder,
                },
                selectedSectionId: selectedSectionId,
                fetchingReportTopicSections: false,
            };
        }
    }
    return state;
};

export default reporting;

export const getDashboard = (state: ReduxReportingStateType): ?DashboardType => {
    return state.dashboard;
};

export const getReportTopicsForEditing = (state: ReduxReportingStateType): ?Array<ReportTopicType> => {
    if (!state.topicsForEditing) {
        return;
    }
    // $FlowFixMe
    return Object.values(state.topicsForEditing).sort(function (topicA, topicB) {
        return topicA.name.localeCompare(topicB.name);
    });
};

export const getOutlierDataPointsBySchool = (state: ReduxReportingStateType): DataPointsOutlierSchoolDataPointsType => {
    let items = state.outliers;
    if (items === undefined) {
        return {};
    }
    let newDataPoints = {};
    items.forEach(function (item) {
        item.schools.forEach(function (school) {
            let schoolId = school.schoolId;
            if (!newDataPoints[schoolId]) {
                let reportTopics = {};
                Object.keys(item.dataPoint).forEach(function (key) {
                    reportTopics[key] = item.dataPoint[key];
                });
                reportTopics.value = school.value;
                newDataPoints[schoolId] = {
                    reportTopics: [reportTopics],
                    school: {
                        schoolId: schoolId,
                        schoolName: school.schoolName,
                    },
                };
            } else {
                let dataPoint = {};
                Object.keys(item.dataPoint).forEach(function (key) {
                    dataPoint[key] = item.dataPoint[key];
                });
                dataPoint.value = school.value;
                if (newDataPoints[schoolId].reportTopics) {
                    newDataPoints[schoolId].reportTopics.push(dataPoint);
                }
            }
        });
    });
    return newDataPoints;
};

export const getOutlierSearchField = (state: ReduxReportingStateType): ?string => {
    return state.searchFilter;
};

export const getReportErrorMessage = (state: ReduxReportingStateType): ?string => {
    return state.errorMessage;
};

export const getSelectedReportTopicId = (state: ReduxReportingStateType): ?string => {
    return state.selectedReportTopicId;
};

export const getRatioData = (state: ReduxReportingStateType): ?ReportRatiosResponseType => {
    return state.ratioData;
};

export const getReportFilters = (state: ReduxReportingStateType): ?Array<ReportFilterType> => {
    return state.filters;
};

export const getReportData = (state: ReduxReportingStateType): ?ReportDataType => {
    return state.data;
};

export const getReportTopicsBySection = (state: ReduxReportingStateType): ?Array<ReportTopicSectionType> => {
    return state.topics;
};

export const getReportTopics = (state: ReduxReportingStateType): ?Array<ReportTopicType> => {
    if (state.flattenedTopics) {
        return state.flattenedTopics;
    }
    if (state.topics) {
        let topics = [];
        state.topics.forEach((s) => (topics = topics.concat(s.topics)));
        return topics.filter((t) => t && t.type === 'numeric').sort((t1, t2) => t1.name.localeCompare(t2.name));
    }
};

export const isFetchingReportTopicSections = (state: ReduxReportingStateType): boolean => {
    return state.fetchingReportTopicSections;
};

export const isFetchingReportData = (state: ReduxReportingStateType): boolean => {
    return state.fetchingReportData;
};

export const isFetchingRatioData = (state: ReduxReportingStateType): boolean => {
    return state.fetchingRatioData;
};

export const isFetchingReportFilters = (state: ReduxReportingStateType): boolean => {
    return state.fetchingReportFilters;
};

export const isFetchingReportTopics = (state: ReduxReportingStateType): boolean => {
    return state.fetchingReportTopics;
};

export const getSelectedReportFilters = (state: ReduxReportingStateType): SelectedReportFilterType => {
    return state.selectedReportFilters;
};

export const getAllSchoolReportData = (state: ReduxReportingStateType): ?Array<AllSchoolReportDataType> => {
    return state.allSchoolReportData;
};

export const getOutlierDataPoints = (state: ReduxReportingStateType): ?Array<OutlierDataPointSchoolsType> => {
    return state.outliers;
};

export const getUserDashboard = (state: ReduxReportingStateType): ?UserDashboardType => {
    return state.userDashboard;
};

export const getReportTopicSectionData = (state: ReduxReportingStateType): ?ReportTopicSectionDataType => {
    if (!state.topics || state.filter === '') {
        return state.sectionData;
    }
    if (!state.sectionData) {
        return state.sectionData;
    }
    const oriSectionData = {
        ...state.sectionData,
    };
    if (!oriSectionData) {
        return oriSectionData;
    }

    return state.sectionData;
};

export const getSelectedReportTopicSectionId = (state: ReduxReportingStateType): string => {
    return state.selectedReportTopicSectionId;
};
