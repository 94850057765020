// @flow
'use strict';

import axios from 'axios';
import * as Utils from '../utils';

const Constants = Utils.Constants;

const BaseUrl = Constants.apiBaseUrl + 'reports/';

const Reports = {};

const getTopics: (schoolId?: string, flattened?: boolean) => Promise<Array<ReportTopicSectionType>> = (schoolId, flattened) => {
    return axios
        .get(BaseUrl + 'topics', {
            params: {
                school: schoolId,
                flattened: flattened,
            },
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Dashboard returned from Api');
            }
            return response.data;
        });
};

Reports.getTopics = getTopics;

const getTopicsForEditing: () => Promise<Array<ReportTopicSectionType> | Array<ReportTopicType>> = () => {
    return axios.get(BaseUrl + 'topics', { withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('No Dashboard returned from Api');
        }
        return response.data;
    });
};

Reports.getTopicsForEditing = getTopicsForEditing;

const getReportData: (schoolId: string, reportId: string, selectedFilters: SelectedReportFilterType) => Promise<ReportDataType> = (schoolId, reportId, selectedFilters) => {
    let requestFilters = {};
    Object.keys(selectedFilters).forEach(function (key) {
        if (key !== 'topics' && selectedFilters[key] !== '') {
            requestFilters[key] = selectedFilters[key];
        }
    });
    return axios
        .get(BaseUrl + reportId + '/data', {
            params: Object.assign({ school: schoolId }, requestFilters),
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Dashboard returned from Api');
            }
            return response.data;
        });
};

Reports.getReportData = getReportData;

Reports.getRatiosForSchool = (schoolId: string): Promise<ReportRatiosResponseType> => {
    return axios
        .get(Constants.apiBaseUrl + 'ratios', {
            params: { school: schoolId },
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Dashboard returned from Api');
            }
            return response.data;
        });
};

Reports.getRatiosForEditing = () => {
    return axios
        .get(Constants.apiBaseUrl + 'ratios', {
            params: { forEditing: true },
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Dashboard returned from Api');
            }
            return response.data;
        });
};

Reports.getDataPointsForOutliers = (year: YearNumberType) => {
    return axios
        .get(BaseUrl + 'outliers', {
            params: { year: year },
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Outliers returned from Api');
            }
            return response.data;
        });
};

Reports.getAllSchoolReportData = (topicId: string): Promise<AllSchoolReportDataType> => {
    return axios
        .get(BaseUrl + topicId + '/data', {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Outliers returned from Api');
            }
            return response.data;
        });
};

Reports.fetchDataTables = (): Promise<Array<DataTableShortType>> => {
    return axios
        .get(BaseUrl + 'datatables', {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Data Tables returned from Api');
            }
            return response.data;
        });
};

Reports.fetchDataTable = (schoolId: string, dataTableId: string, selected: ?SelectedReportFilterType = {}): Promise<DataTableType> => {
    const params = {
        school: schoolId,
        years: parseInt(Constants.currentYear),
    };
    if (selected) {
        Object.assign(params, selected);
    }
    return axios
        .get(BaseUrl + 'datatables/' + dataTableId, {
            withCredentials: true,
            params,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Data Tables returned from Api');
            }
            return response.data;
        });
};

Reports.fetchCustomDataTable = (dataTable: DataTableCreateType): Promise<DataTableType> => {
    let dt = {
        ...dataTable,
        rows: dataTable.rows.map((d) => {
            if (d.type === 'header') {
                return d;
            }

            return {
                ...d,
                value: !d.value ? undefined : 'tp-' + d.value._id,
                modifier: !d.modifier ? undefined : 'tp-' + d.modifier._id,
            };
        }),
    };
    return axios
        .put(BaseUrl + 'datatables', dt, {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Data Tables returned from Api');
            }
            return response.data;
        });
};

const fetchRatios: (schoolId: string, year: number) => Promise<ReportDataType> = (schoolId, year) => {
    return axios
        .get(Constants.apiBaseUrl + 'answers', {
            params: { school: schoolId, year },
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Dashboard returned from Api');
            }
            return response.data;
        });
};

Reports.fetchRatios = fetchRatios;

const updateReportTopic = (reportTopicId, update) => {
    return axios.put(Constants.apiBaseUrl + `reports/${reportTopicId}`, { ...update }, { withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('Error saving report topic');
        }
        return response.data;
    });
};

Reports.updateReportTopic = updateReportTopic;

const getAllSections: () => Promise<Array<ReportTopicSectionType>> = () => {
    return axios
        .get(Constants.apiBaseUrl + 'reports/sections', {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No sections returned from Api');
            }

            return response.data;
        });
};

Reports.getAllSections = getAllSections;

const getReportTopicsForEditing = (section?: string): Promise<Array<ReportDataType>> => {
    var url = Constants.apiBaseUrl + 'reports/topicsForEditing';
    var urlQuery = {};
    if (section && section !== '') {
        urlQuery.section = section;
    }

    return axios.get(url, { params: urlQuery, withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('No user returned from Api');
        }
        return response.data;
    });
};

Reports.getReportTopicsForEditing = getReportTopicsForEditing;

export default Reports;
