// @flow
'use strict';

import axios from 'axios';
import * as Utils from '../utils';

const Constants = Utils.Constants;

const BaseUrl = Constants.apiBaseUrl + 'categoryreports';

const CategoryReports = {};

const getReport: (type: 'revenues' | 'expenses', year: number) => Promise<Array<any>> = (type, year) => {
    return axios
        .get(BaseUrl + '/' + type + '/' + year, {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Data returned from Api');
            }
            return response.data;
        });
};

CategoryReports.getReport = getReport;

const getSchoolReport: (schoolId: string, type: 'revenues' | 'expenses', year: number) => Promise<Array<any>> = (schoolId, type, year) => {
    return axios
        .get(BaseUrl + '/' + schoolId + '/' + type + '/' + year, {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Data returned from Api');
            }
            return response.data;
        });
};

CategoryReports.getSchoolReport = getSchoolReport;

const downloadPowerpointSlides: (image: string) => Promise<string> = (image) => {
    return axios.post(BaseUrl + '/ppt', { image }, { responseType: 'blob', withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('No File returned from Api');
        }
        return response.data;
    });
};

CategoryReports.downloadPowerpointSlides = downloadPowerpointSlides;

const getCategoryReportTypes: () => Promise<Array<any>> = () => {
    return axios
        .get(BaseUrl, {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Data returned from Api');
            }
            return response.data;
        });
};

CategoryReports.getCategoryReportTypes = getCategoryReportTypes;

export default CategoryReports;
