// import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { getSelectedSchool, getUser } from '../redux/reducers/index.jsx';
import { gtag } from 'ga-gtag';

export const GA_EVENTS = {
    PAGEVIEW: {
        NAME: 'page_view',
    },
    SECURITY: {
        NAME: 'Security',
        EVENTS: {
            LOG_IN: 'biis_login',
            LOG_OUT: 'biis_logout',
        },
    },
    REPORTING: {
        NAME: 'Reporting',
        EVENTS: {
            CREATE_PEER_GROUP: 'biis_create_peer_group',
            CREATE_CHART: 'biis_create_chart',
            DOWNLOAD_CHART_DATA: 'biis_download_chart_data',

            VIEW_FPS_RATIOS: 'biis_view_fps_rat ios',
            VIEW_FPS_RANGE_TABLE: 'biis_view_fps_range_table',
            VIEW_CFI_CALCULATIONS: 'biis_view_cfi_calculations',
            VIEW_FSI_CALCULATIONS: 'biis_view_fsi_calculations',
        },
    },
    RESOURCES: {
        NAME: 'Resources',
        EVENTS: {
            DOWNLOAD_BIIS_PRIMER: 'biis_download_biis_primer',
            DOWNLOAD_HEAT_MAP_USER_GUIDE: 'biis_download_heat_map_user_guide',
        },
    },
    DASHBOARDS: {
        NAME: 'Dashboards',
        EVENTS: {
            VIEW_HEAT_MAP_CALCULATIONS: 'biis_view_heat_map_calculations',
            VIEW_CFI_DASHBOARD: 'biis_view_cfi_dashboard',
            DOWNLOAD_CFI_DASHBOARD_SLIDES: 'biis_download_cfi_dashboard_slides',
            CLICK_CFI_DASHBOARD_PDF_BUTTON: 'biis_click_cfi_dashboard_pdf_button',
            VIEW_FINANCIAL_DASHBOARD: 'biis_view_financial_dashboard',
            DOWNLOAD_FINANCIAL_DASHBOARD_SLIDES: 'biis_download_financial_dashboard_slides',
            CLICK_FINANCIAL_DASHBOARD_PDF_BUTTON: 'biis_click_financial_dashboard_pdf_button',
            VIEW_HEAT_MAP: 'biis_view_heat_map',
            CHANGE_HEAT_MAP_TARGET: 'biis_change_heat_map_target',
            DOWNLOAD_HEAT_MAP_PDF: 'biis_download_heat_map_pdf',
        },
    },
};

export const GA_DIMENSIONS = {
    SCHOOL_NAME: 'dimension1',
    SCHOOL_TYPE: 'dimension2',
    STATE: 'dimension3',
    IS_PRIMARY_CONTACT: 'dimension4',
    IS_NYSAIS_SCHOOL: 'dimension5',
};

export const useTrackEvents = () => {
    const userFromState = useSelector(getUser);
    const schoolFromState = useSelector(getSelectedSchool);

    return (action, category, label, user, school) => {
        const u = user || userFromState;
        const s = school || schoolFromState;
        let eventPayload = {
            category,
            label,
            page: [location.pathname, location.search, location.hash].join(''),
        };
        if (u && s && !u.nboaAdmin) {
            // try using assign to event payload
            eventPayload = Object.assign(eventPayload, {
                [GA_DIMENSIONS.SCHOOL_NAME]: s.name,
                [GA_DIMENSIONS.SCHOOL_TYPE]: s.schoolType,
                [GA_DIMENSIONS.STATE]: s.state,
                [GA_DIMENSIONS.IS_PRIMARY_CONTACT]: u._id === s.primaryContact ? 'Y' : 'N',
                [GA_DIMENSIONS.IS_NYSAIS_SCHOOL]: u.associationUser || u.associationAdmin ? 'Y' : 'N',
            });
        } else if (u && !u.nboaAdmin) {
            // try using assign to event payload
            eventPayload = Object.assign(eventPayload, {
                [GA_DIMENSIONS.IS_PRIMARY_CONTACT]: u.primaryContact ? 'Y' : 'N',
                [GA_DIMENSIONS.IS_NYSAIS_SCHOOL]: u.associationUser || u.associationAdmin ? 'Y' : 'N',
            });
        }

        if (userFromState && !userFromState.impersonating) {
            gtag('event', action, eventPayload);
        }
    };
};
