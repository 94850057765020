// @flow
'use strict';

import { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ToastContainer, toast } from 'react-toastify';
import Header from './components/Header';
import ErrorBoundary from './components/ErrorBoundary';
import LoadingAnimation from './components/LoadingAnimation';
import UserDashboard from './views/UserDashboard';
import HomePage from './views/HomePage';

import { routesForUser } from './routes';

import { getSelectedSchool, getUser } from './redux/reducers';

import styles from './stylesheets/app.scss';
import { ReportsContextProvider } from './context/reports';
import 'react-toastify/dist/ReactToastify.css';

const waitingComponent: (Component: React$ComponentType<*>) => (props: *) => React$Element<*> = (Component) => {
    const waitingComponent = (props) => {
        return <Suspense fallback={<div>Loading...</div>}>{<Component {...props} />}</Suspense>;
    };
    return waitingComponent;
};
export function App() {
    const selectedSchool = useSelector(getSelectedSchool);
    const user = useSelector(getUser);

    const renderSubRoutes: (route: RouteType) => Array<React$Element<*>> = (route) => {
        if (!route.subRoutes) {
            return [];
        }
        const subRoutes = [];
        subRoutes.push(<Redirect key={'routes-' + route.path + '-redirect'} exact={true} from={route.path} to={route.path + (route.subRoutes[0] && route.subRoutes[0].path)} />);
        route.subRoutes.forEach(function (subName) {
            if (subName.subRoutes) {
                subRoutes.push(
                    <Route key={'route-' + route.path + '-' + subName.path} path={route.path + subName.path}>
                        <Switch>{renderSecondarySubRoutes(route.path, subName)}</Switch>
                    </Route>,
                );
            } else if (subName.component) {
                subRoutes.push(
                    <Route
                        key={'route-' + route.path + '-' + subName.path}
                        path={route.path + subName.path}
                        render={(props: any = {}) => {
                            // $FlowFixMe
                            const Component = waitingComponent(subName.component);
                            // $FlowFixMe
                            if (subName.props) {
                                const p = {
                                    ...props,
                                    ...subName.props,
                                };
                                return <Component {...p} />;
                            } else {
                                return <Component {...props} />;
                            }
                        }}
                    />,
                );
            }
        });
        return subRoutes;
    };

    const renderSecondarySubRoutes: (name: string, subName: any) => Array<React$Element<any>> = (name, subName) => {
        const secondarySubRoutes = [];
        if (subName.subRoutes) {
            secondarySubRoutes.push(<Redirect key={'routes-' + name + '-redirect'} exact={true} from={name + subName.path} to={name + subName.path + subName.subRoutes[0].path} />);
            subName.subRoutes.forEach(function (secondarySubName) {
                secondarySubRoutes.push(
                    <Route
                        key={'route-' + name + '-' + subName.path + '-' + secondarySubName.path}
                        path={name + subName.path + secondarySubName.path}
                        component={waitingComponent(secondarySubName.component)}
                    />,
                );
            });
        }
        return secondarySubRoutes;
    };

    const renderRoutes: () => Array<*> = () => {
        const routes = [];
        routes.push(<Route key='route-dashboard' path={'/'} exact={true} component={UserDashboard} />);
        routesForUser(user, selectedSchool).forEach(function (route) {
            let name = route.fullPath || route.path;
            if (route.subRoutes) {
                routes.push(
                    <Route key={'route' + name} path={name}>
                        <Switch>{renderSubRoutes(route)}</Switch>
                    </Route>,
                );
            } else if (route.component) {
                const Component = waitingComponent(route.component);
                routes.push(
                    <Route key={'route' + name} path={name}>
                        <Component />
                    </Route>,
                );
            }
        });
        routes.push(
            <Route key='unknown-redirect' path='*'>
                <Redirect to='/' />
            </Route>,
        );
        return routes;
    };

    return (
        <div className={styles.app}>
            {user && Object.keys(user).length > 0 && <Header />}
            <ErrorBoundary>
                <LoadingAnimation />
                <ToastContainer hideProgressBar={true} position={toast.POSITION.TOP_CENTER} autoClose={2000} />
                <DndProvider backend={HTML5Backend}>
                    <div className={styles.content}>
                        {user && Object.keys(user).length > 0 ? (
                            <Switch>
                                <ReportsContextProvider>{renderRoutes()}</ReportsContextProvider>
                            </Switch>
                        ) : (
                            <HomePage />
                        )}
                    </div>
                </DndProvider>
            </ErrorBoundary>
        </div>
    );
}

export default App;
