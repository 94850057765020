// @flow
'use strict';

import { Box, Button } from '@mui/material';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import api from '../../api';
import { ExcelIcon } from '../../icons';
import { setLoading, unsetLoading } from '../../redux/actions';
import styles from './schoolStatusTable.scss';

type PropTypes = {|
    className?: string,
    status: SchoolStatusKeyType,
    selected: boolean,
    selectStatus: (status: SchoolStatusKeyType) => void,
    selectSchool: (_id: string) => void,
    schools: Array<SingleSchoolStatusType>,
|};

const titleForStatus = {
    saved: 'Saved',
    submitted: 'Submitted',
    reviewed: 'Reviewed',
};

export default function SchoolStatusTable(props: PropTypes) {
    let className = styles.wrapper;
    const selectSchool = props.selectSchool;
    if (props.className) {
        className += ' ' + props.className;
    }
    if (props.selected) {
        className += ' ' + styles.fullHeight;
    }
    const dispatch = useDispatch();

    const handleDownload = async (status) => {
        try {
            dispatch(setLoading());
            let response;
            let fileName;
            fileName = `school-status-${dayjs().format('YYYY-MM-DD-HH-mm-ss')}.xlsx`;
            response = await api.Schools.downloadReviewedStatusReports(status);
            let blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, fileName);
        } catch (error) {
            toast.error('An error occured.  Please contact administrator.');
        } finally {
            dispatch(unsetLoading());
        }
    };
    return (
        <div className={className}>
            <div className={styles.headerDiv}>
                <Box
                    // test-id='selectbtn'
                    className={styles.statusButton}
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                    onClick={() => props.selectStatus(props.status)}
                >
                    <div>{(props.selected ? '-' : '+') + '  ' + titleForStatus[props.status] + ' - ' + props.schools.length}</div>
                    {titleForStatus[props.status] === 'Reviewed' && (
                        <Button
                            sx={{ backgroundColor: '#417851', marginTop: '0.2rem' }}
                            variant='contained'
                            key='downloadExport'
                            id='downloadExport'
                            onClick={async (e) => {
                                e.stopPropagation();
                                await handleDownload(props.status);
                            }}
                            startIcon={<ExcelIcon />}
                        >
                            Download
                        </Button>
                    )}
                </Box>
            </div>
            {!props.selected ? null : (
                <div className={styles.schoolStatusTableWrapper}>
                    <table className={styles.schoolStatusTable}>
                        <thead>
                            <tr>
                                <th>School</th>
                                <th>Contact Email</th>
                                <th>{props.status === 'reviewed' ? null : 'Answered'}</th>
                                <th>{props.status === 'reviewed' ? null : 'Required'}</th>
                                <th>{props.status === 'reviewed' ? null : 'Diff'}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.schools.map(function (school) {
                                let diff;
                                if (props.status !== 'reviewed' && school.requiredCounts) {
                                    diff = school.requiredCounts.required - school.requiredCounts.answered;
                                }
                                return (
                                    <tr className={diff && diff > 3 ? styles.diff : ''} key={school._id}>
                                        <td>
                                            <button
                                                // test-id='schoolselectbtn'
                                                className={styles.schoolButton}
                                                onClick={() => selectSchool(school._id)}
                                            >
                                                {school.name}
                                            </button>
                                        </td>
                                        <td>{!school.updatedBy ? '' : <a href={'mailto:' + school.updatedBy}>{school.updatedBy}</a>}</td>
                                        <td>{props.status === 'reviewed' ? null : school.requiredCounts.answered}</td>
                                        <td>{props.status === 'reviewed' ? null : school.requiredCounts.required}</td>
                                        <td>{props.status === 'reviewed' ? null : diff}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}
