// @flow
'use strict';

const possibleYearsNumbers: Array<number> = [];
for (let i = parseInt(process.env.CURRENT_YEAR || new Date().getFullYear()); i >= parseInt(process.env.START_YEAR || 2009); i--) {
    possibleYearsNumbers.push(i);
}
export const possibleYears: Array<string> = possibleYearsNumbers.map((year) => '' + year);

let currentYear = process.env.CURRENT_YEAR;
currentYear = possibleYears.find((y) => y === currentYear) ? currentYear : possibleYears[possibleYears.length - 1];

let startYear = process.env.START_YEAR;
startYear = possibleYears.find((y) => y === startYear) ? startYear : possibleYears[0];

const appConstants = {
    apiBaseUrl: process.env.API_BASE_URL || '',
    xWebBaseUrl: process.env.XWEB_BASE_URL || '',
    environment: '', //process.env.ENVIRONMENT,
    currentYear,
    startYear,
    possibleYears,
    graylog: {
        host: process.env.GRAYLOG_HOST || 'graylog.msuccess.org',
        port: parseInt(process.env.GRAYLOG_PORT || '12201'),
        bufferSize: parseInt(process.env.GRAYLOG_BUFFERSIZE || '262144'),
    },
    daslUrl: process.env.DASL_URL || 'https://devdasl.nais.org/DataOverTime',
    gaMeasurementId: process.env.GA_MEASUREMENT_ID,
    peergroupCutoffYear: process.env.PEERGROUP_CUTOFF_YEAR,
    //['School, USA', 'School, N American', 'School, International']
};
if (!appConstants.apiBaseUrl.endsWith('/')) {
    appConstants.apiBaseUrl += '/';
}

export default appConstants;
