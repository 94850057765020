export const initialState = {
    searchText: '',
    selectedSectionId: null,
    selectedTopicId: null,
    selectedSchoolId: null,

    isFetchingReportData: false,
    isFetchingReportTopics: false,
    isFetchingReportTopicSections: false,
    topics: null,
    reportData: null,
    filters: null,
    selectedReportFilters: {},
    errorMessage: null,
    sectionData: null,
};

export const REPORT_ACTION_TYPES = {
    SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
    SET_SELECTED_SECTION_ID: 'SET_SELECTED_SECTION_ID',
    SET_SELECTED_TOPIC_ID: 'SET_SELECTED_TOPIC_ID',
    SET_SELECTED_SCHOOL_ID: 'SET_SELECTED_SCHOOL_ID',
    SET_IS_FETCHING_REPORT_DATA: 'SET_IS_FETCHING_REPORT_DATA',
    SET_IS_FETCHING_REPORT_TOPICS: 'SET_IS_FETCHING_REPORT_TOPICS',
    SET_IS_FETCHING_REPORT_TOPIC_SECTIONS: 'SET_IS_FETCHING_REPORT_TOPIC_SECTIONS',
    SET_TOPICS: 'SET_TOPICS',
    SET_REPORT_DATA: 'SET_REPORT_DATA',
    SET_FILTERS: 'SET_FILTERS',
    SET_SELECTED_REPORT_FILTERS: 'SET_SELECTED_REPORT_FILTERS',
    SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
    SET_SECTION_DATA: 'SET_SECTION_DATA',
    RESET_REPORTING: 'RESET_REPORTING',
    RESET_REPORT_ERROR_MESSAGE: 'RESET_REPORT_ERROR_MESSAGE',
};

export const setSearchText = (param) => ({
    type: REPORT_ACTION_TYPES.SET_SEARCH_TEXT,
    payload: param,
});

export const setSelectedSectionId = (param) => ({
    type: REPORT_ACTION_TYPES.SET_SELECTED_SECTION_ID,
    payload: param,
});

export const setSelectedTopicId = (param) => ({
    type: REPORT_ACTION_TYPES.SET_SELECTED_TOPIC_ID,
    payload: param,
});

export const setSelectedSchoolId = (param) => ({
    type: REPORT_ACTION_TYPES.SET_SELECTED_SCHOOL_ID,
    payload: param,
});
export const setIsFetchingReportData = (param) => ({
    type: REPORT_ACTION_TYPES.SET_IS_FETCHING_REPORT_DATA,
    payload: param,
});
export const setIsFetchingReportTopics = (param) => ({
    type: REPORT_ACTION_TYPES.SET_IS_FETCHING_REPORT_TOPICS,
    payload: param,
});
export const setIsFetchingReportTopicSections = (param) => ({
    type: REPORT_ACTION_TYPES.SET_IS_FETCHING_REPORT_TOPIC_SECTIONS,
    payload: param,
});
export const setTopics = (param) => ({
    type: REPORT_ACTION_TYPES.SET_TOPICS,
    payload: param,
});
export const setReportData = (param) => ({
    type: REPORT_ACTION_TYPES.SET_REPORT_DATA,
    payload: param,
});
export const setFilters = (param) => ({
    type: REPORT_ACTION_TYPES.SET_FILTERS,
    payload: param,
});
export const setSelectedReportFilters = (param) => ({
    type: REPORT_ACTION_TYPES.SET_SELECTED_REPORT_FILTERS,
    payload: param,
});
export const setErrorMessage = (param) => ({
    type: REPORT_ACTION_TYPES.SET_ERROR_MESSAGE,
    payload: param,
});
export const setSectionData = (param) => ({
    type: REPORT_ACTION_TYPES.SET_SECTION_DATA,
    payload: param,
});
export const resetReporting = () => ({
    type: REPORT_ACTION_TYPES.RESET_REPORTING,
});
export const resetReportErrorMessage = () => ({
    type: REPORT_ACTION_TYPES.RESET_REPORTING,
});

const reportsReducer = (state, action) => {
    const newState = { ...state };

    switch (action.type) {
        case REPORT_ACTION_TYPES.SET_SEARCH_TEXT:
            newState.searchText = action.payload;
            return newState;
        case REPORT_ACTION_TYPES.SET_SELECTED_SECTION_ID:
            newState.selectedSectionId = action.payload;
            return newState;
        case REPORT_ACTION_TYPES.SET_SELECTED_TOPIC_ID:
            newState.selectedTopicId = action.payload;
            return newState;
        case REPORT_ACTION_TYPES.SET_SELECTED_SCHOOL_ID:
            newState.selectedSchoolId = action.payload;
            return newState;
        case REPORT_ACTION_TYPES.SET_IS_FETCHING_REPORT_DATA:
            newState.isFetchingReportData = action.payload;
            return newState;
        case REPORT_ACTION_TYPES.SET_IS_FETCHING_REPORT_TOPICS:
            newState.isFetchingReportTopics = action.payload;
            return newState;
        case REPORT_ACTION_TYPES.SET_IS_FETCHING_REPORT_TOPIC_SECTIONS:
            newState.isFetchingReportTopicSections = action.payload;
            return newState;
        case REPORT_ACTION_TYPES.SET_TOPICS:
            newState.topics = action.payload;
            return newState;
        case REPORT_ACTION_TYPES.SET_REPORT_DATA:
            newState.reportData = action.payload;
            return newState;
        case REPORT_ACTION_TYPES.SET_FILTERS:
            newState.filters = action.payload;
            return newState;
        case REPORT_ACTION_TYPES.SET_SELECTED_REPORT_FILTERS:
            newState.selectedReportFilters = action.payload;
            return newState;
        case REPORT_ACTION_TYPES.SET_ERROR_MESSAGE:
            newState.errorMessage = action.payload;
            return newState;
        case REPORT_ACTION_TYPES.SET_SECTION_DATA:
            newState.sectionData = action.payload;
            return newState;
        case REPORT_ACTION_TYPES.RESET_REPORT_ERROR_MESSAGE:
            newState.errorMessage = '';
            return newState;
        case REPORT_ACTION_TYPES.RESET_REPORTING:
            return initialState;
        default:
            return state;
    }
};

export default reportsReducer;
