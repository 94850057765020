// @flow
'use strict';

import {
    FETCHING_DATAPOINTS_DATA,
    FETCHING_DATAPOINTS_SECTIONS,
    RECEIVE_DATAPOINTS_DATA,
    RECEIVE_DATAPOINTS_FOR_SECTION,
    RECEIVE_DATAPOINTS_SECTIONS,
    RECEIVE_REQUIRED_COUNTS,
    RECEIVE_UPDATED_DATAPOINTS,
    RECEIVE_UPDATED_STATUS,
    RESET_DATAPOINTS_DATA,
    RESET_DATAPOINTS_SECTIONS,
    SET_DISPLAY_ALL,
    SET_DISPLAY_UNANSWERED,
    SET_SELECTED_SECTION_AND_SUBSECTION_ID,
    SET_SELECTED_SECTION_ID,
    SET_SELECTED_SUBSECTION_ID,
    UNSET_DISPLAY_ALL,
    SET_DATAPOINT_FILTERS,
    SET_FETCHING_FILTERS,
    SET_DATA_POINTS,
    UPDATE_SINGLE_DATAPOINT,
    SELECTED_YEAR,
    RESET_DATAPOINTS,
    SET_SELECTED_FILTER,
} from '../actions/constants';

import { values } from 'lodash';

export const defaultState: ReduxDataPointStateType = {
    dataPoints: undefined,
    displayAll: false,
    displayUnanswered: false,
    fetchingDataPoints: false,
    fetchingDataPointSections: false,
    requiredCounts: undefined,
    selectedSectionId: undefined,
    selectedSubsectionId: undefined,
    fetchingFilters: false,
    filter: '',
};

const dataPoints = (
    state: ReduxDataPointStateType = defaultState,
    action: ReduxDataPointActionType,
): ReduxDataPointStateType => {
    switch (action.type) {
        case SET_SELECTED_FILTER:
            return {
                ...state,
                filter: action.filter,
            };
        case RECEIVE_DATAPOINTS_DATA:
            return {
                ...state,
                dataPoints: action.dataPoints,
                fetchingDataPoints: false,
            };
        case RECEIVE_DATAPOINTS_SECTIONS: {
            let selectedSectionId = state.selectedSectionId;
            let selectedSubsectionId = state.selectedSubsectionId;
            if (!selectedSectionId || !action.sections[selectedSectionId]) {
                selectedSectionId = action.sectionOrder[0];
                selectedSubsectionId = undefined;
            }
            const section = action.sections[selectedSectionId];
            if (
                (section && !selectedSubsectionId) ||
                !section.subsections ||
                !section.subsections.find((s) => s._id === selectedSubsectionId)
            ) {
                selectedSubsectionId = section.subsections[0]._id;
            }
            return {
                ...state,
                sectionData: {
                    sections: action.sections,
                    sectionOrder: action.sectionOrder,
                },
                selectedSectionId: selectedSectionId,
                selectedSubsectionId: selectedSubsectionId,
                fetchingDataPointSections: false,
            };
        }
        case RESET_DATAPOINTS_DATA:
            return {
                ...defaultState,
            };
        case RESET_DATAPOINTS:
            return {
                ...state,
                dataPoints: undefined,
            };
        case RESET_DATAPOINTS_SECTIONS:
            return {
                ...state,
                sectionData: undefined,
                fetchingDataPointSections: false,
            };
        case FETCHING_DATAPOINTS_DATA:
            return {
                ...state,
                fetchingDataPoints: action.fetching || false,
            };
        case FETCHING_DATAPOINTS_SECTIONS: {
            return {
                ...state,
                fetchingDataPointSections: action.fetching || false,
            };
        }
        case RECEIVE_DATAPOINTS_FOR_SECTION: {
            return {
                ...state,
                // $FlowFixMe
                dataPoints: {
                    ...action.dataPointResponse.dataPoints,
                },
            };
        }
        case RECEIVE_UPDATED_DATAPOINTS: {
            // $FlowFixMe
            let dataPoints = {
                ...state.dataPoints,
                ...action.dataPoints,
            };
            return {
                ...state,
                dataPoints,
            };
        }
        case RECEIVE_UPDATED_STATUS: {
            return {
                ...state,
                status: action.status,
            };
        }
        case RECEIVE_REQUIRED_COUNTS: {
            return {
                ...state,
                requiredCounts: action.requiredCounts,
            };
        }
        case SET_DISPLAY_ALL: {
            return {
                ...state,
                displayAll: true,
                fetchingDataPoints: false,
                fetchingDataPointSections: false,
                dataPoints: undefined,
                requiredCounts: undefined,
            };
        }
        case UNSET_DISPLAY_ALL: {
            return {
                ...state,
                displayAll: false,
                fetchingDataPoints: false,
                fetchingDataPointSections: false,
                dataPoints: undefined,
                requiredCounts: undefined,
            };
        }
        case SET_DISPLAY_UNANSWERED: {
            return {
                ...state,
                displayUnanswered: action.isDisplayingUnanswered,
                sectionData: undefined,
                fetchingDataPoints: false,
                fetchingDataPointSections: false,
                dataPoints: undefined,
                requiredCounts: undefined,
            };
        }
        case SET_SELECTED_SECTION_ID: {
            return {
                ...state,
                selectedSectionId: action.selectedSectionId,
            };
        }
        case SET_SELECTED_SUBSECTION_ID: {
            return {
                ...state,
                selectedSubsectionId: action.selectedSubsectionId,
            };
        }
        case SET_SELECTED_SECTION_AND_SUBSECTION_ID: {
            return {
                ...state,
                selectedSectionId: action.selectedSectionId,
                selectedSubsectionId: action.selectedSubsectionId,
            };
        }
        case SET_DATAPOINT_FILTERS: {
            return {
                ...state,
                dataPointFilters: action.filters,
            };
        }
        case SET_FETCHING_FILTERS: {
            return {
                ...state,
                fetchingFilters: action.fetching,
            };
        }
        case SET_DATA_POINTS: {
            return {
                ...state,
                dataPoints: action.dataPoints,
            };
        }
        case UPDATE_SINGLE_DATAPOINT: {
            var dataPoints = {
                ...state.dataPoints,
            };
            dataPoints[action.dataPoint._id] = action.dataPoint;
            return {
                ...state,
                // $FlowFixMe
                dataPoints: dataPoints,
            };
        }
        case SELECTED_YEAR: {
            return {
                ...state,
                dataPoints: undefined,
                requiredCounts: undefined,
            };
        }
    }
    return state;
};

export default dataPoints;

export const isFetchingDataPoints = (state: ReduxDataPointStateType): boolean => {
    return state.fetchingDataPoints;
};

export const getDataPointsForEditing = (state: ReduxDataPointStateType): ?Array<DataPointType> => {
    if (!state.dataPoints) {
        return;
    }
    // $FlowFixMe
    return Object.values(state.dataPoints).sort((d1, d2) => d1.order - d2.order);
};

export const getDataPoints = (
    state: ReduxDataPointStateType,
    sectionId?: string,
    subsectionId?: string,
): ?Array<DataPointType> => {
    if (!state.dataPoints) {
        return;
    }
    const filter = state.filter;
    let dataPoints: {| [_id: string]: DataPointType |};
    if (!filter || filter === '') {
        dataPoints = {
            ...state.dataPoints,
        };
    } else {
        dataPoints = {};
        values(state.dataPoints)
            .filter((d) => d.filters && d.filters.includes(filter))
            .forEach((d) => (dataPoints[d._id] = d));
    }
    if (!dataPoints) {
        return;
    }
    const sectionData = state.sectionData;
    if (!sectionId || !subsectionId || !sectionData) {
        let dpArray: Array<DataPointType> = values(dataPoints);
        return dpArray.sort(function (dpA, dpB) {
            return dpA.order - dpB.order;
        });
    }
    let dataPointIds = [];
    if (sectionId) {
        const section: DataPointSectionType = sectionData.sections[sectionId];
        if (!section) {
            return;
        }
        if (subsectionId) {
            const subsection = section.subsections.find((sub) => sub._id === subsectionId);
            if (!subsection) {
                return;
            }
            dataPointIds = subsection.dataPoints;
        } else {
            section.subsections
                .sort(function (subA, subB) {
                    return subA.order - subB.order;
                })
                .forEach(function (subsection) {
                    dataPointIds = dataPointIds.concat(subsection.dataPoints);
                });
        }
    }
    if (!dataPointIds) {
        return;
    }
    return dataPointIds.map((dpId) => dataPoints[dpId]);
};

export const getDataPointsForIds = (state: ReduxDataPointStateType, ids: Array<string>): Array<DataPointType> => {
    const dataPoints = state.dataPoints;
    if (!dataPoints) {
        return [];
    }
    return ids.map((id) => dataPoints[id]).filter((dp) => !!dp);
};

export const getDataPointSectionData = (state: ReduxDataPointStateType): ?SectionDataType => {
    if (!state.dataPoints || state.filter === '') {
        return state.sectionData;
    }
    if (!state.sectionData) {
        return state.sectionData;
    }
    const oriSectionData = {
        ...state.sectionData,
    };
    if (!oriSectionData) {
        return oriSectionData;
    }
    const sectionData = {
        sections: {},
        sectionOrder: [],
    };
    if (state.filter !== '') {
        const dataPoints = getDataPoints(state);
        const dataPointIds = dataPoints.map((d) => d._id);
        values(oriSectionData.sections).forEach((s) => {
            const subsections = [];
            s.subsections.forEach((su) => {
                const sub = {
                    ...su,
                };
                sub.dataPoints = sub.dataPoints.filter((dpId) => dataPointIds.includes(dpId));
                if (sub.dataPoints.length > 0) {
                    subsections.push(sub);
                }
            });
            if (subsections.length > 0) {
                sectionData.sections[s._id] = {
                    ...s,
                    subsections,
                };
            }
        });
        sectionData.sectionOrder = values(sectionData.sections)
            .sort((a, b) => {
                return a.order - b.order;
            })
            .map((s) => s._id);
        return sectionData;
    }
    return state.sectionData;
};

export const getSectionById = (state: ReduxDataPointStateType, sectionId: string): ?DataPointSectionType => {
    if (!state.sectionData) {
        return;
    }
    return state.sectionData.sections[sectionId];
};

export const getDataPointById = (state: ReduxDataPointStateType, id: string): ?DataPointType => {
    const dataPoints = state.dataPoints;
    if (!dataPoints || !id) {
        return;
    }
    const dataPoint = dataPoints[id];
    if (!dataPoint) {
        return;
    }
    if (dataPoint.contingency && typeof dataPoint.contingency !== 'string') {
        const contingency = dataPoint.contingency;
        // $FlowFixMe
        delete dataPoint.contingency;
        const contingentDataPoint = dataPoints[contingency._id];
        if (!contingentDataPoint) {
            return;
        }

        if (!contingency.operator) {
            if (contingency.value !== contingentDataPoint.answer) {
                return;
            }
            return dataPoint;
        } else {
            if (contingentDataPoint.answer === undefined || contingentDataPoint.answer === null) {
                return;
            }
            if (contingency.operator === '>') {
                // $FlowFixMe
                return contingentDataPoint.answer > contingency.value;
            }
            if (contingency.operator === 'includes') {
                // $FlowFixMe
                if (!contingentDataPoint.answer.includes(contingency.value)) {
                    return;
                }
            }
        }
    }
    if (dataPoint.innerContingency) {
        // $FlowFixMe
        return {
            ...dataPoint,
            contingency: dataPoint.innerContingency,
        };
    }
    return dataPoint;
};

export const getDataPointAnswerById = (state: ReduxDataPointStateType, dataPointId: string): any => {
    const dataPoint = getDataPointById(state, dataPointId);
    if (dataPoint && dataPoint.answer) {
        return dataPoint.answer;
    }
};

export const getDataPointsForSection = (
    state: ReduxDataPointStateType,
    sectionId: string,
): { [_id: string]: DataPointType } => {
    const stateDataPoints = state.dataPoints;
    if (!stateDataPoints || !sectionId || !state.sectionData || !state.sectionData.sections[sectionId]) {
        return {};
    }
    const dataPoints = {};
    const filter = state.filter;
    Object.keys(stateDataPoints).forEach(function (key) {
        let dataPoint = stateDataPoints[key];
        if (filter === '' || !dataPoint.filters.includes(filter)) {
            return;
        }
        if (dataPoint.section === sectionId) {
            dataPoints[key] = dataPoint;
        }
    });
    return dataPoints;
};

export const getFilter = (state: ReduxDataPointStateType): string => {
    return state.filter;
};

export const isFetchingDataPointSections = (state: ReduxDataPointStateType): boolean => {
    return state.fetchingDataPointSections;
};

export const getRequiredCounts = (state: ReduxDataPointStateType): ?{ required: number, answered: number } => {
    return state.requiredCounts;
};

export const getDataPointsStatus = (state: ReduxDataPointStateType): ?DataPointStatusType => {
    return state.status;
};

export const getSelectedSectionId = (state: ReduxDataPointStateType): ?string => {
    return state.selectedSectionId;
};

export const getSelectedSection = (state: ReduxDataPointStateType): ?DataPointSectionType => {
    let { selectedSectionId, sectionData } = state;
    if (!selectedSectionId || !sectionData) {
        return;
    }
    return sectionData.sections[selectedSectionId];
};

export const getSelectedSubsectionId = (state: ReduxDataPointStateType): ?string => {
    const selectedSectionId = getSelectedSectionId(state);
    if (!selectedSectionId) {
        return state.selectedSubsectionId;
    }
    const selectedSection = getSectionById(state, selectedSectionId);
    if (!selectedSection || !state.selectedSubsectionId) {
        return state.selectedSubsectionId;
    }
    let selectedSubsection = selectedSection.subsections.find(
        (subsection) => subsection._id === state.selectedSubsectionId,
    );
    if (!selectedSubsection) {
        return;
    }
    return selectedSubsection._id;
};

export const isDisplayingAll = (state: ReduxDataPointStateType): boolean => {
    return state.displayAll;
};

export const isDisplayingUnanswered = (state: ReduxDataPointStateType): boolean => {
    return state.displayUnanswered;
};

export const isFirstSubsection = (state: ReduxDataPointStateType): boolean => {
    const sectionData = state.sectionData;
    const selectedSectionId = getSelectedSectionId(state);
    const selectedSubsectionId = getSelectedSubsectionId(state);
    if (!sectionData || !selectedSectionId) {
        return true;
    }
    if (sectionData.sectionOrder[0] !== selectedSectionId) {
        return false;
    }
    const section = sectionData.sections[selectedSectionId];
    return !selectedSubsectionId || section.subsections[0]._id === selectedSubsectionId;
};

export const isLastSubsection = (state: ReduxDataPointStateType): boolean => {
    const sectionData = state.sectionData;
    const selectedSectionId = getSelectedSectionId(state);
    const selectedSubsectionId = getSelectedSubsectionId(state);
    if (!sectionData || !selectedSectionId) {
        return false;
    }
    if (sectionData.sectionOrder[sectionData.sectionOrder.length - 1] !== selectedSectionId) {
        return false;
    }
    const section = sectionData.sections[selectedSectionId];
    return !!(selectedSubsectionId && section.subsections[section.subsections.length - 1]._id === selectedSubsectionId);
};

export const getDataPointFilters = (state: ReduxDataPointStateType): ?Array<DataPointFilterType> => {
    return state.dataPointFilters;
};

export const isFetchingFilters = (state: ReduxDataPointStateType): boolean => {
    return state.fetchingFilters;
};
