// @flow
'use strict';

import { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
import {
    TextField,
    // Accordion, AccordionDetails, AccordionSummary, Typography
} from '@material-ui/core';
import {
    Search,
    // ExpandMore as ExpandMoreIcon
} from '@material-ui/icons';
import styles from './topicSelector.scss';
// import { fetchReportTopicsForEditing } from '../../redux/actions';
import { useReportsContext } from '../../context/reports';
import { setSearchText, setSelectedSectionId, setSelectedTopicId } from '../../context/ducks/reports';
// import { getReportTopicsForEditing } from '../../redux/reducers/reporting';

type OwnProps = {|
    topics: Array<ReportTopicSectionType>,
    sectionData: Array<SectionDataType>,
    showNotAvailableModal: () => void,
|};

type Props = {
    // ...ReduxActionProps,
    ...OwnProps,
};

const TopicSelector = (props: Props) => {
    const [allTopics, setAllTopics] = useState(null);
    const [filteredTopics, setFilteredTopics] = useState([]);
    const [sectionsAndNames, setSectionsAndNames] = useState(null);

    // const { fetchingReportTopics, topicsForEditing } = useSelector((state) => state.reporting);

    const {
        dispatch,
        state: { selectedTopicId, searchText, selectedSectionId },
    } = useReportsContext();

    // const reduxDispatch = useDispatch();

    const handleSearchChange: (event: SyntheticInputEvent<*>) => void = (event) => {
        event.preventDefault();
        dispatch(setSearchText(event.target.value));
    };

    const handleSectionClick: (sectionId: string) => void = (sectionId) => {
        dispatch(setSelectedSectionId(sectionId));
    };

    const handleTopicClick: (topicId: string) => void = (topicId) => {
        dispatch(setSearchText(''));
        dispatch(setSelectedTopicId(topicId));
    };

    useEffect(() => {
        if (props.sectionData) {
            const { sections } = props.sectionData;
            const sectionsAndNames = Object.keys(sections)
                .map((s) => sections[s])
                .sort((sectionA, sectionB) => {
                    return sectionA.name.localeCompare(sectionB.name);
                });

            setSectionsAndNames(sectionsAndNames);
            if (sectionsAndNames.length > 0) dispatch(setSelectedSectionId(sectionsAndNames[0]._id));
        }
    }, [props.sectionData]);

    useEffect(() => {
        const topics = props.topics.reduce((prev, curr) => {
            return [...prev, ...curr.topics];
        }, []);

        const orderedTopics = topics.sort(function (topicA, topicB) {
            return topicA.name.localeCompare(topicB.name);
        });

        setAllTopics(orderedTopics);
        setFilteredTopics(orderedTopics);
    }, [props.topics]);

    useEffect(() => {
        if (allTopics) {
            const searchTextToLowerCase = (searchText || '').toLowerCase();
            const filteredTopics = allTopics.filter((t) => t.name.toLowerCase().includes(searchTextToLowerCase));
            setFilteredTopics(filteredTopics);
        }
    }, [searchText]);

    const renderTabs: () => Array<React$Element<any>> = () => {
        if (!sectionsAndNames) return null;

        return sectionsAndNames.map((section) => {
            const disabled = props.topics.findIndex((t) => section._id === t._id) < 0;

            return (
                <div
                    key={`tab-${section._id}`}
                    onClick={() => {
                        if (!disabled) handleSectionClick(section._id);
                    }}
                    style={{
                        fontWeight: selectedSectionId === section._id ? 'bold' : 'normal',
                        marginBottom: '0.5rem',
                        backgroundColor: selectedSectionId === section._id ? 'rgba(204,230,247,1)' : 'transparent',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        height: '2rem',
                        paddingLeft: '1rem',
                        cursor: disabled ? 'default' : 'pointer',
                        color: disabled ? 'gray' : null,
                    }}
                >
                    {disabled ? (
                        <div
                            style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
                            onClick={() => {
                                props.showNotAvailableModal();
                            }}
                        >
                            <span>{`${section.name}`}&nbsp;</span>
                            <div style={{ color: 'blue' }}>*</div>
                        </div>
                    ) : (
                        <div>{section.name}</div>
                    )}
                </div>
            );
        });
    };

    const renderTabContent: () => Array<React$Element<any>> = () => {
        if (!allTopics) return null;

        if (searchText) {
            return filteredTopics.map((t) => {
                return (
                    <div
                        style={{
                            fontWeight: selectedTopicId === t._id ? 'bold' : 'normal',

                            textDecoration: 'underline',
                            marginBottom: '0.5rem',
                            height: '2rem',
                            paddingLeft: '1rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            cursor: 'pointer',
                        }}
                        key={t._id}
                        onClick={() => {
                            handleTopicClick(t._id);
                        }}
                    >
                        {t.name}
                    </div>
                );
            });
        } else {
            return sectionsAndNames.map((section) => {
                const sectionIndex = filteredTopics.findIndex((t) => section._id === t.section);
                const sectionDisabled = sectionIndex < 0;

                return (
                    <div
                        key={`tabcontent-${section._id}`}
                        style={{
                            display: `${section._id === selectedSectionId ? 'flex' : 'none'}`,
                            flexDirection: 'column',
                            width: '80%',
                            height: '700px',

                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            borderColor: selectedSectionId === section._id ? 'rgba(204,230,247,1)' : 'transparent',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            overflowY: 'auto',
                        }}
                    >
                        {filteredTopics
                            .filter((t) => t.section === section._id)
                            .map((t) => {
                                const disabled = sectionDisabled;
                                if (disabled) return null;
                                return (
                                    <div
                                        style={{
                                            fontWeight: selectedTopicId === t._id ? 'bold' : 'normal',
                                            color: disabled ? 'gray' : null,
                                            textDecoration: disabled ? 'none' : 'underline',
                                            marginBottom: '0.5rem',
                                            height: '2rem',
                                            paddingLeft: '1rem',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            cursor: 'pointer',
                                        }}
                                        key={t._id}
                                        onClick={() => {
                                            if (!disabled) handleTopicClick(t._id);
                                        }}
                                    >
                                        {t.name}
                                    </div>
                                );
                            })}
                    </div>
                );
            });
        }
    };

    return (
        <div className={styles.overlay}>
            <div className={styles.topicSelector}>
                <TextField
                    className={styles.searchWrapper}
                    style={{ marginTop: '1rem' }}
                    onChange={handleSearchChange}
                    value={searchText}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    placeholder='Search by Topic'
                    InputProps={{
                        startAdornment: <Search className={styles.searchLabel} />,
                    }}
                    variant='outlined'
                />
                <div
                    className={styles.topicWrapper}
                    style={{ marginTop: '1rem', display: searchText ? 'block' : 'flex', flex: 1, flexDirection: 'row', alignItems: 'flex-start', marginLeft: '3.25rem' }}
                >
                    {!searchText && (
                        <div style={{ flex: 1.5, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', height: '700px' }}>
                            {renderTabs()}
                            <div style={{ fontWeight: 'bold' }}>* = Why is this not available to me?</div>
                        </div>
                    )}
                    <div style={{ flex: 5, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', height: '700px' }}>{renderTabContent()}</div>
                </div>
            </div>
        </div>
    );
};
export default TopicSelector;
