// @flow
'use strict';

import { useState, useEffect } from 'react';
import {
    FormControl,
    FormHelperText,
    FormLabel,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    Input,
    OutlinedInput,
    Paper,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { FormControlProps } from '@material-ui/core/FormControl/FormControl';

type Props<ItemType> = {|
    items: Array<ItemType>,
    formControlProps?: typeof FormControlProps,
    formHelperTextProps?: any,
    label?: string,
    onChange: (item: ItemType) => void,
    error?: boolean,
    value: any,
    searchFieldPlaceholder?: string,
    keyPropFn?: (option: ItemType) => any,
    itemToString?: (option: ?ItemType) => string,
    helperText?: string,
    variant?: 'standard' | 'outlined' | 'filled',
|};

const useStyles = makeStyles(() => ({
    hidden: {
        display: 'none',
    },
    expand: {
        cursor: 'pointer',
    },
    listWrapper: {
        overflowY: 'auto',
        maxHeight: 450,
    },
}));

export default function SearchableSelect<T>(props: Props<T>) {
    const {
        formControlProps = {},
        label,
        onChange,
        error,
        variant,
        value,
        items,
        formHelperTextProps = {},
        itemToString = (option: ?T) => {
            if (option && option.value && typeof option.value === 'string') {
                return option.value;
            }
            return '';
        },
        helperText,
    } = props;
    const Component = variant && variant == 'outlined' ? OutlinedInput : Input;
    const [isOpen, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState(value ? itemToString(value) : '');

    useEffect(() => {
        if (value && itemToString(value) !== inputValue) {
            setInputValue(itemToString(value));
        }
    }, [value]);

    const classes = useStyles();
    return (
        <FormControl {...formControlProps}>
            {label && <FormLabel>{label}</FormLabel>}
            <Component
                autoFocus
                endAdornment={
                    <InputAdornment className={classes.expand} position='end' onClick={() => setOpen(!isOpen)}>
                        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </InputAdornment>
                }
                name={label}
                value={inputValue}
                onChange={(e) => {
                    if (e.target.value && !isOpen) {
                        setOpen(true);
                    }
                    if (!e.target.value && isOpen) {
                        setOpen(false);
                    }
                    setInputValue(e.target.value);
                }}
            />
            {!isOpen ? null : (
                <Paper className={classes.listWrapper}>
                    <List className={classes.root}>
                        {items
                            .filter(
                                (item) =>
                                    !inputValue ||
                                    itemToString(item)
                                        .toLowerCase()
                                        .includes(inputValue.toLowerCase()),
                            )
                            .map((item, index) => {
                                return (
                                    <ListItem
                                        key={itemToString(item) + '-' + index}
                                        button
                                        onClick={() => {
                                            setOpen(false);
                                            onChange(item);
                                            setInputValue(itemToString(item));
                                        }}
                                    >
                                        <ListItemText primary={itemToString(item)} />
                                    </ListItem>
                                );
                            })}
                    </List>
                </Paper>
            )}
            {helperText && (
                <FormHelperText error={error} {...formHelperTextProps}>
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
}
