// @flow
'use strict';

import {
    SET_LOADING,
    UNSET_LOADING,
    SET_EDIT_DATAPOINTS,
    SELECTED_YEAR,
    RESET_DATAPOINTS_DATA,
    RECEIVE_USER_FROM_API,
    CLOSE_CHROME_WARNING,
    OPEN_CHROME_WARNING,
} from '../actions/constants';

import { Constants } from '../../utils';

export const defaultState = {
    loading: 0,
    loadingIds: new Map(),
    canEdit: false,
    showChromeWarning: true,
};

const settings = (
    state: ReduxSettingsStateType = defaultState,
    action: ReduxSettingsActionType,
): ReduxSettingsStateType => {
    const loadingIds = new Map(state.loadingIds);
    switch (action.type) {
        case SELECTED_YEAR:
            return {
                ...state,
                selectedYear: action.selectedYear,
            };
        case SET_LOADING:
            if (!action.id) {
                return {
                    ...state,
                    loading: state.loading + 1,
                };
            }
            if (action.id && !loadingIds.has(action.id)) {
                loadingIds.set(action.id, 0);
            }
            if (action.id) {
                loadingIds.set(action.id, (loadingIds.get(action.id) || 0) + 1);
            }
            return {
                ...state,
                loadingIds: loadingIds,
            };
        case UNSET_LOADING:
            if (!action.id) {
                return {
                    ...state,
                    loading: state.loading > 0 ? state.loading - 1 : 0,
                };
            }
            if (!loadingIds.has(action.id)) {
                return state;
            }
            var value = loadingIds.get(action.id || '');
            if (!value) {
                loadingIds.delete(action.id || '');
            } else if (value === 1) {
                loadingIds.delete(action.id || '');
            } else {
                loadingIds.set(action.id || '', value - 1);
            }
            return {
                ...state,
                loadingIds: loadingIds,
            };
        case SET_EDIT_DATAPOINTS:
            return {
                ...state,
                canEdit: action.edit,
            };
        case RESET_DATAPOINTS_DATA:
            return {
                ...state,
                canEdit: false,
            };
        case RECEIVE_USER_FROM_API:
            if (!state.selectedYear) {
                return {
                    ...state,
                    // $FlowFixMe
                    selectedYear: action.user.currentYear,
                };
            }
            break;
        case OPEN_CHROME_WARNING:
            return {
                ...state,
                showChromeWarning: true,
            };
        case CLOSE_CHROME_WARNING:
            return {
                ...state,
                showChromeWarning: false,
            };
    }
    return state;
};

export default settings;

export const isLoading = (state: ReduxSettingsStateType): boolean => {
    const loading = state.loading > 0;
    if (loading) {
        return true;
    }
    let found = false;
    for (const v of state.loadingIds.values()) {
        if (v > 0) {
            found = true;
        }
    }
    return found;
};

export const canEdit = (state: ReduxSettingsStateType): boolean => {
    return state.canEdit;
};

export const getSelectedYear = (state: ReduxSettingsStateType): YearNumberType => {
    return state.selectedYear || parseInt(Constants.currentYear);
};

export const showChromeWarning = (state: ReduxSettingsStateType): boolean => {
    return state.showChromeWarning;
};
