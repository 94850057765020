// @flow
'use strict';

import { FormControl, InputLabel, Select, MenuItem, ListSubheader } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
// import { useEffect, useState } from 'react';

type Props = {|
    filterItem: ReportFilterType,
    value: any,
    className?: string,
    onChange?: (id: $Keys<SelectedReportFilterType>, value: string) => void,
    disabled: boolean,
    id?: ?string,
    // selected: SelectedReportFilterType,
    // topics?: ?Array<ReportTopicType>,
|};

const useStyles = makeStyles(() => ({
    frmCtrl: {
        minWidth: 120,
    },
    select: {
        background: 'none',
        borderRadius: 4,
        border: '1px solid rgba(0,0,0,0.87)',
    },
}));

export default function FilterSelect(props: Props) {
    const {
        id,
        filterItem,
        value = '',
        className = '',
        onChange,
        disabled,
        // selected,
        // topics
    } = props;
    const classes = useStyles();

    let filterElements = [];
    let width = filterItem.name.length;
    if (filterItem.sectioned) {
        filterItem.choices.forEach(function (section, sectionIndex) {
            if (!section.choices && section.name && section.name !== '') {
                if (section.name.length > width) {
                    width = section.name.length;
                }
                if (section.name) {
                    filterElements.push(
                        <MenuItem key={filterItem._id + '-' + (section._id || '') + sectionIndex} value={section._id}>
                            {section.name}
                        </MenuItem>,
                    );
                }
                return;
            }
            filterElements.push(<ListSubheader key={filterItem._id + '-' + section._id + '-' + sectionIndex}>{section.name}</ListSubheader>);
            (section.choices || []).forEach(function (choice, choiceIndex) {
                if (choice.name.length > width) {
                    width = choice.name.length;
                }
                filterElements.push(
                    <MenuItem key={filterItem._id + '-' + (section._id || '') + '-' + (choice._id || '') + '-' + choiceIndex} value={choice._id}>
                        {choice.name}
                    </MenuItem>,
                );
            });
        });
    } else {
        filterElements = filterItem.choices.map(function (choice, choiceIndex) {
            if (choice.name.length > width) {
                width = choice.name.length;
            }
            return (
                <MenuItem key={filterItem._id + '-' + (choice._id || '') + '-' + choiceIndex} value={choice._id}>
                    {choice.name}
                </MenuItem>
            );
        });
    }

    //
    width = width * 12;
    return (
        <FormControl style={{ width: width + 'px' }} className={className + ' ' + classes.frmCtrl} disabled={disabled} variant='filled'>
            <InputLabel id={id}>{filterItem.name}</InputLabel>
            <Select
                className={classes.select}
                multiple={filterItem.multiSelect}
                labelId={filterItem.name + 'label'}
                value={value}
                onChange={(event) => {
                    if (onChange) {
                        if (typeof event.target.value === 'string' || event.target.value.findIndex((x) => x === '') < 0) {
                            onChange(filterItem._id, event.target.value);
                        } else {
                            onChange(filterItem._id, []);
                        }
                    }
                }}
            >
                {filterElements}
            </Select>
        </FormControl>
    );
}
