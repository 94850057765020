// @flow
'use strict';

import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import DataPointInput from '../DataPointInput';
import BufferedDropdown from '../BufferedDropdown';

import { saveAnswerForDataPoint } from '../../redux/actions';
import { isDisplayingUnanswered, getSelectedYear, getSelectedSchoolId, isNboaAdmin, isOnDataCommittee } from '../../redux/reducers';

import { formatValue, formatName, Constants } from '../../utils';

import styles from './dataPointScalarQuestion.scss';

type Props = {|
    canEdit: boolean,
    dataPoint: DataPointScalarType,
    className?: string,
    inputOnly?: boolean,
|};

export function DataPointScalarQuestion(props: Props) {
    const { canEdit, dataPoint, className, inputOnly } = props;
    const displayUnanswered = useSelector(isDisplayingUnanswered);
    const selectedYear = useSelector(getSelectedYear);
    const selectedSchoolId = useSelector(getSelectedSchoolId);
    const admin = useSelector(isNboaAdmin) || useSelector(isOnDataCommittee);
    const dispatch = useDispatch();
    let element;
    const saveAnswer = (params: SavingDataPointAnswerType) => {
        dispatch(saveAnswerForDataPoint(params));
    };
    if (!dataPoint) {
        return <div />;
    }
    const isSelected: () => boolean = () => {
        let selected = false;
        let activeElement = document.activeElement;
        if (activeElement && element) {
            selected = element.contains(activeElement);
        }
        return selected;
    };

    const renderDefinition: () => ?React$Element<any> = () => {
        if (!dataPoint.definition || dataPoint.definition === '') {
            return null;
        }
        return (
            <Tooltip disableFocusListener title={dataPoint.definition}>
                <HelpOutline className={styles.questionInfoButton} />
            </Tooltip>
        );
    };

    const renderName: () => React$Element<*> = () => {
        let className = '';
        if (dataPoint.type === 'text' && dataPoint.format === 'textarea') {
            className = styles.textAreaName;
        } else {
            className = styles.name;
        }
        if (isSelected()) {
            className += ' ' + styles.selected;
        }
        return (
            <div key={'name-' + dataPoint._id} className={className}>
                <p style={{ display: 'inline-block' }}>{formatName(dataPoint.name, selectedYear)}</p>
                {renderDefinition()}
            </div>
        );
    };

    //

    const renderHistoricalValue: (input: React$Element<*>, isScrubber: boolean) => React$Element<*> = (input, isScrubber) => {
        if (!isScrubber || !dataPoint || !dataPoint.priorYearAnswer || dataPoint.type !== 'numeric') {
            return input;
        }
        const historicalAnswerText = 'Prior Year Answer: ' + formatValue(dataPoint.format || 'integer', dataPoint.unit, dataPoint.decimalPlaces, dataPoint.priorYearAnswer);

        return (
            <Tooltip disableFocusListener placement='top' title={historicalAnswerText || ''}>
                {input}
            </Tooltip>
        );
    };

    const handleChange: (dataPointId: string, answer: string, year?: string) => void = (dataPointId, oAnswer, year) => {
        let answer;
        if (oAnswer === null || oAnswer === undefined || oAnswer === '') {
            answer = undefined;
        } else {
            if (typeof oAnswer === 'number') {
                answer = '' + oAnswer;
            } else if (typeof oAnswer === 'boolean') {
                answer = oAnswer ? 'true' : 'false';
            } else if (typeof oAnswer === 'string') {
                answer = oAnswer;
            } else if (Array.isArray(oAnswer)) {
                answer = oAnswer.join(',');
            } else {
                throw new Error('Invalid answer type: ' + oAnswer);
            }
        }
        if (!selectedSchoolId) {
            return;
        }
        saveAnswer({
            dataPointId: dataPointId,
            answer: answer,
            schoolId: selectedSchoolId,
            year: year,
        });
    };

    const _renderInput: (value: ?any, year: YearNumberType) => React$Element<any> = (iValue, year) => {
        let value;
        let element;
        if (typeof iValue === 'boolean') {
            value = iValue ? 'true' : 'false';
        } else if (typeof iValue === 'number') {
            value = '' + iValue;
        } else {
            value = iValue;
        }
        let additionalStyle;
        let isError = false;
        let isWarning = false;
        let errorMessages = [];
        let warningMessages = [];
        if (dataPoint.validations) {
            dataPoint.validations.forEach(function (validation, index) {
                if (validation.level === 'error') {
                    additionalStyle = styles.errorBox;
                    isError = true;
                    errorMessages.push(
                        <p key={index} className={styles.errorText}>
                            {validation.text}
                        </p>,
                    );
                } else {
                    if (!additionalStyle) {
                        additionalStyle = styles.warningBox;
                    }
                    isWarning = true;

                    warningMessages.push(
                        <p key={index} className={styles.warningText}>
                            {validation.text}
                        </p>,
                    );
                }
            });
        }
        const unanswered = displayUnanswered && !value && dataPoint.required;
        if (unanswered) {
            isError = true;
            errorMessages = [
                <p key={'-1'} className={styles.errorText}>
                    This question is required!
                </p>,
            ].concat(errorMessages);
        }
        let messages = errorMessages.concat(warningMessages);
        if (dataPoint.type === 'choice' || dataPoint.type === 'multiple-choice') {
            element = (
                <div className={styles.inputWrapper + ' ' + (unanswered ? styles.unanswered : '')}>
                    <BufferedDropdown
                        editable={canEdit}
                        onChange={handleChange}
                        year={year}
                        className={styles.questionChoice + ' ' + (additionalStyle || '')}
                        name={dataPoint._id + '-' + year}
                        choices={dataPoint.choices || []}
                        value={value || ''}
                        multiple={dataPoint.type === 'multiple-choice'}
                    />
                </div>
            );
        } else if (dataPoint.type === 'numeric' || dataPoint.type === 'checkbox' || dataPoint.type === 'text') {
            element = (
                <div className={styles.inputWrapper + ' ' + (unanswered ? styles.unanswered : '')}>
                    <DataPointInput
                        editable={canEdit}
                        year={year}
                        onChange={handleChange}
                        validation={errorMessages.length > 0 ? 'error' : warningMessages.length > 0 ? 'warning' : undefined}
                        allowNegative={dataPoint.allowNegative}
                        dataPointId={dataPoint._id}
                        name={dataPoint._id + '-' + year}
                        type={dataPoint.type}
                        unit={dataPoint.unit || undefined}
                        format={dataPoint.format || undefined}
                        decimalPlaces={dataPoint.decimalPlaces || undefined}
                        value={value || ''}
                    />
                </div>
            );
        } else {
            element = <div />;
        }
        if (isError || isWarning) {
            element = (
                <Tooltip title={messages} trigger={['hover', 'click']}>
                    {element}
                </Tooltip>
            );
        }
        return element;
    };

    const renderInput: (dataPoint: DataPointScalarType) => React$Element<*> = (dataPoint: DataPointScalarType) => {
        if (!dataPoint.requiredPriorYears) {
            return renderHistoricalValue(_renderInput(dataPoint.answer, selectedYear), admin);
        }
        let unanswered = displayUnanswered;
        if (unanswered) {
            if (dataPoint.answer !== undefined && dataPoint.answer !== null) {
                unanswered = false;
            }
        }

        return (
            <div className={styles.priorYearWrapper + ' ' + (unanswered ? styles.unanswered : '')}>
                {[0].concat(dataPoint.requiredPriorYears).map(function (subtractor) {
                    if (selectedYear - subtractor < 2008 || selectedYear - subtractor > Constants.currentYear) {
                        return;
                    }
                    const tyear = selectedYear - subtractor;
                    if (tyear < 2008 || tyear > Constants.currentYear) {
                        return;
                    }
                    // $FlowFixMe
                    const year: YearNumberType = tyear;
                    let value = subtractor === 0 ? dataPoint.answer : dataPoint.priorYearAnswers ? dataPoint.priorYearAnswers[year] : '';
                    if (typeof value === 'number') {
                        value = '' + value;
                    } else if (typeof value === 'boolean') {
                        value = value ? 'true' : 'false';
                    }
                    return (
                        <div key={dataPoint._id + year} className={styles.singleYear}>
                            <div className={styles.yearLabel}>{year}</div>
                            {renderHistoricalValue(_renderInput(typeof value === 'string' ? value : '', year), admin)}
                        </div>
                    );
                })}
            </div>
        );
    };

    if (inputOnly) {
        return renderInput(dataPoint);
    }
    let iClassName = '';
    if (className) {
        iClassName += className;
    }
    if (dataPoint.type !== 'text' || dataPoint.format !== 'textarea') {
        iClassName += ' ' + styles.dataPointQuestion;
    }
    return (
        <div key={dataPoint._id} className={iClassName}>
            {renderName()}
            {renderInput(dataPoint)}
        </div>
    );
}

export default DataPointScalarQuestion;
