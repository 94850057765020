// @flow
'use strict';

import { useState, useEffect, createRef } from 'react';

import { debounce } from 'lodash';

import { Checkbox, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const r = new RegExp('[^0-9\\.]');
const TIMEOUT = 3000;

type Props = {|
    align?: 'right' | 'left' | 'center',
    disabled?: boolean,
    type?: 'textarea' | 'text' | 'checkbox',
    value: string,
    className?: string,
    label: string,
    formatValue?: (value: string) => string,
    unformatValue?: (value: string) => string,
    onFocus?: (event: SyntheticInputEvent<HTMLInputElement>) => void,
    onBlur?: (event: SyntheticInputEvent<HTMLInputElement>) => void,
    onKeyPress?: (event: SyntheticKeyboardEvent<*>) => void,
    onImmediateChange?: (event: SyntheticInputEvent<HTMLInputElement>) => void,
    onBufferedChange: (value: string) => void,
    classes?: Object,
|};

const useStyles = makeStyles(() => ({
    root: {
        border: 'none',
    },
}));

const _propegateChange = function(value: string, onBufferedChange: (value: string) => void): void {
    onBufferedChange(value);
};

const propegateChange = debounce(_propegateChange, TIMEOUT);

export default function DataPointInput(props: Props) {
    const {
        align = 'right',
        disabled,
        type = 'text',
        value,
        className = '',
        label,
        onFocus,
        onBlur,
        onImmediateChange,
        onBufferedChange,
        onKeyPress,
        classes = {},
        formatValue,
        unformatValue,
    } = props;

    const [inputValue, setInputValue] = useState(value);
    const [active, setActive] = useState(false);
    const ref = createRef();
    const iClasses = useStyles();

    useEffect(() => {
        if (ref && document.activeElement === ref.current && !active) {
            setActive(true);
        }
        if (disabled && active) {
            setActive(false);
        }
    }, [active, disabled, inputValue, value]);

    const handleChange: (event: SyntheticInputEvent<HTMLInputElement>) => any = (event) => {
        const target = event.target;
        const tValue = target.type === 'checkbox' ? (target.checked ? 'true' : 'false') : target.value;
        setInputValue(tValue);
        if (onImmediateChange) {
            onImmediateChange(event);
        }
        if (type === 'checkbox') {
            onBufferedChange(tValue);
            return;
        }
        propegateChange(tValue, onBufferedChange);
    };

    let v = inputValue;
    if ((disabled || !active) && formatValue) {
        v = formatValue(inputValue);
    } else if (active && unformatValue && v.match(r)) {
        v = unformatValue(inputValue);
    }
    if (type === 'checkbox') {
        return <Checkbox checked={value === 'true'} disabled={disabled} onChange={handleChange} />;
    }

    return (
        <TextField
            key={label}
            ref={ref}
            align={align}
            disabled={disabled}
            fullWidth={type === 'textarea'}
            value={v}
            name={label || ''}
            multiline={type === 'textarea'}
            inputProps={{
                style: {
                    textAlign: align,
                },
            }}
            InputProps={{
                classes: {
                    underline: iClasses.underline,
                },
            }}
            classes={{
                ...classes,
                root: iClasses.root,
            }}
            onChange={handleChange}
            className={className}
            onBlur={(e) => {
                const target = e.target;
                const tValue = target.type === 'checkbox' ? (target.checked ? 'true' : 'false') : target.value;
                onBufferedChange(tValue);
                if (active) {
                    setActive(false);
                }
                if (onBlur) {
                    onBlur(e);
                }
            }}
            onFocus={(e) => {
                if (!active) {
                    setActive(true);
                }
                if (onFocus) {
                    onFocus(e);
                }
            }}
            onKeyPress={onKeyPress}
            type='text'
            autoComplete='off'
        />
    );
}
