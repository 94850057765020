// @flow
'use strict';

import { forwardRef } from 'react';
import styles from './subNav.scss';
import { AppBar, Tabs, Tab } from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';
import { routesForUser } from '../../routes';
import SchoolSelect from '../SchoolSelect';
import { connect } from 'react-redux';

import { getSelectedSchool, getUser } from '../../redux/reducers';
import { makeStyles } from '@material-ui/styles';

type ReduxReducerProps = {|
    user: ?UserType,
    selectedSchool: ?SchoolType,
|};

const useStyles = makeStyles(() => ({
    subNav: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#6E6259',
        height: '40px',
        minHeight: '40px',
    },
    tabs: {
        height: '40px',
        minHeight: '40px',
        textTransform: 'unset',
        minWidth: 'unset',
        fontSize: 'unset',
        flexShrink: 0,
    },
    divider: {
        height: '40px',
        backgroundColor: 'white',
    },
    schoolSelect: {
        marginRight: 10,
    },
}));

type OwnProps = {|
    pages: Array<string>,
|};

type Props = {
    ...OwnProps,
    ...ReduxReducerProps,
};

const LinkTab = (props, ref) => <RouterLink innerRef={ref} {...props} />;

export function SubNav(props: Props) {
    const { user, pages, selectedSchool } = props;

    const classes = useStyles();

    const name = '/' + pages[0];
    let selectedRoute = routesForUser(user, selectedSchool).find(function(route) {
        return route.path === '/' + pages[0];
    });
    let selectedSubRoute =
        !selectedRoute || !selectedRoute.subRoutes
            ? null
            : selectedRoute.subRoutes.find(function(subRoute) {
                return subRoute.path === '/' + pages[1];
            });
    let hide = !selectedRoute
        ? false
        : selectedRoute.hideSelectSchool
            ? selectedRoute.hideSelectSchool
            : selectedSubRoute && selectedSubRoute.hideSelectSchool
                ? selectedSubRoute.hideSelectSchool
                : false;

    return (
        <AppBar position='relative' className={classes.subNav}>
            {!selectedRoute || !selectedRoute.subRoutes ? null : (
                <Tabs classes={{ root: classes.tabs }} value={selectedSubRoute ? selectedSubRoute.id : ''}>
                    {selectedRoute.subRoutes
                        .filter(function(subRoute) {
                            if (subRoute.hide) {
                                return false;
                            }
                            if (subRoute.isABOPS) {
                                return selectedSchool ? selectedSchool.isABOPS : false;
                            }
                            return true;
                        })
                        .map(function(subName) {
                            let linkTo = {};
                            linkTo.pathname = name + subName.path;
                            const selected = '/' + pages[1] === subName.path;
                            return (
                                <Tab
                                    component={forwardRef(LinkTab)}
                                    key={name + subName.path}
                                    id={subName.path.slice(1)}
                                    value={subName.id}
                                    classes={{
                                        root: classes.tabs,
                                        wrapper: classes.tabs,
                                    }}
                                    className={
                                        styles.navButton +
                                        ' ' +
                                        (selected ? styles.secondarySelected : styles.unselected)
                                    }
                                    to={linkTo}
                                    label={subName.id}
                                />
                            );
                        })}
                </Tabs>
            )}
            {!user || hide ? null : <SchoolSelect className={classes.schoolSelect} />}
        </AppBar>
    );
}
/* istanbul ignore next */
const mapStateToProps: (state: ReduxStateType) => ReduxReducerProps = (state) => {
    return {
        selectedSchool: getSelectedSchool(state),
        user: getUser(state),
    };
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps)(SubNav);
