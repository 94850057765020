// @flow
'use strict';

import { Button, AppBar, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';

import { setSelectedSectionId, setSelectedSectionAndSubsectionIds, resetOnlyDataPoints } from '../../redux/actions';

const useStyles = makeStyles(() => ({
    wrapper: {
        backgroundColor: '#A7A6A6',
        height: 50,
        minHeight: 50,
        justifyContent: 'space-between',
    },
    buttons: {
        width: 85,
    },
    next: {
        backgroundColor: '#203480',
        color: 'white',
    },
    previous: {},
}));

type ReduxProvidedPropFunctionTypes = {|
    setSelectedSectionId: (sectionId: string, resetDP: boolean) => void,
|};

type OwnProps = {|
    className?: string,
    selectedSectionId: string,
    selectedSubsectionId: ?string,
    sectionData: SectionDataType,
|};

type Props = {|
    ...ReduxProvidedPropFunctionTypes,
    ...OwnProps,
|};

const calculateSectionIds = function (sectionData: SectionDataType, selectedSectionId: string, selectedSubsectionId: ?string): Array<?string> {
    let nextSectionId;
    let priorSectionId;
    let sectionIndex = sectionData.sectionOrder.indexOf(selectedSectionId);
    const selectedSection = sectionData.sections[selectedSectionId];
    if (sectionIndex !== sectionData.sectionOrder.length - 1 || selectedSection.paginate) {
        if (selectedSection.paginate) {
            let subsectionIndex = selectedSection.subsections.length - 1;
            let subsection;
            if (!selectedSubsectionId) {
                subsection = selectedSection.subsections[0];
                subsectionIndex = 0;
            } else {
                selectedSection.subsections.forEach(function (fSubsection, index) {
                    if (subsection) {
                        return;
                    }
                    if (fSubsection._id === selectedSubsectionId) {
                        subsectionIndex = index;
                        subsection = fSubsection;
                    }
                });
            }
            if (!subsection) {
                nextSectionId = selectedSection._id + '-' + selectedSection.subsections[0]._id;
            } else if (subsectionIndex !== selectedSection.subsections.length - 1) {
                nextSectionId = selectedSection._id + '-' + selectedSection.subsections[subsectionIndex + 1]._id;
            } else if (sectionIndex !== sectionData.sectionOrder.length - 1) {
                nextSectionId = sectionData.sectionOrder[sectionIndex + 1];
            }
        } else {
            nextSectionId = sectionData.sectionOrder[sectionIndex + 1];
        }
    }
    if (sectionIndex !== 0 || selectedSection.paginate) {
        if (selectedSection.paginate) {
            let subsectionIndex = 0;
            let subsection = selectedSection.subsections.find(function (subsection, index) {
                if (subsection._id === selectedSubsectionId) {
                    subsectionIndex = index;
                    return true;
                }
            });
            if (!subsection) {
                priorSectionId = selectedSection._id + '-' + selectedSection.subsections[selectedSection.subsections.length - 1]._id;
            } else if (subsectionIndex !== 0) {
                priorSectionId = selectedSection._id + '-' + selectedSection.subsections[subsectionIndex - 1]._id;
            } else if (sectionIndex !== 0) {
                priorSectionId = sectionData.sectionOrder[sectionIndex - 1];
            }
        } else {
            priorSectionId = sectionData.sectionOrder[sectionIndex - 1];
        }
    }
    return [nextSectionId, priorSectionId];
};

export function DataPointFooter(props: Props) {
    const { setSelectedSectionId, sectionData, selectedSectionId, selectedSubsectionId, className = '' } = props;
    let [nextSection, priorSection] = calculateSectionIds(sectionData, selectedSectionId, selectedSubsectionId);

    const classes = useStyles();
    return (
        <AppBar id='footer' position='relative' className={classes.wrapper + ' ' + className}>
            <Toolbar className={classes.wrapper}>
                {priorSection ? (
                    <Button className={classes.buttons + ' ' + classes.previous} name='back' variant='contained' onClick={() => setSelectedSectionId(priorSection, true)}>
                        BACK
                    </Button>
                ) : (
                    <div />
                )}
                {nextSection ? (
                    <Button className={classes.buttons + ' ' + classes.next} name='next' variant='contained' onClick={() => setSelectedSectionId(nextSection, true)}>
                        NEXT
                    </Button>
                ) : null}
            </Toolbar>
        </AppBar>
    );
}

/* istanbul ignore next */
const mapDispatchToProps: (dispatch: GenericDispatch, ownProps: OwnProps) => ReduxProvidedPropFunctionTypes = (dispatch) => {
    return {
        setSelectedSectionId: (sectionId: string, resetDP: boolean) => {
            if (sectionId.indexOf('-') === -1) {
                if (resetDP) {
                    dispatch(resetOnlyDataPoints());
                }
                dispatch(setSelectedSectionId(sectionId));
            } else {
                if (resetDP) {
                    dispatch(resetOnlyDataPoints());
                }
                const splitSection = sectionId.split('-');
                dispatch(setSelectedSectionAndSubsectionIds(splitSection[0], splitSection[1]));
            }
        },
    };
};

export default connect<Props, OwnProps, _, _, _, _>(null, mapDispatchToProps)(DataPointFooter);
