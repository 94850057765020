// @flow
'use strict';

import axios from 'axios';
import * as Utils from '../utils';
import User from './user';
const Constants = Utils.Constants;
const Impersonate = {};

const getImpersonationUsers: (schoolId: string) => Promise<Array<ImpersonationUserType>> = (schoolId: string) => {
    return axios
        .get(Constants.apiBaseUrl + 'impersonate/' + schoolId + '/users', {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Bad response from api for get impersonation users');
            }
            return response.data;
        });
};

Impersonate.getImpersonationUsers = getImpersonationUsers;

const loginUserFromImpersonation = (user: ImpersonationUserType): Promise<{ user: ImpersonationUserType }> => {
    return axios
        .post(
            Constants.apiBaseUrl + 'impersonate',
            {
                userId: user._id,
            },
            { withCredentials: true },
        )
        .then(function (response) {
            if (!response.data) {
                throw new Error('No User Found');
            }
            return User.getLoggedInUser().then((response) => {
                return response;
            });
        })
        .catch(function (err) {
            if (err && err.response) {
                throw new Error(err.response.data.message);
            }
            throw new Error('Error logging in');
        });
};

Impersonate.loginUserFromImpersonation = loginUserFromImpersonation;

export default Impersonate;
