// @flow
'use strict';

export const dataEntry = [
    {
        title: 'Edit Data',
        content: 'You can click here to enable editing data',
        target: '#editButtonId',
        placement: 'left',
    },
    {
        title: 'Side Bar',
        content:
            'Use the Side Bar to navigate through the different sections and subsections.',
        target: '#sidebar',
        placement: 'right',
    },
    {
        title: 'Track Progress',
        content: 'Track your progress of the required questions here.',
        target: '#jr-bottomWrapper',
        placement: 'top-start',
    },
    {
        title: 'Toggle Unanswered Questions',
        content:
            'Click the progress bar to toggle between displaying only unanswered questions and all questions at once.',
        target: '#progressbar',
        placement: 'top-end',
    },
    {
        title: 'Submit Data',
        content:
            'After all the required questions have been answered, you may submit the data here.',
        target: '#submitButton',
        placement: 'top-end',
    },
    {
        title: 'Download Data',
        content:
            'Use this button to download a .csv file of all questions with current responses and historical data (if any)',
        target: '#downloadJoyrideId',
        placement: 'left',
    },
    {
        title: 'Change Year',
        content: 'You can select which year you would like to view/edit data for',
        target: '#yearJoyrideId',
        placement: 'left',
    },
    {
        title: 'Back/Next Navigation',
        content:
            'Use these buttons to navigate to the previous or next subsection of questions',
        target: '#footer',
        placement: 'top',
    }, {
        title: 'Financial Position Survey',
        content:
            'Use this tab to just view the questions that are part of the Financial Position Survey',
        target: '#mainDataFps',
        placement: 'bottom',
    }
];

export const peergroups = [
    {
        title: 'New Peer Group',
        content: 'Use this button to create a new peer group',
        target: '#jr-newpeergroupbutton',
        placement: 'bottom-start',
    },
    {
        title: 'Switch Peer Groups',
        content: 'Use this dropdown menu to switch between existing peer groups',
        target: '#jr-peergroupwrapper',
        placement: 'bottom-start',
    },
    {
        title: 'Filters',
        content: 'Filter the list by region',
        target: '#jr-regionwrapper',
        placement: 'bottom-end',
    },
    {
        title: 'Filters',
        content: 'Filter the list by state',
        target: '#jr-statewrapper',
        placement: 'bottom-end',
    },
    {
        title: 'Filters',
        content: 'Filter the list by grade',
        target: '#jr-gradewrapper',
        placement: 'bottom-end',
    },
    {
        title: 'Filters',
        content: 'Filter the list by type of school',
        target: '#jr-schoolTypewrapper',
        placement: 'bottom-end',
    },
    {
        title: 'Filters',
        content: 'Filter the list by number of students enrolled',
        target: '#jr-enrollmentwrapper',
        placement: 'bottom-end',
    },
    {
        title: 'Edit Peer Group Name',
        content: 'Click on the pencil icon to edit the name of the current peer group',
        target: '#jr-editname',
        placement: 'top-start',
    },
    {
        title: 'Sort Schools',
        content: 'Click on a column header to sort the table by the corresponding field, or select the circle on the left hand side to sort by selected schools',
        target: '#peerGroupTableHeader',
        placement: 'top',
    },
    {
        title: 'Add School',
        content: 'Check the circle to the left of a school name to add the corresponding school to the current peer group',
        target: '#selectedTd',
        placement: 'top-start',
    },
];

export const charts = [
    {
        title: 'Select a topic',
        content: 'You can use the dropdown to change topics',
        target: '#topicsWrapper',
        placement: 'bottom',
    },
    {
        title: 'Filters',
        content: 'Filter the schools used for the chart data by region',
        target: '#regionsWrapper',
        placement: 'bottom-end',
    },
    {
        title: 'Filters',
        content: 'Filter the schools used for the chart data by state',
        target: '#statesWrapper',
        placement: 'bottom-end',
    },
    {
        title: 'Filters',
        content: 'Filter the charts by year(s)',
        target: '#yearsWrapper',
        placement: 'bottom-end',
    },
    {
        title: 'Filters',
        content: 'Filter the schools used for the chart data by type of school',
        target: '#schoolTypesWrapper',
        placement: 'bottom-end',
    },
    {
        title: 'Filters',
        content: 'Filter the schools used for the chart data by number of students enrolled',
        target: '#enrollmentsWrapper',
        placement: 'bottom-end',
    },
    {
        title: 'Views',
        content: 'Change the grouping of the data to eirther percentiles or peer groups (for line charts)',
        target: '#groupingWrapper',
        placement: 'bottom-end',
    },
    {
        title: 'Views',
        content: 'Change the chart type to either bar or line charts',
        target: '#chartTypeWrapper',
        placement: 'bottom-end',
    },
    {
        title: 'Download',
        content: 'Click here to download a .csv file of the table below or a .png file for the chart',
        target: '#downloadButton button',
        placement: 'bottom-end',
    },
];

export const selectTopic = [
    {
        title: 'Select a topic',
        content: 'To start, try selecting a topic by clicking the dropdown menu above',
        target: '#topicsWrapper',
        placement: 'bottom',
    },
];
