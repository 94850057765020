// @flow
'use strict';

import React, { useState, useEffect, createContext } from 'react';
import { useLocation } from 'react-router-dom';
import parser from 'html-react-parser';
import { connect } from 'react-redux';
import { Button, Paper, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { useQueryItems } from '../../components/QueryListener';
import DashboardRow from '../../components/DashboardRow-pre-highcharts';

import styles from './dashboards.scss';

import { fetchDashboard, resetDashboard, setLoading, unsetLoading } from '../../redux/actions';
import { getSelectedSchool, getDashboard, isFetchingReportData, getPeergroups, getPeergroupsOrder, QUERY_ITEMS, isLoading } from '../../redux/reducers';

import Dashboards from '../../api/dashboards';

import { jsPDF } from 'jspdf';
import { merge } from 'lodash';
import { formatNumericValueAsString } from '../../utils';
import cfiLayout from '../../utils/cfi-layout';
import financialLayout from '../../utils/financial-layout';

const useStyles = makeStyles(() => ({
    infoButton: {
        color: 'white',
        backgroundColor: '#1C8DE6',
        height: '94%',
        marginTop: '5px',
        marginRight: '10px',
    },
    dashboardPaper: {
        width: '1080px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    dashboardPaperPrinting: {
        width: '1080px',
    },
    peergroups: {},
}));

const queryItems = [QUERY_ITEMS.school];

type ReduxReducerProps = {|
    selectedSchool: ?SchoolType,
    dashboard: ?DashboardType,
    isFetchingReportData: boolean,
    peergroupOrder: Array<string>,
    peergroups: ?{ [_id: string]: PeergroupType },
    isLoading: boolean,
|};

type ReduxActionProps = {|
    fetchDashboard: (dashboardId: string, schoolId: string, selectedPeergroup: ?string) => void,
    resetDashboard: () => void,
    setIsLoading: () => void,
    unsetIsLoading: () => void,
|};

type OwnProps = {||};

type Props = {|
    ...ReduxReducerProps,
    ...ReduxActionProps,
    ...OwnProps,
|};

export const DashboardContext = createContext();

export function Dashboard(props: Props) {
    const { isLoading, selectedSchool, dashboard, isFetchingReportData, peergroupOrder, peergroups, fetchDashboard, resetDashboard, setIsLoading, unsetIsLoading } = props;
    const [selectedPeergroup, setSelectedPeergroup] = useState();
    const [slidesToPrint] = useState([]);

    const location = useLocation();
    const path = location.pathname.split('/');
    const dashboardId = path[path.length - 1];
    const classes = useStyles();
    useQueryItems(queryItems);

    useEffect(() => {
        if (!selectedSchool) {
            return;
        }

        if (!dashboard && !isFetchingReportData) {
            fetchDashboard(dashboardId, selectedSchool._id, selectedPeergroup);
        }
        if (dashboard && !isFetchingReportData && dashboard._id !== dashboardId) {
            fetchDashboard(dashboardId, selectedSchool._id, selectedPeergroup);
        }
    }, [selectedSchool, dashboard, isFetchingReportData, dashboardId]);

    useEffect(() => {
        return function cleanup() {
            resetDashboard();
        };
    }, []);

    useEffect(() => {
        slidesToPrint.length = 0;
    }, [selectedSchool, dashboardId, selectedPeergroup]);

    const getPowerpointfile = async (title: ?string) => {
        try {
            setIsLoading();
            const response = await Dashboards.downloadPowerpointSlides(slidesToPrint);
            unsetIsLoading();
            const fileName = `${title || dashboard.pages[0].title}-${moment().format('YYYYMMDD')}`;
            saveAs(response, fileName + '.pptx');
        } catch (error) {
            unsetIsLoading();
        }
    };

    const printPDFs = () => {
        setIsLoading();
        const fullDashboard = merge(dashboard, dashboard._id === 'cfi' ? cfiLayout : financialLayout);
        const doc = new jsPDF({ orientation: 'landscape', unit: 'px', format: 'letter' });
        fullDashboard.pages.forEach((page, pageIndex) => {
            if (pageIndex === 1 && fullDashboard._id === 'financial') doc.addPage();
            if (page.title) {
                doc.setFontSize(10);
                doc.text(page.title, 5, 15);
            }
            page.rows.forEach((row, rowIndex) => {
                if (rowIndex === 2) doc.addPage();

                row.items.forEach((item) => {
                    if (row.height === 'small') {
                        doc.setFontSize(10);
                        doc.text(item.title, item.x, item.y, {
                            maxWidth: item.width,
                            align: 'center',
                        });
                        const format = item.unit === 'year' ? 'year' : item.format || 'integer';
                        const unit = item.unit === 'year' ? 'raw' : item.unit || 'raw';
                        let number = formatNumericValueAsString(item.value, format, unit, item.decimalPlaces);
                        doc.setFontSize(22);
                        doc.text(number, item.x, item.y + 25, { align: 'center' });
                    } else {
                        const slide = slidesToPrint.find((slide) => slide._id === item._id);
                        doc.setFontSize(10);
                        doc.text(parser(item.title), item.x, item.y, {
                            maxWidth: item.width,
                            align: 'left',
                        });
                        doc.text(slide ? slide.subheader : item.subtitle, item.x, item.y + 10, {
                            maxWidth: item.width,
                            align: 'left',
                        });
                        if (slide) doc.addImage(slide.image, 'PNG', item.x, item.y + 45, item.width, item.height);
                    }
                });
            });
        });
        doc.save(`${fullDashboard._id}-dashboard.pdf`);

        unsetIsLoading();
    };

    const renderDashboardPage: (dashboardPage: DashboardPageType, index: number, dashboard: DashboardType) => React$Element<*> = (dashboardPage, index) => {
        let count = 0;
        dashboardPage.rows.forEach(function (row) {
            if (row.height === 'small') {
                count += 1;
            } else if (row.height === 'medium') {
                count += 2;
            } else if (row.height === 'large') {
                count += 3;
            }
        });
        count += 0.1 * (dashboardPage.rows.length - 1);
        const multiplier = 10 / count;
        const dashboardPaper = classes.dashboardPaper + ' ' + styles.page;

        return (
            <Paper key={index} className={dashboardPaper}>
                <div className={styles.titleText}>{dashboardPage.title}</div>

                {dashboardPage.rows.map(function (row, rowIndex) {
                    let heightMuliplier = 1;
                    if (row.height === 'small') {
                        heightMuliplier = 1;
                    } else if (row.height === 'medium') {
                        heightMuliplier = 2;
                    } else if (row.height === 'large') {
                        heightMuliplier = 3;
                    }

                    return row.height === 'small' && dashboard._id === 'cfi' ? (
                        <DashboardRow
                            key={'row' + rowIndex}
                            row={row}
                            style={{
                                height: heightMuliplier * multiplier + 'in',
                            }}
                        />
                    ) : (
                        <DashboardRow key={'row' + rowIndex} row={row} />
                    );
                })}
            </Paper>
        );
    };

    const renderPeergroupSelect: () => React$Element<*> = () => {
        const noPeergroups = !peergroupOrder || peergroupOrder.length === 0;
        return (
            <Select
                value={selectedPeergroup || 'default'}
                className={styles.printHidden}
                disabled={noPeergroups}
                onChange={(e) => {
                    setSelectedPeergroup(e.target.value !== 'default' ? e.target.value : undefined);
                    resetDashboard();
                }}
                name='Grouping'
            >
                <MenuItem value='default' key='default'>
                    {noPeergroups ? 'Create peergroups' : 'Percentiles'}
                </MenuItem>
                {!peergroupOrder
                    ? null
                    : peergroupOrder.map(function (peergroupId) {
                          const peergroup = peergroups && peergroups[peergroupId];
                          if (!peergroup) {
                              return;
                          }
                          return (
                              <MenuItem value={peergroupId} key={peergroupId}>
                                  {peergroup.name}
                              </MenuItem>
                          );
                      })}
            </Select>
        );
    };

    const renderDashboard: (dashboard: DashboardType) => React$Element<*> = (dashboard) => {
        const dashboardStyle = {};
        if (!dashboard.isFake) {
            dashboardStyle.overflowY = 'auto';
            dashboardStyle.height = '100%';
        }
        let resourcesLinkSrc = dashboard.resourcesLink;
        return (
            <div className={styles.dashboard} style={dashboardStyle}>
                <div className={styles.title}>
                    {resourcesLinkSrc ? (
                        <div>
                            <Button classes={{ root: classes.infoButton }} className={classes.infoButton + ' ' + styles.titleLink} href={resourcesLinkSrc}>
                                Get More Information
                            </Button>
                            <Button
                                classes={{ root: classes.infoButton }}
                                className={classes.infoButton + ' ' + styles.titleLink}
                                onClick={() => {
                                    getPowerpointfile(dashboard.title);
                                }}
                            >
                                Download Powerpoint Slides
                            </Button>
                            <Button classes={{ root: classes.infoButton }} className={classes.infoButton + ' ' + styles.titleLink} onClick={printPDFs}>
                                Print PDF
                            </Button>
                        </div>
                    ) : null}
                    {!dashboard.allowPeergroups ? null : renderPeergroupSelect()}
                    {/*<Button
                    onClick={this.handlePrint}
                >Print</Button>*/}
                </div>
                {dashboard.isFake ? <div id='fakeWarningOverlay' className={styles.fakeWarningOverlay} /> : null}
                {dashboard.warning ? <div className={styles.missingWarning}>{parser(dashboard.warning)}</div> : null}

                <div id='dashboard-pdf'>
                    {dashboard.pages.map(function (page, pageIndex) {
                        return renderDashboardPage(page, pageIndex, dashboard);
                    })}
                </div>
            </div>
        );
    };

    if (isLoading) return <div></div>;

    const message =
        dashboardId === 'cfi'
            ? 'Thank you for your interest in the CFI. The calculations require two years of data in BIIS.'
            : 'You currently do not have enough data to display this dashboard. The data you are seeing is simulated data to give you an idea of what this dashboard looks like.';

    if (!dashboard && !isLoading) {
        return (
            <div className={styles.fake}>
                <div className={styles.fakeWarning}>
                    <div className={styles.warning}>{message}</div>
                </div>
            </div>
        );
    }

    if (dashboard.isFake) {
        return (
            <div className={styles.fake}>
                {renderDashboard(dashboard)}
                <div className={styles.fakeWarning}>
                    <div className={styles.warning}>{message}</div>
                </div>
            </div>
        );
    }
    return (
        <DashboardContext.Provider
            value={{
                slides: slidesToPrint,
                addSlide: (slide) => {
                    const slideIndex = slidesToPrint.findIndex((s) => s.title === slide.title);

                    if (slideIndex < 0) slidesToPrint.push(slide);
                },
            }}
        >
            {renderDashboard(dashboard)}
        </DashboardContext.Provider>
    );
}
/* istanbul ignore next */
const mapStateToProps: (state: ReduxStateType) => ReduxReducerProps = (state) => {
    return {
        selectedSchool: getSelectedSchool(state),
        dashboard: getDashboard(state),
        isFetchingReportData: isFetchingReportData(state),
        peergroupOrder: getPeergroupsOrder(state),
        peergroups: getPeergroups(state),
        isLoading: isLoading(state),
    };
};
/* istanbul ignore next */
const mapDispatchToProps: (dispatch: GenericDispatch) => ReduxActionProps = (dispatch) => {
    return {
        fetchDashboard: (dashboardId: string, schoolId: string, selectedPeergroup: ?string) => {
            dispatch(fetchDashboard(dashboardId, schoolId, selectedPeergroup));
        },
        resetDashboard: () => {
            dispatch(resetDashboard());
        },
        setIsLoading: () => {
            dispatch(setLoading());
        },
        unsetIsLoading: () => {
            dispatch(unsetLoading());
        },
    };
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(Dashboard);
