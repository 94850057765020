// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Arimo);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://p.typekit.net/p.css?s=1&k=wvy7sbk&ht=tk&f=139.169.173.175.5474.25136&a=108967835&app=typekit&e=css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media print{.app-components-DashboardItem-pre-highcharts-dashboardItem__numberCardHeader--JtuZR span{font-size:12px !important}.app-components-DashboardItem-pre-highcharts-dashboardItem__numberCardNumber--n7etw{font-size:24px !important}}", "",{"version":3,"sources":["webpack://./app/components/DashboardItem-pre-highcharts/dashboardItem.scss"],"names":[],"mappings":"AAEA,aAKQ,yFACI,yBAAA,CAIR,oFACI,yBAAA,CAAA","sourcesContent":["@import '../../stylesheets/constants.scss';\n\n@media print {\n    .numberCard {\n    }\n\n    .numberCardHeader {\n        span {\n            font-size: 12px !important;\n        }\n    }\n\n    .numberCardNumber {\n        font-size: 24px !important;\n    }\n}\n//\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"numberCardHeader": "app-components-DashboardItem-pre-highcharts-dashboardItem__numberCardHeader--JtuZR",
	"numberCardNumber": "app-components-DashboardItem-pre-highcharts-dashboardItem__numberCardNumber--n7etw"
};
export default ___CSS_LOADER_EXPORT___;
