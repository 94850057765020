// @flow
'use strict';

import styles from './adminNav.scss';
import { Link } from 'react-router-dom';
import { routesForUser } from '../../routes';

type PropTypes = {
    user?: ?UserType,
    selectedSchool: SchoolType,
    pages: Array<string>,
};

export default function AdminNav(props: PropTypes) {
    const name = '/' + props.pages[0];
    const routes = routesForUser(props.user, props.selectedSchool);
    let selectedRoute = routes.find(function(storedRoute) {
        return storedRoute.path === name;
    });
    return (
        <div className={styles.adminNav}>
            <div key={'adminNav' + name} className={styles.leftAlign}>
                {!selectedRoute || !selectedRoute.subRoutes
                    ? null
                    : selectedRoute.subRoutes.map(function(subRoute) {
                        return subRoute.subRoutes
                            ? subRoute.subRoutes.map(function(subName) {
                                const selected = props.pages[2] === subName.path.slice(1);
                                return (
                                    <Link
                                        key={name + '/' + props.pages[1] + subName.path}
                                        onClick={(e) => {
                                            if (selected) {
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }
                                        }}
                                        className={
                                            styles.navButton +
                                                ' ' +
                                                (selected ? styles.secondarySelected : styles.unselected)
                                        }
                                        to={name + '/' + props.pages[1] + subName.path}
                                    >
                                        {subName.id}
                                    </Link>
                                );
                            })
                            : null;
                    })}
            </div>
        </div>
    );
}
