// @flow
'use strict';

import styles from './toggleButton.scss';

const INPUT_ID = 'toggleInputId';

type PropTypes = {|
    className?: string,
    title: string | Array<string>,
    multLabels?: boolean,
    id?: string,
    checked: boolean,
    access?: boolean,
    disabled: boolean,
    onChange: (event: SyntheticInputEvent<*>) => void,
|};

export default function ToggleButton(props: PropTypes) {
    let className = styles.toggleWrapper;
    if (props.className) {
        className += ' ' + props.className;
    }
    return (
        <div className={className}>
            {props.multLabels && Array.isArray(props.title) ? (
                <span className={styles.leftTitle}>{props.title[0]}</span>
            ) : null}
            <span
                className={
                    styles.title + ' ' + (props.multLabels && Array.isArray(props.title) ? styles.multLabelsSpan : '')
                }
            >
                {props.multLabels && Array.isArray(props.title) ? props.title[1] : props.title}
            </span>
            <input
                className={styles.checkbox}
                type='checkbox'
                id={INPUT_ID + (props.id ? props.id : '')}
                checked={props.checked}
                disabled={props.disabled}
                onChange={props.onChange}
            />
            <label
                id='toggleUnanswered'
                className={styles.label + ' ' + (props.access ? styles.accessLabel : '')}
                htmlFor={INPUT_ID + (props.id ? props.id : '')}
            />
        </div>
    );
}
