// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Arimo);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://p.typekit.net/p.css?s=1&k=wvy7sbk&ht=tk&f=139.169.173.175.5474.25136&a=108967835&app=typekit&e=css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media print{canvas{min-height:100%;max-width:100%;max-height:100%;height:auto !important;width:auto !important}}.app-components-ChartComp-chartComp__wrapper--kRCir{background-color:#fff;height:100%}.app-components-ChartComp-chartComp__chart--fqdZr{background-color:#fff}", "",{"version":3,"sources":["webpack://./app/components/ChartComp/chartComp.scss"],"names":[],"mappings":"AAEA,aACI,OACI,eAAA,CACA,cAAA,CACA,eAAA,CACA,sBAAA,CACA,qBAAA,CAAA,CAIR,oDACI,qBAAA,CACA,WAAA,CAGJ,kDACI,qBAAA","sourcesContent":["@import '../../stylesheets/constants.scss';\n\n@media print {\n    canvas {\n        min-height: 100%;\n        max-width: 100%;\n        max-height: 100%;\n        height: auto!important;\n        width: auto!important;\n    }\n}\n\n.wrapper {\n    background-color: white;\n    height: 100%;\n}\n\n.chart {\n    background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "app-components-ChartComp-chartComp__wrapper--kRCir",
	"chart": "app-components-ChartComp-chartComp__chart--fqdZr"
};
export default ___CSS_LOADER_EXPORT___;
