// @flow
'use strict';

import { IconButton, TableRow, TableCell, Checkbox, FormControl, TextField } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import { Autocomplete } from '@material-ui/lab';

type Props = {|
    row: DataTableCreateRowType,
    topics: ?Array<ReportTopicType>,
    rowIndex: number,
    updateRow: (
        type: 'header' | 'value',
        label: string,
        value: ?ReportTopicType,
        modifier: ?ReportTopicType,
        id: number,
    ) => void,
    deleteRow: (index: number) => void,
|};

export function DataTableCreateRow(props: Props) {
    const { row, updateRow, topics, rowIndex, deleteRow } = props;
    let labelIsItemName = false;
    if (row.label === '') {
        labelIsItemName = true;
    } else if (row.value && row.label) {
        if (row.value.name === row.label) {
            labelIsItemName = true;
        } else if (row.modifier && row.label === row.value.name + ' / ' + row.modifier.name) {
            labelIsItemName = true;
        }
    }
    return (
        <TableRow
        >
            <TableCell size='small' padding='checkbox'>
                {/* <IconButton size='medium' ref={ref}>
                    <DragIndicator />
                </IconButton> */}
            </TableCell>
            <TableCell size='small' padding='checkbox' align='center'>
                <FormControl>
                    <Checkbox
                        checked={row.type === 'header'}
                        onChange={(e) =>
                            updateRow(
                                e.target.checked ? 'header' : 'value',
                                row.label,
                                row.value || undefined,
                                row.modifier || undefined,
                                row.id,
                            )
                        }
                    />
                </FormControl>
            </TableCell>
            <TableCell size='medium' align='center'>
                <FormControl fullWidth>
                    <TextField
                        variant='outlined'
                        name='Label'
                        onChange={(e) =>
                            updateRow(
                                row.type,
                                e.target.value,
                                row.value || undefined,
                                row.modifier || undefined,
                                row.id,
                            )
                        }
                        fullWidth
                        value={row.label}
                    />
                </FormControl>
            </TableCell>
            <TableCell size='medium' align='left'>
                {row.type === 'header' ? (
                    <div />
                ) : !topics ? null : (
                    <Autocomplete
                        options={topics}
                        getOptionLabel={(option) => (option ? option.name : '')}
                        renderInput={(params) => <TextField {...params} label='Topic' variant='outlined' fullWidth />}
                        onChange={(x, item) => {
                            item &&
                                updateRow(row.type, labelIsItemName ? item.name : row.label, item, undefined, row.id);
                        }}
                    />
                )}
            </TableCell>
            <TableCell size='medium' align='left'>
                {row.type === 'header' ? (
                    <div />
                ) : !topics ? null : (
                    <Autocomplete
                        options={topics}
                        getOptionLabel={(option) => (option ? option.name : '')}
                        renderInput={(params) => (
                            <TextField {...params} label='Modifier' variant='outlined' fullWidth />
                        )}
                        onChange={(x, item) => {
                            item &&
                                updateRow(
                                    row.type,
                                    labelIsItemName && row.value ? row.value.name + ' / ' + item.name : row.label,
                                    row.value,
                                    item,
                                    row.id,
                                );
                        }}
                    />
                )}
            </TableCell>
            <TableCell size='small'>
                <IconButton size='small' onClick={() => deleteRow(rowIndex)}>
                    <Delete />
                </IconButton>
            </TableCell>
        </TableRow>
    );
}

export default DataTableCreateRow;
