// @flow
'use strict';

import User from './user';
import DataPoints from './dataPoints';
import Dashboards from './dashboards';
import Schools from './schools';
import Reports from './reports';
import HeatMap from './heatmap';
import Impersonate from './impersonate';
import Constants from '../utils/constants';
import type { Location } from 'react-router-dom';
import axios from 'axios';
import BulkExport from './bulkExport';
import CategoryReports from './categoryReports';
import AssociationSchools from './associationSchools';
axios.defaults.responseType = 'json';

axios.defaults.paramsSerializer = function (params: Object) {
    return params
        ? Object.keys(params)
              .map(function (key) {
                  const value = params[key];
                  if (value === '' || value === undefined || value === undefined) {
                      return;
                  }
                  return key + '=' + encodeURIComponent(value);
              })
              .join('&')
        : '';
};

const sendError: (error: Error, user?: ?UserType, location: Location) => any = (error, user, location) => {
    const sendError = {
        message: error.message,
        name: error.name,
        stack: error.stack,
        location: location.pathname,
        search: location.search,
        user: {},
    };
    if (user) {
        sendError.user = {
            name: user.name,
            id: user._id,
        };
    }
    return axios
        .put(Constants.apiBaseUrl + 'errors', {
            error: sendError,
        })
        .catch(function () {});
};

const api = {
    sendError: sendError,
    User: User,
    DataPoints: DataPoints,
    Dashboards: Dashboards,
    Schools: Schools,
    Reports: Reports,
    HeatMap: HeatMap,
    BulkExport: BulkExport,
    CategoryReports: CategoryReports,
    AssociationScools: AssociationSchools,
    Impersonate: Impersonate,
};

export default api;
