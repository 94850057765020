import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import parser from 'html-react-parser';

export const helpText = `If you require further assistance, contact NBOA
at <a style="color: blue" href='mailto:research@nboa.org'>research@nboa.org</a> or 202-407-7149.`;

function index(props) {
    const { handleClose, open, title } = props;

    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{title || 'Need Help?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{parser(helpText)}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default index;
