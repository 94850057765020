// @flow
'use strict';

import { Component } from 'react';
import styles from './unassignedSchool.scss';

import { DragSource } from 'react-dnd';
import { flow } from 'lodash';

type PropTypes = {
    _id: string,
    name: string,
    scrubberId?: string, // eslint-disable-line
    connectDragSource: (any) => any,
    //connectDropTarget: (any) => any,
    connectDragPreview: (any) => any,
    //removeCard: (scrubberId: string, unassignedSchoolId: string) => void,
    //onCardDrop: (scrubberId: string, unassignedSchoolId: string) => void
};

const itemSource = {
    beginDrag(props: PropTypes) {
        return props.scrubberId
            ? {
                _id: props._id,
                name: props.name,
                scrubberId: props.scrubberId,
            }
            : {
                _id: props._id,
                name: props.name,
            };
    },
};


export class UnassignedSchool extends Component<PropTypes, void> {
    props: PropTypes;

    timer: TimeoutID | null;

    constructor(props: PropTypes) {
        super(props);
    }

    render() {
        const { connectDragSource, connectDragPreview } = this.props;
        return connectDragPreview(
            connectDragSource(
                <tr key={this.props._id} className={styles.schoolRow}>
                    <th className={styles.leftRibbon}>
                        <div className={styles.dragButton} />
                    </th>
                    <th className={styles.schoolTd}>{this.props.name}</th>
                </tr>,
            ),
        );
    }
}

export default flow([
    DragSource('UnassignedSchool', itemSource, (connect) => {
        return {
            connectDragSource: connect.dragSource(),
            connectDragPreview: connect.dragPreview(),
        };
    }),
])(UnassignedSchool);
