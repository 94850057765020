// @flow
'use strict';

import axios from 'axios';
import * as Utils from '../utils';

const Constants = Utils.Constants;

const DataPoints = {};

DataPoints.submit = (schoolId: string, doSubmit: boolean, year: string) => {
    return axios
        .put(
            Constants.apiBaseUrl + 'datapoints',
            {
                school: schoolId,
                year: year,
                action: doSubmit ? 'submit' : 'unsubmit',
            },
            { withCredentials: true },
        )
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No user returned from Api');
            }
            return response.data;
        });
};

DataPoints.markReviewed = (schoolId: string, doReview: boolean, year: string) => {
    return axios
        .put(
            Constants.apiBaseUrl + 'datapoints',
            {
                school: schoolId,
                action: doReview ? 'review' : 'unreview',
                year: year,
            },
            { withCredentials: true },
        )
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No user returned from Api');
            }
            return response.data;
        });
};

DataPoints.getAllDataPoints = (forEditing?: boolean) => {
    var url = Constants.apiBaseUrl + 'datapoints';
    if (forEditing) {
        url += '?forEditing=true';
    }
    return axios.get(url, { withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('No user returned from Api');
        }
        return response.data;
    });
};

DataPoints.getDataPointsForEditing = (section: string, subsection: string): Promise<Array<DataPointType>> => {
    var url = Constants.apiBaseUrl + 'datapoints';
    var urlQuery = {};
    urlQuery.forEditing = true;
    if (section && section !== '') {
        urlQuery.section = section;
    }
    if (subsection && subsection !== '') {
        urlQuery.subsection = subsection;
    }
    return axios.get(url, { params: urlQuery, withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('No user returned from Api');
        }
        return response.data;
    });
};

DataPoints.getDataPointsForTopics = (): Promise<Array<DataPointType>> => {
    var url = Constants.apiBaseUrl + 'datapoints';
    var urlQuery = {};
    urlQuery.forTopics = true;
    return axios.get(url, { params: urlQuery, withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('No user returned from Api');
        }
        return response.data;
    });
};

const getSections: (filter: ?string, unanswered: boolean, schoolId: string) => Promise<Array<DataPointSectionType>> = (
    filter,
    unanswered,
    schoolId,
) => {
    let options = {};
    options.withCredentials = true;
    options.params = {};
    if (filter) {
        options.params.filter = filter;
    }
    if (unanswered) {
        options.params.unanswered = true;
    }
    if (schoolId) {
        options.params.school = schoolId;
    }
    return axios.get(Constants.apiBaseUrl + 'datapoints/sections', options).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('No sections returned from Api');
        }
        return response.data;
    });
};

DataPoints.getSections = getSections;

const getAllSections: () => Promise<Array<DataPointSectionType>> = () => {
    return axios
        .get(Constants.apiBaseUrl + 'datapoints/sections', {
            params: { showEmpty: true },
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No user returned from Api');
            }
            return response.data;
        });
};

DataPoints.getAllSections = getAllSections;

const getDataPoints: (params: DataPointFetchRequiredParamTypes) => Promise<DataPointResponseType> = (params) => {
    var url = Constants.apiBaseUrl + 'datapoints';
    var urlQuery = {};
    urlQuery.schoolId = params.schoolId;
    urlQuery.year = params.year;
    if (params.section && params.section !== '') {
        urlQuery.section = params.section;
    }
    if (params.subsection && params.subsection !== '') {
        urlQuery.subsection = params.subsection;
    }
    if (params.filter) {
        urlQuery.filter = params.filter;
    }
    if (params.unanswered) {
        urlQuery.unanswered = true;
    }
    return axios.get(url, { params: urlQuery, withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('No user returned from Api');
        }
        return response.data;
    });
};

DataPoints.getDataPoints = getDataPoints;

const copyPreviousDataPoints: (
    selectedSchoolId: string,
    selectedYear: YearNumberType,
    selectedSectionId: ?string,
) => Promise<DataPointType> = (selectedSchoolId, selectedYear, selectedSectionId) => {
    const params = {
        school: selectedSchoolId,
        year: '' + selectedYear,
        section: selectedSectionId,
    };
    return axios
        .put(Constants.apiBaseUrl + 'datapoints/answers/copy', params, {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Error saving data point');
            }
            return response.data;
        });
};

DataPoints.copyPreviousDataPoints = copyPreviousDataPoints;

const saveAnswerForDataPoint: (params: SavingDataPointAnswerType) => Promise<DataPointResponseType> = (params) => {
    let year, selectedYear;
    if (params.year) {
        year = typeof params.year === 'string' ? parseInt(params.year) : params.year;
    }
    // $FlowFixMe
    if (params.selectedYear) {
        selectedYear = typeof params.selectedYear === 'string' ? parseInt(params.selectedYear) : params.selectedYear;
    }
    return axios
        .put(
            Constants.apiBaseUrl + 'datapoints/' + params.dataPointId + '/answer',
            {
                answer: params.answer,
                schoolId: params.schoolId,
                year: year,
                selectedYear: selectedYear,
            },
            { withCredentials: true },
        )
        .then(function (response) {
            return response.data;
        });
};

DataPoints.saveAnswerForDataPoint = saveAnswerForDataPoint;

const getDataPointsDictionary: () => Promise<Array<DataDictionarySectionType>> = () => {
    return axios.get(Constants.apiBaseUrl + 'datapoints/dictionary').then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('No Data Points returned from Api');
        }
        return response.data;
    });
};

DataPoints.getDataPointsDictionary = getDataPointsDictionary;

const updateDataPoint: (dataPointId: string, update: any) => Promise<DataPointType> = (dataPointId, update) => {
    return axios
        .put(Constants.apiBaseUrl + 'datapoints/' + dataPointId, update, {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Error saving data point');
            }
            return response.data;
        });
};

DataPoints.updateDataPoint = updateDataPoint;

DataPoints.updateDataPointOrder = (dataPoints: Array<string>): Promise<Array<DataPointType>> => {
    return axios
        .put(Constants.apiBaseUrl + 'datapoints/order', { dataPoints: dataPoints }, { withCredentials: true })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Error saving data point');
            }
            return response.data;
        });
};

DataPoints.updateSectionOrder = (sections: Array<string>): Promise<Array<DataPointSectionType>> => {
    return axios
        .put(Constants.apiBaseUrl + 'sections/order', { sections: sections }, { withCredentials: true })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Error saving data point');
            }
            return response.data;
        });
};

DataPoints.updateSubsectionOrder = (section: string, subsections: Array<string>): Promise<DataPointSectionType> => {
    return axios
        .put(
            Constants.apiBaseUrl + 'sections/' + section + '/subsections/order',
            { subsections: subsections },
            { withCredentials: true },
        )
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Error saving data point');
            }
            return response.data;
        });
};

DataPoints.moveSubsectionToSection = (
    subsection: string,
    section: string,
    direction?: 'top' | 'bottom',
): Promise<Array<DataPointSectionType>> => {
    return axios
        .put(
            Constants.apiBaseUrl + 'sections/' + section + '/subsections',
            {
                subsection: subsection,
                direction: direction || 'bottom',
            },
            { withCredentials: true },
        )
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Error saving data point');
            }
            return response.data;
        });
};

DataPoints.updateSectionName = (section: string, name: string): Promise<DataPointSectionType> => {
    return axios
        .put(
            Constants.apiBaseUrl + 'sections/' + section,
            {
                name: name,
            },
            { withCredentials: true },
        )
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Error saving data point');
            }
            return response.data;
        });
};

DataPoints.updateSubsectionName = (
    section: string,
    subsection: string,
    name: string,
): Promise<DataPointSectionType> => {
    return axios
        .put(
            Constants.apiBaseUrl + 'sections/' + section + '/subsections/' + subsection,
            {
                name: name,
            },
            { withCredentials: true },
        )
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Error saving data point');
            }
            return response.data;
        });
};

DataPoints.fetchFilters = (schoolId?: string): Promise<Array<DataPointFilterType>> => {
    let params = {};
    if (schoolId) {
        params.school = schoolId;
    }
    return axios
        .get(Constants.apiBaseUrl + 'datapoints/filters', { params, withCredentials: true })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Error saving data point');
            }
            return response.data;
        });
};

export default DataPoints;
