// @flow
'use strict';

import { useEffect, useState } from 'react';
import Joyride, { 
    ACTIONS, 
    EVENTS,
    STATUS, 
} from 'react-joyride';

import { useSelector } from 'react-redux';

import sideBarStyles from '../SideBar/sideBar.scss'; // eslint-disable-line
import progressBarStyles from '../ProgressBar/progressBar.scss'; // eslint-disable-line

import { isLoading } from '../../redux/reducers';

type Props = {|
    steps: Array<JoyrideStepType>,
    run: boolean,
    spotlightClicks?: boolean,
    handleClose: (didFinish: boolean) => void,
|};

export function JoyrideWrapper({ steps, run, handleClose, spotlightClicks}: Props) {
    const loading = useSelector(isLoading);
    const [isRunning, setRunning] = useState(!loading && run);
    const [dismissed, setDismissed] = useState(false);
    
    useEffect(() => {
        if (!loading && !isRunning && run && !dismissed) {
            setRunning(true);
        }
    }, [loading, isRunning, run, dismissed,]);

    const callback: (data: JoyrideCallbackArgumentType) => void = ({action, status, type}) => {
        if (action === ACTIONS.CLOSE && status === STATUS.RUNNING) {
            setDismissed(true);
            handleClose(false);
        }
        if (action === ACTIONS.SKIP || (status === STATUS.FINISHED && type === EVENTS.TOUR_END)) {
            handleClose(true);
        }
    };
    return (
        <Joyride
            callback={callback}
            continuous={true}
            disableOverlayClose={true}
            disableScrolling={false}
            locale={{
                skip: 'Do Not Show Again'
            }}
            run={isRunning && !dismissed}
            showProgress={true}
            showSkipButton={true}
            spotlightClicks={spotlightClicks}
            steps={steps.map(s => ({...s, disableBeacon: true}))}
            styles={{
                overlay: { cursor: 'initial' },
                tooltipFooter: { justifyContent: 'flex-end' },
            }}
        />
    );
}

export default JoyrideWrapper;
