// @flow
'use strict';

import { useState } from 'react';
import styles from './scrubber.scss';
import UnassignedSchool from '../../components/UnassignedSchool';

import { useDrop } from 'react-dnd';

type PropTypes = {|
    scrubber: ScrubberType,
    hoveredOver?: boolean,
    onCardDrop: (scrubberId: string, unassignedSchoolId: string) => void // eslint-disable-line
|};

export default function Scrubber({
    scrubber,
    hoveredOver,
    onCardDrop
}: PropTypes) {
    const [, drop] = useDrop({
        accept: 'UnassignedSchool',
        drop: (item, monitor) => {
            const unassignedSchool = monitor.getItem();
            onCardDrop(scrubber._id, unassignedSchool._id);
        },
        collect: (monitor) => {
            return {
                hoveredOver: monitor.isOver(),
                expanded: monitor.didDrop(),
            };
        }
    });
    const [expanded, setExpanded] = useState(false);




    const handleExpand: (event: SyntheticInputEvent<*>) => void = (event) => {
        const element = document.getElementById(event.target.id);
        if (element) {
            element.innerHTML = expanded ? '+' : '-';
        }
        setExpanded(!expanded);
    };

    return (
        <tr
            ref={drop}
            id={scrubber._id}
            className={styles.scrubberRow + ' ' + (hoveredOver ? styles.hoveredOver : '')}
        >
            <td className={styles.scrubberTd}>
                <table className={styles.individualScrubberTable}>
                    <tbody className={styles.individualTbody}>
                        <tr key='top-row-key' className={styles.scrubberNameRow}>
                            <td className={styles.bullet}>
                                <button
                                    className={styles.expandButton}
                                    type='button'
                                    id={scrubber._id + '-button'}
                                    name='expandButton'
                                    onClick={handleExpand}
                                >
                                        +
                                </button>
                            </td>
                            <th className={styles.scrubberName}>
                                {scrubber.name + ' (' + scrubber.assignedSchools.length + ')'}
                            </th>
                        </tr>
                        {expanded && scrubber.assignedSchools.length > 0
                            ? scrubber.assignedSchools.map(function(school) {
                                return (
                                    <UnassignedSchool
                                        scrubberId={scrubber._id}
                                        key={school._id}
                                        _id={school._id}
                                        name={school.name}
                                    />
                                );
                            })
                            : null}
                    </tbody>
                </table>
            </td>
        </tr>);
}


