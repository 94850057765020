// @flow
'use strict';
import { Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ChromeWarning } from './components';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { parseQueryString, getLogoutUrl } from './utils';
import { logout, clearApiError, receiveUserIdAndToken, fetchUser, unsetError } from './redux/actions';

import { getError, hasTriedAuthenticating, getFetchingUser } from './redux/reducers';

import type { ChildrenArray } from 'react';
import { useTrackEvents } from './ga/analytics';

type ComponentPropTypes = {|
    children: ChildrenArray<any>,
|};

const HOME = '/';
const LOGIN_HANDLER = '/loginhandler';
const LOGOUT = '/logout';

export function Auth({ children }: ComponentPropTypes) {
    const triedAuthenticating = useSelector(hasTriedAuthenticating);
    const dispatch = useDispatch();
    const trackEvent = useTrackEvents();
    const isFetchingUser = useSelector(getFetchingUser);
    const error = useSelector(getError);
    const history = useHistory();
    const location = useLocation();
    const beginAuth = () => {
        let path = location.pathname;
        switch (path) {
            case LOGIN_HANDLER:
                onLoginhandler();
                break;
            case LOGOUT:
                dispatch(logout());
                history.replace('/', []);
                break;
            case HOME:
            default:
                onHome();
                break;
        }
    };

    const onHome = () => {
        if (!isFetchingUser && !triedAuthenticating) {
            dispatch(fetchUser());
        }
    };

    const onLoginhandler = () => {
        let search = location.search;
        if (!search || search === '') {
            history.replace('/');
            return;
        }
        let queryParams: {
            cstKey: string,
            token: string,
            redirectTo: string,
        } = parseQueryString(search);

        dispatch(receiveUserIdAndToken(queryParams.cstKey, queryParams.token, trackEvent));
        // dispatch(receiveUserIdAndToken(queryParams.cstKey, queryParams.token));
    };

    const handleUnsetError = (type?: ReduxErrorTypeType) => {
        dispatch(unsetError());
        if (type === 'Auth') {
            dispatch(clearApiError());
        } else {
            const logoutUrl = getLogoutUrl();
            dispatch(logout());
            window.location.href = logoutUrl;
        }
    };
    let message;
    let title;

    if (error && error.type && error.type === 'Non-member') {
        title = 'Not Authorized';
        message = (
            <DialogContent>
                <DialogContentText>You are not authorized to access BIIS.</DialogContentText>
                <DialogContentText>
                    For assistance, please email
                    <a style={{ color: 'blue' }} href={`mailto:research@nboa.org`}>
                        {' '}
                        research@nboa.org
                    </a>
                </DialogContentText>
            </DialogContent>
        );
    } else if (error && error.type) {
        title = 'There was a problem';
        message = (
            <DialogContent>
                <DialogContentText>{'We apologize, but there was a problem. Please try again later.'}</DialogContentText>
            </DialogContent>
        );
    } else {
        title = '';
        message = null;
    }
    if (!triedAuthenticating && !isFetchingUser) {
        beginAuth();
    }
    const chrome = navigator.userAgent.indexOf('Edge') === -1 && navigator.userAgent.indexOf('Chrome') !== -1;
    return triedAuthenticating ? (
        <Fragment>
            {chrome ? null : <ChromeWarning />}
            <Dialog open={Boolean(error)} onClose={() => handleUnsetError(error?.type)}>
                <DialogTitle>{title || ''}</DialogTitle>
                {message}
                <DialogActions>
                    <Button onClick={() => handleUnsetError(error?.type)}>Okay</Button>
                </DialogActions>
            </Dialog>
            {children}
        </Fragment>
    ) : (
        <div />
    );
}

export default Auth;
