// @flow
'use strict';

import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListSubheader, TextField, Typography } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
type Props = {
    filterItem: ReportFilterType,
    value?: ?string,
    className?: string,
    onChange: (id: $Keys<SelectedReportFilterType>, value: string) => void,
    disabled?: boolean,
    id?: ?string,
};

const useStyles = makeStyles(() => ({
    wrapper: {
        width: '100%',
        height: '100%',
    },
    label: {
        display: 'flex',
        color: 'black',
    },
    buttonIcon: {},
    mainButton: {
        width: '100%',
        height: '100%',
        textAlign: 'left',
    },
    innerLabel: {
        display: 'inline-block',
        flexGrow: 1,
    },
    upperLabel: {
        fontSize: '10px',
    },
    subheader: {
        backgroundColor: 'white',
    },
}));

const getName = (choices, value) => {
    let name;
    choices.forEach((c) => {
        if (name) {
            return;
        }
        if (c._id === value) {
            name = c.name;
            return;
        }
        if (Array.isArray(c.choices)) {
            c.choices.forEach((i) => {
                if (name) {
                    return;
                }
                if (i._id === value) {
                    name = i.name;
                }
                return;
            });
        }
    });
    return name || '';
};

export const FilterModal = ({ filterItem, value, onChange, disabled, className = '', id }: Props) => {
    const [searchFilter, setSearchFilter] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [name, setName] = useState(getName(filterItem.choices, value));
    const classes = useStyles();

    useEffect(() => {
        const nName = getName(filterItem.choices, value);
        if (nName !== name) {
            setName(nName);
        }
    }, [filterItem, value, name]);

    const getItems = (choices, filter) => {
        return choices
            .map((c) => {
                if (Array.isArray(c.choices)) {
                    let innerChoices = c.choices.filter((i) => {
                        return !filter || filter.trim() === '' || i.name.toLocaleLowerCase().includes(filter.trim().toLowerCase());
                    });
                    if (innerChoices.length === 0) {
                        return;
                    }
                    return (
                        <li key={c._id} className={classes.listSection}>
                            <ul>
                                <ListSubheader key={c._id}>{c.name}</ListSubheader>
                                {innerChoices.map((i) => {
                                    return (
                                        <ListItem
                                            key={i._id}
                                            button
                                            onClick={() => {
                                                onChange(filterItem._id, i._id);
                                                setShowModal(false);
                                            }}
                                        >
                                            {i.name}
                                        </ListItem>
                                    );
                                })}
                            </ul>
                        </li>
                    );
                } else {
                    if (filter && filter.trim() !== '') {
                        if (!c.name.toLocaleLowerCase().includes(filter.trim().toLowerCase())) {
                            return;
                        }
                    }
                    return (
                        <ListItem
                            key={filterItem._id}
                            button
                            onClick={() => {
                                onChange(filterItem._id, c._id);
                                setShowModal(false);
                            }}
                        >
                            {c.name}
                        </ListItem>
                    );
                }
            })
            .filter((i) => i);
    };
    return (
        <div id={id} className={`${className} ${classes.wrapper}`}>
            <Button disabled={disabled} className={classes.mainButton} classes={{ label: classes.label }} id={filterItem._id} onClick={() => setShowModal(true)} variant='outlined'>
                <div className={classes.innerLabel}>
                    <Typography className={classes.upperLabel} component='p'>
                        {filterItem.name}
                    </Typography>
                    <Typography component='p'>{name}</Typography>
                </div>
                <ArrowDropDown className={classes.buttonIcon} />
            </Button>
            <Dialog onClose={() => setShowModal(false)} open={showModal}>
                <DialogTitle>Choose a topic:</DialogTitle>
                <TextField label='Search' variant='outlined' value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)} />
                <DialogContent>
                    <List subheader={<li className={classes.subheader} />}>{getItems(filterItem.choices, searchFilter)}</List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowModal(false)} color='primary'>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default FilterModal;
