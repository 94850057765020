// @flow
'use strict';

import { useSelector } from 'react-redux';
import styles from './loginButton.scss';
import * as Utils from '../../utils';
import { getUser } from '../../redux/reducers';
// import { useHistory } from 'react-router-dom';

// import { Button } from '@material-ui/core';

export function LoginButton() {
    const user = useSelector(getUser);
    // const navigate = useNavigate();

    const loginRedirectUrl = Utils.getLoginRedirectUrl();

    const userExists = user && !Utils.isEmpty(user._id);

    if (!userExists) {
        return (
            <button
                className={styles['biis-login-button']}
                onClick={() => {
                    // navigate('/')
                    window.location.href = loginRedirectUrl;
                }}
            >
                LOGIN
            </button>
        );
    }

    return <></>;
}

export default LoginButton;
