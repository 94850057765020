// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-components-SideBar-sideBar__sideBarWrapper--dK3Tj{height:100%;width:100%;margin-top:8px;padding:0;display:flex;flex-direction:column;justify-content:space-between}.app-components-SideBar-sideBar__sectionListWrapper--1jyQJ{overflow-y:auto}.app-components-SideBar-sideBar__bottomWrapper--dnJ3q{margin-bottom:8px}.app-components-SideBar-sideBar__panelDetails--2V4uG{margin:-4px 0 10px 40px}.app-components-SideBar-sideBar__submitOverrideWrapper--YqftP{display:flex;height:25px;margin-top:-8px;margin-left:15px}.app-components-SideBar-sideBar__progressBar--1cWfp{margin-bottom:10px}.app-components-SideBar-sideBar__btnWrapper--dBwvv{display:flex;flex-direction:column;justify-content:center}.app-components-SideBar-sideBar__confirmWrapper--1SrG7{position:relative}", "",{"version":3,"sources":["webpack://./app/components/SideBar/sideBar.scss"],"names":[],"mappings":"AAAA,uDACI,WAAA,CACA,UAAA,CACA,cAAA,CACA,SAAA,CACA,YAAA,CACA,qBAAA,CACA,6BAAA,CAGJ,2DACI,eAAA,CAGJ,sDACI,iBAAA,CAGJ,qDACI,uBAAA,CAGJ,8DACI,YAAA,CACA,WAAA,CACA,eAAA,CACA,gBAAA,CAGJ,oDACI,kBAAA,CAGJ,mDACI,YAAA,CACA,qBAAA,CACA,sBAAA,CAGJ,uDACI,iBAAA","sourcesContent":[".sideBarWrapper {\n    height: 100%;\n    width: 100%;\n    margin-top: 8px;\n    padding: 0;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\n.sectionListWrapper {\n    overflow-y: auto;\n}\n\n.bottomWrapper {\n    margin-bottom: 8px;\n}\n\n.panelDetails {\n    margin: -4px 0 10px 40px;\n}\n\n.submitOverrideWrapper {\n    display: flex;\n    height: 25px;\n    margin-top: -8px;\n    margin-left: 15px;\n}\n\n.progressBar {\n    margin-bottom: 10px;\n}\n\n.btnWrapper {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n}\n\n.confirmWrapper {\n    position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sideBarWrapper": "app-components-SideBar-sideBar__sideBarWrapper--dK3Tj",
	"sectionListWrapper": "app-components-SideBar-sideBar__sectionListWrapper--1jyQJ",
	"bottomWrapper": "app-components-SideBar-sideBar__bottomWrapper--dnJ3q",
	"panelDetails": "app-components-SideBar-sideBar__panelDetails--2V4uG",
	"submitOverrideWrapper": "app-components-SideBar-sideBar__submitOverrideWrapper--YqftP",
	"progressBar": "app-components-SideBar-sideBar__progressBar--1cWfp",
	"btnWrapper": "app-components-SideBar-sideBar__btnWrapper--dBwvv",
	"confirmWrapper": "app-components-SideBar-sideBar__confirmWrapper--1SrG7"
};
export default ___CSS_LOADER_EXPORT___;
