// @flow
'use strict';

import { Component } from 'react';
import styles from './outlierItem.scss';

type PropTypes = {
    item: any,
    bySchool?: boolean,
    handleUpdateValue: (value: string) => void,
    handleRedirect: () => void,
};

type StateType = {
    canEdit: boolean,
    value: string,
};

export class OutlierItem extends Component<PropTypes, StateType> {
    props: PropTypes;

    state: StateType;

    constructor(props: PropTypes) {
        super(props);
        this.state = {
            canEdit: false,
            value: props.item.value,
        };
        this.handleCanEdit = this.handleCanEdit.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.onUpdateValue = this.onUpdateValue.bind(this);
    }

    shouldComponentUpdate(nextProps: PropTypes, nextState: StateType) {
        const self = this;
        if (
            self.state.canEdit !== nextState.canEdit ||
            self.state.value !== nextState.value ||
            self.props.bySchool !== nextProps.bySchool
        ) {
            return true;
        }
        return false;
    }

    handleCanEdit: () => void = () => {
        const self = this;
        self.setState({
            canEdit: !self.state.canEdit,
            value: self.props.item.value,
        });
    };

    handleValueChange: (event: SyntheticInputEvent<*>) => void = (event) => {
        const self = this;
        event.preventDefault();
        if (self.state.value === event.target.value) {
            return;
        }
        self.setState({
            value: event.target.value,
        });
    };

    onUpdateValue: (event: SyntheticInputEvent<*>) => void = (event) => {
        event.preventDefault();
        if (this.state.value === this.props.item.value) {
            return;
        }
        this.setState({
            canEdit: false,
        });
        this.props.handleUpdateValue(this.state.value);
    };

    render() {
        let item = Object.assign({}, this.props.item);
        return (
            <tr className={styles.innerTableRow}>
                <td className={styles.schoolName}>
                    {this.props.bySchool
                        ? item.name + ' (95th Percentile: ' + item.upperValue.toString() + ')'
                        : item.schoolName}
                </td>
                <td className={styles.value}>
                    {this.state.canEdit ? (
                        <input className={styles.input} onChange={this.handleValueChange} value={this.state.value} />
                    ) : (
                        this.state.value
                    )}
                </td>
                <td className={styles.editTd}>
                    <div className={styles.editButtonsWrapper}>
                        {!this.state.canEdit ? (
                            <button className={styles.editButton} onClick={this.handleCanEdit} />
                        ) : (
                            [
                                <button
                                    key='deleteButton'
                                    className={styles.deleteButton}
                                    onClick={this.handleCanEdit}
                                />,
                                <button key='saveButton' className={styles.saveButton} onClick={this.onUpdateValue}>
                                    Save
                                </button>,
                            ]
                        )}
                        {
                            <button className={styles.goToDataButton} onClick={this.props.handleRedirect}>
                                Go To Data
                            </button>
                        }
                    </div>
                </td>
            </tr>
        );
    }
}

export default OutlierItem;
