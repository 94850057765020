// @flow
'use strict';
import { loadPolyfill } from './polyfill';
loadPolyfill();

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import configureStore from './redux/store/configureStore';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Auth from './Auth';
import App from './App';
import { Constants, parseQueryString } from './utils';

import { setSelectedSectionAndSubsectionIds, selectSchool } from './redux/actions';
import { getSelectedSchoolId } from './redux/reducers';
// const tracker: SnowplowTracker = window.tracker;

import { CssBaseline } from '@material-ui/core';
import { install } from 'ga-gtag';

const apiUrl = Constants.apiBaseUrl;

const history = createBrowserHistory();
// if (tracker) {
//     tracker.trackPageView(history.location.pathname);
// }
install(Constants.gaMeasurementId);
const store = configureStore();
store.subscribe(() => {
    const tracker = window.tracker;

    const state = store.getState();
    const userId = state.userInfo.user?._id;
    if (userId && tracker) {
        const currentUserId = tracker.getUserId();
        if (userId !== currentUserId && tracker) {
            tracker.setUserId(userId);
        }
    }
});

const cubejsApi = cubejs(
    () => {
        return store.getState().userInfo.token;
    },
    {
        apiUrl: `${apiUrl}cubejs-api/v1`,
        credentials: 'omit',
    },
);

history.listen((location, action) => {
    const parsed = parseQueryString(location.search);
    switch (action) {
        case 'POP': {
            store.dispatch(setSelectedSectionAndSubsectionIds(parsed.section, parsed.subsection));
            const state = store.getState();
            if (parsed.school && getSelectedSchoolId(state) !== parsed.school) {
                store.dispatch(selectSchool(parsed.school));
            }
            break;
        }
    }
});

let rootElement = document.getElementById('container__root');
if (!rootElement) {
    throw new Error('Unable to find root element');
}

const theme = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '14px',
            },
        },
        MuiAppBar: {
            root: {
                zIndex: 99,
            },
        },
    },
    palette: {
        primary: {
            main: '#009CDE',
            dark: '#203480',
        },
        secondary: {
            main: '#6e6259',
            light: '#A59C94',
        },
        error: {
            main: '#D22630',
        },
    },
    typography: {
        fontFamily: ['"Helvetica Neue"', 'HelveticaNeue', '"TeX Gyre Heros"', 'TeXGyreHeros', 'FreeSans', '"Nimbus Sans L"', '"Liberation Sans"', 'Arimo', 'Helvetica', 'Arial', 'sans-serif'].join(
            ',',
        ),
    },
});

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        {/* <LocalizationProvider dateAdapter={MomentUtils}> */}
        <CssBaseline />
        <CubeProvider cubejsApi={cubejsApi}>
            <Provider store={store}>
                <Router>
                    <Auth>
                        <App />
                    </Auth>
                </Router>
            </Provider>
        </CubeProvider>
        {/* </LocalizationProvider> */}
    </MuiThemeProvider>,
    rootElement,
);
