export default {
    _id: 'cfi',
    pages: [
        {
            rows: [
                {
                    items: [
                        {
                            _id: '5beb1a1ad3dcef12f9379ae5',
                            height: 0,
                            width: 60,
                            x: 100,
                            y: 40,
                        },
                        {
                            _id: '5bec3563a1cb7a3fecaddd91',

                            height: 0,
                            width: 60,
                            x: 200,
                            y: 40,
                        },
                        {
                            _id: '5bec3563a1cb7a3fecaddd92',

                            height: 0,
                            width: 60,
                            x: 300,
                            y: 40,
                        },
                        {
                            _id: '5bec3563a1cb7a3fecaddd93',

                            height: 0,
                            width: 60,
                            x: 400,
                            y: 40,
                        },
                        {
                            _id: '5bec5f71a1cb7a3fecaddda9',

                            height: 0,
                            width: 60,
                            x: 500,
                            y: 40,
                        },
                    ],
                },
                {
                    items: [
                        {
                            _id: '5bed900f57266bf48ae4c471',

                            height: 180,
                            width: 250,
                            x: 150,
                            y: 100,
                        },
                    ],
                },
                {
                    items: [
                        {
                            _id: '5beed578fa12e190f09274ee',

                            height: 150,
                            width: 250,
                            x: 20,
                            y: 30,
                        },
                        {
                            _id: '5beed57ffa12e190f09274ef',

                            height: 150,
                            width: 250,
                            x: 300,
                            y: 30,
                        },
                    ],
                },
                {
                    items: [
                        {
                            _id: '5beed839fa12e190f09274f0',

                            height: 150,
                            width: 250,
                            x: 20,
                            y: 250,
                        },
                        {
                            _id: '5beed83bfa12e190f09274f1',

                            height: 150,
                            width: 250,
                            x: 300,
                            y: 250,
                        },
                    ],
                },
            ],
        },
    ],
};
