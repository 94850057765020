// @flow
'use strict';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import DashboardItem from '../DashboardItem';

import styles from './dashboardRow.scss';

// const DEFAULT_HEIGHT = 300;

const useStyles = makeStyles(() => ({
    item: {
        height: '100% !important',
        paddingBottom: 0,
    },
    row: {
        height: '100%',
    },
}));

type PropTypes = {|
    row: DashboardRowType,
    className?: string,
    style?: Object,
|};

export function DashboardRow(props: PropTypes) {
    const classes = useStyles();
    let className = styles.dashboardRow;
    if (props.className) {
        className += ' ' + props.className;
    }
    let rowStyle = props.row.style || {};
    if (props.style) {
        rowStyle = {
            ...rowStyle,
            ...props.style,
        };
    }
    // const height = props.row.height || DEFAULT_HEIGHT;
    // if (!rowStyle.height) {
    //     rowStyle.height = height + 'px';
    // }
    let xs = 1;
    switch (props.row.items.length) {
        case 1:
            xs = 11;
            break;
        case 2:
            xs = 5;
            break;
        case 3:
            xs = 3;
            break;
        case 4:
        case 5:
            xs = 2;
            break;
        case 6:
        case 7:
            xs = 1;
            break;
    }
    return (
        <div style={rowStyle} className={className}>
            <Grid spacing={2} direction='row' alignContent='space-between' justify='center' container={true} className={classes.row}>
                {props.row.items.map(function (item) {
                    if (!item) {
                        return;
                    }
                    return (
                        <Grid xs={xs} item key={item._id} className={classes.item}>
                            <DashboardItem item={item} style={item.style} />
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
}

export default DashboardRow;
