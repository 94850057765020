// @flow
'use strict';

/**
 * All action types are defined as constants
 */

export const RECEIVE_USER_ID_AND_TOKEN = 'RECEIVE_USER_ID_AND_TOKEN';
export const RECIEVE_API_TOKEN = 'RECIEVE_API_TOKEN';
export const RECEIVE_USER_FROM_API = 'RECEIVE_USER_FROM_API';

export const FETCHING_USER = 'FETCHING_USER';
export const USER_FETCH_FAILED = 'USER_FETCH_FAILED';
export const USER_TOKEN = 'USER_TOKEN';

export const SELECTED_SCHOOL = 'SELECTED_SCHOOL';
export const FETCHING_SELECTED_SCHOOL = 'FETCHING_SELECTED_SCHOOL';

export const FETCHING_PEERGROUPS = 'FETCHING_PEERGROUPS';
export const RECEIVE_PEERGROUPS = 'RECEIVE_PEERGROUPS';
export const RECEIVE_PEER_GROUP_SCHOOLS = 'RECEIVE_PEER_GROUP_SCHOOLS';
export const RESET_PEER_GROUP_SCHOOLS = 'RESET_PEER_GROUP_SCHOOLS';
export const SET_SELECTED_PEERGROUP_ID = 'SET_SELECTED_PEERGROUP_ID';
export const SET_PEERGROUP_SCHOOL_FILTER = 'SET_PEERGROUP_SCHOOL_FILTER';
export const UPDATE_PEERGROUP = 'UPDATE_PEERGROUP';

export const FETCHING_CANNED_PEERGROUPS = 'FETCHING_CANNED_PEERGROUPS';
export const RECEIVE_CANNED_PEERGROUPS = 'RECEIVE_CANNED_PEERGROUPS';
export const SET_SELECTED_CANNED_PEERGROUP_ID = 'SET_SELECTED_CANNED_PEERGROUP_ID';

export const SELECTED_YEAR = 'SELECTED_YEAR';

export const RESET_DATAPOINTS_DATA = 'RESET_DATAPOINTS_DATA';
export const RECEIVE_DATAPOINTS_DATA = 'RECEIVE_DATAPOINTS_DATA';
export const FETCHING_DATAPOINTS_DATA = 'FETCHING_DATAPOINTS_DATA';
export const FETCHING_DATAPOINTS_SECTIONS = 'FETCHING_DATAPOINTS_SECTIONS';
export const RECEIVE_DATAPOINTS_SECTIONS = 'RECEIVE_DATAPOINTS_SECTIONS';
export const RESET_DATAPOINTS_SECTIONS = 'RESET_DATAPOINTS_SECTIONS';
export const RECEIVE_DATAPOINTS_FOR_SECTION = 'RECEIVE_DATAPOINTS_FOR_SECTION';
export const SET_EDIT_DATAPOINTS = 'SET_EDIT_DATAPOINTS';
export const SET_SELECTED_FILTER = 'SET_SELECTED_FILTER';

export const RESET_REPORT_FILTERS = 'RESET_REPORT_FILTERS';
export const RESET_REPORT_DATA = 'RESET_REPORT_DATA';
export const RESET_REPORT_ERROR_MESSAGE = 'RESET_REPORT_ERROR_MESSAGE';
export const FETCHING_REPORT_TOPICS = 'FETCHING_REPORT_TOPICS';
export const FETCHING_REPORT_TOPICS_FOR_EDITING = 'FETCHING_REPORT_TOPICS_FOR_EDITING';
export const FETCHING_REPORT_TOPIC_SECTIONS = 'FETCHING_REPORT_TOPIC_SECTIONS';
export const RECEIVE_REPORT_TOPIC_SECTIONS = 'RECEIVE_REPORT_TOPIC_SECTIONS';
export const SET_SELECTED_REPORT_TOPIC_SECTION_ID = 'SET_SELECTED_REPORT_TOPIC_SECTION_ID';
export const SET_REPORT_TOPICS = 'SET_REPORT_TOPICS';
export const SET_REPORT_TOPICS_FOR_EDITING = 'SET_REPORT_TOPICS_FOR_EDITING';
export const SET_REPORT_FILTERS = 'SET_REPORT_FILTERS';
export const SET_REPORT_DATA = 'SET_REPORT_DATA';
export const SET_SELECTED_REPORT_TOPIC = 'SET_SELECTED_REPORT_TOPIC';
export const SET_SELECTED_REPORT_FILTERS = 'SET_SELECTED_REPORT_FILTERS';
export const RESET_ALL_REPORT_DATA = 'RESET_ALL_REPORT_DATA';
export const SET_RATIOS = 'SET_RATIOS';
export const SET_FETCHING_RATIOS = 'SET_FETCHING_RATIOS';
export const RECEIVE_DASHBOARD = 'RECEIVE_DASHBOARD';
export const RESET_DASHBOARD = 'RESET_DASHBOARD';
export const SET_FETCHING_OUTLIERS = 'SET_FETCHING_OUTLIERS';
export const SET_OUTLIERS = 'SET_OUTLIERS';
export const SET_DATAPOINTS_BY_SCHOOL = 'SET_DATAPOINTS_BY_SCHOOL';

export const RECEIVE_REQUIRED_COUNTS = 'RECEIVE_REQUIRED_COUNTS';
export const RECEIVE_UPDATED_DATAPOINTS = 'RECEIVE_UPDATED_DATAPOINTS';
export const RECEIVE_UPDATED_STATUS = 'RECEIVE_UPDATED_STATUS';
export const RECEIVE_PARTICIPATING_SCHOOLS = 'RECEIVE_PARTICIPATING_SCHOOLS';

export const FETCHING_PEERGROUP_SCHOOLS = 'FETCHING_PEERGROUP_SCHOOLS';
export const ERROR_FETCHING_REPORT_DATA = 'ERROR_FETCHING_REPORT_DATA';
export const FETCHING_REPORT_FILTERS = 'FETCHING_REPORT_FILTERS';
export const FETCHING_REPORT_DATA = 'FETCHING_REPORT_DATA';
export const API_AUTH_ERR = 'API_AUTH_ERR';

export const SET_PARTICIPATING_SCHOOL_FILTER = 'SET_PARTICIPATING_SCHOOL_FILTER';
export const CLEAR_PARTICIPATING_SCHOOL_FILTER = 'CLEAR_PARTICIPATING_SCHOOL_FILTER';

export const SET_DISPLAY_UNANSWERED = 'SET_DISPLAY_UNANSWERED';

export const SET_DISPLAY_ALL = 'SET_DISPLAY_ALL';
export const UNSET_DISPLAY_ALL = 'UNSET_DISPLAY_ALL';

export const SET_LOADING = 'SET_LOADING';
export const UNSET_LOADING = 'UNSET_LOADING';

export const SET_ERROR = 'SET_ERROR';
export const UNSET_ERROR = 'UNSET_ERROR';
export const SET_SELECTED_SECTION_ID = 'SET_SELECTED_SECTION_ID';
export const SET_SELECTED_SUBSECTION_ID = 'SET_SELECTED_SUBSECTION_ID';
export const SET_SELECTED_SECTION_AND_SUBSECTION_ID = 'SET_SELECTED_SECTION_AND_SUBSECTION_ID';
export const SET_ALL_SCHOOL_REPORT_DATA = 'SET_ALL_SCHOOL_REPORT_DATA';

export const SET_REPORT_ERROR = 'SET_REPORT_ERROR';
export const SET_SCRUBBERS = 'SET_SCRUBBERS';
export const SET_FETCHING_SCRUBBERS = 'SET_FETCHING_SCRUBBERS';

export const SET_FETCHING_UNASSIGNED_SCHOOLS = 'SET_FETCHING_UNASSIGNED_SCHOOLS';
export const SET_UNASSIGNED_SCHOOLS = 'SET_UNASSIGNED_SCHOOLS';

export const SET_DATAPOINT_FILTERS = 'SET_DATAPOINT_FILTERS';
export const SET_FETCHING_FILTERS = 'SET_FETCHING_FILTERS';
export const SET_SEARCH_FILTER = 'SET_SEARCH_FILTER';

export const CLOSE_CHROME_WARNING = 'CLOSE_CHROME_WARNING';
export const OPEN_CHROME_WARNING = 'OPEN_CHROME_WARNING';

export const SET_DATA_POINTS = 'SET_DATA_POINTS';
export const UPDATE_SINGLE_DATAPOINT = 'UPDATE_SINGLE_DATAPOINT';
export const RESET_DATAPOINTS = 'RESET_DATAPOINTS';

export const SET_FETCHING_DATA_TABLES = 'SET_FETCHING_DATA_TABLES';
export const SET_DATA_TABLES = 'SET_DATA_TABLES';
export const SET_FETCHING_DATA_TABLE = 'SET_FETCHING_DATA_TABLE';
export const SET_DATA_TABLE = 'SET_DATA_TABLE';
export const RESET_DATA_TABLE = 'RESET_DATA_TABLE';
export const UPDATE_DATA_TABLE_ROW = 'UPDATE_DATA_TABLE_ROW';
export const UPDATE_DATA_TABLE_ROWS = 'UPDATE_DATA_TABLE_ROWS';
export const ADD_DATA_TABLE_ROW = 'ADD_DATA_TABLE_ROW';
export const DELETE_DATA_TABLE_ROW = 'DELETE_DATA_TABLE_ROW';

export const RECEIVE_USER_DASHBOARD = 'RECEIVE_USER_DASHBOARD';
export const SET_FETCHING_SCHOOLS = 'SET_FETCHING_SCHOOLS';
export const RECEIVE_SCHOOLS_BY_STATUS = 'RECEIVE_SCHOOLS_BY_STATUS';

export const UPDATE_SINGLE_REPORTTOPIC = 'UPDATE_SINGLE_REPORTTOPIC';

export const SET_DIALOG_SELECTED_REPORT_SECTION_ID = 'SET_DIALOG_SELECTED_REPORT_SECTION_ID';
export const SET_DIALOG_SELECTED_REPORT_TOPIC_ID = 'SET_DIALOG_SELECTED_REPORT_TOPIC_ID';
export const SET_DIALOG_SEARCH_TEXT = 'SET_DIALOG_SEARCH_TEXT';
