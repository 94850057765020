// @flow
'use strict';

import { Component } from 'react';
import DataPointQuestion from '../DataPointQuestion';
import {formatName} from '../../utils';
import styles from './dataPointSubsection.scss';

type OwnProps = {|
    subsection: DataPointSubsectionType,
    selectedYear: YearNumberType
|};

type Props = {
    ...OwnProps,
};

export class DataPointSubsection extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.renderHeader = this.renderHeader.bind(this);
        this.renderYears = this.renderYears.bind(this);
    }

    renderHeader: () => ?React$Element<any> = () => {
        const selectedYear = this.props.selectedYear;
        let name = '';
        if (this.props.subsection.name) {
            name = formatName(this.props.subsection.name, selectedYear);
        }
        return (
            <div id={this.props.subsection._id} className={styles.subsectionHeader}>
                {name}
            </div>
        );
    };

    renderYears: (historicalAnswers: Array<HistoricalAnswerType>, index: number) => React$Element<*> = (
        historicalAnswers,
        index,
    ) => {
        const answers = historicalAnswers.sort(function(a, b) {
            return b.year - a.year;
        });
        return (
            <tr key={index} className={styles.years}>
                <th />
                {answers.map(function(answer) {
                    const currYear = answer.year.toString();
                    return (
                        <th className={styles.yearItem} key={currYear}>
                            {currYear}
                        </th>
                    );
                })}
            </tr>
        );
    };

    render() {
        const {
            subsection,
        } = this.props;
        const self = this;
        return (
            <div className={styles.dataPointSubsection}>
                {self.renderHeader()}
                <table className={styles.subsectionTable}>
                    <tbody>
                        {subsection.dataPoints
                            ? subsection.dataPoints.map(function(dataPointId) {
                                return (
                                    <tr key={dataPointId} className={styles.dataPointRow}>
                                        <td className={styles.dataPointCell}>
                                            <DataPointQuestion dataPointId={dataPointId} />
                                        </td>
                                    </tr>
                                );
                            })
                            : null}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default DataPointSubsection;
