// @flow
'use strict';

import { useState } from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';
import { Close, Edit, Save } from '@material-ui/icons';
import { debounce } from 'lodash';
import { TextField } from '@mui/material';

const EDIT_TIMEOUT = 2 * 1000;

type Props = {|
    name: string,
    onChange: (name: string) => void,
    id?: ?string,
|};

const _handleNameChange = function (name: string, updateName: (name: string) => void): void {
    updateName(name);
};

const handleNameChange = debounce(_handleNameChange, EDIT_TIMEOUT);

export function PeergroupName(props: Props) {
    const { id, name, onChange } = props;
    const [editingName, setEditingName] = useState(false);
    const [peergroupName, setPeergroupName] = useState(name);

    return (
        <TextField
            id={id}
            style={{ width: '20rem', paddingLeft: '0.5rem', paddingRight: '0.5rem' }}
            type='text'
            disabled={!editingName}
            value={peergroupName}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <IconButton size='small' onClick={() => setEditingName(!editingName)}>
                            {editingName && <Save />}
                        </IconButton>
                        <IconButton size='small' onClick={() => setEditingName(!editingName)}>
                            {editingName ? <Close /> : <Edit />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            onChange={(event) => {
                setPeergroupName(event.target.value);
                handleNameChange(event.target.value, (name) => {
                    setEditingName(false);
                    onChange(name);
                });
            }}
        />
    );
}

export default PeergroupName;
