import styles from './homePage.scss';

import biisBanner from '../../images/redesign/biis-banner.jpg';
import dewarLogo from '../../images/redesign/dewar-logo.png';
import benchmarking from '../../images/redesign/benchmarking.png';
import indicator from '../../images/redesign/indicator.png';
import financial from '../../images/redesign/financial-health.png';
import LoginButton from '../../components/LoginButton';

import Header2 from '../../components/Header2';
import HeaderBiis from '../../components/Header-Biis';
import { GA_EVENTS, useTrackEvents } from '../../ga/analytics';
import { useEffect } from 'react';

function index() {
    const trackEvent = useTrackEvents();
    useEffect(() => {
        trackEvent(GA_EVENTS.PAGEVIEW.NAME);
    }, []);

    return (
        <>
            <div id='container' className={styles['container']} style={{ display: 'flex', flexDirection: 'column' }}>
                <Header2 />
                <HeaderBiis />
                {/* add menu here */}
                <section className={styles['login-header']}>
                    <div style={{ flex: 1 }}>
                        <img src={biisBanner} className={styles['img-biis-banner']} />
                    </div>
                    <div style={{ flex: 1 }} className={`${styles['bg-nboa-blue']} ${styles['bg-grid-img']} ${styles['py-2']} ${styles['pl-3']}`}>
                        <div className={styles['biis-text-container']}>
                            <div className={styles['biis-header-text']}>Business Intelligence for Independent Schools</div>
                            <div className={styles['biis-info-text']}>
                                <p>The Business Intelligence for Independent Schools (BIIS) platform was developed by business officers for business officers.</p>
                                <p>The BIIS platform equips school leaders with a set of data tools that provide a comprehensive look at their financial health.</p>
                            </div>
                            <div className={styles['mt-2']}>
                                <LoginButton />
                            </div>
                        </div>
                    </div>
                </section>
                <section className={`${styles['bg-nboa-light-gray']} ${styles['dewar-container']} ${styles['py-1']}`}>
                    <div className={styles['dewar-text']}>Sponsored by</div>
                    <div className={styles['dewar-logo-container']}>
                        <img src={dewarLogo} className={styles['dewar-logo']} />
                    </div>
                </section>
                <section className={`${styles['strategic-container']} ${styles['ml-4']}`}>
                    <div className={styles['strategic-header-text']}>Inform Strategic Decision Making</div>
                    <div className={styles['strategic-subtitle-text']}>Examine your school&apos;s operational performance and long-term financial sustainability.</div>
                </section>
                <section className={`${styles['icons-container']}`}>
                    <div className={`${styles['icons-imgs-container']}`}>
                        <div>
                            <div className={styles['icon-img-container']}>
                                <img src={benchmarking} className={styles['icon-img']} />
                            </div>
                            <div className={styles['icon-text']}>Benchmark financial and operational data</div>
                        </div>
                        <div>
                            <div className={styles['icon-img-container']}>
                                <img src={indicator} className={styles['icon-img']} />
                            </div>
                            <div className={styles['icon-text']}>Understand and track financial indicators</div>
                        </div>
                        <div>
                            <div className={styles['icon-img-container']}>
                                <img src={financial} className={styles['icon-img']} />
                            </div>
                            <div className={styles['icon-text']}>Communicate about your school&apos;s financial health with school leaders</div>
                        </div>
                    </div>
                </section>
                <section className={`${styles['industry-reasearch-container']} ${styles['mt-2']} ${styles['ml-4']} ${styles['mr-4']} ${styles['mb-2']}`}>
                    <div className={`${styles['bg-nboa-purple']} ${styles['bg-industry-research']} ${styles['py-2']}`}>
                        <div className={styles['industry-research-header-text']}>BIIS Fuels Industry Research</div>
                        <div className={styles['industry-research-info-text']}>NBOA analyzes BIIS data to conduct research on topics important to independent schools</div>
                        <div className={`${styles['industry-research-button']}  ${styles['mt-1']}`}>
                            <button
                                className={styles['biis-read-more-button']}
                                onClick={() => {
                                    window.location.href = 'https://www.nboa.org/publications/research-reports';
                                }}
                            >
                                READ MORE
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default index;
