// @flow
'use strict';

import { useState } from 'react';
import { Dialog, DialogTitle, List, ListItem, ListItemText } from '@material-ui/core';
import styles from './schoolModal.scss';

type Props = {
    schools: Array<PeergroupSchoolType>,
    onSelectSchool: (selectedSchoolId: string) => void,
    close: () => void,
};

export const SchoolModal = (props: Props) => {
    const {
        schools,
        onSelectSchool,
        close
    } = props;
    const [searchFilter, setSearchFilter] = useState('');

    const renderSchoolItem = (school: PeergroupSchoolType) => {
        return (
            <ListItem button
                key={school._id}
                onClick={() => onSelectSchool(school._id)}
            >
                <ListItemText primary={school.name} secondary={`${school.city}, ${school.state}`} />
            </ListItem>
        );
    };
    return (
        <Dialog open={true} onClose={() => close()} className={styles.modal}>
            <DialogTitle>School Name</DialogTitle>

            <input
                type='text'
                name='schoolModalSearch'
                className={styles.search}
                onChange={e => setSearchFilter(e.target.value)}
                placeholder='Search'
                value={searchFilter}
            />
            <List dense>
                {searchFilter !== ''
                    ? schools
                        .filter(function (school) {
                            return school.name
                                .toLowerCase()
                                .indexOf(searchFilter.toLowerCase()) !== -1
                                ? true
                                : false;
                        })
                        .map((school) => renderSchoolItem(school))
                    : schools.map((school) => renderSchoolItem(school))
                }
            </List>
        </Dialog >
    );
};

export default SchoolModal;
