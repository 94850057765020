// @flow
'use strict';

import { Component } from 'react';
import styles from './sectionEditorItem.scss';
import SectionEditorSubsection from './sectionEditorSubsection';
import { DragSource, DropTarget } from 'react-dnd';
import { flow, isEqual } from 'lodash';
import * as Utils from '../../utils';

const TIMEOUT = 3 * 1000;

type PropTypes = {|
    ...DragDropType,
    section: DataPointSectionType,
    index: number,
    selected: boolean,
    moveCard: (dragIndex: number, hoverIndex: number) => void,// eslint-disable-line
    onCardDrop: () => void,// eslint-disable-line
    onLongHover: (sectionId: string) => void,// eslint-disable-line
    onExpand: (sectionId: string) => void,
    onSubsectionDrop: (section: string, subsections: Array<string> ) => void,// eslint-disable-line
    onSubsectionDropInSection: (section: string, subsection: string) => void,// eslint-disable-line
    updateSectionName: (section: string, name: string) => void,
    updateSubsectionName: (section: string, subsection?: string, name: string) => void,
    handleButton: (event: SyntheticInputEvent<*>) => void,// eslint-disable-line
|};

const itemSource = {
    beginDrag(props: PropTypes) {
        return {
            _id: props.section._id,
            section: props.section.name,
            subsections: props.section.subsections,
            index: props.index,
        };
    },
};

const itemTarget = {
    drop(props: PropTypes, monitor) {
        const type = monitor.getItemType();
        if (type === 'SectionEditorItems') {
            return props.onCardDrop();
        }
        return props.onSubsectionDropInSection(props.section._id, monitor.getItem().subsection._id);
    },
    hover(props: PropTypes, monitor, component) {
        const type = monitor.getItemType();
        if (type === 'SectionEditorItems') {
            const dragIndex = monitor.getItem().index;
            const hoverIndex = props.index;
            if (dragIndex === hoverIndex) {
                return;
            }

            if (!component) {
                return;
            }

            const domNode = component.getDecoratedComponentInstance();
            if (!domNode) {
                return;
            }
            const hoverBoundingRect = domNode;
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            props.moveCard(dragIndex, hoverIndex);
            monitor.getItem().index = hoverIndex;
        }
    },
};

type StateType = {
    section: DataPointSectionType,
    subsections?: Array<DataPointSubsectionType>,
};

export class SectionEditorItem extends Component<*, *> {
    props: PropTypes;
    state: StateType;

    timer: TimeoutID | null;

    constructor(props: PropTypes) {
        super(props);
        this.state = {
            section: props.section,
            subsections: props.section.subsections || undefined,
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleTimeout = this.handleTimeout.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.startTimeout = this.startTimeout.bind(this);
        this.stopTimeout = this.stopTimeout.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleSubsectionDrop = this.handleSubsectionDrop.bind(this);
        this.moveSubsectionCard = this.moveSubsectionCard.bind(this);
        this.handleSubsectionNameUpdate = this.handleSubsectionNameUpdate.bind(this);
    }

    shouldComponentUpdate(nextProps: PropTypes, nextState: StateType) {
        const self = this;
        if (
            self.props.index !== nextProps.index ||
            self.props.section._id !== nextProps.section._id ||
            self.props.section.name !== nextProps.section.name ||
            self.state.section._id !== nextState.section._id ||
            self.state.section.name !== nextState.section.name ||
            self.props.selected !== nextProps.selected
        ) {
            return true;
        }
        let subsectionsUpdate = Utils.shouldArrayUpdate(self.state.subsections, nextState.subsections, (prev, next) => {
            return prev._id !== next._id || prev.name !== next.name;
        });
        if (subsectionsUpdate) {
            return true;
        }
        return false;
    }

    handleClick: (event: SyntheticInputEvent<*>) => void = (event) => {
        if (event.target.name) {
            return;
        }
        this.props.onExpand(this.props.selected ? '' : this.props.section._id);
    };

    handleTimeout: () => void = () => {
        const self = this;
        self.stopTimeout();
        this.props.updateSectionName(self.state.section._id, self.state.section.name);
    };

    startTimeout: () => void = () => {
        if (this.timer) {
            this.stopTimeout();
        }
        this.timer = setTimeout(this.handleTimeout, TIMEOUT);
    };

    stopTimeout: () => void = () => {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    };

    handleChange: (event: SyntheticInputEvent<*>) => void = (event) => {
        event.preventDefault();
        const self = this;
        const field = event.target.name;
        const value = event.target.value;
        const section = Object.assign({}, self.state.section);
        section[field] = value;
        self.setState({
            section: section,
        });
        self.startTimeout();
    };

    handleBlur: (event: SyntheticInputEvent<*>) => void = (event) => {
        event.preventDefault();
        if (this.timer) {
            this.handleTimeout();
        }
    };

    onFocus: (event: SyntheticInputEvent<*>) => void = (event) => {
        event.preventDefault();
    };

    moveSubsectionCard: (dragIndex: number, hoverIndex: number) => void = (dragIndex, hoverIndex) => {
        if (!this.state.subsections) {
            return;
        }
        const subsections = [...this.state.subsections];
        const dragCard = subsections[dragIndex];
        subsections.splice(dragIndex, 1).splice(hoverIndex, 0, dragCard);
        this.setState({
            subsections: subsections,
        });
    };

    handleSubsectionNameUpdate: (subsection: string, name: string) => void = (subsection, name) => {
        const section = this.state.section._id;
        this.props.updateSubsectionName(section, subsection, name);
    };

    handleSubsectionDrop: () => void = () => {
        const self = this;
        if (!self.state.subsections) {
            return;
        }
        const subsectionsArray = self.state.subsections.map(function(subsection) {
            return subsection._id;
        });
        self.props.onSubsectionDrop(self.state.section._id, subsectionsArray);
    };

    componentDidUpdate(prevProps: PropTypes) {
        const self = this;
        if (!isEqual(self.props.section.subsections, prevProps.section.subsections)) {
            self.setState({
                subsections: self.props.section.subsections,
            });
        }
    }

    render() {
        const { isDragging, connectDragSource, connectDropTarget, connectDragPreview } = this.props;
        const self = this;
        let dragSource = (
            <div className={styles.leftRibbon}>
                <div className={styles.dragButton} />
            </div>
        );
        if (connectDragSource) {
            dragSource = connectDragSource(dragSource);
        }
        let dropTarget = (
            <div className={styles.sectionNameDiv}>
                {dragSource}
                {self.state.subsections ? (
                    <div className={styles.arrowWrapper} onClick={self.handleClick}>
                        <div className={styles.arrowDiv + ' ' + (self.props.selected ? styles.selected : '')} />
                    </div>
                ) : null}
                <div className={styles.sectionItem} onClick={self.handleClick}>
                    <input
                        type='text'
                        name='name'
                        className={styles.input + ' ' + styles.sectionInput}
                        onChange={self.handleChange}
                        value={self.state.section.name}
                        onFocus={self.onFocus}
                        onBlur={self.handleBlur}
                    />
                </div>
            </div>
        );
        if (connectDropTarget) {
            dropTarget = connectDropTarget(dropTarget);
        }
        if (connectDragPreview) {
            dropTarget = connectDragPreview(dropTarget);
        }
        return (
            <div className={styles.sectionItemDiv + ' ' + (isDragging ? styles.dragging : '')}>
                {dropTarget}
                {self.props.selected ? (
                    <table className={styles.subsectionTable}>
                        <tbody>
                            {/* <tr className={styles.subsectionTitle}>
                        <td className={styles.subsectionTitleElement}>Subsections</td>
                        <td colSpan = '2' className={styles.addButton}>
                            {/* <button className={styles.button} type='button' name='addbutton' onClick={self.props.handleButton}>+</button> */}
                            {/*</td>
                    </tr> */}
                            {self.state.subsections
                                ? self.state.subsections.map(function(subsection, currentSubIndex) {
                                    return (
                                        <tr key={'subsection-' + subsection._id} className={styles.subsectionRow}>
                                            <td colSpan='3'>
                                                <SectionEditorSubsection
                                                    subsection={subsection}
                                                    section={self.state.section._id}
                                                    index={currentSubIndex}
                                                    updateSubsectionName={self.handleSubsectionNameUpdate}
                                                    moveCard={self.moveSubsectionCard}
                                                    onCardDrop={self.handleSubsectionDrop}
                                                    handleButton={self.props.handleButton}
                                                />
                                            </td>
                                            {/* <td className={styles.removeButton}>
                                <button className={styles.button} type='button' name='removebutton' onClick={self.props.handleButton}>&ndash;</button>
                            </td> */}
                                        </tr>
                                    );
                                })
                                : null}
                        </tbody>
                    </table>
                ) : null}
            </div>
        );
    }
}

export default flow([
    DragSource('SectionEditorItems', itemSource, (connect, monitor) => {
        return {
            connectDragSource: connect.dragSource(),
            connectDragPreview: connect.dragPreview(),
            isDragging: monitor.isDragging(),
        };
    }),
    DropTarget(['SectionEditorItems', 'SectionEditorSubsection'], itemTarget, (connect) => {
        return {
            connectDropTarget: connect.dropTarget(),
        };
    }),
])(SectionEditorItem);
