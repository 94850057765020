import { Constants } from '../../utils';

// @flow
export const gradesFromSchool: (school: any) => string = (school) => {
    if (!school.startingGrade && school.startingGrade !== 0) {
        return '';
    }
    if (!school.endingGrade && school.endingGrade !== 0) {
        return '';
    }
    var grades = '';
    if (school.startingGrade === -2) {
        grades += 'P';
    } else if (school.startingGrade === -1) {
        grades += 'K';
    } else {
        grades += school.startingGrade;
    }
    grades += ' - ' + school.endingGrade;
    return grades;
};

export const schoolTypeFromSchool: (school: any) => string = (school) => {
    if (!school.schoolType || school.schoolType.trim() === '') {
        return '';
    }
    switch (school.schoolType) {
        case 'd':
            return 'Day';
        case 'b':
            return 'Boarding';
        case 'db':
            return 'Day-Boarding';
        case 'bd':
            return 'Boarding-Day';
        default:
            return '';
    }
};

export const budgetFilters = [
    { _id: '', name: 'All' },
    { _id: '0', name: 'Less than $5M', high: 4999999 },
    { _id: '1', name: '$5M to <$10M', low: 5000000, high: 9999999 },
    { _id: '2', name: '$10M to <$15M', low: 10000000, high: 14999999 },
    { _id: '3', name: '$15M to <$20M', low: 15000000, high: 19999999 },
    { _id: '4', name: '$20M to <$30M', low: 20000000, high: 29999999 },
    { _id: '5', name: '$30M to <$50M', low: 30000000, high: 49999999 },
    { _id: '6', name: '$50M+', low: 50000000, high: Number.MAX_SAFE_INTEGER },
];

const currentYear = parseInt(Constants.currentYear);
const startYear = parseInt(Constants.peergroupCutoffYear);
const datesArray = [];

for (let index = startYear; index <= currentYear; index++) {
    datesArray.push(`${index}`);
}

export const participatingFilters = datesArray.map((a) => {
    return {
        _id: a,
        name: a,
    };
});

export const isSubset = (array1, array2) => array2.every((element) => array1.includes(element));
