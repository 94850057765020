// @flow
'use strict';

import { Component } from 'react';

import styles from './filterSearch.scss';

type PropTypes = {|
    filterItem: ReportFilterType,
    value?: ?string,
    className?: string,
    handleSearch?: (event: SyntheticInputEvent<*>) => void,
    disabled?: boolean,
    id?: ?string,
|};

export default class FilterSearch extends Component<PropTypes> {
    constructor(props: PropTypes) {
        super(props);
    }

    render() {
        return (
            <div
                id={this.props.id}
                className={styles.filterSearch + (this.props.className ? ' ' + this.props.className : '')}
            >
                <div className={styles.wrapper}>
                    <div className={styles.title}>{this.props.filterItem.name}</div>
                    <input
                        disabled={this.props.disabled}
                        id={this.props.filterItem._id}
                        type='text'
                        name={this.props.filterItem.name}
                        className={styles.search}
                        onChange={this.props.handleSearch}
                        value={this.props.value}
                    />
                </div>
            </div>
        );
    }
}
