// @flow
'use strict';

import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Tooltip, DialogActions } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { copyPreviousDataPoints } from '../../redux/actions';

import { getSelectedSectionId } from '../../redux/reducers';

const useStyles = makeStyles(() => ({
    white: {
        color: 'white',
    },
}));

type Props = {|
    selectedYear: YearNumberType,
    selectedSchoolId: string,
|};

export function DataEntryCopy(props: Props) {
    const { selectedSchoolId, selectedYear } = props;
    const selectedSectionId = useSelector(getSelectedSectionId);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [showCopy, setShowCopy] = useState(false);

    const handleClose: (doCopy: boolean, sectionOnly: boolean) => void = (doCopy, sectionOnly) => {
        setShowCopy(false);
        if (!doCopy) {
            return;
        }

        dispatch(copyPreviousDataPoints(selectedSchoolId, selectedYear, sectionOnly ? selectedSectionId : undefined));
    };
    return (
        <div>
            <Tooltip disableFocusListener title='Copy Prior Year Data'>
                <Button onClick={() => setShowCopy(true)} className={classes.white}>
                    <FileCopy id='downloadJoyrideId' />
                </Button>
            </Tooltip>
            <Dialog
                open={showCopy}
                disable
                onClose={(_event, reason) => {
                    if (reason !== 'backdropClick') handleClose(false, false);
                }}
                keepMounted={false}
            >
                <DialogTitle>{"Copy Prior Year's Data?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {'This will copy all data from ' + (selectedYear - 1) + ' to ' + selectedYear + '. Any existing data WILL BE ' + 'SAVED. Only unanswered questions will be copied!'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false, false)} color='primary'>
                        Cancel
                    </Button>
                    <Button onClick={() => handleClose(true, true)} color='primary'>
                        Copy Just This Page
                    </Button>
                    <Button onClick={() => handleClose(true, false)} color='primary'>
                        Copy All
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DataEntryCopy;
