// @flow
'use strict';

import styles from './peergroupSchools.scss';
import Constants from '../../utils/constants';
import { gradesFromSchool, schoolTypeFromSchool } from './methods';
import { useEffect, useState } from 'react';
const currentYear = parseInt(Constants.currentYear);
const startYear = parseInt(Constants.peergroupCutoffYear);

// const TIMEOUT = 1000;

const headers = [
    {
        _id: 'name',
        name: 'School Name',
    },
    {
        _id: 'state',
        name: 'State',
    },
    {
        _id: 'grades',
        name: 'Grades',
    },
    {
        _id: 'schoolType',
        name: 'Type',
    },
];

for (let index = currentYear; index >= startYear; index--) {
    headers.push({
        _id: `${index}`,
        name: `${index}`,
    });
}

const datesArray = [];

for (let index = currentYear; index >= startYear; index--) {
    datesArray.push(`${index}`);
}
type PropTypes = {|
    schools: Array<PeergroupSchoolType>,
    selectedSchools: Array<string>,
    className?: string,
    saveSelectedSchools: (Array<string>) => void,
|};

function PeergroupSchools(props: PropTypes) {
    const [sortField, setSortField] = useState('name');
    const [sortDirection, setSortDirection] = useState('asc');
    const [className, setClassName] = useState(styles.peergroupSchools);

    const [selectedSchools, setSelectedSchools] = useState(props.selectedSchools);

    useEffect(() => {
        if (props.className) {
            setClassName(className + ' ' + props.className);
        }
    }, [props.className]);

    useEffect(() => {
        setSelectedSchools(props.selectedSchools);
    }, [props.selectedSchools]);

    const handleSortClick: (event: SyntheticInputEvent<*>) => void = (event) => {
        if (event.target.id === sortField) {
            const lsortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
            setSortDirection(lsortDirection);
        } else {
            setSortDirection('asc');
            setSortField(event.target.id);
        }
    };

    const handleChange: (schoolId: string, selected: boolean) => void = (schoolId, selected) => {
        const lSelectedSchools = selectedSchools.slice();

        const index = lSelectedSchools.indexOf(schoolId);
        if (!selected) {
            if (index === -1) {
                console.error('Error finding selected school ', schoolId);
                return;
            }
            lSelectedSchools.splice(index, 1);
        } else {
            if (index !== -1) {
                console.error('Error selected school already in peergroup', schoolId);
                return;
            }
            lSelectedSchools.push(schoolId);
        }
        props.saveSelectedSchools(lSelectedSchools);
        setSelectedSchools(lSelectedSchools);

        return;
    };

    const renderHeader: (sortField: string, sortDirection: 'asc' | 'desc') => React$Element<*> = (sortField, sortDirection) => {
        return (
            <thead className={styles.thead}>
                <tr>
                    <th className={styles.header} id='selectedTh'>
                        {/* <div className={styles.round}>
                            <input type='checkbox' name={'checkbox-all'} id={'checkbox-all'} />
                            <label
                                htmlFor={'checkbox-all'}
                                style={{
                                    marginTop: '-20px',
                                }}
                            />
                        </div> */}
                    </th>
                    {headers.map(function (header) {
                        return (
                            <th id={header._id} key={header._id} className={styles.header}>
                                <div className={styles.headerTh}>
                                    <div className={styles.headerThInner}>
                                        <div className={styles.headerThInner2}>
                                            <button
                                                className={styles.sortButton + ' ' + (sortField === header._id ? (sortDirection === 'asc' ? styles.up : styles.down) : '')}
                                                id={header._id}
                                                onClick={handleSortClick}
                                            >
                                                {header.name}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </th>
                        );
                    })}
                </tr>
            </thead>
        );
    };

    return (
        <div className={className}>
            <div className={styles.headerBackground} id='peerGroupTableHeader' />
            <div className={styles.fixedTableWrapper}>
                {!props.schools || props.schools.length === 0 ? null : (
                    <table className={styles.peergroupTable}>
                        {renderHeader(sortField, sortDirection)}
                        <tbody>
                            {props.schools
                                .sort(function (schoolA, schoolB) {
                                    if (sortField === 'selected') {
                                        if (sortDirection === 'asc') {
                                            if (
                                                (selectedSchools.indexOf(schoolA._id) !== -1 && selectedSchools.indexOf(schoolB._id) !== -1) ||
                                                (selectedSchools.indexOf(schoolA._id) === -1 && selectedSchools.indexOf(schoolB._id) === -1)
                                            ) {
                                                return schoolA['name'].localeCompare(schoolB['name']);
                                            } else if (selectedSchools.indexOf(schoolA._id) !== -1 && selectedSchools.indexOf(schoolB._id) === -1) {
                                                return -1;
                                            } else {
                                                return 1;
                                            }
                                        } else {
                                            if (
                                                (selectedSchools.indexOf(schoolA._id) !== -1 && selectedSchools.indexOf(schoolB._id) !== -1) ||
                                                (selectedSchools.indexOf(schoolA._id) === -1 && selectedSchools.indexOf(schoolB._id) === -1)
                                            ) {
                                                return schoolA['name'].localeCompare(schoolB['name']);
                                            } else if (selectedSchools.indexOf(schoolA._id) !== -1 && selectedSchools.indexOf(schoolB._id) === -1) {
                                                return 1;
                                            } else {
                                                return -1;
                                            }
                                        }
                                    }
                                    if (sortField === 'grades') {
                                        if (!schoolA.startingGrade && !schoolB.startingGrade) {
                                            return schoolA['name'].localeCompare(schoolB['name']);
                                        }
                                        if (sortDirection === 'asc') {
                                            if (!schoolA.startingGrade) {
                                                return 1;
                                            } else if (!schoolB.startingGrade) {
                                                return -1;
                                            } else if (schoolA.startingGrade === schoolB.startingGrade) {
                                                return schoolA.endingGrade - schoolB.endingGrade || schoolA['name'].localeCompare(schoolB['name']);
                                            } else {
                                                return schoolA.startingGrade - schoolB.startingGrade;
                                            }
                                        } else {
                                            if (!schoolA.startingGrade) {
                                                return -1;
                                            } else if (!schoolB.startingGrade) {
                                                return 1;
                                            } else if (schoolA.startingGrade === schoolB.startingGrade) {
                                                return schoolB.endingGrade - schoolA.endingGrade || schoolA['name'].localeCompare(schoolB['name']);
                                            } else {
                                                return schoolB.startingGrade - schoolA.startingGrade;
                                            }
                                        }
                                    } else if (datesArray.indexOf(sortField) >= 0) {
                                        if (sortDirection === 'asc') {
                                            if (
                                                (schoolA.participatingYears.indexOf(parseInt(sortField)) !== -1 && schoolB.participatingYears.indexOf(parseInt(sortField)) !== -1) ||
                                                (schoolA.participatingYears.indexOf(parseInt(sortField)) === -1 && schoolB.participatingYears.indexOf(parseInt(sortField)) === -1)
                                            ) {
                                                return schoolA['name'].localeCompare(schoolB['name']);
                                            } else if (schoolA.participatingYears.indexOf(parseInt(sortField)) !== -1 && schoolB.participatingYears.indexOf(parseInt(sortField)) === -1) {
                                                return -1;
                                            } else {
                                                return 1;
                                            }
                                        } else {
                                            if (
                                                (schoolA.participatingYears.indexOf(parseInt(sortField)) !== -1 && schoolB.participatingYears.indexOf(parseInt(sortField)) !== -1) ||
                                                (schoolA.participatingYears.indexOf(parseInt(sortField)) === -1 && schoolB.participatingYears.indexOf(parseInt(sortField)) === -1)
                                            ) {
                                                return schoolA['name'].localeCompare(schoolB['name']);
                                            } else if (schoolA.participatingYears.indexOf(parseInt(sortField)) !== -1 && schoolB.participatingYears.indexOf(parseInt(sortField)) === -1) {
                                                return 1;
                                            } else {
                                                return -1;
                                            }
                                        }
                                    }
                                    let fieldA = schoolA[sortField];
                                    let fieldB = schoolB[sortField];

                                    if (!fieldA && !fieldB) {
                                        return schoolA['name'].localeCompare(schoolB['name']);
                                    }
                                    if (sortDirection === 'asc') {
                                        if (!fieldA || fieldA === '') {
                                            return 1;
                                        }
                                        if (!fieldB || fieldB === '') {
                                            return -1;
                                        }
                                    } else {
                                        if (!fieldA || fieldA === '') {
                                            return -1;
                                        }
                                        if (!fieldB || fieldB === '') {
                                            return 1;
                                        }
                                    }
                                    if (typeof fieldA === 'string') {
                                        if (sortDirection === 'asc') {
                                            return fieldA.localeCompare(fieldB) || schoolA['name'].localeCompare(schoolB['name']);
                                        } else {
                                            return fieldB.localeCompare(fieldA) || schoolA['name'].localeCompare(schoolB['name']);
                                        }
                                    }
                                    if (sortDirection === 'asc') {
                                        if (typeof fieldA === 'string') {
                                            return fieldA.localeCompare(fieldB) || schoolA['name'].localeCompare(schoolB['name']);
                                        } else {
                                            return fieldB - fieldA;
                                        }
                                    } else {
                                        if (typeof fieldB === 'string') {
                                            return fieldB.localeCompare(fieldA) || schoolA['name'].localeCompare(schoolB['name']);
                                        } else {
                                            return fieldA - fieldB;
                                        }
                                    }
                                })
                                .map(function (school, index) {
                                    return (
                                        <tr className={styles.row} key={'schoolRowKey-' + index}>
                                            <td className={styles.roundTd} id='selectedTd'>
                                                <div className={styles.round}>
                                                    <input
                                                        type='checkbox'
                                                        checked={selectedSchools.indexOf(school._id) !== -1}
                                                        name={school._id}
                                                        id={'checkbox-' + school._id}
                                                        onChange={(e) => handleChange(school._id, e.target.checked)}
                                                    />
                                                    <label htmlFor={'checkbox-' + school._id} />
                                                </div>
                                            </td>
                                            <td>{school.name}</td>
                                            <td className={styles.center}>{school.state}</td>
                                            <td className={styles.center}>{gradesFromSchool(school)}</td>
                                            <td className={styles.center}>{schoolTypeFromSchool(school)}</td>

                                            {datesArray.map((date) => {
                                                return (
                                                    <td key={date} className={styles.center}>
                                                        {school.participatingYears.indexOf(parseInt(date)) !== -1 ? 'Y' : ''}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
}

export default PeergroupSchools;
