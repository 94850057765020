// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../images/headerLogoBackgroundRepeat.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Arimo);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://p.typekit.net/p.css?s=1&k=wvy7sbk&ht=tk&f=139.169.173.175.5474.25136&a=108967835&app=typekit&e=css);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media print{.app-components-Header-header__header--gx0g\\+{display:none !important}}.app-components-Header-header__header--gx0g\\+{position:relative;margin:0;color:#fff;overflow:visible;width:100%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:repeat-x}", "",{"version":3,"sources":["webpack://./app/components/Header/header.scss"],"names":[],"mappings":"AAEA,aACI,8CACI,uBAAA,CAAA,CAIR,8CACI,iBAAA,CACA,QAAA,CACA,UAAA,CACA,gBAAA,CACA,UAAA,CACA,wDAAA,CACA,0BAAA","sourcesContent":["@import '../../stylesheets/constants.scss';\n\n@media print {\n    .header {\n        display: none !important;\n    }\n}\n\n.header {\n    position: relative;\n    margin: 0;\n    color: white;\n    overflow: visible;\n    width: 100%;\n    background-image: url('../../images/headerLogoBackgroundRepeat.png');\n    background-repeat: repeat-x;\n}\n\n// .logoBar {\n//     width: 100%;\n//     background-image: url('../../images/headerLogoBackground.png');\n//     background-repeat: no-repeat;\n//     overflow: hidden;\n//     width: 100%;\n//     padding: 0;\n//     margin: 0;\n//     height: $logoHeaderHeight;\n//     background-position: left;\n//     top: 0;\n//     left: 0;\n//     display: flex;\n//     flex-direction: row;\n//     justify-content: space-between;\n// }\n\n// .mainLink {\n//     height: 50px;\n//     width: 640px;\n//     margin-top: 15px;\n//     margin-left: 5px;\n//     display: inline-block;\n// }\n\n// .mainName {\n//     background-repeat: no-repeat;\n//     height: 50px;\n//     width: 553px;\n//     display: inline-block;\n// }\n\n// .environment {\n//     display: inline-block;\n// }\n\n// .nboaLogoLink {\n//     height: 36px;\n//     width: 124px;\n//     display: inline-block;\n//     margin-right: 15px;\n//     margin-top: 20px;\n// }\n\n// .nboaLogoImage {\n//     height: 100%;\n//     width: 100%;\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "app-components-Header-header__header--gx0g+"
};
export default ___CSS_LOADER_EXPORT___;
