// @flow
'use strict';

import Api from '../../api';
import { setLoading, unsetLoading } from './';
import { logout } from './user';
import { getRequiredCounts, getSelectedYear } from '../reducers';

import {
    RECEIVE_DATAPOINTS_DATA,
    RESET_DATAPOINTS_SECTIONS,
    FETCHING_DATAPOINTS_SECTIONS,
    FETCHING_DATAPOINTS_DATA,
    RECEIVE_DATAPOINTS_SECTIONS,
    RECEIVE_DATAPOINTS_FOR_SECTION,
    SET_SELECTED_SECTION_AND_SUBSECTION_ID,
    RESET_DATAPOINTS_DATA,
    SET_LOADING,
    UNSET_LOADING,
    SET_SELECTED_SECTION_ID,
    SET_SELECTED_SUBSECTION_ID,
    SET_EDIT_DATAPOINTS,
    SET_DISPLAY_UNANSWERED,
    RECEIVE_REQUIRED_COUNTS,
    RECEIVE_UPDATED_DATAPOINTS,
    RECEIVE_UPDATED_STATUS,
    SET_DATAPOINT_FILTERS,
    SET_FETCHING_FILTERS,
    SET_DATA_POINTS,
    UPDATE_SINGLE_DATAPOINT,
    RESET_DATAPOINTS,
    SET_SELECTED_FILTER,
} from './constants';

export function setEditDataPoints(edit: boolean) {
    return {
        type: SET_EDIT_DATAPOINTS,
        edit,
    };
}

export function setDisplayingUnanswered(isDisplayingUnanswered: boolean) {
    return {
        type: SET_DISPLAY_UNANSWERED,
        isDisplayingUnanswered,
    };
}

export function resetDataPoints(): any {
    return {
        type: RESET_DATAPOINTS_DATA,
    };
}

export function resetOnlyDataPoints(): any {
    return {
        type: RESET_DATAPOINTS,
    };
}

export function resetDataPointSections(): any {
    return {
        type: RESET_DATAPOINTS_SECTIONS,
    };
}

export function setSelectedSectionId(selectedSectionId: ?string) {
    return {
        type: SET_SELECTED_SECTION_ID,
        selectedSectionId,
    };
}

export function setSelectedSubsectionId(selectedSubsectionId: ?string) {
    return {
        type: SET_SELECTED_SUBSECTION_ID,
        selectedSubsectionId,
    };
}

export function setSelectedSectionAndSubsectionIds(selectedSectionId: ?string, selectedSubsectionId: ?string) {
    return {
        type: SET_SELECTED_SECTION_AND_SUBSECTION_ID,
        selectedSectionId,
        selectedSubsectionId,
    };
}

export function setSelectedFilter(filter?: string) {
    return {
        type: SET_SELECTED_FILTER,
        filter,
    };
}

export function receiveUpdatedDataPointsForSection(sectionId: string, dataPointResponse: DataPointResponseType): any {
    return {
        type: RECEIVE_DATAPOINTS_FOR_SECTION,
        sectionId,
        dataPointResponse,
    };
}

export function receiveUpdatedDataPoints(dataPoints: { [_id: string]: DataPointType }) {
    return {
        type: RECEIVE_UPDATED_DATAPOINTS,
        dataPoints,
    };
}

export function receiveRequiredCounts(requiredCounts: RequiredCountsType) {
    return {
        type: RECEIVE_REQUIRED_COUNTS,
        requiredCounts,
    };
}

export function receiveStatus(status: DataPointStatusType) {
    return {
        type: RECEIVE_UPDATED_STATUS,
        status,
    };
}

export function fetchSections(filter: ?string, unanswered: boolean, schoolId: string): (dispatch: GenericDispatch) => void {
    return function (dispatch) {
        dispatch(setFetchingDatapointSections());
        dispatch(setLoading());
        Api.DataPoints.getSections(filter, unanswered, schoolId)
            .then(function (sections) {
                const sectionOrder = [];
                const sectionObject = {};
                sections.forEach(function (section) {
                    sectionOrder.push(section._id);
                    sectionObject[section._id] = section;
                });
                dispatch({
                    type: RECEIVE_DATAPOINTS_SECTIONS,
                    sections: sectionObject,
                    sectionOrder: sectionOrder,
                });
            })
            .finally(function () {
                dispatch(unsetFetchingDatapointSections());
                dispatch(unsetLoading());
            });
    };
}

export function fetchDataPointSections(forEditing: boolean): (dispatch: GenericDispatch) => void {
    return function (dispatch: GenericDispatch) {
        if (forEditing) {
            dispatch(setLoading());
            dispatch(setFetchingDatapointSections);
            Api.DataPoints.getAllSections()
                .then(function (sections) {
                    const sectionOrder = [];
                    const sectionObject = {};
                    sections.forEach(function (section) {
                        sectionOrder.push(section._id);
                        sectionObject[section._id] = section;
                    });
                    dispatch({
                        type: RECEIVE_DATAPOINTS_SECTIONS,
                        sections: sectionObject,
                        sectionOrder: sectionOrder,
                    });
                    return sections;
                })
                .finally(function () {
                    dispatch(unsetLoading());
                    dispatch(unsetFetchingDatapointSections);
                });
        }
    };
}

function receiveDataPoints(dataPoints: { string: DataPointType }) {
    return {
        type: SET_DATA_POINTS,
        dataPoints,
    };
}

export function fetchDataPointsForEditing(selectedSectionId: string, selectedSubsectionId: string): (dispatch: GenericDispatch) => void {
    return function (dispatch: GenericDispatch) {
        dispatch(setLoading());
        dispatch(setFetchingDatapoints());
        Api.DataPoints.getDataPointsForEditing(selectedSectionId, selectedSubsectionId)
            .then(function (response) {
                const dataPoints = {};
                response.forEach((dp) => (dataPoints[dp._id] = dp));
                dispatch(receiveDataPoints(dataPoints));
            })
            .finally(function () {
                dispatch(unsetFetchingDatapoints());
                dispatch(unsetLoading());
            });
    };
}

export function setFetchingDatapointSections(): any {
    return {
        type: FETCHING_DATAPOINTS_SECTIONS,
        fetching: true,
    };
}

export function setFetchingDatapoints(): any {
    return {
        type: FETCHING_DATAPOINTS_DATA,
        fetching: true,
    };
}

export function unsetFetchingDatapointSections(): any {
    return {
        type: FETCHING_DATAPOINTS_SECTIONS,
        fetching: false,
    };
}

export function unsetFetchingDatapoints(): any {
    return {
        type: FETCHING_DATAPOINTS_DATA,
        fetching: false,
    };
}

export function fetchDataPointsForSection(sectionId: string, selectedSchool: string, filters: ?string, unanswered: boolean, selectedYear: YearNumberType): (dispatch: GenericDispatch) => void {
    return function (dispatch) {
        dispatch(setFetchingDatapoints());
        dispatch({
            type: SET_LOADING,
        });
        Api.DataPoints.getDataPoints({
            // section: sectionId,
            schoolId: selectedSchool,
            filter: filters,
            unanswered: unanswered,
            year: '' + selectedYear,
        }).then(function (dataPointsResponse) {
            dispatch(unsetLoading());
            dispatch(receiveRequiredCounts(dataPointsResponse.requiredCounts));
            dispatch(receiveStatus(dataPointsResponse.status));
            dispatch(receiveUpdatedDataPointsForSection(sectionId, dataPointsResponse));
            dispatch(unsetFetchingDatapoints());
        });
    };
}

export function saveAnswerForDataPoint(params: SavingDataPointAnswerType): (dispatch: GenericDispatch, getState: () => ReduxStateType) => any {
    return function (dispatch, getState) {
        // $FlowFixMe
        if (!params.selectedYear) {
            // $FlowFixMe
            params.selectedYear = getSelectedYear(getState());
        }
        return Api.DataPoints.saveAnswerForDataPoint(params)
            .then(function (savedDataPoint) {
                const { dataPoints, requiredCounts, status } = savedDataPoint;
                const oldRequiredCounts = getRequiredCounts(getState());
                if (!oldRequiredCounts || oldRequiredCounts.required !== requiredCounts.required || oldRequiredCounts.answered !== requiredCounts.answered) {
                    dispatch(receiveRequiredCounts(requiredCounts));
                }
                dispatch(receiveStatus(status));
                dispatch(receiveUpdatedDataPoints(dataPoints));
            })
            .catch(function () {});
    };
}

export function updateDataPointStatus(schoolId: string, status: DataPointStatusUpdateType, year: YearNumberType): (dispatch: GenericDispatch) => any {
    return function (dispatch) {
        let promise;
        if (status === 'Review') {
            promise = Api.DataPoints.markReviewed(schoolId, true, '' + year);
        } else if (status === 'Unreview') {
            promise = Api.DataPoints.markReviewed(schoolId, false, '' + year);
        } else if (status === 'Unsubmit') {
            promise = Api.DataPoints.submit(schoolId, false, '' + year);
        } else if (status === 'Submit') {
            promise = Api.DataPoints.submit(schoolId, true, '' + year);
        }
        if (!promise) {
            return;
        }
        return promise.then(function (response) {
            dispatch(receiveStatus(response.status));
        });
    };
}

export function copyPreviousDataPoints(selectedSchoolId: string, selectedYear: YearNumberType, selectedSectionId: ?string): (dispatch: GenericDispatch) => void {
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        Api.DataPoints.copyPreviousDataPoints(selectedSchoolId, selectedYear, selectedSectionId)
            .then(function () {
                dispatch(resetDataPoints());
            })
            .finally(function () {
                dispatch({ type: UNSET_LOADING });
            });
    };
}

export function getDataPointsForDictionary(): (dispatch: GenericDispatch) => void {
    return function (dispatch) {
        dispatch({ type: SET_LOADING });
        Api.DataPoints.getDataPointsDictionary()
            .then(function (dataPointsForDictionary) {
                const dataPoints = {};
                const sectionObject: {
                    [_id: string]: DataPointSectionType,
                } = {};
                const sectionOrder = dataPointsForDictionary.map(function (dataPointSection) {
                    sectionObject[dataPointSection._id] = {
                        name: dataPointSection.name,
                        _id: dataPointSection._id,
                        order: dataPointSection.order,
                        subsections: [
                            {
                                name: dataPointSection.name,
                                _id: dataPointSection._id,
                                order: dataPointSection.order,
                                dataPoints: [],
                            },
                        ],
                    };
                    dataPointSection.dataPoints.forEach(function (dataPoint) {
                        dataPoints[dataPoint._id] = dataPoint;
                        sectionObject[dataPoint.section].subsections[0].dataPoints.push(dataPoint._id);
                    });
                    return dataPointSection._id;
                });
                dispatch({
                    type: RECEIVE_DATAPOINTS_SECTIONS,
                    sections: sectionObject,
                    sectionOrder: sectionOrder,
                });

                dispatch({
                    type: RECEIVE_DATAPOINTS_DATA,
                    dataPoints: dataPoints,
                });
            })
            .finally(function () {
                dispatch({ type: UNSET_LOADING });
            });
    };
}

export function fetchDataPointFilters(schoolId?: string): (dispatch: GenericDispatch) => void {
    return function (dispatch) {
        dispatch({
            type: SET_LOADING,
        });
        dispatch({
            type: SET_FETCHING_FILTERS,
            fetching: true,
        });
        Api.DataPoints.fetchFilters(schoolId)
            .then(function (filters) {
                dispatch({
                    type: SET_DATAPOINT_FILTERS,
                    filters,
                });
            })
            .catch(function (err) {
                if (err && err.response && err.response.status === 401) {
                    dispatch(logout());
                }
            })
            .finally(function () {
                dispatch({
                    type: SET_FETCHING_FILTERS,
                    fetching: false,
                });
                dispatch({
                    type: UNSET_LOADING,
                });
            });
    };
}

export function moveDataPoint(dataPointId: string, section: string, subsection: string): (dispatch: GenericDispatch, getState: () => ReduxStateType) => void {
    return function (dispatch) {
        dispatch({
            type: SET_LOADING,
        });
        Api.DataPoints.updateDataPoint(dataPointId, {
            section: section,
            subsection: subsection,
        })
            .then(function () {})
            .finally(function () {
                dispatch({
                    type: UNSET_LOADING,
                });
                dispatch(resetDataPoints());
            });
    };
}

export function updateDataPoint(dataPointId: string, update: { name?: string, definition?: string }): (dispatch: GenericDispatch, getState: () => ReduxStateType) => void {
    return function (dispatch) {
        Api.DataPoints.updateDataPoint(dataPointId, update).then(function (dataPoint) {
            dispatch({
                type: UPDATE_SINGLE_DATAPOINT,
                dataPoint,
            });
        });
    };
}

export function updateDataPointOrder(dataPoints: Array<string>): (dispatch: GenericDispatch) => void {
    return function (dispatch) {
        dispatch(setLoading());
        Api.DataPoints.updateDataPointOrder(dataPoints).then(function (response) {
            const dataPoints = {};
            response.forEach((dp) => (dataPoints[dp._id] = dp));
            dispatch(receiveDataPoints(dataPoints));
            dispatch(unsetLoading());
        });
    };
}
