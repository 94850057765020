// @flow
'use strict';

import {useSelector, useDispatch} from 'react-redux';
import { IconButton, Slide, Snackbar, SnackbarContent } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { showChromeWarning } from '../../redux/reducers';
import { closeChromeWarning } from '../../redux/actions';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles(() => ({
    warning: {
        backgroundColor: '#ffc107',
        color: 'black',
        fontWeight: 'bold',
    },
    message: {
        width: 'calc(100% - 56px)',
    },
}));


const MESSAGE = 'To optimize your BIIS experience, we highly recommend using Google Chrome as your internet browser.';

export default function ChromeWarning() {
    const doShowChromeWarning = useSelector(showChromeWarning);
    const classes = useStyles();
    const dispatch = useDispatch();
    const closeWarning = () => dispatch(closeChromeWarning());
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={doShowChromeWarning}
            key='chrome-warning'
            color='warning'
            TransitionComponent={(p) => <Slide {...p} direction={'left'} />}
        >
            <SnackbarContent
                className={classes.warning}
                classes={{ message: classes.message }}
                aria-describedby='client-snackbar'
                message={<span id='message-id'>{MESSAGE}</span>}
                action={[
                    <IconButton key='close' aria-label='close' color='inherit' onClick={closeWarning}>
                        <CloseIcon />
                    </IconButton>,
                ]}
            />
        </Snackbar>
    );
}
