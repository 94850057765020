// @flow
'use strict';

import { Component } from 'react';

import styles from './permissionsTable.scss';

type PropTypes = {
    school?: any,
    users?: any,
    user: any,
    nboa: boolean,
    editable: boolean,
    handleCheckbox: (event: SyntheticInputEvent<*>) => void,
};

const permissionItems = [
    { name: 'Standard Data Read', id: 'readSchoolNonConfidentialData' },
    { name: 'Confidential Data Read', id: 'readSchoolConfidentialData' },
];

const adminPermissionItems = [
    { name: 'Standard Data Read', id: 'readSchoolNonConfidentialData' },
    { name: 'Standard Data Write', id: 'writeSchoolNonConfidentialData' },
    { name: 'Confidential Data Read', id: 'readSchoolConfidentialData' },
    { name: 'Confidential Data Write', id: 'writeSchoolConfidentialData' },
];

const permissionItemsNBOA = [
    { name: 'Scrubber', id: 'scrubber' },
    { name: 'Admin Scrubber', id: 'adminScrubber' },
];

type StateType = {
    expanded: boolean,
    editable: boolean,
    items: Array,
};

export default class PermissionsTable extends Component<PropTypes, StateType> {
    constructor(props: PropTypes) {
        super(props);
        this.state = {
            expanded: false,
            editable: props.editable,
            items: props.user.nboaAdmin ? adminPermissionItems : permissionItems,
        };
    }
    //

    static getDerivedStateFromProps(nextProps: PropTypes) {
        return {
            editable: nextProps.editable,
        };
    }

    handleClick: (event: SyntheticInputEvent<*>) => void = (event) => {
        const element = document.getElementById(event.target.id);
        if (element) {
            element.innerHTML = this.state.expanded ? '+' : '-';
        }
        const expanded = !this.state.expanded;
        this.setState({
            expanded,
        });
    };

    render() {
        const self = this;
        let users = [];
        if (this.props.school) {
            users = this.props.school.users;
        } else if (this.props.users) {
            users = this.props.users;
        }
        const editable = this.state.editable;
        const items = this.state.items;

        const handleCheckbox = this.props.handleCheckbox;
        return (
            <table className={styles.permissionsTable}>
                <tbody>
                    {self.props.school ? (
                        <tr className={styles.schoolNameRow}>
                            <td className={styles.bullet}>
                                <button className={styles.expandButton} type='button' id={self.props.school.name + ' button'} name={self.props.school.name} onClick={self.handleClick}>
                                    +
                                </button>
                            </td>
                            <td className={styles.schoolName}>{self.props.school.name}</td>
                        </tr>
                    ) : null}
                    {self.state.expanded || !self.props.school ? (
                        this.props.nboa ? (
                            <tr className={styles.header}>
                                <th rowSpan='2' colSpan='2' className={styles.headerSchool}>
                                    Name
                                </th>
                                <th key='Primary Contact' rowSpan='2' className={styles.permissionsTableHeaderItem}>
                                    Primary Contact
                                </th>
                                <th colSpan={self.props.user.nboaAdmin ? '2' : '1'} key='Standard Data' className={styles.top}>
                                    Standard Data
                                </th>
                                <th colSpan={self.props.user.nboaAdmin ? '2' : '1'} key='Confidential Data' className={styles.top}>
                                    Confidential Data
                                </th>
                                <th rowSpan='2' key='Scrubber' className={styles.permissionsTableHeaderItem}>
                                    Scrubber
                                </th>
                                <th rowSpan='2' key='Admin Scrubber' className={styles.permissionsTableHeaderItem}>
                                    Admin Scrubber
                                </th>
                            </tr>
                        ) : (
                            <tr className={styles.header}>
                                <th rowSpan='2' colSpan='2' className={styles.headerSchool}>
                                    Name
                                </th>
                                <th key='Primary Contact' rowSpan='2' className={styles.permissionsTableHeaderItem}>
                                    Primary Contact
                                </th>
                                <th colSpan={self.props.user.nboaAdmin ? '2' : '1'} key='Standard Data' className={styles.top}>
                                    Standard Data
                                </th>
                                <th colSpan={self.props.user.nboaAdmin ? '2' : '1'} key='Confidential Data' className={styles.top}>
                                    Confidential Data
                                </th>
                            </tr>
                        )
                    ) : null}
                    {self.state.expanded || !self.props.school ? (
                        <tr className={styles.header}>
                            <th key='Standard Data Read' className={styles.permissionsTableHeaderItem}>
                                Read
                            </th>
                            {self.props.user.nboaAdmin && (
                                <th key='Standard Data Write' className={styles.permissionsTableHeaderItem}>
                                    Write
                                </th>
                            )}
                            <th key='Confidential Data Read' className={styles.permissionsTableHeaderItem}>
                                Read
                            </th>
                            {self.props.user.nboaAdmin && (
                                <th key='Confidential Data Write' className={styles.permissionsTableHeaderItem}>
                                    Write
                                </th>
                            )}
                        </tr>
                    ) : null}

                    {(self.state.expanded || !self.props.school) && users.length > 0
                        ? users.map(function (user) {
                              return (
                                  <tr key={user._id} className={styles.individualRow}>
                                      <td colSpan='2' className={styles.name + ' ' + styles.individualRowItem}>
                                          {user.name}
                                      </td>
                                      {user.primaryContact ? <td className={styles.checkmark + ' ' + styles.individualRowItem}>&#x2714;</td> : <td className={styles.checkmark} />}
                                      {items.map(function (item) {
                                          return editable && users.length > 0 ? (
                                              <td key={item.id} className={styles.checkbox + ' ' + styles.individualRowItem}>
                                                  <input
                                                      checked={user.permissions.indexOf(item.id) !== -1 || (item.name == 'Primary Contact' && user.primaryContact)}
                                                      onChange={self.props.handleCheckbox}
                                                      name={user._id}
                                                      id={item.id}
                                                      type='checkbox'
                                                  />
                                              </td>
                                          ) : (user.permissions && user.permissions.indexOf(item.id) !== -1) || (item.name == 'Primary Contact' && user.primaryContact) ? (
                                              <td key={item.name} className={styles.checkmark}>
                                                  &#x2714;
                                              </td>
                                          ) : (
                                              <td key={item.id} className={styles.checkmark} />
                                          );
                                      })}
                                      {self.props.nboa
                                          ? permissionItemsNBOA.map(function (item) {
                                                return editable && users.length > 0 ? (
                                                    <td key={item.id} className={styles.checkbox}>
                                                        <input checked={user.permissions.indexOf(item.id) !== -1} onChange={handleCheckbox} name={user._id} id={item.id} type='checkbox' />
                                                    </td>
                                                ) : user.permissions && user.permissions.indexOf(item.id) !== -1 ? (
                                                    <td key={item.name} className={styles.checkmark}>
                                                        &#x2714;
                                                    </td>
                                                ) : (
                                                    <td key={item.id} className={styles.checkmark} />
                                                );
                                            })
                                          : null}
                                  </tr>
                              );
                          })
                        : null}
                </tbody>
            </table>
        );
    }
}
