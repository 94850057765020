// @flow
'use strict';
import Api from '../../api';

import { setError, unsetLoading, setLoading } from './';
import { getPeergroupSchoolFilters, getPeergroupsOrder, getUser } from '../reducers';
import {
    FETCHING_PEERGROUPS,
    RECEIVE_USER_ID_AND_TOKEN,
    RECIEVE_API_TOKEN,
    RECEIVE_USER_FROM_API,
    FETCHING_USER,
    USER_FETCH_FAILED,
    SELECTED_SCHOOL,
    RECEIVE_PARTICIPATING_SCHOOLS,
    SET_PARTICIPATING_SCHOOL_FILTER,
    CLEAR_PARTICIPATING_SCHOOL_FILTER,
    FETCHING_SELECTED_SCHOOL,
    API_AUTH_ERR,
    RECEIVE_PEERGROUPS,
    FETCHING_PEERGROUP_SCHOOLS,
    RECEIVE_PEER_GROUP_SCHOOLS,
    RESET_PEER_GROUP_SCHOOLS,
    SET_PEERGROUP_SCHOOL_FILTER,
    SET_SELECTED_PEERGROUP_ID,
    UPDATE_PEERGROUP,
    SET_SCRUBBERS,
    SET_FETCHING_SCRUBBERS,
    SET_FETCHING_UNASSIGNED_SCHOOLS,
    SET_UNASSIGNED_SCHOOLS,
    SET_FETCHING_SCHOOLS,
    RECEIVE_SCHOOLS_BY_STATUS,
    USER_TOKEN,
    FETCHING_CANNED_PEERGROUPS,
    RECEIVE_CANNED_PEERGROUPS,
} from './constants';
import { GA_EVENTS } from '../../ga/analytics';

export const NON_MEMBER_ERROR_MESSAGE = 'User is not a member';

export function clearApiError(): any {
    return {
        type: API_AUTH_ERR,
        clear: true,
    };
}

export function receiveUserFromApi(user: UserType | {}) {
    return {
        type: RECEIVE_USER_FROM_API,
        user,
    };
}

export function setParticipatingSchoolFilter(filter: (school: SchoolType) => boolean) {
    return {
        type: SET_PARTICIPATING_SCHOOL_FILTER,
        filter,
    };
}

export function clearParticipatingSchoolFilter() {
    const filter = null;
    return {
        type: CLEAR_PARTICIPATING_SCHOOL_FILTER,
        filter,
    };
}

export function selectSchool(schoolId: ?string): (dispatch: GenericDispatch) => Promise<void> {
    return function (dispatch: GenericDispatch): Promise<void> {
        if (!schoolId) {
            return dispatch({
                type: SELECTED_SCHOOL,
            });
        }
        dispatch(setLoading());
        dispatch({
            type: FETCHING_SELECTED_SCHOOL,
            fetchingSelectedSchool: true,
        });
        return Api.Schools.getSchoolById(schoolId)
            .then((selectedSchool) => {
                dispatch({
                    type: SELECTED_SCHOOL,
                    selectedSchool,
                });

                dispatch(unsetLoading());
            })
            .catch(function (err) {
                console.error('-------ErrorGetting School');
                console.error(err);
                dispatch(unsetLoading());
            });
    };
}

export function recieveAuthInfo(userId: string, netforumToken: string): any {
    return {
        type: RECEIVE_USER_ID_AND_TOKEN,
        userId,
        netforumToken,
    };
}

export function receiveUserIdAndToken(userId: string, netforumToken: string, trackEvent: Function): (dispatch: GenericDispatch) => void {
    return function (dispatch: GenericDispatch) {
        dispatch(fetchingUser(true));
        dispatch(setLoading());

        Api.User.loginUserFromNetforum(userId, netforumToken)
            .then(({ user, token }) => {
                if (token) {
                    dispatch({
                        type: USER_TOKEN,
                        token,
                    });
                }

                processUser(user, dispatch, trackEvent);
            })
            .catch(function (err) {
                const message = err.response?.data?.message || err.message;
                if (message === NON_MEMBER_ERROR_MESSAGE) {
                    dispatch(setError({ message: message, type: 'Non-member' }));
                } else {
                    dispatch(setError({ message: message, type: 'Auth' }));
                }
                dispatch({
                    type: USER_FETCH_FAILED,
                });
            })
            .finally(function () {
                dispatch(unsetLoading());
            });
    };
}

export function receiveApiToken(apiToken: string) {
    return {
        type: RECIEVE_API_TOKEN,
        apiToken,
    };
}

export function fetchingUser(fetchingUser: boolean): any {
    return {
        type: FETCHING_USER,
        fetchingUser,
    };
}

export function processUser(user: UserType, dispatch: GenericDispatch, trackEvent: ?Function): void {
    dispatch(receiveUserFromApi(user));
    dispatch(fetchPeergroupsForUser(user._id));
    if (!user.nboaAdmin && !user.dataCommittee && user.organization && !user.associationAdmin) {
        dispatch(selectSchool(user.organization._id));
        trackEvent && trackEvent(GA_EVENTS.SECURITY.EVENTS.LOG_IN, GA_EVENTS.SECURITY.NAME, null, user, user.organization);
    } else if (!user.nboaAdmin && !user.dataCommittee && !user.associationAdmin) {
        dispatch(selectSchool(user.orgId));
        trackEvent && trackEvent(GA_EVENTS.SECURITY.EVENTS.LOG_IN, GA_EVENTS.SECURITY.NAME, null, user);
    } else {
        dispatch(fetchParticipatingSchools());
        trackEvent && trackEvent(GA_EVENTS.SECURITY.EVENTS.LOG_IN, GA_EVENTS.SECURITY.NAME, null, user);
    }
}

export function fetchUser(): (dispatch: GenericDispatch) => void {
    return function (dispatch: GenericDispatch) {
        dispatch(fetchingUser(true));
        dispatch(setLoading());
        Api.User.getLoggedInUser()
            .then(function ({ user, token }) {
                if (token) {
                    dispatch({
                        type: USER_TOKEN,
                        token,
                    });
                }
                processUser(user, dispatch);
            })
            .catch(function () {
                dispatch({
                    type: USER_FETCH_FAILED,
                });
            })
            .finally(function () {
                dispatch(unsetLoading());
            });
    };
}

export function setParticipatingSchools(participatingSchools: any): any {
    return {
        type: RECEIVE_PARTICIPATING_SCHOOLS,
        participatingSchools,
    };
}

export function fetchParticipatingSchools(): any {
    return function (dispatch: GenericDispatch, getState: () => ReduxStateType) {
        const state = getState();
        const user = getUser(state);

        dispatch(setLoading());
        let promise;
        if (user && !user.nboaAdmin && user.dataCommittee) {
            promise = Api.User.getScrubberSchools();
        } else if (user && !user.nboaAdmin && user.associationAdmin) {
            promise = Api.User.getAssociationSchools();
        } else if (user.nboaAdmin) {
            promise = Api.Schools.getAllSchools();
        } else {
            promise = Api.Schools.getParticipatingSchools();
        }
        return promise
            .then(function (participatingSchools) {
                let selectedSchoolId = state.userInfo.selectedSchoolId;
                if (!participatingSchools || participatingSchools.length === 0) {
                    selectedSchoolId = undefined;
                } else {
                    if (!selectedSchoolId) {
                        selectedSchoolId = participatingSchools[0]._id;
                    } else {
                        let selectedSchool = participatingSchools.find((s) => s._id === selectedSchoolId);
                        if (!selectedSchool) {
                            selectedSchoolId = participatingSchools[0]._id;
                        }
                    }
                }

                dispatch(setParticipatingSchools(participatingSchools));
                dispatch(selectSchool(selectedSchoolId));
            })
            .finally(function () {
                dispatch(unsetLoading());
            });
    };
}

export function fetchScrubberSchools(): any {
    return function (dispatch: GenericDispatch) {
        dispatch(setLoading());
        Api.User.getScrubberSchools()
            .then(function (participatingSchools) {
                dispatch({
                    type: RECEIVE_PARTICIPATING_SCHOOLS,
                    participatingSchools,
                });
            })
            .finally(function () {
                dispatch(unsetLoading());
            });
    };
}

export function updateWizard(name: string, seen: boolean, callback?: () => void): (dispatch: GenericDispatch) => any {
    return function (dispatch: GenericDispatch) {
        dispatch(setLoading());
        Api.User.updateWizard(name, seen)
            .then(function () {
                dispatch({
                    type: RECEIVE_USER_FROM_API,
                });
            })
            .finally(function () {
                dispatch(unsetLoading());
                if (callback) {
                    callback();
                }
            });
    };
}

export function dismissDataWizard(complete: boolean) {
    if (complete) {
        return function (dispatch: GenericDispatch) {
            dispatch(updateWizard('dataEntry', true));
        };
    }
}

export function logout(): (dispatch: GenericDispatch) => Promise<any> {
    return function (dispatch: GenericDispatch) {
        dispatch(fetchingUser(true));
        return Api.User.logout().then(function (response) {
            if (!response) {
                dispatch({
                    type: USER_FETCH_FAILED,
                });
            } else {
                if (!response.data && response.status !== 204) {
                    throw new Error('No User Found');
                }
                dispatch(fetchUser());
            }
        });
    };
}

export function createPeerGroup(name: string = 'First Peer Group', trackEvent: Function): any {
    return function (dispatch: GenericDispatch, getState: () => ReduxStateType) {
        const peergroupsOrder = getPeergroupsOrder(getState());
        dispatch(setLoading());
        Api.User.createPeerGroup(name)
            .then(function (peergroups) {
                dispatch({
                    type: RECEIVE_PEERGROUPS,
                    peergroups,
                });

                trackEvent(GA_EVENTS.REPORTING.EVENTS.CREATE_PEER_GROUP, GA_EVENTS.REPORTING.NAME);
                if (peergroups.length !== peergroupsOrder.length) {
                    const selectedPeergroup = peergroups.find((p) => peergroupsOrder.indexOf(p._id) === -1);
                    if (selectedPeergroup) {
                        dispatch(setSelectedPeergroupId(selectedPeergroup._id));
                    }
                }
            })
            .finally(function () {
                dispatch(unsetLoading());
            });
    };
}

export function deletePeerGroup(id: string): any {
    return function (dispatch: GenericDispatch) {
        dispatch(setLoading());
        Api.User.deletePeergroup(id)
            .then(function (peergroups) {
                dispatch({
                    type: RECEIVE_PEERGROUPS,
                    peergroups,
                });
                let peergroupId = undefined;
                if (peergroups.length > 0) {
                    peergroupId = peergroups[0]._id;
                }
                dispatch(setSelectedPeergroupId(peergroupId));
            })
            .finally(function () {
                dispatch(unsetLoading());
            });
    };
}

export function fetchPeergroupSchools(): (dispatch: GenericDispatch, getState: () => ReduxStateType) => Promise<any> {
    return function (dispatch, getState) {
        dispatch({
            type: FETCHING_PEERGROUP_SCHOOLS,
            fetchingPeergroupSchools: true,
        });
        dispatch(setLoading());
        let selectedFilters = getPeergroupSchoolFilters(getState());
        return Api.Schools.getPeergroupSchools(selectedFilters)
            .then(function (peergroupSchools) {
                return dispatch({
                    type: RECEIVE_PEER_GROUP_SCHOOLS,
                    peergroupSchools: peergroupSchools,
                });
            })
            .catch(function (error) {
                dispatch(setError({ message: error.message, type: 'API_ERROR' }));
                dispatch({
                    type: FETCHING_PEERGROUP_SCHOOLS,
                    fetchingPeergroupSchools: false,
                });
            })
            .finally(function () {
                dispatch(unsetLoading());
            });
    };
}

export function fetchPeergroupsForUser(userId?: string): (dispatch: GenericDispatch, getState: () => ReduxStateType) => void {
    return function (dispatch, getState) {
        const user = getUser(getState());
        if (!userId) {
            if (!user) {
                return;
            }
            userId = user._id;
        }
        dispatch(setLoading());
        dispatch({
            type: FETCHING_PEERGROUPS,
            fetchingPeergroups: true,
        });
        Api.User.getPeergroups(userId)
            .then(function (peergroups) {
                dispatch({
                    type: RECEIVE_PEERGROUPS,
                    peergroups,
                });
            })
            .catch(function () {
                dispatch({
                    type: FETCHING_PEERGROUPS,
                    fetchingPeergroups: false,
                });
            })
            .finally(function () {
                dispatch(unsetLoading());
            });
    };
}

export function fetchCannedPeergroups(): (dispatch: GenericDispatch) => void {
    return function (dispatch) {
        dispatch(setLoading());

        dispatch({
            type: FETCHING_CANNED_PEERGROUPS,
            fetchingCannedPeergroups: true,
        });
        Api.User.getCannedPeergroups()
            .then(function (cannedPeergroups) {
                dispatch({
                    type: RECEIVE_CANNED_PEERGROUPS,
                    cannedPeergroups,
                });
            })
            .catch(function () {
                dispatch({
                    type: FETCHING_CANNED_PEERGROUPS,
                    fetchingCannedPeergroups: false,
                });
            })
            .finally(function () {
                dispatch(unsetLoading());
            });
    };
}

export function resetPeergroupSchools(): any {
    return {
        type: RESET_PEER_GROUP_SCHOOLS,
    };
}

export function setPeergroupSchoolFilter(filterId: ReportFilterKeyType, value: ?string) {
    return {
        type: SET_PEERGROUP_SCHOOL_FILTER,
        filterId,
        value,
    };
}

export function updatePeergroup(peergroup: PeergroupType) {
    return {
        type: UPDATE_PEERGROUP,
        peergroup,
    };
}

export function updatePeergroupName(id: string, name: string): (dispatch: GenericDispatch) => void {
    return function (dispatch) {
        Api.User.savePeergroupName(id, name).then(function (peergroup) {
            dispatch(updatePeergroup(peergroup));
        });
    };
}

export function saveSchoolsForPeergroup(peergroupId: string, schools: Array<string>): (dispatch: GenericDispatch) => void {
    return function (dispatch) {
        Api.User.setPeergroupSchools(peergroupId, schools).then(function (peergroup) {
            dispatch(updatePeergroup(peergroup));
        });
    };
}

export function setSelectedPeergroupId(selectedPeergroupId: ?string) {
    return {
        type: SET_SELECTED_PEERGROUP_ID,
        selectedPeergroupId,
    };
}

export function setScrubbers(scrubbers: { [id: string]: UserType }, scrubberOrder: Array<string>): any {
    return {
        type: SET_SCRUBBERS,
        scrubbers,
        scrubberOrder,
    };
}

export function fetchScrubbers(): (dispatch: GenericDispatch) => void {
    return function (dispatch) {
        dispatch(setLoading());
        dispatch({
            type: SET_FETCHING_SCRUBBERS,
            fetching: true,
        });
        Api.User.getScrubbers()
            .then(function (scrubbers) {
                const scrubbersObject = {};
                const scrubberOrder = scrubbers.map(function (scrubber) {
                    scrubbersObject[scrubber._id] = scrubber;
                    return scrubber._id;
                });
                dispatch(setScrubbers(scrubbersObject, scrubberOrder));
            })
            .finally(function () {
                dispatch({
                    type: SET_FETCHING_SCRUBBERS,
                    fetching: false,
                });
                dispatch(unsetLoading());
            });
    };
}

export function fetchUnassignedSchools(): (dispatch: GenericDispatch) => void {
    return function (dispatch) {
        dispatch(setLoading());
        dispatch({
            type: SET_FETCHING_UNASSIGNED_SCHOOLS,
            fetching: true,
        });

        Api.Schools.getUnassignedSchools()
            .then(function (unassignedSchools) {
                dispatch({
                    type: SET_UNASSIGNED_SCHOOLS,
                    unassignedSchools,
                });
            })
            .finally(function () {
                dispatch({
                    type: SET_FETCHING_UNASSIGNED_SCHOOLS,
                    fetching: false,
                });
                dispatch(unsetLoading());
            });
    };
}

export function fetchSchoolsByStatus(): (dispatch: GenericDispatch) => void {
    return function (dispatch) {
        dispatch(setLoading());
        dispatch({
            type: SET_FETCHING_SCHOOLS,
            fetching: true,
        });
        Api.Schools.getByStatus()
            .then(function (schoolsByStatus) {
                dispatch({
                    type: RECEIVE_SCHOOLS_BY_STATUS,
                    schoolsByStatus,
                });
            })
            .catch(function () {
                console.error('--err');
            })
            .finally(function () {
                dispatch(unsetLoading());
                dispatch({
                    type: SET_FETCHING_SCHOOLS,
                    fetching: false,
                });
            });
    };
}
