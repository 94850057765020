// @flow
'use strict';
/**
 * @file Polyfill for older browsers
 */

//
require('core-js');
require('blob.js');
/**
 * Polyfill for browsers missing certain items
 * @return {void} void
 */
export function loadPolyfill(): void {
    if (typeof String.prototype.startsWith !== 'function') {
        // $FlowFixMe
        String.prototype.startsWith = function(searchString: string, position?: number): boolean {
            position = position || 0;
            var index: number = this.indexOf(searchString, position);
            return index === position;
        };
    }
}
