// @flow
'use strict';

import {
    SET_SELECTED_REPORT_FILTERS,
    RESET_ALL_REPORT_DATA,
    SET_FETCHING_DATA_TABLES,
    SET_DATA_TABLES,
    SET_FETCHING_DATA_TABLE,
    SET_DATA_TABLE,
    RESET_DATA_TABLE,
    UPDATE_DATA_TABLE_ROWS,
    ADD_DATA_TABLE_ROW,
    DELETE_DATA_TABLE_ROW,
    UPDATE_DATA_TABLE_ROW,
} from '../actions/constants';

export const defaultState = {
    fetchingDataTables: false,
    fetchingDataTable: false,
};

const reporting = (
    state: ReduxDataTablesStateType = defaultState,
    action: ReduxDataTablesActionType,
): ReduxDataTablesStateType => {
    switch (action.type) {
        case RESET_ALL_REPORT_DATA:
            return {
                ...defaultState,
            };
        case SET_SELECTED_REPORT_FILTERS:
            return {
                ...state,
                dataTable: undefined,
            };
        case SET_FETCHING_DATA_TABLES:
            return {
                ...state,
                // $FlowFixMe
                fetchingDataTables: action.fetching,
            };
        case SET_DATA_TABLES:
            return {
                ...state,
                dataTables: action.dataTables,
            };
        case SET_FETCHING_DATA_TABLE:
            return {
                ...state,
                // $FlowFixMe
                fetchingDataTable: action.fetching,
            };
        case SET_DATA_TABLE:
            return {
                ...state,
                dataTable: action.dataTable,
            };
        case RESET_DATA_TABLE:
            return {
                ...state,
                dataTable: undefined,
            };
        case UPDATE_DATA_TABLE_ROWS:
            return {
                ...state,
                dataTableRows:
                    action.dataTableRows && action.dataTableRows.length > 0 ? action.dataTableRows : undefined,
            };
        case ADD_DATA_TABLE_ROW: {
            let dtr = action.dataTableRow;
            if (!dtr) {
                return state;
            }
            return {
                ...state,
                dataTableRows: (action.bottom ? [] : [dtr])
                    .concat([...(state.dataTableRows || [])])
                    .concat(action.bottom ? [dtr] : []),
            };
        }
        case UPDATE_DATA_TABLE_ROW: {
            let dtrs = [];
            if (state.dataTableRows) {
                dtrs = [...state.dataTableRows];
            }
            if (action.dataTableRow) {
                dtrs[action.index || 0] = action.dataTableRow;
            }
            return {
                ...state,
                dataTableRows: dtrs,
            };
        }
        case DELETE_DATA_TABLE_ROW: {
            let dtrs;
            if (state.dataTableRows) {
                dtrs = state.dataTableRows
                    .slice(0, action.index)
                    .concat(state.dataTableRows ? state.dataTableRows.slice((action.index || 0) + 1) : []);
            }
            return {
                ...state,
                dataTableRows: dtrs,
            };
        }
    }
    return state;
};

export default reporting;

export const getDataTables = (state: ReduxDataTablesStateType): ?Array<DataTableShortType> => {
    return state.dataTables;
};

export const isFetchingDataTables = (state: ReduxDataTablesStateType): boolean => {
    return state.fetchingDataTables;
};

export const getDataTable = (state: ReduxDataTablesStateType): ?DataTableType => {
    return state.dataTable;
};

export const isFetchingDataTable = (state: ReduxDataTablesStateType): boolean => {
    return state.fetchingDataTable;
};

export const getDataTableRows = (state: ReduxDataTablesStateType): ?Array<DataTableCreateRowType> => {
    return state.dataTableRows;
};
