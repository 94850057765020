// @flow
'use strict';

import { useState, useEffect } from 'react';
import BufferedInput from '../BufferedInput';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as Utils from '../../utils';

type Props = {|
    type: 'numeric' | 'text' | 'textarea' | 'checkbox',
    unit?: ?DataUnitType,
    format?: ?(DataFormatNumericType | 'textarea'),
    dataPointId: string,
    decimalPlaces?: ?number,
    year: YearNumberType,
    editable: boolean,
    value: string,
    className?: string,
    name: string,
    validation: ?('error' | 'warning'),
    allowNegative?: boolean,
    onChange: (dataPointId: string, answer: string, year: string) => void,
|};

const useStyles = makeStyles(() => ({
    root: {
        height: '40px !important',
    },


    error: {
        borderColor: 'red !important',
    },

    warning: {
        borderColor: 'orange !important',
    },
    wrapper: {
        padding: '1px 2px 0 2px',
        margin: 0,
        borderWidth: 2,
        borderRadius: '5px',
        borderStyle: 'solid',
        borderColor: 'rgba(0,0,0, 0.26)',
        maxWidth: 'unset',
    },
}));

export default function DataPointInput(props: Props) {
    const {
        allowNegative,
        type,
        unit,
        format,
        dataPointId,
        decimalPlaces,
        year,
        editable,
        value,
        className = '',
        name,
        onChange,
        validation,
    } = props;

    const classes = useStyles();
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        if (!editable && value !== inputValue) {
            setInputValue(value);
        }
    }, [value]);

    const formatValue: (tValue: string) => string = (tValue) => {
        if (type !== 'numeric' || format === 'textarea') {
            return tValue;
        }
        const formatted = Utils.formatValue(format || 'integer', unit, decimalPlaces, tValue);
        return formatted;
    };

    const handleKeyPress: (event: SyntheticKeyboardEvent<Node>) => void = (event) => {
        // $FlowFixMe
        const evalue = event.target.value;

        if (type !== 'numeric') {
            return;
        }

        if (event.altKey || event.ctrlKey) {
            return;
        }

        if (parseInt(event.key) || parseInt(event.key) === 0) {
            return;
        }

        if (event.key === '.') {
            if (evalue && evalue.indexOf('.') !== -1) {
                return event.preventDefault();
            }
        } else if (event.key === '-') {
            if (!allowNegative || evalue.indexOf('-') !== -1) {
                return event.preventDefault();
            }

            // $FlowFixMe
            if (event.target.selectionStart !== 0) {
                return event.preventDefault();
            }
        } else if (event.key !== '.') {
            return event.preventDefault();
        }
    };

    const iType = type === 'numeric' ? 'text' : type;


    return (
        <Container
            className={
                type === 'checkbox'
                    ? ''
                    : classes.wrapper +
                      ' ' +
                      (validation === 'error' ? classes.error : validation === 'warning' ? classes.warning : '')
            }
        >
            <BufferedInput
                align='right'
                disabled={!editable}
                value={inputValue}
                label={name}
                onBufferedChange={(value) => {
                    setInputValue(value);
                    onChange(dataPointId, value, '' + year);
                }}
                className={className}
                formatValue={formatValue}
                unformatValue={Utils.unformatValue}
                onKeyPress={handleKeyPress}
                type={format === 'textarea' ? 'textarea' : iType}
            />
        </Container>
    );
}
