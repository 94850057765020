// @flow
'use strict';

import { useState } from 'react';

import SubNav from './subNav';
import AdminNav from './adminNav';
import styles from './navBar.scss';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Warning } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { DialogTitle, Button, Box, Dialog, DialogActions, DialogContent, DialogContentText, TextField, Autocomplete } from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';
import { routesForUser } from '../../routes';
import ProfileButton from '../ProfileButton';
import { getUser, getSelectedSchool, showChromeWarning, isLoading, getParticipatingSchools } from '../../redux/reducers';
import { NON_MEMBER_ERROR_MESSAGE, openChromeWarning, processUser, setError, setLoading, unsetLoading } from '../../redux/actions';
import api from '../../api';
import { USER_FETCH_FAILED, USER_TOKEN } from '../../redux/actions/constants';
import { getSchoolLabel } from '../../utils/schools';
import HelpDialog from '../HelpDialog';
import { useTrackEvents } from '../../ga/analytics';

export default function NavBar() {
    const user = useSelector(getUser);
    const selectedSchool = useSelector(getSelectedSchool);
    const participatingSchools = useSelector(getParticipatingSchools);
    const doShowChromeWarning = useSelector(showChromeWarning);
    const [showHelp, setShowHelp] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [impersonateModalVisible, setImpersonateModalVisible] = useState(false);
    const [isFetchingUsers, setIsFetchingUsers] = useState(false);
    const [impersonateSchool, setImpersonateSchool] = useState(null);
    const [impersonateUser, setImpersonateUser] = useState(null);
    const [users, setUsers] = useState([]);
    const trackEvent = useTrackEvents();
    const isAppLoading = useSelector(isLoading);

    const dispatch = useDispatch();
    const location = useLocation();
    const chrome = navigator.userAgent.indexOf('Edge') === -1 && navigator.userAgent.indexOf('Chrome') !== -1;
    const handleClick = (event: SyntheticInputEvent<*>, selected: boolean): void => {
        if (selected) {
            event.preventDefault();
            event.stopPropagation();
        }
    };
    const path = (location.pathname || '').slice(1);
    const pages = path.split('/');
    const userExists = user && user._id !== '';
    const routes = routesForUser(user, selectedSchool);
    let selectedRoute = routes.find(function (storedRoute) {
        return storedRoute.path === '/' + pages[0];
    });
    // $FlowFixMe
    const showSubNav = userExists && selectedRoute && (!selectedRoute || (selectedRoute && selectedRoute.subRoutes));
    const showAdminNav = showSubNav && pages.length > 1 && pages[1] === 'content';

    let mainClassName = styles.nav;
    if (showSubNav) {
        if (showAdminNav) {
            mainClassName += ' ' + styles.fullHeightWithExtraNav;
        } else {
            mainClassName += ' ' + styles.fullHeight;
        }
    }

    const handleClose = () => {
        setImpersonateModalVisible(false);
    };

    const fetchSchoolUsers = async (schoolId) => {
        setErrorMessage('');
        try {
            if (user && user.nboaAdmin) {
                if (schoolId) {
                    setIsFetchingUsers(true);
                    const users = await api.Impersonate.getImpersonationUsers(schoolId);
                    setUsers(users);
                }
            }
        } catch (error) {
            setErrorMessage('Error retrieving users.');
        } finally {
            setIsFetchingUsers(false);
        }
    };
    const toggle = () => {
        setImpersonateModalVisible(!impersonateModalVisible);
    };

    const handleLoginWithImpersonation = async () => {
        try {
            setErrorMessage('');
            dispatch(setLoading());
            const response = await api.Impersonate.loginUserFromImpersonation(impersonateUser);
            const { user, token } = response;
            if (token) {
                dispatch({
                    type: USER_TOKEN,
                    token,
                });
            }

            processUser(user, dispatch, trackEvent);
            toggle();
        } catch (err) {
            const message = err.response?.data?.message || err.message;

            if (message === NON_MEMBER_ERROR_MESSAGE) {
                dispatch(setError({ message: message, type: 'Non-member' }));
            } else {
                dispatch(setError({ message: message, type: 'Auth' }));
            }
            dispatch({
                type: USER_FETCH_FAILED,
            });
        } finally {
            setImpersonateModalVisible(false);
            dispatch(unsetLoading());
        }
    };

    return (
        <div key={'nav' + pages[0]} className={mainClassName}>
            <HelpDialog open={showHelp} handleClose={() => setShowHelp(false)} />

            <Dialog open={impersonateModalVisible} onClose={handleClose} maxWidth='md' fullWidth>
                <DialogTitle>Impersonate User</DialogTitle>
                <DialogContent>
                    {errorMessage && <DialogContentText sx={{ color: 'red' }}>{errorMessage}</DialogContentText>}
                    {isAppLoading && <DialogContentText>Loading....</DialogContentText>}
                    <Box sx={{ marginBottom: '1rem', marginTop: '1rem' }}>
                        <Autocomplete
                            fullWidth
                            options={(participatingSchools || []).map((s) => s._id)}
                            getOptionLabel={(option) => (option && participatingSchools ? getSchoolLabel(participatingSchools.find((s) => s._id === option)) : '')}
                            renderInput={(params) => <TextField {...params} label='Select School' variant='outlined' fullWidth />}
                            renderOption={(props, option, index) => {
                                if (participatingSchools) {
                                    const key = `school-${index}-${option}`;
                                    const school = participatingSchools.find((s) => s._id === option);

                                    return (
                                        <li {...props} key={key}>
                                            {getSchoolLabel(school)}
                                        </li>
                                    );
                                }
                                return <></>;
                            }}
                            onChange={async (_, item) => {
                                setImpersonateUser(null);
                                const impersonateSchool = participatingSchools.find((s) => s._id === item);
                                // const schoolsWithPrimaryContact = participatingSchools.filter((s) => s.primaryContact);
                                setImpersonateSchool(impersonateSchool);
                                if (item) {
                                    await fetchSchoolUsers(item);
                                }
                            }}
                        />
                    </Box>
                    <Box>
                        {isFetchingUsers ? (
                            <DialogContentText>Loading....</DialogContentText>
                        ) : (
                            <Autocomplete
                                options={users.sort((a, b) => -b.lastName.localeCompare(a.lastName))}
                                fullWidth
                                getOptionLabel={(option) => (option ? `${option.lastName}, ${option.firstName} - (${option.email})` : '')}
                                renderInput={(params) => <TextField {...params} label='User' variant='outlined' />}
                                renderOption={(props, option, index) => {
                                    if (users) {
                                        const key = `user-${index}-${option._id}`;

                                        return (
                                            <li {...props} key={key}>
                                                {`${option._id === impersonateSchool.primaryContact ? '*-' : ''}${option.lastName}, ${option.firstName} - (${option.email})`}
                                            </li>
                                        );
                                    }
                                    return <></>;
                                }}
                                onChange={(_, item) => {
                                    setImpersonateUser(item);
                                }}
                            />
                        )}
                        <Box>* = Primary Contact</Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleLoginWithImpersonation} disabled={isAppLoading}>
                        Login
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={styles.mainNav}>
                <div className={styles.leftAlign}>
                    {userExists
                        ? routes
                              .filter(function (route) {
                                  if (route.participating && user && !user.nboaAdmin && !user.dataCommittee) {
                                      return selectedSchool && selectedSchool.participatingYears.length > 0;
                                  }
                                  return true;
                              })
                              .map(function (route) {
                                  {
                                      /* if (!route.subRoutes || route.subRoutes.length === 0) {
                                    return null;
                                } */
                                  }
                                  const selected = pages[0] === route.path.slice(1);
                                  let linkTo = {};
                                  linkTo.pathname = route.path;
                                  if (route.subRoutes) {
                                      linkTo.pathname += route.subRoutes[0] ? route.subRoutes[0].path : '';
                                  }
                                  return (
                                      <Link
                                          key={route.path + (route.subRoutes ? route.subRoutes[0].path : '')}
                                          onClick={(e) => handleClick(e, selected)}
                                          className={styles.navButton + ' ' + (selected ? styles.primarySelected : '')}
                                          to={linkTo}
                                      >
                                          {route.secId}
                                      </Link>
                                  );
                              })
                        : null}
                </div>
                <div className={styles.rightAlign}>
                    {chrome || doShowChromeWarning ? null : (
                        <IconButton onClick={() => dispatch(openChromeWarning())}>
                            <Warning color='error' />
                        </IconButton>
                    )}
                    <a href='https://www.surveymonkey.com/r/NCDVVK6' className={styles.feedbackButton}>
                        Submit Feedback
                    </a>
                    {/* <Button className={styles.helpButton} onClick={() => setShowHelp(true)}>
                        <HelpOutline fontSize={'large'} className={styles.helpButton} />
                    </Button> */}
                    <ProfileButton
                        className={styles.profileButton}
                        user={user}
                        handleOpenImpersonateModal={() => {
                            setImpersonateModalVisible(true);
                        }}
                    />
                </div>
            </div>
            {showSubNav ? <SubNav pages={pages} /> : null}
            {showAdminNav && selectedSchool ? <AdminNav pages={pages} user={user} selectedSchool={selectedSchool} /> : null}
        </div>
    );
}
