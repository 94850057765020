// @flow
'use strict';

import { useEffect } from 'react';
import { useLocation, 
} from 'react-router-dom';
import { queryItemSelectors } from '../../redux/reducers';
import { useSelector } from 'react-redux';
import queryString from 'query-string';


const selector = (queryItem) => (state) => queryItemSelectors[queryItem](state);

export function useQueryItems(QueryItems: Array<string>): void {
    const location = useLocation();
    const queryItems = {};
    QueryItems.forEach(function(queryItem) {
        queryItems[queryItem] = useSelector(selector(queryItem));
    });
    useEffect(() => {
        let updatedQuery = {};
        let doUpdate = false;
        let search = queryString.parse(location.search);
        Object.keys(queryItems).forEach(function(queryItem) {
            const value = queryItems[queryItem];
            if (value !== undefined) {
                updatedQuery[queryItem] = '' + value;
            }
            if (value !== undefined && '' + value !== search[queryItem] && value !== search[queryItem]) {
                doUpdate = true;
            }
        });
        if (doUpdate) {
            window.history.pushState('', 'BIIS', `${location.pathname}?${queryString.stringify(updatedQuery)}`);
        }
    }, [Object.values(queryItems)]);
}
