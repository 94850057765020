// @flow
'use strict';

import { useState, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styles from './editorItem.scss';

const TIMEOUT = 1000;

type PropTypes = {|
    ...DragDropType,
    item: DataPointType,
    className?: string,
    index: number,
    topic?: boolean,
    moveCard: (dragIndex: number, hoverIndex: number) => void,
    onCardDrop: () => void,
    onUpdate?: (dataPointId: string, update: { name?: string, definition?: string }) => void,
|};

export function EditorItem(props: PropTypes) {
    let timer: TimeoutID | null;
    const ref = useRef(null);
    const { item, index, onCardDrop, moveCard, onUpdate } = props;
    const [sItem, setSItem] = useState(item);

    const startTimeout: () => void = () => {
        if (timer) {
            stopTimeout();
        }
        timer = setTimeout(handleTimeout, TIMEOUT);
    };

    const stopTimeout: () => void = () => {
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    };

    const handleBlur: (event: SyntheticInputEvent<*>) => void = (event) => {
        event.preventDefault();
        startTimeout();
    };

    const handleTimeout: () => void = () => {
        stopTimeout();

        const update = {};
        let doUpdate = false;
        if (sItem.name !== item.name) {
            update.name = sItem.name;
            doUpdate = true;
        }
        if (sItem.definition !== item.definition) {
            update.definition = sItem.definition;
            doUpdate = true;
        }
        if (doUpdate && onUpdate) {
            onUpdate(item._id, update);
        }
    };

    const handleChange: (update: { ['name' | 'def{inition']: string }) => void = (update) => {
        // $FlowFixMe
        setSItem({
            ...sItem,
            ...update,
        });
        startTimeout();
    };

    const [{ isDragging }, drag] = useDrag({
        item: {
            type: 'EditorItem',
            id: item._id,
            section: item.section,
            subsection: item.subsection,
            index: index,
        },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: 'EditorItem',
        drop: function () {
            onCardDrop();
        },
        hover: (item, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = ref.current.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });
    drag(ref);

    return (
        <div ref={drop} className={styles.editorItem + ' ' + (props.className || '') + ' ' + (isDragging ? styles.dragging : '')}>
            <div ref={ref} className={styles.leftRibbon}>
                <div className={styles.dragButton} />
            </div>
            <div className={styles.itemDetails}>
                <table className={styles.itemTable + ' ' + (props.topic ? styles.topicItemTable : '')}>
                    <tbody>
                        <tr className={styles.inputRow}>
                            <td colSpan='4' className={styles.nameInput}>
                                <input
                                    type='text'
                                    name='name'
                                    className={styles.textInput + ' ' + styles.input + ' ' + styles.nameInput}
                                    onChange={(e) => handleChange({ name: e.target.value })}
                                    value={sItem.name}
                                    onBlur={handleBlur}
                                />
                            </td>
                        </tr>
                        <tr className={styles.inputRow}>
                            <td colSpan='4' className={styles.input}>
                                <textarea
                                    className={styles.textareaQuestionInput}
                                    name='definition'
                                    placeholder='Enter Definition...'
                                    // $FlowFixMe
                                    onChange={(e) => handleChange({ definition: e.target.value })}
                                    value={sItem.definition}
                                    onBlur={handleBlur}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default EditorItem;
