// @flow
'use strict';

import { Component } from 'react';
import Chart from 'chart.js';
import isEqual from 'lodash/isEqual';
import { DashboardContext } from '../../views/Dashboards-pre-highcharts';

type Props = {|
    data: ChartDataType,
    getDatasetAtEvent?: ((event: SyntheticInputEvent<*>) => any, event: SyntheticInputEvent<*>) => any,
    getElementAtEvent?: ((event: SyntheticInputEvent<*>) => any, event: SyntheticInputEvent<*>) => any,
    getElementsAtEvent?: ((event: SyntheticInputEvent<*>) => any, event: SyntheticInputEvent<*>) => any,
    legend?: LegendType,
    options: Object,
    plugins?: Array<{
        [_id: string]: any,
    }>,
    style?: Object,
    redraw?: boolean,
    type?: ChartType,
    width?: number,
    className?: string,
    title: string,
    subheader: string,
    item?: DashboardItemType,
|};

Chart.defaults.global.elements.point.radius = 5;

class ChartComponent extends Component<Props, void> {
    chartInstance: any;
    element: ?HTMLCanvasElement;

    constructor(props: Props) {
        super(props);
        this.chartInstance = undefined;
        this.toBase64Image = this.toBase64Image.bind(this);
    }

    toBase64Image: () => string = () => {
        return this.chartInstance.toBase64Image();
    };

    componentDidMount() {
        const self = this;
        const title = this.props.title;
        const subheader = this.props.subheader;
        const item = this.props.item;
        Chart.plugins.register({
            beforeDraw: function (chartInstance) {
                let ctx = chartInstance.chart.ctx;
                ctx.fillStyle = 'white';
                ctx.fillRect(0, 0, chartInstance.chart.width, chartInstance.chart.height);
            },
            afterRender: function () {
                if (self.chartInstance.canvas && self.context) {
                    const imageString = self.toBase64Image().replace('data:image/png;base64,', '');
                    self.context.addSlide({
                        title,
                        subheader: typeof subheader === 'string' ? subheader : subheader[0],
                        image: imageString,
                        ...item,
                        width: self.chartInstance.chart.width,
                        height: self.chartInstance.chart.height,
                    });
                }
            },
        });
        this.renderChart();
    }

    componentDidUpdate(prevProps) {
        if (this.props.redraw) {
            if (!isEqual(this.props, prevProps)) {
                this.chartInstance.destroy();
                this.renderChart();
            }
            return;
        }
        this.updateChart();
    }

    componentWillUnmount() {
        if (this.chartInstance) {
            this.chartInstance.destroy();
        }
    }

    transformDataProp(props: Props) {
        const { data } = props;
        if (typeof data == 'function') {
            const node = this.element;
            return data(node);
        } else {
            return data;
        }
    }

    updateChart() {
        if (!this.chartInstance) {
            return;
        }
        const { options, legend, type } = this.props;
        const data = {
            ...this.props.data,
        };

        if (typeof legend !== 'undefined') {
            options.legend = legend;
        }

        const self = this;
        let doUpdate = false;

        Object.keys(data).forEach(function (key) {
            // $FlowFixMe
            if (!isEqual(data[key], self.chartInstance.data[key])) {
                self.chartInstance.data[key] = data[key];
                if (key !== 'label') {
                    doUpdate = true;
                }
            }
        });
        let doUpdateForType = false;
        if (this.chartInstance.type !== type && type !== 'mixed') {
            this.chartInstance.type = type;
            doUpdateForType = true;
        }
        if (doUpdate) {
            if (type === 'mixed') {
                if (!data.labels) {
                    data.labels = [];
                } else {
                    data.labels = data.labels.sort((a, b) => a - b);
                }
                data.datasets = data.datasets.reverse();
            }
            this.chartInstance.data = data;
        }
        if (doUpdateForType) {
            this.chartInstance.destroy();
            this.renderChart();
        }
        if (doUpdate) {
            this.chartInstance.update();
        }
    }

    renderChart() {
        let { options, legend, type, plugins } = this.props;
        const node = this.element;
        let data = {
            ...this.props.data,
        };

        if (typeof legend !== 'undefined') {
            options.legend = legend;
        }
        if (!options.elements) {
            options.elements = {};
        }
        if (!options.elements.line) {
            options.elements.line = {};
        }
        options.elements.line.tension = 0;
        if (type === 'stacked-bar') {
            type = 'bar';
            options.scales = {
                xAxes: [
                    {
                        stacked: true,
                    },
                ],
                yAxes: [
                    {
                        stacked: true,
                    },
                ],
            };
        }
        if (type === 'mixed') {
            const mData = {
                labels: data.labels,
                datasets: [data.datasets[0]],
            };
            // $FlowFixMe
            const mtype = data.datasets[0].type;
            this.chartInstance = new Chart(node, {
                type: mtype,
                data: mData,
                options,
                plugins,
            });
            this.updateChart();
            return;
        }
        this.chartInstance = new Chart(node, { type, data, options, plugins });
    }

    handleOnClick = (event: SyntheticInputEvent<*>) => {
        const instance = this.chartInstance;
        const { getDatasetAtEvent, getElementAtEvent, getElementsAtEvent } = this.props;

        getDatasetAtEvent && getDatasetAtEvent(instance.getDatasetAtEvent(event), event);
        getElementAtEvent && getElementAtEvent(instance.getElementAtEvent(event), event);
        getElementsAtEvent && getElementsAtEvent(instance.getElementsAtEvent(event), event);
    };

    render() {
        const { width } = this.props;
        const style = this.props.style || {};
        style.height = '100%';
        style.minHeight = '250px';
        return (
            <canvas
                className={this.props.className || ''}
                style={style}
                ref={(canvas) => {
                    this.element = canvas;
                }}
                width={width}
                onClick={this.handleOnClick}
            />
        );
    }
}

ChartComponent.contextType = DashboardContext;
export default ChartComponent;

export class Doughnut extends Component<Props, void> {
    chartInstance: any;

    render() {
        return <ChartComponent {...this.props} ref={(ref) => (this.chartInstance = ref && ref.chartInstance)} type='doughnut' />;
    }
}

export class Pie extends Component<Props, void> {
    chartInstance: any;

    render() {
        return <ChartComponent {...this.props} ref={(ref) => (this.chartInstance = ref && ref.chartInstance)} type='pie' />;
    }
}

export class Line extends Component<Props, void> {
    chartInstance: any;

    render() {
        return <ChartComponent {...this.props} ref={(ref) => (this.chartInstance = ref && ref.chartInstance)} type='line' />;
    }
}

export class Bar extends Component<Props, void> {
    chartInstance: any;

    render() {
        return <ChartComponent {...this.props} ref={(ref) => (this.chartInstance = ref && ref.chartInstance)} type='bar' />;
    }
}

export class HorizontalBar extends Component<Props, void> {
    chartInstance: any;

    render() {
        return <ChartComponent {...this.props} ref={(ref) => (this.chartInstance = ref && ref.chartInstance)} type='horizontalBar' />;
    }
}

export class Radar extends Component<Props, void> {
    chartInstance: any;

    render() {
        return <ChartComponent {...this.props} ref={(ref) => (this.chartInstance = ref && ref.chartInstance)} type='radar' />;
    }
}

export class Polar extends Component<Props, void> {
    chartInstance: any;

    render() {
        return <ChartComponent {...this.props} ref={(ref) => (this.chartInstance = ref && ref.chartInstance)} type='polarArea' />;
    }
}

export class Bubble extends Component<Props, void> {
    chartInstance: any;

    render() {
        return <ChartComponent {...this.props} ref={(ref) => (this.chartInstance = ref && ref.chartInstance)} type='bubble' />;
    }
}

export class Scatter extends Component<Props, void> {
    chartInstance: any;

    render() {
        return <ChartComponent {...this.props} ref={(ref) => (this.chartInstance = ref && ref.chartInstance)} type='scatter' />;
    }
}

export const defaults = Chart.defaults;
export { Chart };
