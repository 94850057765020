// @flow

import { useSelector } from 'react-redux';
import DataPointQuestionWrapper from '../DataPointQuestionWrapper';


import {
    getDataPointById,
    canEdit,
    isNboaAdmin,
    isOnDataCommittee,
    userCanWriteConfidentialData,
    getSelectedYear,
} from '../../redux/reducers';


type Props = {|
    dataPointId: string,
|};

export function DataPointQuestion({ dataPointId}: Props) {
    const getDataPoint = (state: ReduxStateType) => getDataPointById(state, dataPointId);
    const dataPoint = useSelector(getDataPoint);
    const edit = useSelector(canEdit);
    const isAdmin = (state => isNboaAdmin(state) || isOnDataCommittee(state));
    const admin = useSelector(isAdmin);
    const canWriteConfidentialData = useSelector(userCanWriteConfidentialData);
    const selectedYear = useSelector(getSelectedYear);
    if (!dataPoint) {
        return <div />;
    }
    const props = {
        dataPoint,
        admin,
        canWriteConfidentialData,
        selectedYear,
        canEdit: edit
    };
    return <DataPointQuestionWrapper {...props} />;
}

export default DataPointQuestion;
