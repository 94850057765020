// @flow
'use strict';

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer, { reduxStateFromSearchQuery } from '../reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore() {
    const preloadedState: ?ReduxStateType = reduxStateFromSearchQuery(window.location.search);
    const middleware = composeEnhancers(applyMiddleware(thunk));

    let store = preloadedState
        ? createStore<ReduxStateType, any, any>(rootReducer, preloadedState, middleware)
        : createStore<ReduxStateType, any, any>(rootReducer, middleware);

    /*  Not sure if this is still necessary
    */

    return store;
}
