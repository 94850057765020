// @flow
'use strict';
import DataPointQuestionMatrix from '../DataPointQuestionMatrix';
import DataPointContingentChoice from '../DataPointQuestionContingentChoice';
import DataPointScalarQuestion from '../DataPointScalarQuestion';

type Props = {
    dataPoint: ?DataPointType,
    canEdit: boolean,
    admin: boolean,
    canWriteConfidentialData: boolean,
    selectedYear: YearNumberType,
    contingencyValue?: any,
    contingency?: {
        _id: string,
        value: string | number | boolean,
        editable?: boolean,
        operator?: 'includes' | '>'
    },
}
export function DataPointQuestionWrapper(props: Props) {
    const { dataPoint, contingency, admin, canWriteConfidentialData, selectedYear, contingencyValue } = props;

    let canEdit = props.canEdit; // || props.admin;

    if (dataPoint && dataPoint.confidential && canWriteConfidentialData && !admin) {
        canEdit = false;
    }
    if (canEdit && contingency) {
        if (typeof contingency.value === 'boolean') {
            canEdit = !!contingency.value === !!contingencyValue;
        } else {
            canEdit = contingency.value === contingencyValue;
        }
    }

    if (!dataPoint) {
        return <div />;
    }
    switch (dataPoint.type) {
        case 'numeric':
        case 'text':
        case 'choice':
        case 'multiple-choice':
            return <DataPointScalarQuestion dataPoint={dataPoint} canEdit={canEdit} />;
        case 'contingent-choice':
            return <DataPointContingentChoice 
                dataPoint={dataPoint} 
                canEdit={canEdit} 
                admin={admin}
                canWriteConfidentialData={canWriteConfidentialData}
                selectedYear={selectedYear} />;
        case 'matrix':
            return <DataPointQuestionMatrix dataPoint={dataPoint} canEdit={canEdit} selectedYear={selectedYear}/>;
    }
    return null;
}

export default DataPointQuestionWrapper;