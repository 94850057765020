// @flow
'use strict';
import styles from './header2.scss';
import nboaLogo from '../../images/redesign/nboa-logo-1.png';
import * as Utils from '../../utils';

export function Header2() {
    let environment = Utils.Constants.environment;
    if (environment && environment.length > 0) {
        environment = environment[0].toUpperCase() + environment.slice(1);
    }
    return (
        <header className={`${styles['bg-nboa-blue']} ${styles['main-header']}`}>
            <a href='http://nboa.org'>
                <img src={nboaLogo} className={styles['nboa-logo']} />
            </a>
            {!Utils.isEmpty(environment) ? <div className={styles.environment}>--- {environment} Site</div> : null}
        </header>
    );
}

export default Header2;
