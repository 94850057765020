// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media print{.app-components-DashboardRow-dashboardRow__dashboardRow--Stps5{display:block !important;margin:0;width:100% !important}}.app-components-DashboardRow-dashboardRow__dashboardRow--Stps5{margin-top:10px;page-break-inside:avoid}", "",{"version":3,"sources":["webpack://./app/components/DashboardRow/dashboardRow.scss"],"names":[],"mappings":"AACA,aACI,+DACI,wBAAA,CACA,QAAA,CACA,qBAAA,CAAA,CAMR,+DACI,eAAA,CACA,uBAAA","sourcesContent":["\n@media print {\n    .dashboardRow {\n        display: block !important;\n        margin: 0;\n        width: 100% !important;\n    }\n\n    //\n}\n\n.dashboardRow {\n    margin-top: 10px;\n    page-break-inside: avoid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboardRow": "app-components-DashboardRow-dashboardRow__dashboardRow--Stps5"
};
export default ___CSS_LOADER_EXPORT___;
