// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Arimo);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://p.typekit.net/p.css?s=1&k=wvy7sbk&ht=tk&f=139.169.173.175.5474.25136&a=108967835&app=typekit&e=css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-components-LoadingAnimation-loadingAnimation__modal--YnB82{top:50%;left:50%;position:fixed;width:100px !important;height:100px !important;background-color:rgba(255,255,255,.7);border:3px solid rgba(1,1,1,.3);border-radius:10px;padding:5px}", "",{"version":3,"sources":["webpack://./app/components/LoadingAnimation/loadingAnimation.scss"],"names":[],"mappings":"AAIA,gEACI,OAAA,CACA,QAAA,CACA,cAAA,CACA,sBAAA,CACA,uBAAA,CACA,qCAAA,CAEA,+BAAA,CACA,kBAAA,CACA,WAAA","sourcesContent":["@import '../../stylesheets/constants.scss';\n\n$width: 100px;\n\n.modal {\n    top:50%;\n    left:50%;\n    position: fixed;\n    width: $width !important;\n    height: $width !important;\n    background-color: rgba(255,255,255,.7);\n\n    border: 3px solid rgba(1,1,1,.3);\n    border-radius: 10px;\n    padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "app-components-LoadingAnimation-loadingAnimation__modal--YnB82"
};
export default ___CSS_LOADER_EXPORT___;
