// @flow
'use strict';

import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { getUser } from '../../redux/reducers';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Api from '../../api';

import type { ChildrenArray } from 'react';

type Props = {
    error?: Error,
    children?: ChildrenArray<any>,
};

export default function ErrorBoundaryDialog({ error, children }: Props): React$Element<any> {
    const user = useSelector(getUser);
    const location = useLocation();
    const history = useHistory();
    const [dialogOpen, setDialogOpen] = useState(!!error);
    if (process.env.NODE_ENV === 'production' && error) {
        Api.sendError(error, user, location);
    }
    return (
        <Fragment>
            <Dialog open={dialogOpen}>
                <DialogTitle>There was a problem</DialogTitle>
                <DialogContent>
                    <DialogContentText>We apologize, but we ran into a problem. It has been reported and we are looking into it.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDialogOpen(false);
                            if (history) history.back();
                        }}
                        color='primary'
                        autoFocus
                    >
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
            {children || ''}
        </Fragment>
    );
}
