// @flow
'use strict';

import axios from 'axios';
import * as Utils from '../utils';
const Constants = Utils.Constants;
const User = {};

User.loginUserFromNetforum = (userId: string, token: string): Promise<{ user: UserType, token: string }> => {
    return axios
        .post(
            Constants.apiBaseUrl + 'users/login',
            {
                userId: userId,
                token: token,
            },
            { withCredentials: true },
        )
        .then(function (response) {
            if (!response.data) {
                throw new Error('No User Found');
            }
            return User.getLoggedInUser().then((response) => {
                return response;
            });
        })
        .catch(function (err) {
            if (err && err.response) {
                throw new Error(err.response.data.message);
            }
            throw new Error('Error logging in');
        });
};

User.getLoggedInUser = (): Promise<{ user: UserType, token: string }> => {
    return axios
        .get(Constants.apiBaseUrl + 'users/me', { withCredentials: true })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No user returned from Api');
            }
            return {
                user: response.data,
                token: response.headers['x-token'],
            };
        })
        .catch(function (err) {
            if (err && err.response) {
                throw new Error(err.response.data.message);
            }
            throw new Error('Error logging in');
        });
};

User.getPeergroups = (userId: string): Promise<Array<PeergroupType>> => {
    return axios
        .get(Constants.apiBaseUrl + 'users/' + userId + '/peergroups', {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No peergroups returned from Api');
            }
            return response.data;
        });
};
User.getCannedPeergroups = (): Promise<Array<PeergroupType>> => {
    return axios
        .get(Constants.apiBaseUrl + 'peergroups/templates', {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No peergroups returned from Api');
            }
            return response.data;
        });
};

User.logout = (): Promise<void> => {
    return axios.get(Constants.apiBaseUrl + 'users/logout', { withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('Error logging out');
        }
        return response;
    });
};

User.updateWizard = (name: string, seen: boolean): Promise<UserType> => {
    return axios
        .put(
            Constants.apiBaseUrl + 'users/wizard',
            {
                name: name,
                seen: seen,
            },
            {
                withCredentials: true,
            },
        )
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No User returned from Api');
            }
            return response.data;
        });
};

const updatePermission: (userId: string, action: 'add' | 'delete', permission: string) => Promise<Array<PermissionsType>> = (userId, action, permission) => {
    return axios
        .put(
            Constants.apiBaseUrl + 'users/' + userId + '/permissions',
            {
                action: action,
                permission: permission,
            },
            {
                withCredentials: true,
            },
        )
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Error logging out');
            }

            return response.data;
        });
};

User.updatePermission = updatePermission;

const getSchoolsForPermissions: (search: string) => Promise<Array<SchoolMainType>> = (search) => {
    return axios
        .get(Constants.apiBaseUrl + 'schools', {
            params: {
                name: search,
            },
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Error logging out');
            }

            return response.data;
        });
};

User.getSchoolsForPermissions = getSchoolsForPermissions;

const getUsersForSchool: (schoolId: string) => Promise<Array<UserShortType>> = (schoolId) => {
    return axios
        .get(Constants.apiBaseUrl + 'schools/' + schoolId + '/users', {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Error logging out');
            }

            return response.data;
        });
};

User.getUsersForSchool = getUsersForSchool;

const createPeerGroup: (name: string) => Promise<Array<PeergroupType>> = (name) => {
    const data = { name };

    return axios.post(Constants.apiBaseUrl + 'users/peergroups', data, { withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('Error logging out');
        }

        return response.data;
    });
};

User.createPeerGroup = createPeerGroup;

User.setPeergroupSchools = (peergroupId: string, schools: Array<string>): Promise<PeergroupType> => {
    return axios.put(Constants.apiBaseUrl + 'users/peergroups/' + peergroupId + '/schools', { schools: schools }, { withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('No Dashboard returned from Api');
        }
        return response.data;
    });
};

User.savePeergroupName = (peergroupId: string, name: string): Promise<PeergroupType> => {
    return axios.put(Constants.apiBaseUrl + 'users/peergroups/' + peergroupId, { name: name }, { withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('No Dashboard returned from Api');
        }
        return response.data;
    });
};

User.deletePeergroup = (peergroupId: string): Promise<Array<PeergroupType>> => {
    return axios
        .delete(Constants.apiBaseUrl + 'users/peergroups/' + peergroupId, {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Bad Response From for Delete Peergroup');
            }
            return response.data;
        });
};

User.updatePagesForAccess = (
    id: string,
    denyAccess: boolean,
    showMessage: boolean,
    message: string,
): Promise<
    Array<{|
        _id: string,
        denyAccess: boolean,
        showMessage: boolean,
        name: string,
        message: string,
    |}>,
> => {
    return axios
        .put(
            Constants.apiBaseUrl + 'settings/access',
            {
                _id: id,
                denyAccess: denyAccess,
                showMessage: showMessage,
                message: message,
            },
            {
                withCredentials: true,
            },
        )
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Bad response from api for updating pages for access');
            }
            return response.data;
        });
};

User.getDataEntryAccess = () => {
    return axios
        .get(Constants.apiBaseUrl + 'settings/dataentry', {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Bad response from api for pages for access');
            }
            return response.data;
        });
};

User.updateDataEntryAccess = (disabled: boolean): Promise<any> => {
    return axios
        .put(
            Constants.apiBaseUrl + 'settings/dataentry',
            {
                disabled: disabled,
            },
            {
                withCredentials: true,
            },
        )
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Bad response from api for updating pages for access');
            }
            return response.data;
        });
};

const addSchoolToPeergroup: (peergroupId: string, schoolId: string) => Promise<UserType> = (peergroupId, schoolId) => {
    return axios.put(Constants.apiBaseUrl + 'users/peergroups/' + peergroupId + '/' + schoolId, {}, { withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('Bad response from api for add school to peergroup');
        }
        return response.data;
    });
};

User.addSchoolToPeergroup = addSchoolToPeergroup;

const removeSchoolFromPeergroup: (peergroupId: string, schoolId: string) => Promise<UserType> = (peergroupId, schoolId) => {
    return axios.delete(Constants.apiBaseUrl + 'users/peergroups/' + peergroupId + '/' + schoolId, { withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('Bad response from api for remove school from peergroup');
        }
        return response.data;
    });
};

User.removeSchoolFromPeergroup = removeSchoolFromPeergroup;

const getScrubbers: () => Promise<Array<ScrubberType>> = () => {
    return axios
        .get(Constants.apiBaseUrl + 'users/scrubbers', {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Bad response from api for remove school from peergroup');
            }
            return response.data;
        });
};

User.getScrubbers = getScrubbers;

const addSchoolToScrubber: (scrubberId: string, schoolId: string) => Promise<Array<ScrubberType>> = (scrubberId, schoolId) => {
    return axios
        .put(
            Constants.apiBaseUrl + 'users/scrubbers/' + scrubberId,
            {
                school: schoolId,
            },
            { withCredentials: true },
        )
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Bad response from api for remove school from peergroup');
            }
            return response.data;
        });
};

User.addSchoolToScrubber = addSchoolToScrubber;

const removeSchoolFromScrubber: (scrubberId: string, schoolId: string) => Promise<ScrubberType> = (scrubberId, schoolId) => {
    return axios
        .delete(Constants.apiBaseUrl + 'users/scrubbers/' + scrubberId + '/schools/' + schoolId, {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Bad response from api for remove school from peergroup');
            }
            return response.data;
        });
};

User.removeSchoolFromScrubber = removeSchoolFromScrubber;

const getScrubberSchools: () => Promise<Array<PeergroupSchoolType>> = () => {
    return axios
        .get(Constants.apiBaseUrl + 'users/scrubbers/schools', {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Bad response from api for remove school from peergroup');
            }
            return response.data;
        });
};

User.getScrubberSchools = getScrubberSchools;

const getAssociationSchools: () => Promise<Array<PeergroupSchoolType>> = () => {
    return axios
        .get(Constants.apiBaseUrl + 'users/association/schools', {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('Bad response from api for remove school from peergroup');
            }
            return response.data;
        });
};

User.getAssociationSchools = getAssociationSchools;

const downloadPeergroupExcel: (userId: string, schools: string) => Promise<string> = (userId, schools) => {
    return axios
        .post(
            Constants.apiBaseUrl + 'users/peergroups/' + userId + '/download',
            {
                schools,
            },
            { responseType: 'blob', withCredentials: true },
        )
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Data returned from Api');
            }
            return response.data;
        });
};

User.downloadPeergroupExcel = downloadPeergroupExcel;

export default User;
