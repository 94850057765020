// @flow
import React, { useReducer, useContext } from 'react';
import reportsReducer, { initialState } from './ducks/reports';

type ReportsContextProviderProps = {
    children: any,
};

const ReportsContext = React.createContext({ state: initialState, dispatch: () => null });

const ReportsContextProvider = ({ children }: ReportsContextProviderProps) => {
    const [state, dispatch] = useReducer(reportsReducer, initialState);

    return <ReportsContext.Provider value={{ state, dispatch }}>{children}</ReportsContext.Provider>;
};

export const useReportsContext = () => useContext(ReportsContext);

export { ReportsContextProvider };
