// @flow
'use strict';

import { LinearProgress, Tooltip, Button, withStyles, Typography } from '@material-ui/core';
import styles from './progressBar.scss';

const inlineStyles = {
    bar: {
        backgroundColor: '#009CDE',
    },
    root: {
        backgroundColor: '#6e6259',
    },
    prgBtn: {
        height: '30px',
        display: 'unset',
        backgroundColor: 'white',
        padding: 0,
    },
    progress: {
        height: '30px',
    },
    btnLabel: {
        padding: 0,
        margin: 0,
        color: 'white',
    },
    lbl: {
        position: 'absolute',
        right: '5px',
        top: '4px',
    },
};
type ComponentPropTypes = {|
    className?: string,
    total: number,
    count: number,
    labelHelpText?: string,
    labelText?: () => string,
    onLabelClick?: () => void,
    enableConfirm?: () => boolean,
    classes: { [$Keys<typeof inlineStyles>]: string },
|};

export function ProgressBar(props: ComponentPropTypes) {
    const { className, count, total, enableConfirm, labelHelpText, labelText, onLabelClick, classes } = props;
    const completed = count === total;
    const doEnableConfirm = enableConfirm ? enableConfirm() || completed : completed;

    const classNames =
        styles.progressBar + (className ? ' ' + className : '') + (doEnableConfirm ? ' ' + styles.completed : '');
    const progress = Math.floor((100 * props.count) / props.total);
    return (
        <div id='progressbar' className={classNames}>
            <Tooltip placement='top' title={labelHelpText || ''}>
                <Button onClick={onLabelClick} className={classes.prgBtn} classes={{ label: classes.btnLabel }}>
                    <LinearProgress
                        className={classes.progress}
                        classes={{
                            root: classes.root,
                            bar: classes.bar,
                        }}
                        variant='determinate'
                        value={progress}
                    />
                    <Typography className={classes.lbl}>{labelText ? labelText() : count + ' / ' + total}</Typography>
                </Button>
            </Tooltip>
        </div>
    );
}

export default withStyles(inlineStyles)(ProgressBar);
