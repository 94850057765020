// @flow
'use strict';

import { forwardRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';


import { formatValue } from '../../utils';

const useStyles = makeStyles(() => ({
    wrapper: {},
    header: {
        backgroundColor: 'black',
        color: 'white',
    },
    paper: {
        height: '100%',
        overflowY: 'auto',
    },
    negative: {
        color: 'red',
    },
    bold: {
        fontWeight: 'bold',
    },
    hover: {
        backgroundColor: '#009CDE',
        color: 'white',
    },
    table: {
        height: 'calc(100% - 60px)',
    },
}));

type OwnProps = {|
    dataTable: ?DataTableType,
|};

type PropTypes = {|
    ...OwnProps,
|};

export function DataTable(props: PropTypes, tableRef: any) {
    const { dataTable } = props;
    const classes = useStyles();
    if (!dataTable) {
        return <div />;
    }
    if (dataTable && !dataTable.rows) {
        return <Typography variant='h6'>{dataTable.name}</Typography>;
    }
    

    return (
        <div className={classes.wrapper}>
            {!dataTable ? null : (
                <Paper className={classes.paper + ' ' + (dataTable.filters ? classes.table : '')}>
                    <Table ref={tableRef}>
                        <TableBody>
                            {dataTable.rows.map(function(row, rowIndex) {
                                if (row.header) {
                                    return (
                                        <TableRow key={rowIndex + row.label} className={classes.header}>
                                            {[
                                                {
                                                    label: row.label,
                                                    value: row.label,
                                                },
                                            ]
                                                .concat(dataTable.columns)
                                                .map(function(column, cIndex) {
                                                    return (
                                                        <TableCell
                                                            align={cIndex ? 'right' : 'left'}
                                                            key={cIndex + column.label}
                                                            className={classes.header}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    );
                                                })}
                                        </TableRow>
                                    );
                                }
                                return (
                                    <TableRow hover={true} key={rowIndex + row.label}>
                                        <TableCell key={'cell' + rowIndex} className={row.bold ? classes.bold : ''}>
                                            {row.label}
                                        </TableCell>
                                        {row.values.map(function(value, vIndex) {
                                            let column = dataTable.columns[vIndex] || {};
                                            let formattedValue = formatValue(
                                                column.format || row.format,
                                                column.unit || row.unit,
                                                column.decimalPlaces || row.decimalPlaces,
                                                value,
                                            );
                                            if (value < 0) {
                                                formattedValue = '(' + formattedValue.replace('-', '') + ')';
                                            }

                                            return (
                                                <TableCell
                                                    key={vIndex}
                                                    align='right'
                                                    className={
                                                        value < 0 ? classes.negative : row.bold ? classes.bold : ''
                                                    }
                                                >
                                                    {formattedValue}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </Paper>
            )}
        </div>
    );
}

export default forwardRef<*, *>(DataTable);
