// @flow
'use strict';
import Api from '../../api';
export * from './dataPoints';
export * from './user';
export * from './reports';
export * from './dataTables';

import { fetchUser } from './user';

import {
    RECEIVE_USER_FROM_API,
    SELECTED_YEAR,
    SET_LOADING,
    UNSET_LOADING,
    FETCHING_DATAPOINTS_DATA,
    RECEIVE_DATAPOINTS_DATA,
    SET_ERROR,
    UNSET_ERROR,
    CLOSE_CHROME_WARNING,
    OPEN_CHROME_WARNING,
} from './constants';

export function setLoading(id?: string) {
    return {
        type: SET_LOADING,
        id,
    };
}

export function unsetLoading(id?: string) {
    return {
        type: UNSET_LOADING,
        id,
    };
}

export function selectYear(year: string) {
    return {
        type: SELECTED_YEAR,
        selectedYear: year,
    };
}

export function submitData(school: SelectedSchoolType, doSubmit: boolean, year: string) {
    return function (dispatch: GenericDispatch) {
        dispatch({
            type: FETCHING_DATAPOINTS_DATA,
            fetchingDataPoints: true,
        });
        return Api.DataPoints.submit(school._id, doSubmit, year).then(function () {
            dispatch({
                type: FETCHING_DATAPOINTS_DATA,
                fetchingDataPoints: false,
            });
        });
    };
}

export function receiveDataPoints(dataPoints: DataPointResponseType): any {
    return {
        type: RECEIVE_DATAPOINTS_DATA,
        dataPoints: dataPoints.dataPoints,
        dataPointsSections: dataPoints.sections,
        requiredCounts: dataPoints.requiredCounts,
        status: dataPoints.status,
    };
}

export function updateDataEntryAccess(disabled: boolean, user: UserType): (dispatch: GenericDispatch) => any {
    return function (dispatch: GenericDispatch) {
        return Api.User.updateDataEntryAccess(disabled)
            .then(function (response) {
                if (response) {
                    if (user.settings && user.settings.access) {
                        user.settings.access.dataEntry = response;
                    }
                    return dispatch({
                        type: RECEIVE_USER_FROM_API,
                        user: user,
                    });
                }
            })
            .catch(function () {});
    };
}

export function updatePageAccess(iUser: UserType, _id: string, denyAccess: boolean, showMessage: boolean, message: string): (dispatch: GenericDispatch) => any {
    return function (dispatch: GenericDispatch) {
        const user = {
            ...iUser,
        };
        return Api.User.updatePagesForAccess(_id, denyAccess, showMessage, message)
            .then(function (response) {
                if (response) {
                    response.forEach(function (item) {
                        let id = item._id;
                        if (id && user.settings.access && item._id === _id) {
                            user.settings.access[id] = {
                                _id: id,
                                denyAccess: denyAccess,
                                showMessage: showMessage,
                                message: message,
                            };
                        }
                    });
                    return dispatch({
                        type: RECEIVE_USER_FROM_API,
                        user: user,
                    });
                }
            })
            .catch(function () {});
    };
}

export function setError(err: ReduxErrorType): any {
    return {
        type: SET_ERROR,
        error: err,
    };
}

export function unsetError(): any {
    return {
        type: UNSET_ERROR,
    };
}

export function initializeApp(): any {
    return (dispatch) => {
        dispatch(fetchUser());
    };
}

export function closeChromeWarning() {
    return {
        type: CLOSE_CHROME_WARNING,
    };
}

export function openChromeWarning() {
    return {
        type: OPEN_CHROME_WARNING,
    };
}
