// @flow
'use strict';

import { useState, useRef } from 'react';
import { connect } from 'react-redux';
import SchoolModal from '../SchoolModal';
import { Dialog, Button, DialogContent, Tooltip, Typography, DialogTitle, DialogContentText, DialogActions } from '@material-ui/core';
import { ContactMail } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { isNboaAdmin, isOnDataCommittee, getSelectedSchool, getParticipatingSchools, isAssociationAdmin } from '../../redux/reducers';
import { selectSchool, resetDataPoints, resetReporting } from '../../redux/actions';
import { copyTextToClipboard } from '../../utils/index';

type ReduxReducerProps = {|
    isAdmin: boolean,
    selectedSchool: ?SchoolType,
    participatingSchools: ?Array<PeergroupSchoolType>,
|};

type ReduxActionProps = {|
    selectSchool: (schoolId: string) => void,
    resetDataPoints: () => void,
    resetReporting: () => void,
|};

const useStyles = makeStyles(() => ({
    wrapper: {},
    white: {
        color: 'white',
    },
    schoolSelectButton: {
        border: 'none',
        backgroundColor: '#319BDD',
        height: '30px',
        marginTop: '5px',
        color: 'white',
        width: 'calc(100% - 64px)',
        minWidth: 'unset',
    },
    schoolName: {
        marginLeft: '10px',
        fontHeight: '14px',
    },
}));

type OwnProps = {|
    className?: string,
|};

type Props = {
    ...OwnProps,
    ...ReduxReducerProps,
    ...ReduxActionProps,
};

export function SchoolSelect(props: Props) {
    const { isAdmin, selectSchool, selectedSchool, participatingSchools, resetDataPoints, resetReporting, className = '' } = props;
    if (!selectedSchool) {
        return <div />;
    }

    const classes = useStyles();
    const contactRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [showContact, setShowContact] = useState(false);

    const lastUpdatedBy = selectedSchool ? selectedSchool.lastUpdatedBy : undefined;
    return (
        <div id='dataSetStatusContainer' className={className + ' ' + classes.wrapper}>
            {isAdmin && lastUpdatedBy
                ? [
                      <Dialog key='1' open={showContact}>
                          <DialogTitle>Contact School</DialogTitle>
                          <DialogContent>
                              <DialogContentText>{`School data was last updated by: ${lastUpdatedBy.fullName || lastUpdatedBy.name}`}</DialogContentText>
                              <DialogContentText>{`${lastUpdatedBy.email}`}</DialogContentText>
                              <DialogActions>
                                  <Button ref={contactRef} onClick={() => copyTextToClipboard(lastUpdatedBy.email)}>
                                      Copy Email Address
                                  </Button>
                                  <Button href={`mailto:${lastUpdatedBy.email}`} color='primary'>
                                      Email
                                  </Button>
                                  <Button onClick={() => setShowContact(false)} color='primary'>
                                      Close
                                  </Button>
                              </DialogActions>
                          </DialogContent>
                      </Dialog>,
                      <Tooltip key='2' title='Contact School'>
                          <Button className={classes.white} onClick={() => setShowContact(true)}>
                              <ContactMail className={classes.white} />
                          </Button>
                      </Tooltip>,
                  ]
                : null}
            {!isAdmin ? (
                <Typography className={classes.schoolName}>{selectedSchool.name}</Typography>
            ) : !participatingSchools ? null : (
                <Button
                    size='small'
                    className={classes.schoolSelectButton}
                    onClick={() => {
                        setShowModal(true);
                    }}
                >
                    <Typography className={classes.schoolName} noWrap={true}>
                        {selectedSchool ? selectedSchool.name : 'Select School'}
                    </Typography>
                </Button>
            )}
            {showModal && participatingSchools ? (
                <SchoolModal
                    schools={participatingSchools}
                    onSelectSchool={(schoolId) => {
                        resetDataPoints();
                        resetReporting();
                        selectSchool(schoolId);
                        setShowModal(false);
                    }}
                    close={() => setShowModal(false)}
                />
            ) : null}
        </div>
    );
}
/* istanbul ignore next */
const mapStateToProps: (state: ReduxStateType, ownProps: OwnProps) => ReduxReducerProps = (state) => {
    return {
        selectedSchool: getSelectedSchool(state),
        isAdmin: isNboaAdmin(state) || isOnDataCommittee(state) || isAssociationAdmin(state),
        participatingSchools: getParticipatingSchools(state),
    };
};
/* istanbul ignore next */
const mapDispatchToProps: (dispatch: GenericDispatch) => ReduxActionProps = (dispatch) => {
    return {
        selectSchool: (selectedSchoolId: string) => {
            dispatch(selectSchool(selectedSchoolId));
        },
        resetDataPoints: () => {
            dispatch(resetDataPoints());
        },
        resetReporting: () => {
            dispatch(resetReporting());
        },
    };
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(SchoolSelect);
