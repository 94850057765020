// @flow
'use strict';

import { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText,
    DialogTitle,
    Paper,
    CircularProgress,
} from '@material-ui/core';
import DataTable from '../DataTable';
import Api from '../../api';
import Draggable from 'react-draggable';
import {
    getSelectedSchoolId,
    getSelectedYear,
} from '../../redux/reducers';
import { useSelector } from 'react-redux';


type Props = {
    showRatios: boolean,
    close: () => void
};


function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

export const Ratios = ({ showRatios, close }: Props) => {
    const [ratios, setRatios] = useState();
    const [fetchingRatios, setFetchingRatios] = useState(false);
    const schoolId = useSelector(getSelectedSchoolId);
    const year = useSelector(getSelectedYear);
    

    useEffect(() => {
        if (!schoolId || !showRatios || ratios || fetchingRatios) {
            return;
        }
        setFetchingRatios(true);
        Api.Reports.fetchRatios(schoolId, year).then(data => {
            setRatios(data);
            setFetchingRatios(false);
        });
        
    }, [ratios, schoolId, showRatios, fetchingRatios]);

    if (!schoolId) {
        return;
    } 
    return <Dialog
        open={showRatios}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
    >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Subscribe
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                Loading Ratios.
            </DialogContentText>
            {fetchingRatios ? <CircularProgress /> : <DataTable dataTable={ratios} />}
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                setFetchingRatios(true);
                Api.Reports.fetchRatios(schoolId, year).then(data => {
                    setRatios(data);
                    setFetchingRatios(false);
                });
            }} color="primary">
                Refresh Ratios
            </Button>
            <Button autoFocus onClick={close} color="primary">
                Close
            </Button>
        </DialogActions>
    </Dialog>;
};

export default Ratios;