// @flow
'use strict';
import Api from '../../api';

import { setReportError } from './reports';

import {
    SET_LOADING,
    UNSET_LOADING,
    SET_FETCHING_DATA_TABLES,
    SET_DATA_TABLES,
    SET_FETCHING_DATA_TABLE,
    SET_DATA_TABLE,
    RESET_DATA_TABLE,
    UPDATE_DATA_TABLE_ROWS,
    UPDATE_DATA_TABLE_ROW,
    ADD_DATA_TABLE_ROW,
    DELETE_DATA_TABLE_ROW,
} from './constants';

export function updateDataTableRows(dataTableRows: ?Array<DataTableCreateRowType>): ReduxDataTablesActionType {
    return {
        type: UPDATE_DATA_TABLE_ROWS,
        dataTableRows,
    };
}

export function updateDataTableRow(index: number, dataTableRow: DataTableCreateRowType): ReduxDataTablesActionType {
    return {
        type: UPDATE_DATA_TABLE_ROW,
        dataTableRow,
        index,
    };
}

export function addRowToDataTable(dataTableRow: DataTableCreateRowType, bottom: boolean) {
    return {
        type: ADD_DATA_TABLE_ROW,
        dataTableRow,
        bottom,
    };
}

export function fetchDataTables(): (dispatch: GenericDispatch) => void {
    return function(dispatch) {
        dispatch({
            type: SET_LOADING,
        });
        dispatch({
            type: SET_FETCHING_DATA_TABLES,
            fetching: true,
        });
        Api.Reports.fetchDataTables()
            .then(function(dataTables) {
                dispatch({
                    type: SET_DATA_TABLES,
                    dataTables,
                });
            })
            .finally(function() {
                dispatch({
                    type: SET_FETCHING_DATA_TABLES,
                    fetching: false,
                });
                dispatch({
                    type: UNSET_LOADING,
                });
            });
    };
}

export function fetchDataTable(
    schoolId: string,
    dataTableId: string,
    selected: ?SelectedReportFilterType,
    originalSelectedFilters: ?SelectedReportFilterType,
): (dispatch: GenericDispatch) => void {
    return function(dispatch) {
        dispatch({
            type: SET_LOADING,
        });
        dispatch({
            type: SET_FETCHING_DATA_TABLE,
            fetching: true,
        });
        Api.Reports.fetchDataTable(schoolId, dataTableId, selected)
            .then(function(dataTable) {
                if (dataTable.name && !dataTable.id) {
                    dataTable.id = dataTableId;
                }
                dispatch({
                    type: SET_DATA_TABLE,
                    dataTable,
                });
            })
            .catch(function(err) {
                dispatch(
                    setReportError({
                        errorMessage: err.message,
                        originalSelectedFilters,
                        originalTopicId: null,
                    }),
                );
            })
            .finally(function() {
                dispatch({
                    type: SET_FETCHING_DATA_TABLE,
                    fetching: false,
                });
                dispatch({
                    type: UNSET_LOADING,
                });
            });
    };
}

export function resetDataTable() {
    return {
        type: RESET_DATA_TABLE,
    };
}

export function deleteDataTableRow(index: number) {
    return {
        type: DELETE_DATA_TABLE_ROW,
        index,
    };
}

export function fetchCustomDataTable(dataTable: DataTableCreateType): (dispatch: GenericDispatch) => void {
    return function(dispatch) {
        dispatch({
            type: SET_LOADING,
        });
        Api.Reports.fetchCustomDataTable(dataTable)
            .then(function(response) {
                dispatch({
                    type: SET_DATA_TABLE,
                    dataTable: response,
                });
            })
            .finally(function() {
                dispatch({
                    type: UNSET_LOADING,
                });
            });
    };
}
