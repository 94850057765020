// @flow
'use strict';

import { useEffect } from 'react';
import DashboardItem from '../../components/UserDashboardItem';
import { Paper, Grid, Card, CardContent, CardHeader, Typography, Button } from '@material-ui/core';
import { connect } from 'react-redux';

import parser from 'html-react-parser';
import { isFetchingReportData, getUserDashboard } from '../../redux/reducers';
import { fetchUserDashboard, resetDashboard } from '../../redux/actions';
import { makeStyles } from '@material-ui/styles';


type ReduxReducerProps = {|
    userDashboard: ?UserDashboardType,
    fetchingReportData: boolean,
|};

type ReduxActionProps = {|
    fetchUserDashboard: () => void,
    resetDashboard: () => void,
|};

type OwnProps = {||};

type Props = {|
    ...OwnProps,
    ...ReduxActionProps,
    ...ReduxReducerProps,
|};

const useStyles = makeStyles(() => ({
    paper: {
        height: '100%',
        width: '100%',
        minWidth: 1080,
        overflow: 'auto',
        margin: 0,
        padding: 0,
        background: 'none',
        flexGrow: 1,
    },
    grid: {
        justifyContent: 'center',
        width: '100%',
        margin: 0,
        padding: 0,
    },
    header: {
        background: 'rgba(0, 0, 0, 0.7)',
        height: 450,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'white',
        textAlign: 'center',
    },
    headerTitleWrapper: {
        marginTop: 125,
    },
    headerTitle: {
        fontWeight: 'bold',
        fontSize: 28,
    },
    headerContent: {
        '& p': {
            marginTop: 10,
            marginBottom: 10,
        },
    },
    item: {
        height: 396,
    },
}));


const parseText = (text: string): Array<React$Element<*>> => {
    if (text.indexOf('<') === -1) {
        return [<Typography key='1'>{text}</Typography>];
    }
    let parseText = parser(text);
    if (!Array.isArray(parseText)) {
        parseText = [parseText];
    }
    return parseText.map(function(item, index) {
        if (item.type === 'p') {
            return <Typography key={index}>{item.props.children}</Typography>;
        }
        if (item.type === 'a') {
            return (
                <Button key={index} href={item.props.href} variant='contained' color='primary'>
                    {item.props.children}
                </Button>
            );
        }
        return <div key={`spec-${index}`}>item</div>;
    });
};

export function UserDashboard(props: Props) {
    const { userDashboard, fetchingReportData, fetchUserDashboard, resetDashboard } = props;
    const classes = useStyles();
    useEffect(() => {
        if (!userDashboard && !fetchingReportData) {
            fetchUserDashboard();
        }
    }, [userDashboard, fetchingReportData]);

    useEffect(() => {
        return function cleanup() {
            resetDashboard();
        };
    }, []);



    const renderItems: (dashboard: UserDashboardType) => Array<React$Element<*>> = (dashboard) => {
        const items = dashboard.items;
        return items
            .sort((a, b) => a.order - b.order)
            .map(function(item, index) {
                {
                    /* let className = styles.componentElement;
                            if (item.width === 2) {
                                className += ' ' + styles.spanTwo;
                            }
                            if (item.width === 3) {
                                className += ' ' + styles.spanThree;
                            } */
                }
                return (
                    <Grid
                        item
                        xs={item.width === 3 ? 12 : item.width === 2 ? 8 : 4}
                        key={'itemkey' + index}
                        className={classes.item}
                    >
                        <DashboardItem item={item} />
                    </Grid>
                );
            });
    };

    if (!userDashboard) {
        return <div />;
    }
    let graphicStyle = {};
    if (userDashboard.headerGraphic) {
        graphicStyle = {
            backgroundImage: 'url(' + userDashboard.headerGraphic + ')',
        };
    }
    return (
        <Paper className={classes.paper}>
            {/* className={styles.userDashboard + ' ' + (userDashboard.user ? styles.forUser : styles.noUser)}> */}
            <Grid container spacing={3} className={classes.grid}>
                {/* className={styles.header}> */}
                <Grid item xs={12}>
                    {/* className={styles.textWrapper}> */}
                    <Card raised style={graphicStyle} className={classes.header}>
                        <CardHeader
                            className={classes.headerTitleWrapper}
                            classes={{ title: classes.headerTitle }}
                            title={userDashboard.title}
                        />
                        <CardContent className={classes.headerContent}>
                            {parseText(userDashboard.headerText)}
                        </CardContent>
                    </Card>
                </Grid>
                {renderItems(userDashboard)}
            </Grid>
        </Paper>
    );
}

/* istanbul ignore next */
const mapStateToProps: (state: ReduxStateType, ownProps: OwnProps) => ReduxReducerProps = (state) => {
    return {
        userDashboard: getUserDashboard(state),
        fetchingReportData: isFetchingReportData(state),
    };
};
/* istanbul ignore next */
const mapDispatchToProps: (dispatch: GenericDispatch, ownProps: OwnProps) => ReduxActionProps = (dispatch) => {
    return {
        fetchUserDashboard: () => {
            dispatch(fetchUserDashboard());
        },
        resetDashboard: () => {
            dispatch(resetDashboard());
        },
    };
};

export default connect<Props, OwnProps, _, _, _, _>(
    mapStateToProps,
    mapDispatchToProps,
)(UserDashboard);
