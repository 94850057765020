// @flow
'use strict';

import axios from 'axios';
import * as Utils from '../utils';

const Constants = Utils.Constants;

const BaseUrl = Constants.apiBaseUrl + 'bulkexport';

const BulkExport = {};

const getSavedConfigurations: () => Promise<Array<BulkExportType>> = () => {
    return axios
        .get(BaseUrl, {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Saved configurations returned from Api');
            }
            return response.data;
        });
};

BulkExport.getSavedConfigurations = getSavedConfigurations;

const getExportHistory: () => Promise<Array<BulkExportRunType>> = () => {
    return axios
        .get(`${BaseUrl}/history`, {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Saved exports returned from Api');
            }
            return response.data;
        });
};

BulkExport.getExportHistory = getExportHistory;

const saveBulkExportConfiguration: (data: BulkExportType, isUpdate?: boolean) => Promise<Array<BulkExportType>> = (data, isUpdate) => {
    let url = `${BaseUrl}/configuration${isUpdate ? `/${data._id}` : ''}`;

    return axios(url, {
        method: isUpdate ? 'PUT' : 'POST',
        data,
        withCredentials: true,
    }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('No Saved exports returned from Api');
        }
        return response.data;
    });
};

BulkExport.saveBulkExportConfiguration = saveBulkExportConfiguration;

const downloadBulkExport: (params: BulkExportType) => Promise<string> = (params) => {
    return axios.post(BaseUrl + '/download', params, { responseType: 'blob', withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('No Data returned from Api');
        }
        return response.data;
    });
};

BulkExport.downloadBulkExport = downloadBulkExport;

const scheduleBulkExport: (params: BulkExportType) => Promise<any> = (params) => {
    return axios.post(BaseUrl + '/schedule', params, { withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('No Data returned from Api');
        }
        return response.data;
    });
};

BulkExport.scheduleBulkExport = scheduleBulkExport;

const downloadSavedBulkExport: (exportId: string) => Promise<string> = (exportId) => {
    return axios.post(BaseUrl + '/download/saved', { exportId }, { responseType: 'blob', withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('No Data returned from Api');
        }
        return response.data;
    });
};

BulkExport.downloadSavedBulkExport = downloadSavedBulkExport;

const deleteBulkExportRuns: (runs: string[]) => Promise<string> = (runs) => {
    return axios.post(BaseUrl + '/delete', { runs }, { withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('Bad response from api for deleting export runs');
        }
        return response.data;
    });
};

BulkExport.deleteBulkExportRuns = deleteBulkExportRuns;

const deleteConfiguration: (configurationId: string) => Promise<string> = (configurationId) => {
    return axios.delete(BaseUrl + '/delete/' + configurationId, { withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('Bad response from api for deleting configuration');
        }
        return response.data;
    });
};

BulkExport.deleteConfiguration = deleteConfiguration;

export default BulkExport;
