// @flow
'use strict';

import {
    RECEIVE_USER_FROM_API,
    FETCHING_USER,
    USER_FETCH_FAILED,
    SELECTED_SCHOOL,
    RECEIVE_PEER_GROUP_SCHOOLS,
    RECEIVE_PEERGROUPS,
    RECEIVE_CANNED_PEERGROUPS,
    RESET_PEER_GROUP_SCHOOLS,
    FETCHING_PEERGROUP_SCHOOLS,
    FETCHING_SELECTED_SCHOOL,
    API_AUTH_ERR,
    SET_SELECTED_PEERGROUP_ID,
    SET_PEERGROUP_SCHOOL_FILTER,
    UPDATE_PEERGROUP,
    FETCHING_PEERGROUPS,
    FETCHING_CANNED_PEERGROUPS,
    RECEIVE_PARTICIPATING_SCHOOLS,
    SET_SCRUBBERS,
    SET_FETCHING_SCRUBBERS,
    SET_FETCHING_UNASSIGNED_SCHOOLS,
    SET_UNASSIGNED_SCHOOLS,
    SET_FETCHING_SCHOOLS,
    RECEIVE_SCHOOLS_BY_STATUS,
    USER_TOKEN,
    SET_SELECTED_CANNED_PEERGROUP_ID,
} from '../actions/constants';

export const defaultState = {
    fetchingUser: false,
    userFetchFailed: false,
    fetchingPeergroupSchools: false,
    fetchingPeergroups: false,
    apiAuthFailed: false,
    fetchingSelectedSchool: false,
    peergroupSchools: null,
    peergroupOrder: [],
    hasTriedAuthenticating: false,
    peergroupSchoolFilters: {},
    fetchingScrubbers: false,
    fetchingUnassignedSchools: false,
    fetchingSchools: false,
    cannedPeergroupSchools: null,
    cannedPeergroupOrder: [],
    fetchingCannedPeergroups: false,
};

const userInfo = (state: UserInfoStateType = defaultState, action: UserInfoActionType): UserInfoStateType => {
    switch (action.type) {
        case RECEIVE_PEERGROUPS: {
            const peergroups = {};
            const peergroupOrder = action.peergroups.map(function (peergroup: PeergroupType) {
                peergroups[peergroup._id] = peergroup;
                return peergroup._id;
            });
            return {
                ...state,
                peergroups,
                peergroupOrder,
                fetchingPeergroups: false,
                selectedPeergroupId: state.selectedPeergroupId || peergroupOrder[0],
            };
        }
        case FETCHING_PEERGROUPS:
            return {
                ...state,
                fetchingPeergroups: action.fetchingPeergroups,
            };
        case RECEIVE_CANNED_PEERGROUPS: {
            const cannedPeergroups = {};
            const cannedPeergroupOrder = action.cannedPeergroups.map(function (peergroup: PeergroupType) {
                cannedPeergroups[peergroup._id] = peergroup;
                return peergroup._id;
            });

            return {
                ...state,
                cannedPeergroups,
                cannedPeergroupOrder,
                fetchingCannedPeergroups: false,
                selectedCannedPeergroupId: state.selectedCannedPeergroupId,
            };
        }
        case FETCHING_CANNED_PEERGROUPS:
            return {
                ...state,
                fetchingCannedPeergroups: action.fetchingCannedPeergroups,
            };
        case SET_SELECTED_CANNED_PEERGROUP_ID:
            return {
                ...state,
                selectedCannedPeergroupId: action.selectedCannedPeergroupId || undefined,
            };
        case UPDATE_PEERGROUP: {
            const peergroups = {
                ...state.peergroups,
            };
            peergroups[action.peergroup._id] = action.peergroup;
            return {
                ...state,
                peergroups,
            };
        }
        case SET_SELECTED_PEERGROUP_ID:
            return {
                ...state,
                selectedPeergroupId: action.selectedPeergroupId || undefined,
                peergroupSchools: null,
                fetchingPeergroupSchools: false,
            };
        case SET_PEERGROUP_SCHOOL_FILTER: {
            let peergroupSchoolFilters = state.peergroupSchoolFilters || {};
            peergroupSchoolFilters[action.filterId] = action.value;
            return {
                ...state,
                peergroupSchoolFilters,
                peergroupSchools: null,
                fetchingPeergroupSchools: false,
            };
        }
        case FETCHING_SELECTED_SCHOOL:
            return {
                ...state,
                fetchingSelectedSchool: action.fetchingSelectedSchool,
            };
        case RECEIVE_USER_FROM_API: {
            if (!action.user) {
                return {
                    ...state,
                    user: undefined,
                    hasTriedAuthenticating: false,
                };
            }
            const updatedState = {
                user: action.user,
                fetchingUser: false,
                userFetchFailed: false,
                apiAuthFailed: false,
                hasTriedAuthenticating: true,
            };
            if (!action.user.nboaAdmin && action.user.organization) {
                // $FlowFixMe
                updatedState.selectedSchool = action.user.organization;
                // $FlowFixMe
                updatedState.selectedSchoolId = action.user.organization._id;
            }
            // $FlowFixMe
            return {
                ...state,
                ...updatedState,
            };
        }
        case FETCHING_USER:
            return {
                ...state,
                fetchingUser: action.fetchingUser || false,
                userFetchFailed: false,
            };
        case USER_FETCH_FAILED:
            return {
                ...state,
                fetchingUser: false,
                hasTriedAuthenticating: true,
                userFetchFailed: true,
                user: undefined,
            };
        case SELECTED_SCHOOL:
            return {
                ...state,
                selectedSchool: action.selectedSchool,
                selectedSchoolId: action.selectedSchool ? action.selectedSchool._id : undefined,
                fetchingSelectedSchool: false,
            };
        case RECEIVE_PEER_GROUP_SCHOOLS:
            return {
                ...state,
                peergroupSchools: action.peergroupSchools,
                fetchingPeergroupSchools: false,
            };
        case RESET_PEER_GROUP_SCHOOLS:
            return {
                ...state,
                peergroupSchools: null,
                peergroupSchoolFilters: {},
            };
        case FETCHING_PEERGROUP_SCHOOLS:
            return {
                ...state,
                fetchingPeergroupSchools: action.fetchingPeergroupSchools,
            };
        case API_AUTH_ERR:
            return {
                ...state,
                fetchingUser: false,
                apiAuthFailed: action.apiAuthFailed,
            };
        case RECEIVE_PARTICIPATING_SCHOOLS:
            return {
                ...state,
                participatingSchools: action.participatingSchools,
            };
        case SET_SCRUBBERS:
            return {
                ...state,
                scrubbers: action.scrubbers,
                scrubberOrder: action.scrubberOrder,
            };
        case SET_FETCHING_SCRUBBERS:
            return {
                ...state,
                fetchingScrubbers: action.fetching,
            };
        case SET_UNASSIGNED_SCHOOLS:
            return {
                ...state,
                unassignedSchools: action.unassignedSchools,
            };

        case SET_FETCHING_UNASSIGNED_SCHOOLS:
            return {
                ...state,
                fetchingUnassignedSchools: action.fetching,
            };
        case SET_FETCHING_SCHOOLS:
            return {
                ...state,
                fetchingSchools: action.fetching,
            };
        case RECEIVE_SCHOOLS_BY_STATUS:
            return {
                ...state,
                schoolsByStatus: action.schoolsByStatus,
            };
        case USER_TOKEN:
            if (action.token === state.token) {
                return state;
            }
            return {
                ...state,
                token: action.token,
            };
    }
    return state;
};

export default userInfo;

export const hasTriedAuthenticating = (state: UserInfoStateType): boolean => {
    return state.hasTriedAuthenticating;
};

export const getParticipatingSchools = (state: UserInfoStateType): ?Array<PeergroupSchoolType> => {
    return state.participatingSchools;
};
export const getUser = (state: UserInfoStateType): ?UserType => {
    return state.user;
};
export const getSelectedSchool = (state: UserInfoStateType): ?SchoolType => {
    return state.selectedSchool;
};
export const getFetchingUser = (state: UserInfoStateType): boolean => {
    return state.fetchingUser;
};
export const getSelectedSchoolId = (state: UserInfoStateType): ?string => {
    if (!state.selectedSchool) {
        return;
    }
    return state.selectedSchoolId;
};
export const getUserFetchFailed = (state: UserInfoStateType): boolean => {
    return state.userFetchFailed;
};
export const isFetchingSelectedSchool = (state: UserInfoStateType): boolean => {
    return state.fetchingSelectedSchool;
};
export const getPeergroups = (state: UserInfoStateType): ?{ [_id: string]: PeergroupType } => {
    return state.peergroups;
};

export const getPeergroupsOrder = (state: UserInfoStateType): Array<string> => {
    return state.peergroupOrder;
};

export const getCannedPeergroups = (state: UserInfoStateType): ?{ [_id: string]: PeergroupType } => {
    return state.cannedPeergroups;
};

export const getCannedPeergroupsOrder = (state: UserInfoStateType): Array<string> => {
    return state.cannedPeergroupOrder;
};

export const getPeergroupSchools = (state: UserInfoStateType): ?PeergroupSchoolResponseType => {
    return state.peergroupSchools;
};
export const isFetchingPeergroupSchools = (state: UserInfoStateType): boolean => {
    return state.fetchingPeergroupSchools;
};
export const hasApiAuthFailed = (state: UserInfoStateType): boolean => {
    return state.apiAuthFailed;
};
export const hasSeenChartWizard = (state: UserInfoStateType): boolean => {
    return state.user?.hasSeenWizard?.charts === true;
};
export const hasSeenDataEntryWizard = (state: UserInfoStateType): boolean => {
    return state.user?.hasSeenWizard?.dataEntry === true;
};
export const hasSeenPeergroupWizard = (state: UserInfoStateType): boolean => {
    return state.user?.hasSeenWizard?.peergroups === true;
};
export const isAssociationAdmin = (state: UserInfoStateType): boolean => {
    return !!(state.user && state.user.associationAdmin);
};
export const isNboaAdmin = (state: UserInfoStateType): boolean => {
    return !!(state.user && state.user.nboaAdmin);
};
export const isOnDataCommittee = (state: UserInfoStateType): boolean => {
    return !!(state.user && state.user.dataCommittee);
};
export const isPrimaryContact = (state: UserInfoStateType): boolean => {
    return !!(state.user && state.user.primaryContact);
};
export const userCanWriteNonConfidentialData = (state: UserInfoStateType): boolean => {
    return !!(state.user && state.user.permissions.indexOf('writeSchoolNonConfidentialData') === -1);
};
export const userCanWriteConfidentialData = (state: UserInfoStateType): boolean => {
    return !!(state.user && state.user.permissions.indexOf('writeSchoolConfidentialData') === -1);
};

export const getSelectedPeergroupId = (state: UserInfoStateType): ?string => {
    return state.selectedPeergroupId;
};

export const getSelectedPeergroup = (state: UserInfoStateType): ?PeergroupType => {
    const selectedPeergroupId = getSelectedPeergroupId(state);
    const peergroups = getPeergroups(state);
    const peergroupOrder = getPeergroupsOrder(state);
    if (!peergroups || peergroupOrder.length === 0) {
        return;
    }
    if (!selectedPeergroupId) {
        return peergroups[peergroupOrder[0]];
    }
    return peergroups[selectedPeergroupId];
};

export const getSelectedCannedPeergroupId = (state: UserInfoStateType): ?string => {
    return state.selectedCannedPeergroupId;
};
export const getSelectedCannedPeergroup = (state: UserInfoStateType): ?PeergroupType => {
    const selectedPeergroupId = getSelectedCannedPeergroupId(state);
    const peergroups = getCannedPeergroups(state);
    const peergroupOrder = getCannedPeergroupsOrder(state);
    if (!peergroups || peergroupOrder.length === 0) {
        return;
    }
    if (!selectedPeergroupId) {
        return peergroups[peergroupOrder[0]];
    }
    return peergroups[selectedPeergroupId];
};

export const isFetchingPeergroups = (state: UserInfoStateType): boolean => {
    return state.fetchingPeergroups;
};
export const isFetchingCannedPeergroups = (state: UserInfoStateType): boolean => {
    return state.fetchingCannedPeergroups;
};

export const getPeergroupSchoolFilters = (state: UserInfoStateType): { [id: ReportFilterKeyType]: ?string } => {
    return state.peergroupSchoolFilters;
};

export const getScrubbers = (state: UserInfoStateType): ?{ [id: string]: ScrubberType } => {
    return state.scrubbers;
};

export const getScrubberOrder = (state: UserInfoStateType): ?Array<string> => {
    return state.scrubberOrder;
};

export const isFetchingScrubbers = (state: UserInfoStateType): boolean => {
    return state.fetchingScrubbers;
};

export const getUnassignedSchools = (state: UserInfoStateType): ?Array<SchoolMainType> => {
    return state.unassignedSchools;
};

export const isFetchingUnassignedSchools = (state: UserInfoStateType): boolean => {
    return state.fetchingUnassignedSchools;
};

export const getSchoolsByStatus = (state: UserInfoStateType): ?SchoolStatusType => {
    return state.schoolsByStatus;
};

export const isFetchingSchools = (state: UserInfoStateType): boolean => {
    return state.fetchingSchools;
};
