// @flow
'use strict';

import { Component } from 'react';
import ReactDOM from 'react-dom';
import styles from './modal.scss';

type PropTypes = {
    children?: React$Element<*> | Array<React$Element<*>>,
    close: () => void,
    showClose?: boolean,
    className?: string,
    years?: boolean,
    modalQuestion?: boolean,
    modalMultiQuestion?: boolean,
};

export default class Modal extends Component<PropTypes, void> {
    props: PropTypes;
    portal: HTMLDivElement;
    node: HTMLDivElement;

    constructor(props: PropTypes) {
        super(props);
        this.portal = document.createElement('div');
        let className = props.modalQuestion
            ? styles.modalQuestion
            : props.modalMultiQuestion
                ? styles.modalMultiQuestion
                : styles.modal;
        this.portal.className = className;
        if (document.body) {
            document.body.appendChild(this.portal);
        }
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick: (event: SyntheticInputEvent<Node>) => void = (event) => {
        event.preventDefault();
        if (!this.node.contains(event.target)) {
            this.props.close();
        }
    };

    componentDidMount() {
        // $FlowFixMe
        document.addEventListener('click', this.handleClick);
    }

    componentWillUnmount() {
        // $FlowFixMe
        document.removeEventListener('click', this.handleClick);
        if (document.body) {
            document.body.removeChild(this.portal);
        }
    }

    render() {
        return ReactDOM.createPortal(
            <div
                ref={(node) => {
                    if (node) {
                        this.node = node;
                    }
                }}
                className={
                    (this.props.years ? styles.yearsModal : styles.content) + ' ' + (this.props.className || ' ')
                }
            >
                {this.props.showClose ? (
                    <button className={styles.closeButton} onClick={this.props.close}>
                        Close X
                    </button>
                ) : null}
                {this.props.children}
            </div>,
            this.portal,
        );
    }
}
