// @flow
'use strict';

import { createRef, Component } from 'react';

import Chart from '../Chart';
import styles from './chartComp.scss';

type PropTypes = {|
    unit: string,
    chartType: ChartType,
    data: ChartDataType,
    min?: ?number,
    className?: string,
    title: string,
    subheader: string,
    item?: DashboardItemType,
|};

export default class ChartComp extends Component<PropTypes, void> {
    props: PropTypes;

    chart: React$ElementRef<*>;

    constructor(props: PropTypes) {
        super(props);
        this.chart = createRef();
        this.toBase64Image = this.toBase64Image.bind(this);
    }

    toBase64Image: () => string = () => {
        if (this.chart.current) {
            return this.chart.current.toBase64Image();
        }
        return '';
    };

    render() {
        const props = this.props;
        let yAxes = [
            {
                ticks: {
                    padding: 10,
                    fontColor: '#000',
                    fontFamily: 'Roboto',
                    ticks: {},
                    callback: function (value) {
                        //, index, values) {

                        let label = '';
                        if (typeof value === 'number' && props.unit !== 'year') {
                            if (value >= 1000000) {
                                label = (value / 1000000).toLocaleString() + 'M';
                            } else if (value >= 10000) {
                                label = (value / 1000).toLocaleString() + 'K';
                            } else {
                                label = value.toLocaleString();
                            }
                        } else {
                            label = value;
                        }
                        if (props.unit) {
                            if (props.unit === 'dollar') {
                                label = '$ ' + label;
                            }
                            if (props.unit === 'percent') {
                                label = label + ' %';
                            }
                        }
                        return label;
                    },
                },
            },
        ];
        if (this.props.min !== undefined && this.props.min !== null) {
            // $FlowFixMe
            yAxes[0].ticks.suggestedMin = this.props.min;
        }
        const options = {
            scales: {
                yAxes: yAxes,
                xAxes: [],
            },
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                displayColors: false,
            },
            legend: {
                display: true,
            },
            chartArea: {
                backgroundColor: 'rgba(0, 0, 0, 1)',
            },
        };
        return (
            <div className={styles.wrapper + ' ' + (this.props.className || '')}>
                <Chart
                    style={{ backgroundColor: 'white' }}
                    redraw={true}
                    className={styles.chart}
                    ref={this.chart}
                    options={options}
                    data={props.data}
                    type={props.chartType}
                    title={this.props.title}
                    subheader={this.props.subheader}
                    item={this.props.item}
                />
            </div>
        );
    }
}
