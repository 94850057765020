// @flow
'use strict';

import axios from 'axios';
import * as Utils from '../utils';

const Constants = Utils.Constants;

const BaseUrl = Constants.apiBaseUrl + 'heatmap/';

const HeatMap = {};

const getSchoolHeatMap: (schoolId?: string) => Promise<Array<HeatMapIndicatorType>> = (schoolId) => {
    return axios
        .get(BaseUrl + 'school/' + schoolId, {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Dashboard returned from Api');
            }
            return response.data;
        });
};

HeatMap.getSchoolHeatMap = getSchoolHeatMap;

const getAdminHeatMap: () => Promise<Array<HeatMapIndicatorType>> = () => {
    return axios.get(BaseUrl + 'admin', { withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('No Dashboard returned from Api');
        }
        return response.data;
    });
};

HeatMap.getAdminHeatMap = getAdminHeatMap;

const saveHeatMapIndicator: (heatMapId: string, payload: any, type: string) => Promise<Array<HeatMapIndicatorType>> = (heatMapId, payload, type) => {
    return axios.put(`${BaseUrl}${heatMapId}${type === 'school' ? `?type=school` : ''}`, { ...payload }, { withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('No error saving heatmap Item=');
        }
        return response.data;
    });
};

HeatMap.saveHeatMapIndicator = saveHeatMapIndicator;

const getSchoolHeatMapData: (schoolId?: string) => Promise<HeatMapSchoolData> = (schoolId) => {
    return axios
        .get(BaseUrl + schoolId + '/data', {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Dashboard returned from Api');
            }
            return response.data;
        });
};

HeatMap.getSchoolHeatMapData = getSchoolHeatMapData;
export default HeatMap;
