// @flow
'use strict';

import axios from 'axios';
import * as Utils from '../utils';
const Constants = Utils.Constants;

const Schools = {};

const BaseUrl = Constants.apiBaseUrl + 'schools';

Schools.getParticipatingSchools = (): Promise<Array<PeergroupSchoolType>> => {
    return axios
        .get(Constants.apiBaseUrl + 'schools?participating=true', {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Dashboard returned from Api');
            }
            return response.data;
        });
};

Schools.getAllSchools = (): Promise<Array<PeergroupSchoolType>> => {
    return axios
        .get(Constants.apiBaseUrl + 'schools?all=true', {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Dashboard returned from Api');
            }
            return response.data;
        });
};

Schools.getSchoolById = (schoolId: string): Promise<SchoolMainType> => {
    return axios
        .get(Constants.apiBaseUrl + 'schools/' + schoolId, {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Dashboard returned from Api');
            }
            return response.data;
        });
};

Schools.getPeergroupSchools = (selectedFilters: { [_id: ReportFilterKeyType]: ?string }): Promise<PeergroupSchoolResponseType> => {
    let filters = {};
    Object.keys(selectedFilters).forEach(function (key) {
        var value = selectedFilters[key];
        if (value && value.trim() !== '') {
            filters[key] = value;
        }
    });
    return axios
        .get(Constants.apiBaseUrl + 'schools/peergroups', {
            params: filters,
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Dashboard returned from Api');
            }
            return response.data;
        })
        .catch((e) => {
            throw e;
        });
};

const downloadData: (schoolId: string) => Promise<string> = (schoolId) => {
    return axios
        .get(Constants.apiBaseUrl + 'schools/' + schoolId + '/answers', {
            responseType: 'text',
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Dashboard returned from Api');
            }
            return response.data;
        });
};

Schools.downloadData = downloadData;

Schools.downloadDataLink = (schoolId: string): string => {
    return Constants.apiBaseUrl + 'schools/' + schoolId + '/answers';
};

const getUnassignedSchools: () => Promise<Array<SchoolMainType>> = () => {
    return axios
        .get(Constants.apiBaseUrl + 'schools/unassigned', {
            withCredentials: true,
        })
        .then(function (response) {
            if (!response || !response.status || response.status >= 400) {
                throw new Error('No Dashboard returned from Api');
            }
            return response.data;
        });
};

Schools.getUnassignedSchools = getUnassignedSchools;

const getByStatus: () => Promise<SchoolStatusType> = () => {
    return axios.get(Constants.apiBaseUrl + 'schools/status', { withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('No schools returned from Api');
        }
        return response.data;
    });
};

Schools.getByStatus = getByStatus;

Schools.uploadCSV = (file: File, selectedSchoolId: string): Promise<*> => {
    const data = new FormData();
    data.append('file', file);
    return axios
        .post(Constants.apiBaseUrl + 'schools/' + selectedSchoolId + '/upload', data, {
            withCredentials: true,
        })
        .then(function () {});
};

const downloadReviewedStatusReports: (status: string) => Promise<string> = (status) => {
    return axios.get(BaseUrl + '/download/' + status, { responseType: 'blob', withCredentials: true }).then(function (response) {
        if (!response || !response.status || response.status >= 400) {
            throw new Error('No Data returned from Api');
        }
        return response.data;
    });
};

Schools.downloadReviewedStatusReports = downloadReviewedStatusReports;

export default Schools;
