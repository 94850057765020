// @flow
'use strict';
// import { RouterHistory, MemoryHistory } from 'history';
import { createElement } from 'react';
import OConstants from './constants';
export * as JoyrideSteps from './joyrideSteps';
import type { Element } from 'React';
export const Constants = OConstants;

//

export function getChromeVersion() {
    let pieces = navigator.userAgent.match(/Chrom(?:e|ium)\/([0-9]+)\.([0-9]+)\.([0-9]+)\.([0-9]+)/);
    if (pieces == null || pieces.length != 5) {
        return undefined;
    }
    pieces = pieces.map((piece) => parseInt(piece, 10));
    return {
        major: pieces[1],
        minor: pieces[2],
        build: pieces[3],
        patch: pieces[4],
    };
}

export function getLoginRedirectUrl(): string {
    const redirectUrlPrefix = `${OConstants.xWebBaseUrl}/DynamicPage.aspx?WebCode=LoginRequired&Site=NBOA&URL_success=`;
    const location: Location = window.location;
    const originalLocation = encodeURIComponent(location.pathname + location.search);
    const hostname = location.protocol + '//' + location.host;

    var urlSuccess = hostname + '/loginhandler?token={token}&redirectTo=' + originalLocation;

    var redirectUrl = redirectUrlPrefix + encodeURIComponent(urlSuccess);
    return redirectUrl;
}

export function getLogoutUrl(): string {
    const location: Location = window.location;
    const hostname = location.protocol + '//' + location.host;
    var redirectUrl = encodeURIComponent(hostname + '/logout');
    return `${OConstants.xWebBaseUrl}/Logout.aspx?RedirectURL=${redirectUrl}`;
}

export function getSectionSearchQuery(section: number, subsection: number): string {
    var searchURL = '?section=' + encodeURIComponent('' + section) + '&subsection=' + encodeURIComponent('' + subsection);
    return searchURL;
}

// export function searchQueryObject(history: typeof RouterHistory | typeof MemoryHistory): any {
export function searchQueryObject(history: any): any {
    var search = history.location.search;
    if (search.indexOf('?') === 0) {
        search = search.slice(1);
    }
    return parseQueryString(search);
}

// export function getSearchQueryLocation(selectedSectionIndex: number, selectedSubsectionIndex: number, history: typeof RouterHistory | typeof MemoryHistory): any {
export function getSearchQueryLocation(selectedSectionIndex: number, selectedSubsectionIndex: number, history: any): any {
    var path = history.location.pathname;
    var search = parseQueryString(history.location.search);
    search.section = '' + selectedSectionIndex;
    search.subsection = '' + selectedSubsectionIndex;
    const location = {
        path: path,
        search:
            '?' +
            Object.keys(search)
                .map((k) => k + '=' + encodeURIComponent(search[k]))
                .join('&'),
    };
    return location;
}

export function getChartsSearchQueryLocation(
    selectedFilters: { [title: string]: string | Array<string> },
    chartType: string,
    chartFilters: Array<any>,
    history: any,
    // history: typeof RouterHistory | typeof MemoryHistory,
): any {
    var path = history.location.pathname;
    var search = {};
    chartFilters.map(function (filter) {
        const index = filter.choices.findIndex(function (filterItem) {
            if (Array.isArray(selectedFilters[filter._id])) {
                return selectedFilters[filter._id].indexOf(filterItem._id) !== -1;
            } else {
                return filterItem._id === selectedFilters[filter._id];
            }
        });
        if (index === -1 || index == 0) {
            return;
        } else {
            search[filter.title] = '' + (Array.isArray(selectedFilters[filter._id]) ? selectedFilters[filter._id].toString().split(', ').join('-') : selectedFilters[filter._id]);
        }
    });
    if (chartType !== 'bar') {
        search['Chart Type'] = '' + chartType;
    }
    const location = {
        path: path,
        search:
            '?' +
            Object.keys(search)
                .map((k) => k + '=' + encodeURIComponent(search[k]))
                .join('&'),
    };
    return location;
}

/**
 * initialCanEdit determines if data entry should start as editable or not.
 *
 * Start with editing disabled if:
 *  - there is no user
 *  - the user is a scrubber or NBOA Admin
 *  - the selected year is not the current year
 *  - the status is Reviewed or Submitted
 *  - the current settings have data entry disabled
 * @method
 * @param  {UserType} user     [description]
 * @param  {?string} status   [description]
 * @param  {string} currYear [description]
 * @return {boolean}          [description]
 */
export function initialCanEdit(user: ?UserType, status: ?string, currYear: ?string): boolean {
    let canEdit = false;
    if (!user) {
        return canEdit;
    }
    if (!user.dataCommittee && !user.nboaAdmin) {
        return (
            parseInt(currYear || '') !== user.currentYear &&
            (!status || (status !== 'Reviewed' && status !== 'Submitted')) &&
            (!user.settings.access['dataEntry'] || !user.settings.access['dataEntry'].denyAccess)
        );
    }
    return false;
}

export function unformatValue(value: ?string): string {
    if (value === undefined || value === null || (typeof value === 'string' && value.trim() === '')) {
        return '';
    }
    let unformattedValue = typeof value === 'number' ? '' + value : value.slice(0);
    unformattedValue = unformattedValue.replace('$', '');
    unformattedValue = unformattedValue.replace('%', '');
    unformattedValue = unformattedValue.trim();
    return unformattedValue;
}

export function formatValue(format: DataFormatType, unit: ?DataUnitType, decimalPlaces: ?number, value?: number | string): string {
    if ((!value && value !== 0) || value === '') {
        return '';
    }

    let tmpValue = unformatValue('' + value);
    if (tmpValue.indexOf('.') === -1) {
        tmpValue = parseInt(tmpValue);
    } else if (format === 'integer') {
        tmpValue = Math.round(parseFloat(tmpValue));
    } else {
        tmpValue = parseFloat(tmpValue);
    }
    let formattedValue = '';
    if (unit === 'dollar') {
        formattedValue += '$';
    }
    if (format === 'integer') {
        formattedValue += tmpValue.toLocaleString();
        if (formattedValue.indexOf('.') !== -1) {
            formattedValue = formattedValue.slice(0, formattedValue.indexOf('.'));
        }
    } else {
        if (tmpValue === 0) {
            formattedValue = '0.0';
        } else {
            const negative = tmpValue < 0;
            tmpValue = tmpValue.toFixed(decimalPlaces || 1);

            formattedValue += parseInt(tmpValue.slice(0, tmpValue.indexOf('.'))).toLocaleString();
            if (negative && formattedValue[0] !== '-') {
                formattedValue = '-' + formattedValue;
            }
            formattedValue += '.';
            formattedValue += tmpValue.slice(tmpValue.indexOf('.') + 1);
            if (formattedValue.indexOf('.') === -1) {
                formattedValue += '.0';
            }
        }
    }
    if (unit === 'percent') {
        formattedValue += ' %';
    }
    if (unit === 'year') {
        formattedValue = formattedValue.replace(/,/g, '');
    }

    return formattedValue;
}

export function isEmpty(item: ?string): boolean {
    return !item || typeof item !== 'string' || item.trim() === '';
}

export function isLastElement(array: Array<any>, element: any): boolean {
    if (!array || !element || array.length === 0 || array.indexOf(element) === -1) {
        return false;
    }
    return array.indexOf(element) === array.length - 1;
}

export function isFirstElement(array: Array<any>, element: any): boolean {
    if (!array || !element || array.length === 0 || array.indexOf(element) === -1) {
        return false;
    }
    return array.indexOf(element) === 0;
}

export const unformatNumericStringValue = (value?: string): ?number => {
    if (value === undefined || value === null || value.trim() === '') {
        return;
    }
    let v = value.replace(/[\\$%,]/g, '').trim();
    if (v.includes('.')) {
        return parseFloat(v);
    }
    return parseInt(v);
};

export const formatNumericValueAsString = (value: ?number, format: 'integer' | 'float' | 'text' | 'year', unit?: 'dollar' | 'percent' | 'raw', decimalPlaces?: number): string => {
    if (value === undefined || value === null) {
        return '';
    }
    if (format === 'year') {
        return value.toLocaleString().replace(/,/g, '');
    }
    const negative = value < 0;
    const absValue = Math.abs(value);
    const wholeValue = format === 'integer' ? Math.abs(Math.round(value)) : Math.floor(absValue);
    const decimalValue = format === 'float' ? (absValue - wholeValue).toFixed(decimalPlaces) : '';

    return `${negative ? '-' : ''}${unit === 'dollar' ? '$' : ''}${wholeValue.toLocaleString()}${decimalValue.substring(1)}${unit === 'percent' ? '%' : ''}`;
};

/**
 * Decimal adjustment of a number.
 *
 * @param {String}  type  The type of adjustment.
 * @param {Number}  value The number.
 * @param {Integer} exp   The exponent (the 10 logarithm of the adjustment base).
 * @returns {Number} The adjusted value.
 */
export function decimalAdjust(type: string, value: number, exp: number): number {
    if (typeof exp === 'undefined' || +exp === 0) {
        return Math[type](value);
    }
    value = +value;
    exp = +exp;
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN;
    }
    if (value < 0) {
        return -decimalAdjust(type, -value, exp);
    }
    var valueArray = value.toString().split('e');
    value = Math[type](+(valueArray[0] + 'e' + (valueArray[1] ? +valueArray[1] - exp : -exp)));
    value = value.toString().split('e');

    return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp));
}

export function round10(value: number, exp: number): number {
    var exponent = Math.pow(10, exp);
    return Math.round(value * exponent) / exponent;
}

export function roundValue(answer: string, format: string, decimalPlaces?: number): string {
    var formattedValue, tempFloat;
    formattedValue = answer.indexOf('$') === 0 ? answer.slice(1) : answer;
    formattedValue = formattedValue.replace(/,/g, '');
    if (formattedValue !== '') {
        if (format === 'integer') {
            if (formattedValue.indexOf('.') === formattedValue.length - 1) {
                formattedValue = formattedValue + '0';
            }
            tempFloat = Math.round(parseFloat(formattedValue));
            formattedValue = tempFloat.toString();
        } else if (format === 'float' && decimalPlaces) {
            tempFloat = Math.round(parseFloat(formattedValue) * Math.pow(10, decimalPlaces)) / 10;
            formattedValue = tempFloat.toString();
            if (formattedValue.indexOf('.') === -1) {
                formattedValue = formattedValue + '.';
                for (var i = 0; i < decimalPlaces; i++) {
                    formattedValue = formattedValue + '0';
                }
            }
        }
    }
    return formattedValue;
}

const OPERATOR_ORDER = [
    ['&&', '||'],
    ['<', '>', '='],
    ['*', '/'],
];

export function stringFromValuesAndOperators(values: Array<any>, operators: Array<any>): string {
    let hasArray = false;
    let arrayIndex;
    let formula = '';
    let ifIndex = operators.indexOf('if');
    if (ifIndex > 0) {
        if (!Array.isArray(operators[ifIndex + 1]) || operators[ifIndex + 1].indexOf('else') === -1) {
            console.error('Error: If but no Else in Operators');
            throw new Error('If without an Else in the operators');
        }
    }
    operators.forEach(function (operator, operatorIndex) {
        if (hasArray) {
            return;
        }
        if (Array.isArray(operator)) {
            hasArray = true;
            arrayIndex = operatorIndex;
        }
    });
    if (hasArray) {
        let updatedValues = [];
        let updatedOperators = [];
        operators.forEach(function (operator, operatorIndex) {
            if (operatorIndex !== arrayIndex) {
                updatedValues.push(values[operatorIndex]);
                updatedOperators.push(operator);
                return;
            }
            updatedValues.push(stringFromValuesAndOperators(values[operatorIndex], operator));
        });
        return stringFromValuesAndOperators(updatedValues, updatedOperators);
    }
    if (values.length - 1 !== operators.length) {
        throw new Error('Mismatch between values and operators');
    }
    if (operators.length > 1) {
        let foundOperatorIndex = -1;
        OPERATOR_ORDER.forEach(function (orderedOperators, operatorIndex) {
            if (foundOperatorIndex !== -1) {
                return;
            }
            let innerOperatorIndex = -1;
            orderedOperators.forEach(function (innerOperator) {
                if (innerOperatorIndex !== -1) {
                    return;
                }
                innerOperatorIndex = operators.indexOf(innerOperator);
            });
            if (innerOperatorIndex !== -1) {
                foundOperatorIndex = operatorIndex;
            }
        });
        let updatedValues = [];
        let updatedOperators = [];
        if (foundOperatorIndex !== -1) {
            let innerOperatorOrder = OPERATOR_ORDER[foundOperatorIndex];
            let foundInnerOperatorIndex = -1;
            operators.forEach(function (operator, operatorIndex) {
                if (foundInnerOperatorIndex !== -1) {
                    return;
                }
                if (innerOperatorOrder.indexOf(operator) !== -1) {
                    foundInnerOperatorIndex = operatorIndex;
                }
            });
            operators.forEach(function (operator, operatorIndex) {
                if (operatorIndex < foundInnerOperatorIndex) {
                    updatedOperators.push(operator);
                    updatedValues.push(values[operatorIndex]);
                    return;
                }
                if (operatorIndex > foundInnerOperatorIndex) {
                    updatedOperators.push(operator);
                    updatedValues.push(values[operatorIndex + 1]);
                    return;
                }
                let updatedValue = values[operatorIndex];
                let nextValue = values[operatorIndex + 1];
                updatedValues.push(stringFromValuesAndOperators([updatedValue, nextValue], [operator]));
            });
            return stringFromValuesAndOperators(updatedValues, updatedOperators);
        }
    }
    let value = values[0];
    operators.forEach(function (operator, operatorIndex) {
        let rightValue = values[operatorIndex + 1];
        switch (operator) {
            case 'if':
                formula = 'if ' + value + ' {\n\t' + rightValue;
                break;
            case 'else':
                formula = value + '\t\n } else {\n\t' + rightValue + '\n}';
                break;
            case '<':
                formula = '(' + value + '<' + rightValue + ')';
                break;
            case '>':
                formula = '(' + value + '>' + rightValue + ')';
                break;
            case '||':
                formula = '(' + value + '||' + rightValue + ')';
                break;
            case '&&':
                formula = '(' + value + '&&' + rightValue + ')';
                break;
            case '=':
                formula = '(' + value + '=' + rightValue + ')';
                break;
            case '+':
                formula = '(' + value + '+' + rightValue + ')';
                break;
            case '-':
                formula = '(' + value + '-' + rightValue + ')';
                break;
            case '*':
                formula = '(' + value + '*' + rightValue + ')';
                break;
            case '/':
                formula = '(' + value + '/' + rightValue + ')';
                break;
        }
    });
    return formula;
}

export const COLOR_ARRAY = ['#1f78b4', '#33a02c', '#C03634', '#ff7f00', '#6a3d9a', '#a6cee3', '#b2df8a', '#fb9a99', '#fdbf6f', '#cab2d6'];

export function colorForIndex(index: number): string {
    return COLOR_ARRAY[index];
}

export function shouldArrayUpdate(prev: ?Array<any>, next: ?Array<any>, compare: (prevObj: any, nextObj: any) => boolean): boolean {
    let returnVal = false;
    if ((Array.isArray(prev) && !Array.isArray(next)) || (Array.isArray(next) && !Array.isArray(prev))) {
        returnVal = true;
    } else if (Array.isArray(prev) && Array.isArray(next)) {
        if (prev.length !== next.length) {
            returnVal = true;
        } else if (prev.length === 0 && next.length === 0) {
            returnVal = false;
        } else {
            prev.forEach(function (item, index) {
                if (compare(item, Array.isArray(next) && next.length > index ? next[index] : undefined)) {
                    returnVal = true;
                }
            });
        }
    } else if (!Array.isArray(prev) && !Array.isArray(next)) {
        returnVal = prev !== next;
    }
    return returnVal;
}

export function makeCancelable(promise: Promise<any>): Cancelable {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            (val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
            (error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error)),
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
    };
}

export function parseQueryString(queryString: string): Object {
    let queryObject = {};
    if (!queryString) {
        return queryObject;
    }
    (queryString.startsWith('?') ? queryString.substring(1) : queryString).split('&').forEach(function (stringPair) {
        let [key, value] = stringPair.replace(/\+/g, ' ').split('=');
        if (value !== undefined) {
            value = decodeURIComponent(value);
            queryObject[key] = value;
        }
    });
    return queryObject;
}

export function stringifyQueryObject(queryObject: Object): string {
    return Object.keys(queryObject)
        .map((k) => k + '=' + encodeURIComponent(queryObject[k]))
        .join('&');
}

export function getModifiedSearchQuery(currentQuery: string, objToAdd: Object): string {
    const updatedQueryObject = {
        ...parseQueryString(currentQuery),
        ...objToAdd,
    };

    return stringifyQueryObject(updatedQueryObject);
}

export function copyTextToClipboard(text: string): void {
    const el = document.createElement('span');

    el.textContent = text;
    const body = document.body;
    if (!body) {
        return;
    }
    body.appendChild(el);
    const range = document.createRange();
    range.selectNodeContents(el);
    const selection = document.getSelection();
    if (!selection) {
        return;
    }
    selection.addRange(range);

    document.execCommand('copy');
    body.removeChild(el);
}

export const formatName = (originalName: ?string, originalYear: number | string): string => {
    if (!originalName) {
        return '';
    }
    let name = originalName.slice();
    if (!name.includes('{{')) {
        return name;
    }
    const year: number = typeof originalYear === 'string' ? parseInt(originalYear) : originalYear;
    return name.replace('{{y}}', '' + year).replace(/{{y ?([+-]) ?(.*)}}/, (match, p1, p2) => {
        const modifier = parseInt(p2);
        if (p1 === '+') {
            return `${year + modifier}`;
        }
        return `${year - modifier}`;
    });
};

export const encodeElementString = (element: string): Element<any> => {
    return createElement('div', {}, element); //element.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
};
