// @flow
'use strict';

//import ReactDOM from 'react-dom';
import styles from './tableComp.scss';
import * as Utils from '../../utils';

type PropTypes = {|
    data: Array<Array<string | number>>,
    unit: string,
    format: string,
    type: string,
    decimalPlaces: number,
    topic?: ReportTopicType,
|};

export default function TableComp(props: PropTypes) {
    const data = props.data;
    const topic = props.topic;
    return (
        <>
            <table className={styles.table}>
                <tbody>
                    {data.map(function (array, index) {
                        return (
                            <tr key={'row-' + data.indexOf(array)} className={index === 0 ? styles.headerRow : styles.normalRow}>
                                {array.map(function (item, innerIndex) {
                                    let value = item;
                                    if (value !== 'N/A' && index !== 0 && innerIndex > 0 && topic && data[0][innerIndex] !== '# of Schools' && (index !== 1 || props.type === 'numeric')) {
                                        if (props.format === 'integer') {
                                            if (typeof value === 'string') {
                                                value = parseInt(value);
                                            } else {
                                                value = parseInt('' + value);
                                            }
                                            if (props.unit === 'year') {
                                                value = '' + value;
                                            } else {
                                                value = value.toLocaleString();
                                            }
                                        } else {
                                            if (typeof value === 'string' && !isNaN(value)) {
                                                value = parseFloat(value);

                                                value = Utils.round10(value, props.decimalPlaces || 1);
                                                value = '' + value;
                                                if (value.indexOf('.') === -1 && topic && props.decimalPlaces) {
                                                    value = value + '.';
                                                    if (topic) {
                                                        for (let i = 0; i < props.decimalPlaces; i++) {
                                                            value += '0';
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        if (props.unit === 'dollar') {
                                            value = '$ ' + value;
                                        }
                                        if (props.unit === 'percent') {
                                            value = value + ' %';
                                        }
                                    }
                                    return <td key={'row-' + data.indexOf(array) + '-column-' + array.indexOf(item) + innerIndex}>{value}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
}
